import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table, TableColumnsType, Typography } from 'antd';
import TenantInfo from '../../../../components/TenantIdInfo';
import { triggerTypeSelectOptionForRewardHistory, viewRewardHistoryData } from '../../data';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomPagination from '../../../../components/custom-pagination';
import _ from '../../../../helpers/lodash';
import { convertQueryStringToObj, objectHelpers } from '../../../../helpers';
import { useLoader } from '../../../../stores/use-loader';
import { loyaltyService } from '../../services/loyalty.service';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import dayjs from 'dayjs';
import { IRewardData, IRewardHistoryResponse } from '../../types/reward-history';
import ActionButton from '../../../../components/ActionButton';

interface IRewardHistoryProps {}

const RewardHistory: React.FunctionComponent<IRewardHistoryProps> = props => {
  const [form] = Form.useForm();

  const [rewardHistory, setRewardHistory] = React.useState({} as IRewardHistoryResponse);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    if (!_.isEmpty(queryStringObj)) {
      let formParams: any = { ...queryStringObj };
      if (queryStringObj.reward_from) {
        const date_range = [dayjs(queryStringObj.reward_from), dayjs(queryStringObj.reward_to)];
        formParams = { ...formParams, date_range };
      }
      form.setFieldsValue(formParams);
    }
    const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
    await handleSearch(offset);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = form.getFieldsValue();
    let params = { ...formValues, offset };
    if (formValues.date_range) {
      let reward_from = formValues.date_range[0].format('YYYY-MM-DD');
      let reward_to = formValues.date_range[1].format('YYYY-MM-DD');
      delete params.date_range;
      params = { ...params, reward_from, reward_to };
    }
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);

    const { data, errors } = await loyaltyService.getRewardHistory(filteredParams);

    if (_.isEmpty(errors)) {
      setRewardHistory(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const columns: TableColumnsType<IRewardData> = [
    {
      title: 'Customer',
      render(value, record, index) {
        return <>{record.customer_id}</>;
      },
      align: 'center'
    },
    {
      title: 'Reward Name',
      render(value, record, index) {
        return <>{record.reward_name}</>;
      },
      align: 'center'
    },
    {
      title: 'Reward Type',
      render(value, record, index) {
        return <>{record.reward_type}</>;
      },
      align: 'center'
    },
    {
      title: 'Active',
      render(value, record, index) {
        return <>{record.active || 0}</>;
      },
      align: 'center'
    },
    {
      title: 'Redeemed',
      render(value, record, index) {
        return <>{record.redeemed || 0}</>;
      },
      align: 'center'
    },
    {
      title: 'Pending',
      render(value, record, index) {
        return <>{record.pending || 0}</>;
      },
      align: 'center'
    },
    {
      title: 'Expired',
      render(value, record, index) {
        return <>{record.expired || 0}</>;
      },
      align: 'center'
    },
    {
      title: <></>,
      render(value, record, index) {
        return (
          <ActionButton
            action="VIEW"
            title="View Details"
            onClick={() => handleClickDetail(record.customer_id, record.reward_name)}
          ></ActionButton>
        );
      },
      align: 'center'
    }
  ];

  const handleClickDetail = (customer_id: string, reward_name: string) => {
    const backUri = window.location.pathname + `?${searchParams.toString()}`;
    const params = { ...queryStringObj, offset: undefined, customer_id, reward_name, backUri };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    const paramString = new URLSearchParams(filteredParams).toString();

    navigate(`/loyalty/reports/customer/detail?${paramString}`);
  };

  const handlePageChange = (currentPage: number) => {
    handleSearch(currentPage - 1);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Reward History
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <section className="mt-4">
            <Form layout="vertical" onFinish={() => handleSearch(0)} form={form}>
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item name="loyality_type" label="Loyalty Type">
                    <Select
                      allowClear
                      placeholder="Select loyalty Type"
                      size="large"
                      onChange={() => form.setFieldValue('applicablity_type', null)}
                      options={triggerTypeSelectOptionForRewardHistory}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                      return prevValues.loyality_type !== curValues.loyality_type;
                    }}
                  >
                    {form => {
                      const { loyality_type } = form.getFieldsValue();
                      const options = viewRewardHistoryData[loyality_type]?.applicabilityTypes || [];

                      return (
                        <Form.Item name="applicablity_type" label="Applicability Type">
                          <Select
                            allowClear
                            placeholder="Select Applicablity Type"
                            notFoundContent={<>Select a Loyalty type to view options</>}
                            size="large"
                            options={options}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>{' '}
                <Col xs={24} md={6}>
                  <Form.Item name="status" label="Status">
                    <Select
                      allowClear
                      placeholder="Select Status"
                      size="large"
                      options={[
                        { label: 'Initiated', value: 'INITIATED' },
                        { label: 'Active', value: 'ACTIVE' },
                        { label: 'Expired', value: 'EXPIRED' },
                        { label: 'Redeemed', value: 'REDEEMED' }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="customer_id" label="Customer ID">
                    <Input placeholder="Enter customer id" size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="channel" label="Channel">
                    <Select
                      allowClear
                      placeholder="Select Channel"
                      size="large"
                      options={[{ label: 'Online', value: 'ONLINE' }]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="date_range" label="Reward date">
                    <DatePicker.RangePicker size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item label={<></>}>
                    <Button type="primary" block size="large" htmlType="submit">
                      <BoldButtonLabel labelText="Search" />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>{' '}
            <section className="mt-4">
              {!_.isEmpty(rewardHistory.data) && (
                <div className="flex justify-end my-2">
                  <CustomPagination
                    currentPage={rewardHistory.page_info.current_page}
                    totalPages={rewardHistory.page_info.total_pages}
                    handleNext={handlePageChange}
                    handlePageChange={handlePageChange}
                    handlePrevious={handlePageChange}
                  />
                </div>
              )}{' '}
              <Table
                pagination={false}
                bordered
                dataSource={rewardHistory.data}
                columns={columns}
                scroll={{ x: 1000 }}
              ></Table>
            </section>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default RewardHistory;
