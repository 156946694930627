import { Button, Card, Col, Form, Input, Row, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import * as React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import TenantInfo from '../../components/TenantIdInfo';
import CustomPagination from '../../components/custom-pagination';
import { convertQueryStringToObj } from '../../helpers';
import _ from '../../helpers/lodash';
import { displayErrorNotifications } from '../../helpers/toast.helpers';
import { useNodes } from '../../hooks/useNodes';
import PrimaryLayout from '../../layouts/primary-layout';
import { inventoryService } from '../../services';

const PAGE_SIZE = 10;

interface IInventoryPageProps { }

const InventoryPage: React.FunctionComponent<IInventoryPageProps> = props => {

  const columns: ColumnsType<any> = [
    {
      title: 'SKU',
      render(value, record, index) {
        return <Link
          to={`/inventory/details/${record.sku}?from_query=${encodeURIComponent(
            JSON.stringify(convertQueryStringToObj(searchParams.toString()))
          )}`}
        >
          {record.sku}
        </Link>
      },
      align: 'center'
    },
    {
      title: 'Total Nodes Configured',
      render(value, record, index) {
        return (
          <Typography.Text className="text-[#5e5f66]">{record.total_nodes_configured}</Typography.Text>);
      },
      align: 'center'
    },
    {
      title: <Typography className='text-[#ffc000]'>Tags</Typography>,
      align: 'center',
      children: [
        {
          title: <Typography className='text-[#ffc000] min-w-[70px]'>Is Tag Controlled</Typography>,
          render(value, record, index) {
            let tag_controll = JSON.stringify(record.is_tag_controlled)
            return <Typography.Text className="text-[#5e5f66]">{tag_controll}</Typography.Text>;
          },
          align: 'center'
        },
        {
          title: <Typography className='text-[#ffc000] min-w-[70px]'>Tag Type</Typography>,
          render(value, record, index) {
            return <Typography.Text className="text-[#5e5f66]">{record.tag_type}</Typography.Text>;
          },
          align: 'center'
        },
        {
          title: <Typography className='text-[#ffc000]'>Tag ID</Typography>,
          render(value, record, index) {
            return <>{record.tag_id.join(', ')}</>;
          },
          align: 'center'
        }
      ]
    },
    {
      title: <Typography className='text-[#800080]'>Supply</Typography>,
      align: 'center',
      children: [
        {
          title: <Typography className='text-[#800080] min-w-[60px]'>On Order Stock</Typography>,
          render(value, record, index) {
            return <Typography.Text className="text-[#5e5f66]">{record.on_order_stock}</Typography.Text>;
          },
          align: 'center'
        },
        {
          title: <Typography className='text-[#800080] min-w-[60px]'>In Transit Stock</Typography>,
          render(value, record, index) {
            return <Typography.Text className="text-[#5e5f66]">{record.in_transit_stock}</Typography.Text>;
          },
          align: 'center'
        },
        {
          title: <Typography className='text-[#800080] min-w-[60px]'>On Hand Stock</Typography>,
          render(value, record, index) {
            return <Typography.Text className="text-[#5e5f66]">{record.on_hand_stock}</Typography.Text>;
          },
          align: 'center'
        },
        {
          title: <Typography className='text-[#800080] min-w-[60px]'>Damaged Stock</Typography>,
          render(value, record, index) {
            return <Typography.Text className="text-[#5e5f66]">{record.damaged_stock}</Typography.Text>;
          },
          align: 'center'
        },
      ]
    },
    {
      title: <Typography className='text-[#FF5733] min-w-[60px]'>Demand</Typography>,
      align: 'center',
      children: [
        {
          title: <Typography className='text-[#FF5733] min-w-[70px]'>Reserved Stock</Typography>,
          render(value, record, index) {
            return <Typography.Text className="text-[#5e5f66]">{record.reserved_stock}</Typography.Text>;
          },
          align: 'center'
        },
        {
          title: <Typography className='text-[#FF5733] min-w-[60px]'>Demand Stock</Typography>,
          render(value, record, index) {
            return <Typography.Text className="text-[#5e5f66]">{record.demand_stock}</Typography.Text>;
          },
          align: 'center'
        }
      ]
    },
    {
      title: 'Safety Factor',
      render(value, record, index) {
        return <Typography.Text className="text-[#5e5f66]">{record.safety_factor}</Typography.Text>;
      },
      align: 'center'
    },
    {
      title: 'Seasonal Stock',
      render(value, record, index) {
        return <Typography.Text className="text-[#5e5f66]">{record.seasonal_stock}</Typography.Text>;
      },
      align: 'center'
    },

    {
      title: <>History</>,
      align: 'center',
      render: (_, record) => (
        <Link
          to={`/inventory/history?id_type=SKU_ID&id=${record.sku}&from_query=${encodeURIComponent(
            JSON.stringify(convertQueryStringToObj(searchParams.toString()))
          )}`}
        >
          <Button type="link">View</Button>
        </Link>
      )
    }
  ];

  const [form] = Form.useForm<any>();

  const [tableKey, setTableKey] = React.useState(0);
  const [tableLoading, setTableLoading] = React.useState(false);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const { nodes } = useNodes();

  const handleFilterSearch = async (pageNumber: number = 1) => {
    setSearchLoading(true);
    setTableLoading(true);
    const { category, node_id, vendor, unlimited_stock, sku } = form.getFieldsValue();
    const queryParams: { [key: string]: any } = { category, node_id, vendor, unlimited_stock, sku };

    const filteredQueryParams: { [key: string]: any } = { page_number: pageNumber, page_size: PAGE_SIZE };

    Object.keys(queryParams).forEach((key: string) => {
      if (queryParams[key]) {
        filteredQueryParams[key] = queryParams[key];
      }
    });

    setSearchParams(filteredQueryParams);
    const { data, errors } = await inventoryService.getInventoryListing(filteredQueryParams);

    if (!_.isEmpty(errors)) displayErrorNotifications(errors);
    else {
      setInventoryData(data)
    };

    setSearchLoading(false);
    setTableLoading(false);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const [inventoryData, setInventoryData] = React.useState<any>({} as any);

  const loadExistingDataFromQueryParams = async () => {
    const queryString = searchParams.toString();
    const queryStringObj = convertQueryStringToObj(queryString);
    if (!_.isEmpty(queryStringObj)) {
      form.setFieldsValue(queryStringObj);
      const pageNumber = queryStringObj.page_number ? parseInt(queryStringObj.page_number) : 1;
      await handleFilterSearch(pageNumber);
    }
  };

  React.useEffect(() => {
    handleFilterSearch()
    loadExistingDataFromQueryParams();
  }, []);

  const handleClearFilter = () => {
    setTableKey(tableKey => tableKey + 1);
    setSearchParams({});
    setInventoryData({} as any);
    form.resetFields();
  };

  const handleNext = async (currentPage: number) => {
    await handleFilterSearch(currentPage);
  };
  const handlePrevious = async (currentPage: number) => {
    await handleFilterSearch(currentPage);
  };
  const handlePageChange = async (currentPage: number) => {
    await handleFilterSearch(currentPage);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mt-4">
            <Col>
              <Typography.Title level={2} className="text-[#2e2a5b]">
                Inventory
              </Typography.Title>
            </Col>
            {/* <Col>
              <Space>
                <Button type="primary" size="large">
                Import
              </Button>
                <Button type="primary" size="large">
                  Download
                </Button>
              </Space>
            </Col> */}
          </Row>
          {/* <TenantInfo /> */}
          <div className="mb-4">
            <Typography.Text className="text-[#83819c]">
            Track & Manage Inventory by node , by seller{' '}
            </Typography.Text>
          </div>
          <Form onFinish={() => handleFilterSearch()} form={form} layout="vertical">
            {/* <Row className="margin-top-4" gutter={16}>
              <Col lg={5} xs={24}>
                {' '}
                <Form.Item label="Node" name="node_id">
                  <Select
                    placeholder="Select Node"
                    size="large"
                    options={nodes.map(node => ({ label: node.name, value: node.id }))}
                  ></Select>
                </Form.Item>
              </Col> */}
            {/* <Col lg={5} xs={24}>
              <Form.Item label="Vendor / Seller" name="vendor">
                <Select
                  placeholder="Vendor / Seller"
                  size="large"
                  options={[{ value: 'vendor1', label: 'Vendor1' }]}
                ></Select>
              </Form.Item>
            </Col> */}
            {/* <Col lg={5} xs={24}>
              {' '}
              <Form.Item label="Unlimited Stock" name="unlimited_stock">
                <Select
                  placeholder="Select Unlimited stock"
                  size="large"
                  options={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' }
                  ]}
                ></Select>
              </Form.Item>
            </Col> */}

            {/* <Col lg={3} xs={24}>
                <div className="flex items-center h-full">
                  <Button htmlType="submit" size="large" block type="primary">
                    Apply
                  </Button>
                </div>
              </Col>
              <Col lg={3} xs={24}>
                <div className="flex items-center h-full">
                  <Button onClick={handleClearFilter} size="large" block>
                    Clear
                  </Button>
                </div>
              </Col> */}
            {/* </Row>{' '} */}
            {!_.isEmpty(inventoryData) && (
              <>
                <Row justify={'space-between'} className="mt-4 gap-4">
                  <Col xs={24} lg={8}>
                    <Form.Item name="sku">
                      <Input.Search onSearch={() => handleFilterSearch()} placeholder="Search SKU" size="large" enterButton />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form>
          <section>
            {!_.isEmpty(inventoryData) && (
              <div className="flex justify-end">
                <CustomPagination
                  currentPage={inventoryData.page_info.current_page}
                  totalPages={inventoryData.page_info.total_pages}
                  handleNext={handleNext}
                  handlePageChange={handlePageChange}
                  handlePrevious={handlePrevious}
                />
              </div>
            )}
            <Table
              loading={tableLoading}
              key={tableKey}
              className="mt-4"
              pagination={false}
              bordered
              dataSource={inventoryData.items}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default InventoryPage;
