import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import * as React from 'react';
import _ from '../../../../helpers/lodash';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface IRewardForm1Props {
  initialValues?: any;
  onFinish?: () => void;
  formInstance: FormInstance<any>;
  disabled?: boolean;
}

const RewardForm: React.FunctionComponent<IRewardForm1Props> = ({
  formInstance,
  onFinish,
  initialValues,
  disabled
}) => {
  const isUpdate = !_.isEmpty(initialValues);

  React.useEffect(() => {
    if (isUpdate) formInstance.setFieldsValue(initialValues);
  }, [initialValues]);
  const navigate = useNavigate();
  const labelText = isUpdate ? 'Update' : 'Save';

  const [searchParams] = useSearchParams();

  return (
    <Form form={formInstance} layout="vertical" className="mt-4" onFinish={onFinish}>
      <Row gutter={12}>
        <Col xs={24} md={8} lg={6}>
          <Form.Item
            name={'reward_type'}
            label="Reward Type"
            rules={[{ message: 'Reward type is required', required: true }]}
          >
            <Select
              disabled={isUpdate}
              placeholder="Reward type"
              size="large"
              options={[
                { label: 'Points', value: 'POINTS' },
                { label: 'Cash', value: 'CASH' }
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} lg={6}>
          <Form.Item
            name={'name'}
            label="Reward Name"
            rules={[
              { message: 'Reward name is required', required: true },
              { max: 40, message: 'Max limit is 40 characters' }
            ]}
          >
            <Input disabled={isUpdate} placeholder="Enter Reward Name" size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={8} lg={6}>
          <Form.Item
            name={'description'}
            label="Reward Description"
            rules={[
              { message: 'Reward type is required', required: true },
              { max: 100, message: 'Max limit is 100 characters' }
            ]}
          >
            <Input.TextArea placeholder="Enter Reward Name" size="large" />
          </Form.Item>
        </Col>
      </Row>

      {!disabled && (
        <Row gutter={12}>
          <Col xs={24} md={8} lg={6}>
            <Button block htmlType="submit" size="large" type="primary">
              <BoldButtonLabel labelText={labelText} />{' '}
            </Button>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <Button
              block
              size="large"
              onClick={() => {
                const backUrl = searchParams.get('backUrl');
                if (backUrl) navigate(backUrl);
                else navigate(`/loyalty/config/rewards`);
              }}
            >
              <BoldButtonLabel labelText={'Cancel'} />{' '}
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default RewardForm;
