import { countryCodeMap, countryStateMap } from '../data';
import { ICreateNodeRequestType } from '../types/nodes.types';
import { formatSingleDate, formatTimeRange, formatTimeSingle } from './date.helpers';

export const mapNodeFormValuesToRequestDto = (formValues: any, userCurrency: string) => {
  const {
    node_name,
    short_name,
    org_code,
    node_type,
    roles,
    is_operator_managed,
    operator_id,
    timezone,
    lead_time_in_days,
    shipping_lead_time_in_days,
    business_email,
    packages,
    is_package_provided,
    notification_time_in_minutes,
    inventory_execution_lead_time_in_minutes,
    locality_range_in_km,
    currency,
    priority,
  } = formValues;

  const services = (formValues?.services || []).map((item: { service: string }) => item.service);
  const delivery_methods = (formValues?.delivery_methods || []).map((item: string) => ({
    type: item,
    is_active: true
  }));

  const online_orders_delivery_available = formValues.online_orders_delivery_available ? true : false;
  const online_orders_pickup_available = formValues.online_orders_pickup_available ? true : false;
  const is_fulfilment_centre = formValues.is_fulfilment_centre ? true : false;
  const excepts_transfer_order = formValues.excepts_transfer_order ? true : false;
  const expects_return = formValues.expects_return ? true : false;
  const consume_notification_time_in_non_working_hours = formValues.consume_notification_time_in_non_working_hours
    ? true
    : false;
  const consume_inventory_execution_lead_time_in_non_working_hours =
    formValues.consume_inventory_execution_lead_time_in_non_working_hours ? true : false;

  const is_local_delivery_available = formValues.is_local_delivery_available ? true : false;
  const is_local_return_available = formValues.is_local_return_available ? true : false;
  const allow_gift_wrap = formValues.allow_gift_wrap ? true : false;

  const emails = [{ type: 'BUSINESS', email_id: business_email }];

  const phone_numbers = [
    {
      type: 'MOBILE',
      country_code: formValues.phone_number?.country_code,
      number: formValues.phone_number?.number,
      availability: formatTimeRange(formValues.phone_availability)
    }
  ];

  const contacts = (formValues?.contacts || []).map(
    ({
      type,
      organisation,
      business_unit,
      name,
      email_id,
      phone_availability,
      phone_number
    }: {
      type: string;
      organisation: string;
      business_unit: string;
      name: string;
      phone_availability: any;
      email_id: string;
      phone_number: any;
    }) => {
      return {
        type,
        organisation,
        business_unit,
        name,
        email_id,
        availability: formatTimeRange(phone_availability),
        phone_number
      };
    }
  );

  const work_week = (formValues?.work_week || []).map(
    ({
      cut_off_time,
      day_of_the_week,
      shift_time,
      capacities
    }: {
      day_of_the_week: string;
      shift_time: any;
      cut_off_time: any;
      shipping_capacity: number;
      picking_capacity: number;
      capacities: any;
    }) => {
      const mappedCapacities = (capacities || []).map((capacity: any) => {
        const slots = capacity?.slots || [];
        const mappedSlots = slots.map((slot: any) => {
          const { capacity, default_required_capacity_for_uom_line, slot_time } = slot || {};

          const slot_start_time = formatTimeSingle(slot_time[0]);
          const slot_end_time = formatTimeSingle(slot_time[1]);

          return {
            capacity,
            default_required_capacity_for_uom_line,
            slot_start_time,
            slot_end_time
          };
        });

        return { capacity_type: capacity?.capacity_type, capacity_uom: capacity?.capacity_uom, slots: mappedSlots };
      });

      return {
        day_of_the_week,
        shift_start_time: formatTimeSingle(shift_time[0]),
        shift_end_time: formatTimeSingle(shift_time[1]),
        cut_off_time: formatTimeSingle(cut_off_time),
        capacities: mappedCapacities
      };
    }
  );

  const holidays = (formValues?.holidays || []).map(
    ({ date, reason, time }: { date: any[]; time: any[]; reason: string }) => {
      return {
        start_date: formatSingleDate(date[0]),
        end_date: formatSingleDate(date[1]),
        start_time: formatTimeSingle(time[0]),
        end_time: formatTimeSingle(time[1]),
        reason
      };
    }
  );

  const work_contract = {
    work_week,
    holidays
  };

  const transformedPackages = (packages || []).map(
    ({ cost, length_in_mm, width_in_mm, height_in_mm, is_dimension_variable, ...rest }: any) => {
      return {
        ...rest,
        is_dimension_variable: is_dimension_variable ? true : false,
        dimension: {
          length_in_mm,
          width_in_mm,
          height_in_mm
        },
        cost: {
          currency_code: currency,
          amount: convertAmountTobaseAmount(cost, 100),
          fraction: 100
        }
      };
    }
  );

  const {
    type,
    address_line1,
    address_line2,
    address_line3,
    landmark,
    city,
    postal_code,
    country,
    state,
    coordinates,
    custom_type
  } = formValues.primary;

  const other_address = (formValues.other_address || []).map(
    ({
      type,
      address_line1,
      address_line2,
      address_line3,
      landmark,
      city,
      postal_code,
      country,
      state,
      coordinates,
      custom_type
    }: {
      type: string;
      address_line1: string;
      address_line2: string;
      address_line3: string;
      landmark: string;
      city: string;
      postal_code: string;
      country: string;
      state: string;
      custom_type: string;
      coordinates: any;
    }) => {
      return {
        type: type,
        type_description: custom_type,
        address_line1,
        address_line2,
        address_line3,
        landmark,
        city,
        state_code: countryStateMap[country][state].state_code,
        country_code: countryCodeMap[country],
        postal_code,
        coordinates
      };
    }
  );

  const address = {
    primary: {
      type: type,
      type_description: custom_type,
      address_line1,
      address_line2,
      address_line3,
      landmark,
      city,
      state_code: countryStateMap[country][state].state_code,
      country_code: countryCodeMap[country],
      postal_code,
      coordinates
    },
    others: other_address
  };

  const getFlatCharge = () => {
    let data = {
      currency: formValues?.currency_CODlimit,
      amount: Number(formValues?.local_delivery_policy?.local_delivery_shipping_charge?.flat_charge_config?.amount || 0),
      fraction: Number(formValues?.local_delivery_policy?.local_delivery_shipping_charge?.flat_charge_config?.fraction || 1)
    }
    return data
  }

  const getBaseWeight = () => {
    let objData = formValues?.local_delivery_policy?.local_delivery_shipping_charge?.base_weight_charge_config
    let data = {
      base_weight: {
        cost: {
          currency: formValues.currency_CODlimit,
          amount: objData?.base_weight?.cost?.amount,
          fraction: objData?.base_weight?.cost?.fraction
        },
        base_weight_in_kg: objData?.base_weight?.base_weight_in_kg
      },
      additional_weight: {
        cost: {
          currency: formValues.currency_CODlimit,
          amount: objData?.additional_weight?.cost?.amount,
          fraction: objData?.additional_weight?.cost?.fraction,
        },
        additional_weight_in_kg: objData?.additional_weight?.additional_weight_in_kg
      }
    }
    return data
  }

  const getSlabData = () => {
    let data = (formValues?.slab_weight_slab_charge_config || []).map((item: any) => {
      return {
        min_weight_in_kg: Number(item?.min_weight_in_kg),
        max_weight_in_kg: Number(item?.max_weight_in_kg),
        cost: {
          currency: formValues.currency_CODlimit,
          amount: Number(item?.cost?.amount),
          fraction: Number(item?.cost?.fraction),
        }
      }
    })
    return data
  }

  const getOrderData = () => {
    let data = (formValues?.order_price_slab_charge_config || []).map((item: any) => {
      return {
        min_price: Number(item?.min_price),
        max_price: Number(item?.max_price),
        currency: formValues.currency_CODlimit,
        cost: {
          currency: formValues.currency_CODlimit,
          amount: Number(item?.cost?.amount),
          fraction: Number(item?.cost?.fraction),
        }
      }
    })
    return data
  }

  const getlocalDeliveyPolicyData = () => {
    let data = {
      local_delivery_lead_time_in_minutes: Number(formValues?.local_delivery_lead_time_in_minutes),
      is_cod_available: formValues?.is_cod_available,
      cod_limit: formValues?.is_cod_available ? {
        currency: formValues.currency_local_CODlimit,
        amount: Number(formValues?.amount),
        fraction: Number(formValues?.fraction)
      } : null,
      local_delivery_shipping_charge: {
        name: formValues?.name,
        shipping_charge_type: formValues?.shipping_charge_type,
        flat_charge_config: formValues?.shipping_charge_type === 'FLAT' ? getFlatCharge() : null,
        base_weight_charge_config: formValues?.shipping_charge_type === 'BASE_WEIGHT' ? getBaseWeight() : null,
        slab_weight_slab_charge_config: formValues?.shipping_charge_type === 'SLAB_WEIGHT' ? getSlabData() : null,
        order_price_slab_charge_config: formValues?.shipping_charge_type === 'ORDER_PRICE' ? getOrderData() : null,
      },
      min_volume_in_ccm: Number(formValues?.min_volume_in_ccm),
      max_volume_in_ccm: Number(formValues?.max_volume_in_ccm),
      min_weight_in_kg: Number(formValues?.min_weight_in_kg),
      max_weight_in_kg: Number(formValues?.max_weight_in_kg),
    }
    return data
  }

  const Data_local_delivery_config = {
    local_operator_id: "HARD CODED AD FOR NOW",
    local_delivery_postal_codes: formValues?.local_delivery_postal_codes,
    local_delivery_policy: getlocalDeliveyPolicyData(),
  }

  const getPostCodes = () => {
    let data = formValues?.pickup_postal_codes?.map((item: any) => {
      return {
        postcode: item?.postcode,
        is_active: item?.is_active
      }
    })
    return data
  }

  const getPickUpConfig = () => {
    let data = {
      pickup_postal_codes: getPostCodes(),
      pickup_policy: {
        is_cod_available: formValues.is_cod_available,
        cod_limit: formValues.is_cod_available ? {
          currency: formValues.currency_PickUp_CODlimit,
          amount: Number(formValues?.pickUpConfigAmount),
          fraction: Number(formValues?.pickUpConfigFraction)
        } : null
      }
    }
    return data
  }

  const request: Partial<ICreateNodeRequestType> = {
    node_name,
    short_name,
    org_code,
    node_type,
    roles,
    services,
    priority,
    is_package_provided,
    online_orders_delivery_available,
    online_orders_pickup_available,
    pickup_config: online_orders_pickup_available ? getPickUpConfig() : null,
    is_fulfilment_centre,
    excepts_transfer_order,
    timezone,
    expects_return,
    is_operator_managed,
    language: 'en',
    currency_code: currency,
    country_code: country,
    ...(is_operator_managed && { operator_id }),
    delivery_methods,
    lead_time_in_days,
    shipping_lead_time_in_days,
    emails,
    work_contract,
    phone_numbers,
    contacts,
    address,
    packages: transformedPackages,
    notification_time_in_minutes,
    inventory_execution_lead_time_in_minutes,
    consume_notification_time_in_non_working_hours,
    consume_inventory_execution_lead_time_in_non_working_hours,
    locality_range_in_km,
    is_local_delivery_available,
    local_delivery_config: Data_local_delivery_config,
    is_local_return_available,
    allow_gift_wrap
  };
  return request;
};

const convertAmountTobaseAmount = (amount: number, fraction = 100) => {
  const baseAmount = parseInt((amount * fraction).toFixed());
  return baseAmount;
};
