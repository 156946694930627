import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Typography } from "antd"
import PrimaryLayout from "../../../../layouts/primary-layout"
import TenantInfo from "../../../../components/TenantIdInfo"
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../../../stores/use-loader";
import { createBurnRulesInitialXml, createRuleTriggerTypeData, triggerTypeSelectOptions } from "../../data";
import { useTimezone } from "../../../../hooks/useTimezone";
import React from "react";
import DmnModeler from 'dmn-js/lib/Modeler';
import BoldButtonLabel from "../../../../components/BoldButtonLabel";
import { loyaltyService } from "../../services/loyalty.service";
import { displayErrorNotifications, displaySuccessNotification } from "../../../../helpers/toast.helpers";
import _ from "../../../../helpers/lodash";

interface ICreateBurnRulesProps { }

const CreateBurnRules: React.FunctionComponent<ICreateBurnRulesProps> = props => {
    const [createForm] = Form.useForm();
    const navigate = useNavigate();
    const { timezoneSelectOptions } = useTimezone();

    const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
    const modellerRef = React.useRef<any>(null);

    React.useEffect(() => {
        if (modellerRef.current === null) {
            initializeModeller();
        }
        return () => {
            if (modellerRef.current !== null) {
                modellerRef.current.detach();
                modellerRef.current = null;
            }
        };
    }, []);

    const initializeModeller = async () => {
        const modeller = new DmnModeler({
            container: '#createEarnRulesCanvas'
        });
        modellerRef.current = modeller;

        try {
            const { warnings } = await modeller.importXML(createBurnRulesInitialXml);

            if (warnings.length) {
                console.log('import with warnings', warnings);
            } else {
                console.log('import successful');
            }
        } catch (err) {
            console.log('something went wrong:', err);
        }
    };

    const handleCreate = async () => {
        setLoading(true);

        const { name, burn_template_type, reward_name, date_range, timezone } =
            createForm.getFieldsValue();
        const { xml } = await modellerRef.current.saveXML();
        const request: any = {};
        if (date_range?.length) {
            const [start_date, end_date] = date_range;
            request.start_date = start_date.format();
            request.end_date = end_date.format();
        }

        const body = {
            burn_template_type: burn_template_type,
            name: name,
            xml: xml,
            reward_name: reward_name,
            start_date: request.start_date,
            end_date: request.end_date,
            time_zone: timezone
        }
        const { errors } = await loyaltyService.createBurnRule(body);
        if (_.isEmpty(errors)) {
            displaySuccessNotification({ message: 'Rule created successfully' });
            navigate('/loyalty/config/burn-rule');
        } else {
            displayErrorNotifications(errors);
        }
        setLoading(false);
    };

    const handleApplicabilityChange = async () => {
        setLoading(true);
        //API CALL

        setLoading(false);
    };

    return (
        <PrimaryLayout>
            <div className="container mx-auto px-4">
                <Card>
                    <Row justify={'space-between'} className="mb-4">
                        <Col>
                            <Typography.Title level={3} className="text-[#2e2a5b]">
                                Create Burn Rule
                            </Typography.Title>
                        </Col>
                    </Row>
                    <div className="flex gap-4">
                        <TenantInfo />
                    </div>
                    <section className="mt-4">
                        <Form layout="vertical" onFinish={handleCreate} form={createForm}>
                            <Row gutter={12}>
                                <Col xs={24} md={6}>
                                    <Form.Item name="burn_template_type" label="Template Type">
                                        <Select
                                            placeholder="Select Template Type"
                                            size="large"
                                            // onChange={() => searchForm.setFieldValue('applicablity_type', null)}
                                            options={[
                                                { label: 'Customer Order', value: 'CUSTOMER_ORDER' },
                                                { label: 'Gift Card', value: 'GIFT_CARD' },
                                                { label: 'Transfer Reward', value: 'TRANSFER_REWARD' },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        rules={[
                                            { message: 'This field is required', required: true },
                                            {
                                                validator: async (_, ruleName: string) => {
                                                    if (/\s/.test(ruleName)) {
                                                        return Promise.reject(new Error('No spaces allowed in rule name'));
                                                    }
                                                }
                                            }
                                        ]}
                                        name="name"
                                        label="Rule Name"
                                    >
                                        <Input placeholder="Enter Rule Name" size="large" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        rules={[
                                            { message: 'This field is required', required: true },
                                        ]}
                                        name="reward_name"
                                        label="Reward Name"
                                    >
                                        <Input placeholder="Enter Reward Name" size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        rules={[{ message: 'This field is required', required: true }]}
                                        name="timezone"
                                        label="Timezone"
                                    >
                                        <Select options={timezoneSelectOptions} placeholder="Select timezone" size="large" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="date_range" label="Date">
                                        <DatePicker.RangePicker size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div id="createEarnRulesCanvas" className="h-[400px]"></div>
                            <Row gutter={12}>
                                <Col xs={24} md={8} lg={6}>
                                    <Button block htmlType="submit" size="large" type="primary">
                                        <BoldButtonLabel labelText={'Create'} />{' '}
                                    </Button>
                                </Col>
                                <Col xs={24} md={8} lg={6}>
                                    <Button block size="large" onClick={() => navigate(`/loyalty/config/burn-rule`)}>
                                        <BoldButtonLabel labelText={'Back'} />{' '}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </section>
                </Card>
            </div>
        </PrimaryLayout>
    )
}

export default CreateBurnRules