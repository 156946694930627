import { Table, TableColumnsType } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CustomPagination from '../../../components/custom-pagination';
import { profileService } from '../../../services';
import { IUserListingResponse, User } from '../../../types/profile.types';
import _ from '../../../helpers/lodash';
import { useLoader } from '../../../stores/use-loader';

interface IManageUserListing {}

const ManageUserListing: React.FunctionComponent<IManageUserListing> = props => {
  const [userListingResponse, setUserListingResponse] = useState({} as IUserListingResponse);
  const columns: TableColumnsType<User> = [
    {
      title: 'First Name',
      align: 'center',
      render(value, record, index) {
        return <>{record.first_name}</>;
      }
    },
    {
      title: 'Last Name',
      align: 'center',
      render(value, record, index) {
        return <>{record.last_name}</>;
      }
    },
    {
      title: 'Email Id',
      align: 'center',
      render(value, record, index) {
        return <>{record.email}</>;
      }
    },

    {
      title: 'Groups',
      align: 'center',
      render(value, record, index) {
        return <>{(record?.groups || [])?.map((item:any, index: any) => {
          return<div>{item}</div>
        })}</>;
      }
    }
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const offset = parseInt(searchParams.get('offset') || '0');

  useEffect(() => {
    fetchUserList(offset);
  }, []);

  const { setLoading } = useLoader();

  const fetchUserList = async (offset = 0) => {
    setLoading(true);
    setSearchParams({ offset: offset.toFixed() });

    const { data, errors } = await profileService.getUserList({ offset, limit: 10 });

    if (_.isEmpty(errors)) {
      setUserListingResponse(data);
    }
    setLoading(false);
  };
  const handlePageChange = async (currentPage: number) => {
    const offsetValue = (currentPage - 1) * 10;
    await fetchUserList(offsetValue);
  };
  const getPageNumberFromOffset = (offset: number) => {
    //! 10 -> 2
    //!0 ->1
    if (offset === 0) return 1;

    let pageNumber = offset / 10 + 1;

    return pageNumber || 1;
  };

  return (
    <div className="container mx-auto">
      <section className="mt-4">
        <section className="py-2 flex justify-end">
          <CustomPagination
            currentPage={getPageNumberFromOffset(offset)}
            handleNext={handlePageChange}
            handlePageChange={handlePageChange}
            handlePrevious={handlePageChange}
            totalPages={parseInt((userListingResponse.count / 10).toFixed()) || 1}
          />
        </section>

        <Table
          loading={false}
          pagination={false}
          bordered
          dataSource={userListingResponse.users || []}
          columns={columns}
          scroll={{ x: 1000 }}
        ></Table>
      </section>
    </div>
  );
};
export default ManageUserListing;
