import { CloudUploadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Input, Modal, Row, Select, Table, TableColumnsType, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RcFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import ActionButton from '../../../components/ActionButton';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import CustomPagination from '../../../components/custom-pagination';
import { convertQueryStringToObj, objectHelpers, validationHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { useLoader } from '../../../stores/use-loader';
import { priceListService } from '../services/pricelist.service';

interface ICatalogFormProps {
  buttonLabel: string;
  isUpdate: boolean;
  formInstance: FormInstance<any>;
  handleSave: () => void;
  handleGoBack: () => void;
  resetData: () => void;
}

const lockPriceOption = [
  { label: 'True', value: true },
  { label: 'False', value: false }
];

const CatalogForm: React.FunctionComponent<ICatalogFormProps> = ({
  buttonLabel,
  formInstance,
  isUpdate,
  handleSave,
  handleGoBack,
  resetData
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [addItemToCatalogForm] = Form.useForm();
  const [itemList, setItemList] = React.useState({} as any);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
  const [uploadModalVisible, setUploadModalVisible] = React.useState(false);
  const [disableItemId, setDisableItemId] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [importModalVisible, setImportModalVisible] = React.useState(false);
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [excelValidationResp, setExcelValidationResp] = React.useState({} as any);
  const [excelBodyData, setExcelBodyData] = React.useState({} as any);

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const tableColumns: TableColumnsType<any> = [
    {
      title: 'Item ID',
      width: 200,
      align: 'center',
      render: (value: any, record: any, index: any) => {
        return <Typography.Text>{record?.item_id}</Typography.Text>;
      }
    },
    {
      title: 'Cost Price',
      width: 200,
      align: 'center',
      render: (value: any, record: any, index: any) => {
        return <Typography.Text>{record?.cost_price}</Typography.Text>;
      }
    },
    {
      title: 'Unit Price',
      width: 200,
      align: 'center',
      render: (value: any, record: any, index: any) => {
        return <Typography.Text>{record?.unit_price}</Typography.Text>;
      }
    },
    {
      title: 'Minium Mark up percent',
      width: 200,
      align: 'center',
      render: (value: any, record: any, index: any) => {
        return <Typography.Text>{record?.minimum_markup_percent}</Typography.Text>;
      }
    },
    {
      title: 'Maximum Mark up percent',
      width: 200,
      align: 'center',
      render: (value: any, record: any, index: any) => {
        return <Typography.Text>{record?.maximun_markup_percent}</Typography.Text>;
      }
    },
    {
      title: 'Lock Price',
      width: 200,
      align: 'center',
      render: (value: any, record: any, index: any) => {
        const lockPrice = record?.lock_price ? 'True' : 'False';
        return <Typography.Text>{lockPrice}</Typography.Text>;
      }
    },
    {
      title: 'Action',
      width: 200,
      align: 'center',
      render: (value: any, record: any, index: any) => {
        return (
          <section className="flex flex-wrap justify-center">
            {isUpdate && (
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => {
                  handleOnEditItem(record);
                }}
              ></ActionButton>
            )}
            {isUpdate && (
              <ActionButton
                action="DELETE"
                title="Delete"
                onClick={() => {
                  handleOnDelete(record?.item_id);
                }}
              ></ActionButton>
            )}
          </section>
        );
      }
    }
  ];

  const tableColumnsView: TableColumnsType<any> = [
    {
      title: 'Item ID',
      width: 200,
      align: 'center',
      render: (value: any, record: any, index: any) => {
        return <Typography.Text>{record?.item_id}</Typography.Text>;
      }
    },
    {
      title: 'Cost Price',
      width: 200,
      align: 'center',
      render: (value: any, record: any, index: any) => {
        return <Typography.Text>{record?.cost_price}</Typography.Text>;
      }
    },
    {
      title: 'Unit Price',
      width: 200,
      align: 'center',
      render: (value: any, record: any, index: any) => {
        return <Typography.Text>{record?.unit_price}</Typography.Text>;
      }
    },
    {
      title: 'Minium Mark up percent',
      width: 200,
      align: 'center',
      render: (value: any, record: any, index: any) => {
        return <Typography.Text>{record?.minimum_markup_percent}</Typography.Text>;
      }
    },
    {
      title: 'Maximum Mark up percent',
      width: 200,
      align: 'center',
      render: (value: any, record: any, index: any) => {
        return <Typography.Text>{record?.maximun_markup_percent}</Typography.Text>;
      }
    },
    {
      title: 'Lock Price',
      width: 200,
      align: 'center',
      render: (value: any, record: any, index: any) => {
        const lockPrice = record?.lock_price ? 'True' : 'False';
        return <Typography.Text>{lockPrice}</Typography.Text>;
      }
    }
  ];

  const handleOnEditItem = (row: any) => {
    const { cost_price, item_id, lock_price, maximun_markup_percent, minimum_markup_percent, unit_price } = row;
    setUploadModalVisible(true);
    setDisableItemId(true);
    addItemToCatalogForm.setFieldsValue({
      item_id: item_id,
      lock_price: lock_price,
      cost_price: cost_price,
      unit_price: unit_price,
      minimum_markup_percent: minimum_markup_percent,
      maximun_markup_percent: maximun_markup_percent
    });
  };

  const handleOnDelete = async (item_id: any) => {
    const { errors } = await priceListService.deletePriceMasterItem(id as string, item_id);
    if (_.isEmpty(errors)) {
      loadTableItem();
      displaySuccessNotification({ message: 'Item Removed Successfully!' });
    } else {
      displayErrorNotifications(errors);
    }
  };

  const loadTableItem = async (offset = 0) => {
    let params = { offset, limit: 10 };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);
    const { data, errors } = await priceListService.getMasterCatalogItem(id as string, filteredParams);
    if (_.isEmpty(errors)) {
      setItemList(data);
    } else {
      displayErrorNotifications(errors);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    loadTableItem();
    setLoading(false);
  }, []);

  const handlePageChange = (currrentPage: number) => {
    setCurrentPage(currrentPage);
    loadTableItem(currrentPage - 1);
  };

  const closeAndResetModal = () => {
    addItemToCatalogForm.setFieldsValue({
      item_id: null,
      cost_price: null,
      unit_price: null,
      minimum_markup_percent: null,
      maximun_markup_percent: null,
      lock_price: null
    });
    setUploadModalVisible(false);
    setDisableItemId(false);
  };

  const handleOnAddItemToMaster = async () => {
    setLoading(true);
    const { item_id, cost_price, unit_price, minimum_markup_percent, maximun_markup_percent, lock_price } =
      addItemToCatalogForm.getFieldsValue();
    const bodyData = [
      {
        item_id: item_id,
        cost_price: Number(cost_price),
        unit_price: Number(unit_price),
        minimum_markup_percent: Number(minimum_markup_percent),
        maximun_markup_percent: Number(maximun_markup_percent),
        lock_price: lock_price
      }
    ];
    const { errors } = await priceListService.createPriceMasterItem(id as any, bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Item Added Successfully!' });
      closeAndResetModal();
      loadTableItem();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnDataUpload = async () => {
    if (_.isEmpty(fileList)) return;

    const formData = new FormData();
    formData.set('file', fileList[0] as RcFile);
    if (fileList) {
      const fileReader = new FileReader();
      fileReader.onload = event => {
        const data = event?.target?.result as ArrayBuffer;
        const workbook = XLSX.read(data, {
          type: 'binary'
        });
        let excelDataParsed: any[] = [];
        workbook.SheetNames.forEach(sheet => {
          if (excelDataParsed.length === 0) {
            const rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            const jsonObject = JSON.stringify(rowObject);
            const getExceldata = JSON.parse(jsonObject);
            excelDataParsed.push(getExceldata);
            getValidData(excelDataParsed[0]);
          }
        });
      };
      let selectedFile = fileList[0];
      fileReader.readAsBinaryString(selectedFile);
    }
  };

  const getValidData = async (excelData: any) => {
    setLoading(true);
    if (excelData.length > 10000) {
      displayErrorNotifications([{ message: 'Data cannot exced more than ten thousand' }]);
      setLoading(false);
      return;
    } else {
      let bodyData = loadExcelBodyData(excelData);
      setExcelBodyData(bodyData);
      const { data, errors } = await priceListService.uploadItemsValidate(id as string, bodyData);
      if (_.isEmpty(errors)) {
        setExcelValidationResp(data);
      } else {
        displayErrorNotifications(errors);
      }
    }
    setLoading(false);
  };

  const loadExcelBodyData = (excelData: any) => {
    const masterCatalogData = excelData.map((item: any) => {
      return {
        item_id: item.item_id,
        cost_price: item.cost_price,
        unit_price: item.unit_price,
        minimum_markup_percent: item.minimum_markup_percent,
        maximun_markup_percent: item.maximun_markup_percent,
        lock_price: item.lock_price
      };
    });
    return { line_items: masterCatalogData };
  };

  const closePostCodeModal = () => {
    setImportModalVisible(false);
    setExcelValidationResp({} as any);
    setExcelBodyData({} as any);
    loadTableItem(0);
  };

  const handleOnUploadExcelData = async () => {
    setLoading(true);
    const { errors } = await priceListService.batchUploadConfirm(id as string, excelBodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'uploaded Successfully !' });
      closePostCodeModal();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <Form form={formInstance} layout="vertical" onFinish={handleSave}>
      <Row justify={'space-between'} className="mb-4">
        <Col>
          <Typography.Title level={3} className="text-[#2e2a5b]">
            Price Master
          </Typography.Title>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={8}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: 'This Field Cannot be Empty' },
              { max: 200, message: 'Max limit is 200 characters' }
            ]}
          >
            <Input size="large" placeholder="Name" disabled={true} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={24}>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              { required: false, message: "This field can't be empty" },
              { max: 200, message: 'Max limit is 200 characters' }
            ]}
          >
            <TextArea rows={5} placeholder="Description" disabled={!isUpdate} />
          </Form.Item>
        </Col>
      </Row>
      {isUpdate && (
        <section>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Button size="large" block onClick={handleSave} type="primary">
                <BoldButtonLabel labelText={buttonLabel} />
              </Button>
            </Col>
            <Col xs={12} md={6}>
              <Button size="large" block onClick={resetData}>
                <BoldButtonLabel labelText="Reset" />
              </Button>
            </Col>
          </Row>
        </section>
      )}
      <section className="mt-5">
        <div className="flex justify-between my-2">
          <div>
            <Typography.Title level={4} className="text-[#2e2a5b]">
              List of items
            </Typography.Title>
          </div>
          {isUpdate && (
            <Row gutter={12}>
              <Col>
                <Button
                  size="large"
                  block
                  type="primary"
                  onClick={() => {
                    setImportModalVisible(true);
                  }}
                >
                  <BoldButtonLabel labelText="Upload" />
                  <UploadOutlined />
                </Button>
              </Col>
              <Button className="bg-[#41729F]" type="primary" size="large" onClick={() => setUploadModalVisible(true)}>
                <BoldButtonLabel labelText="Add Item" />
              </Button>
            </Row>
          )}
        </div>
        {itemList.count > 0 && (
          <Row className="my-2" justify={'end'} gutter={[12, 12]} align={'middle'}>
            <Col>
              <CustomPagination
                currentPage={currentPage}
                totalPages={Math.ceil(itemList?.count / 10)}
                handleNext={handlePageChange}
                handlePageChange={handlePageChange}
                handlePrevious={handlePageChange}
              />
            </Col>
          </Row>
        )}
        <section className="mt-5">
          <Table
            id="popup"
            scroll={{ x: 1200 }}
            bordered
            size="small"
            pagination={false}
            columns={isUpdate ? tableColumns : tableColumnsView}
            dataSource={itemList.data}
          ></Table>
        </section>
      </section>
      <section className="my-5">
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Button size="large" block onClick={handleGoBack}>
              <BoldButtonLabel labelText="Go Back" />
            </Button>
          </Col>
        </Row>
      </section>
      <Modal
        centered
        open={uploadModalVisible}
        onCancel={() => {
          closeAndResetModal();
        }}
        width={1000}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Catalog Items:</Typography.Title>
          </div>
        }
      >
        <Form form={addItemToCatalogForm} layout="vertical" onFinish={handleOnAddItemToMaster}>
          <section className="flex flex-col justify-center">
            <div style={{ maxHeight: '300px' }}>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Item Id"
                    name="item_id"
                    rules={[
                      { required: true, message: 'This Field Cannot be Empty' },
                      { max: 50, message: 'Max limit is 50 characters' }
                    ]}
                  >
                    <Input size="large" placeholder="Item Id" disabled={disableItemId} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Lock Price"
                    name="lock_price"
                    rules={[{ required: true, message: 'This Field Cannot be Empty' }]}
                  >
                    <Select allowClear size="large" placeholder="Select Filter Type" options={lockPriceOption}></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Cost Price"
                    name="cost_price"
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getNumericValidatorForInput({})
                    ]}
                  >
                    <Input size="large" placeholder="Cost Price" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Unit Price"
                    name="unit_price"
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getNumericValidatorForInput({})
                    ]}
                  >
                    <Input size="large" placeholder="Unit Price" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Minimum Markup Percent"
                    name="minimum_markup_percent"
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getNumericValidatorForInput({})
                    ]}
                  >
                    <Input size="large" placeholder="Minimum Markup Percent" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Maximum Markup Percent"
                    name="maximun_markup_percent"
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getNumericValidatorForInput({})
                    ]}
                  >
                    <Input size="large" placeholder="Maximum Markup Percent" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Button block size="large" type="primary" className="mt-4" htmlType="submit">
              <BoldButtonLabel labelText="Add Item" />
            </Button>
          </section>
        </Form>
      </Modal>
      <Modal
        centered
        open={importModalVisible}
        onCancel={() => {
          // setImportModalVisible(false);
          closePostCodeModal();
        }}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Import Data</Typography.Title>
          </div>
        }
      >
        <section className="flex flex-col justify-center">
          <Button type="link" className="mb-4">
            Download Sample Template
          </Button>
          <Dragger
            beforeUpload={file => {
              setFileList([file]);
              return false;
            }}
            onRemove={() => {
              setFileList([]);
            }}
            fileList={fileList}
            name="file"
            accept={'.csv,.xlsx'}
          >
            <p>
              <CloudUploadOutlined className="text-9xl text-[#5885AF]" />
            </p>
            <p>Click or Drag Customers Excel File to upload</p>
          </Dragger>
          <Button
            disabled={_.isEmpty(fileList)}
            block
            size="large"
            type="primary"
            onClick={handleOnDataUpload}
            className="mt-4"
          >
            <BoldButtonLabel labelText="Validate" />
          </Button>
        </section>

        {Object.keys(excelValidationResp).length > 1 && (
          <div className="flex flex-col mt-4">
            <Typography.Title level={5} className="text-red-500">
              File has been processed and below are the results
            </Typography.Title>
            <Typography.Text className="text-red-500">
              Uploaded File duplicate Entries : {excelValidationResp?.total_duplicate}
            </Typography.Text>
            <Typography.Text className="text-red-500">
              Total Failed: {excelValidationResp?.total_failed}
            </Typography.Text>
            <Typography.Text className="text-blue-500">
              Total uploaded postcodes: {excelValidationResp?.total_line_items}
            </Typography.Text>
            <Typography.Text className="text-green-500">
              Total Success: {excelValidationResp?.total_success}
            </Typography.Text>
            <Row className="mt-4" gutter={[12, 12]}>
              <Col xs={12}>
                <Button
                  block
                  size="large"
                  type="primary"
                  onClick={() => {
                    handleOnUploadExcelData();
                  }}
                  disabled={excelValidationResp.total_success < 1}
                >
                  <BoldButtonLabel labelText="Upload File" />
                </Button>
              </Col>{' '}
              <Col xs={12}>
                <Button size="large" block type="primary" /*onClick={getReportData}*/>
                  <BoldButtonLabel labelText="Download Report" />
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    </Form>
  );
};

export default CatalogForm;
