import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Spin,
  TimePicker,
  Typography
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { countryList, currencyList } from '../../../../data/intl-data';

import { promotionServiceCreate } from '../../services/promotionCreate.service';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { validationHelpers } from '../../../../helpers';
import { getDayJsObjectWithFormattedString } from '../../../../helpers/date.helpers';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import { useLoader } from '../../../../stores/use-loader';
import { useCustomers } from '../../hooks/drop-down-hooks/use-customers';
import { useModels } from '../../hooks/drop-down-hooks/use-models';
import { useOnlineChannels } from '../../hooks/drop-down-hooks/use-online-channels';
import { usePos } from '../../hooks/drop-down-hooks/use-pos-channel';
import { useProducts } from '../../hooks/drop-down-hooks/use-products';
import { useSellers } from '../../hooks/drop-down-hooks/use-sellers';
import { useBrands } from '../../hooks/drop-down-hooks/user-brands';
import { Criteria } from '../../types/create-promo';
import { useAuth, useTenant } from '../../../../stores/use-auth';
import { AnalyticsService } from '../../../loyalty-engine/services/Analytics.service';

interface IPromotionFormProps {
  isUpdate?: boolean;
  promotionFormInitialValues?: any;
  viewOnly?: boolean;
}
const filterRespose = [
  { label: 'Tier Level', value: 'TIER' },
  { label: 'Segments', value: 'SEGMENT' },
  { label: 'RFM Category', value: 'RFM_CATEGORY' }
];

const staff = [
  { label: 'Pavan Kumar', value: 'EMP001' },
  { label: 'Dhanush Amin', value: 'EMP002' },
  { label: 'Ankur Dixit', value: 'EMP003' }
];

const appliesToClassForAll = [
  { label: 'Order Total', value: 'ORDER_TOTAL' },
  { label: 'Line Items', value: 'LINE_ITEMS' },
  { label: 'Shipping', value: 'SHIPPING' },
  { label: 'Loyalty program', value: 'LOYT_POINTS' },
  { label: 'Payments', value: 'PAYMENTS' }
];

const appliesToClassForSubTotal = [
  { label: 'Order Total', value: 'ORDER_TOTAL' },
  { label: 'Line Items', value: 'LINE_ITEMS' }
];

const promotionTypes = [
  { label: 'Sub Total Discount Promo', value: 'SUB_TTL_DIS' },
  // { label: 'Next Order Coupons Promo', value: 'NXT_ORDER_COUPONS' },
  { label: 'Volume Discount Promo', value: 'VOLUME_DISC' },
  { label: 'Bundle Discount Promo', value: 'BUNDLE_DISC' },
  { label: 'Shipping Discount Promo', value: 'SHIPPING_DISC' },
  // { label: 'Payment Discount Promo', value: 'PAYMENT_DISC' },
  { label: 'BXGY Promotion', value: 'BXGY' }
];
const timeZones = [
  { label: 'Indian Standard Time (UTC+05:30)', value: 'IST', country: 'India' },
  { label: 'America (UTC-08:00)', value: 'AMERICA', country: 'USA' },
  { label: 'Chile Continental (UTC-04:00)', value: 'CHILE_CONTINENTAL', country: 'Chile' },
  { label: 'Chile Easter (UTC-06:00)', value: 'CHILE_EASTER', country: 'Chile' },
  { label: 'Europe (UTC+01:00)', value: 'EUROPE', country: 'Europe' }
];
let months = [
  { label: 'January', value: 'January', checked: false },
  { label: 'February', value: 'February', checked: false },
  { label: 'March', value: 'March', checked: false },
  { label: 'April', value: 'April', checked: false },
  { label: 'June', value: 'June', checked: false },
  { label: 'July', value: 'July', checked: false },
  { label: 'August', value: 'August', checked: false },
  { label: 'September', value: 'September', checked: false },
  { label: 'October', value: 'October', checked: false },
  { label: 'November', value: 'November', checked: false },
  { label: 'December', value: 'December', checked: false }
];
let weeks = [
  { label: 'Monday', value: 'Monday', checked: false },
  { label: 'Tuesday', value: 'Tuesday', checked: false },
  { label: 'Wednesday', value: 'Wednesday', checked: false },
  { label: 'Thursday', value: 'Thursday', checked: false },
  { label: 'Friday', value: 'Friday', checked: false },
  { label: 'Saturday', value: 'Saturday', checked: false },
  { label: 'Sunday', value: 'Sunday', checked: false }
];
let seasons = [
  { label: 'Spring', value: 'Spring', checked: false },
  { label: 'Summer', value: 'Summer', checked: false },
  { label: 'Monsoon', value: 'Monsoon', checked: false },
  { label: 'Winter', value: 'Winter', checked: false }
];
let special_day_list = [
  { label: 'BirthDay', value: 'birthday', checked: false }
  // { label: 'Anniversary', value: 'anniversary', checked: false }
];

const PromotionForm: React.FunctionComponent<IPromotionFormProps> = ({ promotionFormInitialValues, viewOnly }) => {
  const [promotionForm] = Form.useForm();

  // rewardType
  const [promotionTypeList, setPromotionTypeList] = React.useState(promotionTypes);
  const [timeZoneList, setTimeZoneList] = React.useState(timeZones);
  const [currencyTypeList, setCurrencyTypeList] = React.useState(currencyList);
  const [promotionID, setpromotionID] = React.useState(null);
  const navigate = useNavigate();
  const [radioValue, setRadioValue] = React.useState(1);
  const [singleRadio, setSingleRadio] = React.useState(1);
  const [validTimeFrame, setValidTimeFrame] = React.useState(1);
  const [filterOption, setFilterOption] = React.useState(filterRespose);
  const { RangePicker } = TimePicker;
  const [categoryOptions, setCategoryOptions] = React.useState<any>([])

  const isUpdate = !_.isEmpty(promotionFormInitialValues);

  const { id } = useParams();

  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    loadCategoryOptions()
    if (!_.isEmpty(promotionFormInitialValues)) loadInitialApiValuesIntoForm();
    else {
      promotionForm.setFieldsValue({
        trigger: searchParams.get('promo_header_type'),
        promotionType: searchParams.get('promotion_type'),
        max_discount_item_count: 1,
        applicability: 'CART',
        onlyForNewUser: false,
        isAvailablePos: true,
        isAvailableOnline: true,
        productSelection_RC: 'ALL',
        status: 'OPEN',
        seller_Selection: 'ALL',
        thresholdSelection: 'NO_THRESHOLD',
        rewards_Data: [
          {
            product_reward: {
              product_info: [{}]
            }
          }
        ]
      });

      //!Set InitialValues of the form
      if (promotionForm.getFieldsValue()?.promotionType === 'BUNDLE_DISC') {
        promotionForm.setFieldsValue({ appliesTo: 'LINE_ITEMS', rewardType: 'BUNDLE_DISCOUNT' });
      } else if (promotionForm.getFieldsValue()?.promotionType === 'SHIPPING_DISC') {
        promotionForm.setFieldsValue({
          appliesTo: 'SHIPPING',
          rewardType: 'DISCOUNT'
        });
      } else if (promotionForm.getFieldsValue()?.promotionType === 'VOLUME_DISC') {
        promotionForm.setFieldsValue({
          appliesTo: 'LINE_ITEMS',
          rewardType: 'VOLUME_DISCOUNT',
          rewards_Data: [
            {
              threshold_selection: 'QUANTITY',
              product_reward: {
                product_info: [{}]
              }
            }
          ]
        });
      } else if (promotionForm.getFieldsValue()?.promotionType === 'BUNDLE_DISC') {
        promotionForm.setFieldsValue({
          appliesTo: 'LINE_ITEMS',
          rewardType: 'BUNDLE_DISCOUNT'
        });
      } else if (promotionForm.getFieldsValue()?.promotionType === 'BXGY') {
        promotionForm.setFieldsValue({
          appliesTo: 'LINE_ITEMS',
          rewardType: 'PRODUCT'
        });
      } else if (promotionForm.getFieldsValue()?.promotionType === 'LOYT_REWARD') {
        promotionForm.setFieldsValue({
          appliesTo: 'LOYT_POINTS',
          rewardType: 'POINTS'
        });
      }

      triggerSelectionHandler(searchParams.get('promo_header_type') || 'PROMO');
    }
  }, [promotionFormInitialValues]);

  const [promotionFormValues, setPromotionFormValues] = React.useState({} as any);
  const { setLoading } = useLoader(({ setLoading }) => ({
    setLoading
  }));

  const loadCategoryOptions = async () => {
    setLoading(true)
    const { data, errors } = await AnalyticsService.getCategoryData()
    if (_.isEmpty(errors)) {
      const outputArray = data?.value?.map((item: any) => ({ label: item.replaceAll('_', ' '), value: item }));
      setCategoryOptions(outputArray)
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  }

  const getRewardData = (data: any) => {
    data.forEach((obj: any) => {
      if (obj.cap_value > 0) {
        // if (obj.hasOwnProperty('cap_value')) {
        obj.isRange = true;
      }
    });
    return data || [];
  };

  const loadInitialApiValuesIntoForm = () => {
    setLoading(true);
    const {
      title,
      status,
      application_type,
      trigger,
      promotion_type,
      criterias,
      timezone,
      discount: {
        eligible_for,
        minimum_value,
        cap_value,
        applies_to_price,
        type,
        percentage,
        product_reward,
        max_discount_item_count
      },
      visible_on_popup,
      priority,
      buyer_selection,
      product_selection,
      products,
      is_pos,
      is_online,
      pos_list,
      online_list,
      max_usage,
      limit_per_user,
      new_buyer,
      seller_selection,
      sellers,
      application,
      reward_type,
      reward_name,
      reward_value,

      visibility,
      tier_restriction,
      buyers,
      code_type,
      code,
      coupon_quantity_to_generate,
      start_date,
      end_date,
      start_time,
      end_time,
      transaction_apply_count,
      valid_on_given_timeframe,
      schedule,
      applies_to_product_in_sale,
      ignore_price_lock,
      combine_with_other_promo,
      combine_promo_ids,
      currency,
      next_order_applicable,
      no_of_next_orders
    } = promotionFormInitialValues as any;

    const { daily, daily_info, monthly, monthly_info, seasonal, seasonal_info, weekly, weekly_info } =
      schedule || ({} as any);
    const { from_date, to_date, from_time, to_time } = daily_info || ({} as any);
    const countryValue: string = extractCountryValue(criterias);
    const appliesPerTransaction = transaction_apply_count === 99999 ? 'UNLIMITED' : 'COUNT';
    const appliesPerTransaction_Count = transaction_apply_count;
    const validTimeFrame = valid_on_given_timeframe ? 1 : 2;

    const { reward_on_product, rewarded_product } = product_reward || ({} as any);
    const selectedItem = reward_on_product?.ids || [];
    const rewardItem = rewarded_product?.ids || [];

    const itemQuantity = reward_on_product?.quantity || 0;
    const qualityReward = rewarded_product?.quantity || 0;
    const rfmCategories = RFMextractValuesFromCriteras(criterias, 'RFM_CATEGORIES');
    const customerSegment = extractValuesFromCriteras(criterias, 'CUSTOMER_SEGMENT');
    const customerTire = extractValuesFromCriteras(criterias, 'CUSTOMER_TIER');
    const brandCrits = extractValuesFromCriteras(criterias, 'BRAND');
    const productCrits = extractValuesFromCriteras(criterias, 'PRODUCTS');
    const categoryCrits = extractValuesFromCriteras(criterias, 'CATEGORY');
    const modelCrits = extractValuesFromCriteras(criterias, 'MODEL');
    //!Criteras and Filters
    const critereas = [];

    if (!_.isEmpty(productCrits)) {
      critereas.push({
        attribute_type: 'SKU',
        sku: productCrits
      });
    }

    if (!_.isEmpty(modelCrits)) {
      critereas.push({
        attribute_type: 'MODEL',
        model: modelCrits
      });
    }

    if (!_.isEmpty(brandCrits)) {
      critereas.push({
        attribute_type: 'BRAND',
        brand: brandCrits
      });
    }
    if (!_.isEmpty(categoryCrits)) {
      critereas.push({
        attribute_type: 'CATEGORIES',
        category: categoryCrits
      });
    }

    const filters = [];
    if (tier_restriction) filters.push({ filter_type: 'TIER', customerTireRestriction: tier_restriction });
    if (!_.isEmpty(customerSegment)) filters.push({ filter_type: 'SEGMENT', segment: customerSegment });
    if (!_.isEmpty(customerTire)) filters.push({ filter_type: 'TIER', customerTireRestriction: customerTire });
    if (!_.isEmpty(rfmCategories)) filters.push({ filter_type: 'RFM_CATEGORY', RFM_CATEGORY: rfmCategories });

    let customerRestrictionSelection = buyer_selection === 'ALL' ? 'EVERYONE' : 'SPECIFIC';

    const excluded_products = getExcludedProductsFromApiCriterea(criterias);
    let formValues: any = {
      title,
      status,
      appliesTo: application_type,
      applicability: application,
      trigger,
      promotionType: promotion_type,
      country: countryValue,
      timeZone: timezone,
      currency,
      priority,
      productSelection_RC: product_selection,
      product1: products || [],
      isAvailablePos: is_pos,
      isAvailableOnline: is_online,
      posList: pos_list,
      onlineList: online_list,
      limitPerUser: limit_per_user,
      maxUsage: max_usage,
      onlyForNewUser: new_buyer,
      seller_Selection: seller_selection,
      specific_seller: sellers,
      rewardType: reward_type,
      thresholdSelection: eligible_for,
      reward_name,
      next_order_applicable: next_order_applicable ? true : false,
      no_of_next_orders: no_of_next_orders,
      reward_value,
      tresholdValue: minimum_value,
      maxDiscount: cap_value,
      applies_to_price,
      selectDiscountType: type,
      discountValue: percentage,
      Combine_with_other_promotions: combine_with_other_promo ? true : false,
      Id_Product_Selection: combine_promo_ids || [],
      for_generate: visibility,
      customerTireRestriction: tier_restriction,
      userSelection: buyer_selection,
      specific_buyer: buyers,
      code_gen: code,
      quantityToGenerate: coupon_quantity_to_generate,

      appliesPerTransaction,
      appliesPerTransaction_Count,
      daily_val: daily,
      week_val: weekly,
      month_val: monthly,
      seasonal_val: seasonal,
      weekValues: weekly_info,
      monthValues: monthly_info,
      seasonalValues: seasonal_info,
      dateRangeInSchedule: [
        getDayJsObjectWithFormattedString(start_date, 'YYYY-MM-DD'),
        getDayJsObjectWithFormattedString(end_date, 'YYYY-MM-DD')
      ],
      timeRangeInSchedule_startTime: getDayJsObjectWithFormattedString(start_time, 'HH:mm:ss'),
      timeRangeInSchedule_endTime: getDayJsObjectWithFormattedString(end_time, 'HH:mm:ss'),
      selectedItem,
      rewardItem,
      itemQuantity,
      qualityReward,
      critereas,
      filters,
      customerRestriction: customerRestrictionSelection,
      applies_to_product_in_sale,
      ignore_price_lock,
      max_discount_item_count,
      combine_with_other_promo,
      excluded_products,
      rewards_Data: getRewardData(promotionFormInitialValues?.discount)
    };

    if (formValues.promotionType === 'BUNDLE_DISC') {
      promotionForm.setFieldsValue({ appliesTo: 'ITEM', rewardType: 'BUNDLE_DISCOUNT' });
    }

    if (from_date && to_date && from_time && to_time) {
      formValues = {
        ...formValues,
        dateRangeInScheduleFilter: [
          getDayJsObjectWithFormattedString(from_date, 'YYYY-MM-DD'),
          getDayJsObjectWithFormattedString(to_date, 'YYYY-MM-DD')
        ],
        timeRangeInScheduleFilter: [
          getDayJsObjectWithFormattedString(from_time, 'HH:mm:ss'),
          getDayJsObjectWithFormattedString(to_time, 'HH:mm:ss')
        ]
      };
    }
    //!Set the unnecessary states also with the form values TODO - need to refactor
    triggerSelectionHandler(trigger);
    changeCountryHandler(countryValue as string);
    //!Visible on popup radio
    setRadioValue(visible_on_popup ? 1 : 2);
    //!Code type
    const codeTypeValue = code_type === 'SINGLE' ? 1 : 2;
    setSingleRadio(codeTypeValue);

    //!ValidTimeframe
    setValidTimeFrame(validTimeFrame);

    promotionForm.setFieldsValue(formValues);

    setPromotionFormValues(formValues);
    setLoading(false);
  };

  React.useEffect(() => {
    promotionForm.setFieldsValue(promotionFormValues);
  }, [promotionFormValues]);

  const getExcludedProductsFromApiCriterea = (criteria: { criteria_list: Criteria[] }): string[] => {
    const productExlcusionCriterea = criteria.criteria_list.find(
      criterion =>
        criterion.type === 'PRODUCTS' && criterion.operation === 'EXCLUDE' && criterion.value_type === 'SKU_ID'
    );

    return productExlcusionCriterea?.values || [];
  };

  const extractCountryValue = (criteria: { criteria_list: Criteria[] }) => {
    const countryCriterion = criteria.criteria_list.find(
      criterion => criterion.type === 'COUNTRY' && criterion.values?.length > 0
    );

    return countryCriterion?.values[0] || '';
  };

  const extractValuesFromCriteras = (criteria: any, key: any) => {
    criteria.criteria_list = criteria.criteria_list.filter((item: any) => item !== null);
    const criterea = criteria?.criteria_list?.find((criterion: any) => criterion.type === key && criterion.values?.length > 0);
    return criterea?.values || [];
  };

  const RFMextractValuesFromCriteras = (criteria: any, key: any) => {
    let nedC = criteria.criteria_list.filter((criteria: any) => criteria !== null);
    let data = nedC?.filter((item: any) => item.type === "RFM_CATEGORY");
    return data.length > 0 ? data[0].values : [];
  };


  const getDateString = (date: any, formatter: string) => {
    let reqDate = dayjs(date).format(formatter);
    return reqDate;
  };

  const getTimeString = (time: any, format: string) => {
    let reqTime = dayjs(time).format(format);
    return reqTime;
  };

  const triggerSelectionHandler = (value: string) => {
    setPromotionTypeList(promotionTypes);
  };

  const changeCountryHandler = (value: string) => {
    promotionForm.getFieldsValue().currency = null;
    promotionForm.getFieldsValue().country = [];
    setCurrencyTypeList(currencyList.filter((item: any) => item.country === value));
    setTimeZoneList(timeZones.filter((item: any) => item.country === value));
  };

  const handleSave = async () => {
    setLoading(true);
    if (isUpdate) {
      await handleUpdate();
    } else {
      const bodyData = generateRequestBody();
      const { data, errors } = await promotionServiceCreate.getCreatePromotion(bodyData);
      if (_.isEmpty(errors)) {
        displaySuccessNotification({ message: 'Promotion created successfully!' });
        setpromotionID(data.promotion.id);
      } else {
        displayErrorNotifications(errors);
      }
    }
    setLoading(false);
  };

  const { tenentName, bussinessGroupName } = useAuth(({ tenentName, bussinessGroupName }) => ({
    tenentName,
    bussinessGroupName
  }));

  const generateRequestBody = () => {
    //!New Fields

    let {
      productSelection_RC,
      critereas,
      filters,
      applies_to_product_in_sale,
      combine_with_other_promo,
      ignore_price_lock,
      excluded_products
    } = promotionForm.getFieldsValue();
    critereas = critereas || [];
    filters = filters || [];
    let TireValues = filters
      .filter((item: any) => item.filter_type === 'TIER')
      .map((item: any) => item.customerTireRestriction)
      .flat();
    let SegmentValues = filters
      .filter((item: any) => item.filter_type === 'SEGMENT')
      .map((item: any) => item.segment)
      .flat();
    let RfmValues = filters.filter((item: any) => item.filter_type === 'RFM_CATEGORY').map((item: any) => item.RFM_CATEGORY).flat();
    let filterData = filters.map((item: any) => {
      if (item.filter_type === 'TIER') {
        return {
          operation: 'INCLUDE',
          type: 'CUSTOMER_TIER',
          value_type: '',
          values: TireValues
        };
      } else if (item.filter_type === 'SEGMENT' && Array.isArray(item.segment)) {
        return {
          operation: 'INCLUDE',
          type: 'CUSTOMER_SEGMENT',
          value_type: '',
          values: SegmentValues
        };
      } else if (item.filter_type === 'RFM_CATEGORY' && Array.isArray(item.RFM_CATEGORY)) {
        return {
          operation: 'INCLUDE',
          type: 'RFM_CATEGORY',
          value_type: '',
          values: RfmValues
        };
      }
      return null;
    });

    let buyer_selection =
      promotionForm.getFieldsValue().userSelection || promotionFormInitialValues?.buyer_selection || 'ALL';
    let buyers: any = promotionForm.getFieldsValue().specific_buyer || [];
    const exclusions = [];

    if (!_.isEmpty(excluded_products)) {
      exclusions.push({
        operation: 'EXCLUDE',
        type: 'PRODUCTS',
        value_type: 'SKU_ID',
        values: excluded_products || []
      });
    }
    let specialDayData = promotionForm.getFieldsValue().special_day
      ?
      {
        operation: 'INCLUDE',
        type: 'SPL_DATE',
        value_type: '',
        values: ['birthday']
      }
      : null;
    let Inclusions = [
      {
        operation: 'INCLUDE',
        type: 'COUNTRY',
        value_type: '',
        values: [promotionForm.getFieldsValue()?.country]
      },
      specialDayData,
      ...filterData
    ];

    let validUntilStartDate = promotionForm.getFieldsValue().dateRangeInDiscount
      ? getDateString(promotionForm.getFieldsValue().dateRangeInDiscount[0], 'YYYY-MM-DD')
      : null;
    let validUnitlEndDate = promotionForm.getFieldsValue().dateRangeInDiscount
      ? getDateString(promotionForm.getFieldsValue().dateRangeInDiscount[1], 'YYYY-MM-DD')
      : null;
    let validUntilStartTime = promotionForm.getFieldsValue().timeRangeInDiscount_StartTime
      ? getTimeString(promotionForm.getFieldsValue().timeRangeInDiscount_StartTime, 'HH:mm:ss')
      : null;
    let validUnitilEndTime = promotionForm.getFieldsValue().timeRangeInDiscount_EndTime
      ? getTimeString(promotionForm.getFieldsValue().timeRangeInDiscount_EndTime, 'HH:mm:ss')
      : null;
    let rewardDetails = promotionForm?.getFieldsValue().rewards_Data
      ? getRewards(promotionForm.getFieldsValue().rewards_Data, promotionForm.getFieldsValue().currency)
      : [];

    let cretriaData = [...exclusions, ...Inclusions].filter(item => item !== null)

    const newRequest = {
      basic_details: {
        tenant_id: tenentName,
        business_group_id: bussinessGroupName,
        title: promotionForm.getFieldsValue().title,
        trigger: promotionForm.getFieldsValue().trigger,
        rank: Number(promotionForm.getFieldsValue().priority),
        promotion_type: promotionForm.getFieldsValue().promotionType,
        status: promotionForm.getFieldsValue().status,
        application_type: promotionForm.getFieldsValue().appliesTo,
        application: promotionForm.getFieldsValue().applicability,
        currency: promotionForm.getFieldsValue().currency,
        visibility: promotionForm.getFieldsValue().for_generate
          ? promotionForm.getFieldsValue().for_generate
          : 'ANONYMOUS',
        buyer_selection,
        buyers,
        seller_selection: promotionForm.getFieldsValue().seller_Selection || 'ALL',
        staff_selection: 'NONE', //hardCoded as their is no staff selection now CONFIRMED BY BE
        sellers: promotionForm.getFieldsValue().specific_seller ? promotionForm.getFieldsValue().specific_seller : [],
        product_selection: promotionForm.getFieldsValue().productSelection_RC || 'ALL',
        reward_type: promotionForm.getFieldsValue().rewardType,
        next_order_applicable:
          promotionForm.getFieldsValue().trigger === 'MANUAL'
            ? promotionForm.getFieldsValue().next_order_applicable
            : null,
        no_of_next_orders:
          promotionForm.getFieldsValue().next_order_applicable === true
            ? promotionForm.getFieldsValue().no_of_next_orders
            : null
      },
      criterias: {
        criteria_list: cretriaData
      },
      channel_details: {
        is_pos: promotionForm.getFieldsValue().isAvailablePos,
        pos: promotionForm.getFieldsValue().posList ? promotionForm.getFieldsValue().posList : [],
        online: promotionForm.getFieldsValue().onlineList ? promotionForm.getFieldsValue().onlineList : [],
        is_online: promotionForm.getFieldsValue().isAvailableOnline
      },
      loyalty_details: promotionForm.getFieldsValue()?.reward_name
        ? {
          reward_name: promotionForm.getFieldsValue()?.reward_name,
          reward_value: promotionForm.getFieldsValue()?.reward_value
        }
        : null,
      usage_details: {
        max_usage: Number(promotionForm.getFieldsValue().maxUsage),
        limit_per_user: Number(promotionForm.getFieldsValue().limitPerUser)
      },
      validity: {
        start_date: promotionForm.getFieldsValue().dateRangeInSchedule
          ? getDateString(promotionForm.getFieldsValue().dateRangeInSchedule[0], 'YYYY-MM-DD')
          : null,
        end_date: promotionForm.getFieldsValue().dateRangeInSchedule
          ? getDateString(promotionForm.getFieldsValue().dateRangeInSchedule[1], 'YYYY-MM-DD')
          : null,
        start_time:
          promotionForm.getFieldsValue().timeRangeInSchedule_startTime &&
          getTimeString(promotionForm.getFieldsValue().timeRangeInSchedule_startTime, 'HH:mm:ss'),
        end_time:
          promotionForm.getFieldsValue().timeRangeInSchedule_endTime &&
          getTimeString(promotionForm.getFieldsValue().timeRangeInSchedule_endTime, 'HH:mm:ss'),
        timezone: promotionForm.getFieldsValue().timeZone,
        valid_on_given_time_frame: validTimeFrame === 1
      },
      schedule: getSchedule(),
      code_details:
        promotionForm.getFieldsValue().trigger === 'MANUAL'
          ? {
            code_type: singleRadio === Number(1) ? 'SINGLE' : 'MULTIPLE',
            code: promotionForm.getFieldsValue()?.code_gen || null,
            coupon_quantity_to_generate:
              singleRadio === Number(2) ? Number(promotionForm.getFieldsValue().quantityToGenerate) : 1,
            coupon_start_date: validUntilStartDate,
            coupon_end_date: validUnitlEndDate,
            coupon_start_time: validUntilStartTime,
            coupon_end_time: validUnitilEndTime,
            coupon_usage_limit: promotionForm.getFieldsValue().coupon_usage_limit
          }
          : null,
      reward_details: rewardDetails,
      additional_config: {
        transaction_apply_count:
          promotionForm.getFieldsValue().appliesPerTransaction === 'UNLIMITED'
            ? 99999
            : Number(promotionForm.getFieldsValue().appliesPerTransaction_Count) || 1,
        new_buyer: promotionForm.getFieldsValue().onlyForNewUser ? true : false,
        visible_on_popup: radioValue === Number(1) ? true : false,
        ignore_price_lock: ignore_price_lock ? ignore_price_lock : false,
        applies_to_product_in_sale: applies_to_product_in_sale ? applies_to_product_in_sale : false,
        combine_with_other_promo: combine_with_other_promo ? combine_with_other_promo : false
      }
    };

    return newRequest;
  };

  const handleUpdate = async () => {
    const bodyData = generateRequestBody();
    const { data, errors } = await promotionServiceCreate.updatePromotion(
      bodyData,
      promotionFormInitialValues?.id || (id as string)
    );
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Promotion updated successfully!' });
      setpromotionID(data?.promotion?.id);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const scheduleDiscount = async () => {
    if (promotionID == null) {
      return;
    }
    const { data, errors } = await promotionServiceCreate.scheduleDiscount(promotionID);

    if (data?.success == true) {
      displaySuccessNotification({ message: 'Promotion scheduled successfully!' });
      setTimeout(() => {
        navigate(
          `/promotion-engine/promotions?promotion_type=${promotionForm.getFieldsValue().promotionType
          }&sort_by=created_at&offset=0`
        );
      }, 1000);
    } else {
      displayErrorNotifications(errors);
    }
  };
  const getSchedule = () => {
    let schedule = {};
    if (promotionForm.getFieldsValue().daily_val) {
      schedule = {
        ...schedule,
        ...{
          daily: promotionForm.getFieldsValue().daily_val,
          daily_info: {
            from_date: promotionForm.getFieldsValue().dateRangeInScheduleFilter
              ? getDateString(promotionForm.getFieldsValue().dateRangeInScheduleFilter[0], 'YYYY-MM-DD')
              : null,
            to_date: promotionForm.getFieldsValue().dateRangeInScheduleFilter
              ? getDateString(promotionForm.getFieldsValue().dateRangeInScheduleFilter[1], 'YYYY-MM-DD')
              : null,
            from_time: promotionForm.getFieldsValue().timeRangeInScheduleFilter
              ? getTimeString(promotionForm.getFieldsValue().timeRangeInScheduleFilter[0], 'HH:mm:ss')
              : null,
            to_time: promotionForm.getFieldsValue().timeRangeInScheduleFilter
              ? getTimeString(promotionForm.getFieldsValue().timeRangeInScheduleFilter[1], 'HH:mm:ss')
              : null
          }
        }
      };
    }
    if (promotionForm.getFieldsValue().week_val) {
      schedule = {
        ...schedule,
        ...{
          weekly: promotionForm.getFieldsValue().week_val,
          weekly_info: promotionForm.getFieldsValue().weekValues || []
        }
      };
    }

    // if (promotionForm.getFieldsValue().special_day) {
    //   schedule = {
    //     ...schedule,
    //     ...{
    //       weekly: promotionForm.getFieldsValue().week_val,
    //       weekly_info: promotionForm.getFieldsValue().specialdays || []
    //     }
    //   };
    // }

    if (promotionForm.getFieldsValue().month_val) {
      schedule = {
        ...schedule,
        ...{
          monthly: promotionForm.getFieldsValue().month_val,
          monthly_info: promotionForm.getFieldsValue().monthValues || []
        }
      };
    }
    if (promotionForm.getFieldsValue().seasonal_val) {
      schedule = {
        ...schedule,
        ...{
          seasonal: promotionForm.getFieldsValue().seasonal_val,
          seasonal_info: promotionForm.getFieldsValue().seasonalValues || []
        }
      };
    }
    let returnScheduleData = Object.values(schedule).length > 0 ? schedule : null;
    return returnScheduleData;
  };

  const onChangeRadio = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const onChangeRadioS_M = (e: RadioChangeEvent) => {
    setSingleRadio(e.target.value);
  };

  const handleValidTimeFrame = (e: RadioChangeEvent) => {
    setValidTimeFrame(e.target.value);
  };

  const handleGenerateRandomString = () => {
    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset[randomIndex];
    }

    promotionForm.setFieldValue('code_gen', result);
  };

  const disabledDate = (current: any) => {
    // Disable dates before today
    return current && current < dayjs().startOf('day');
  };

  type RangeType = 'start' | 'end';

  type DisabledTimes = {
    disabledHours?: () => number[];
    disabledMinutes?: (selectedHour: number) => number[];
    disabledSeconds?: (selectedHour: number, selectedMinute: number) => number[];
  };

  const disabledTime = (_: any | null, type: RangeType): DisabledTimes => {
    if (type === 'start') {
      return {
        disabledHours: () => Array.from<number>({ length: dayjs().hour() + 1 }).map((_, index) => index),
        disabledMinutes: selectedHour => {
          if (selectedHour === dayjs().hour()) {
            return Array.from<number>({ length: dayjs().minute() + 1 }).map((_, index) => index);
          }
          return [];
        },
        disabledSeconds: (selectedHour, selectedMinute) => {
          if (selectedHour === dayjs().hour() && selectedMinute === dayjs().minute()) {
            return Array.from<number>({ length: dayjs().second() + 1 }).map((_, index) => index);
          }
          return [];
        }
      };
    }

    return {};
  };

  const disabledNewTime = (current: Dayjs | null) => {
    if (!current) {
      // allow all times if no value is selected
      return {};
    }

    const now = dayjs();
    const isBeforeCurrentDate = current.isBefore(now, 'day');
    const isSameCurrentDate = current.isSame(now, 'day');

    if (isBeforeCurrentDate) {
      // disable all times if the date is before the current date
      return {
        disabledHours: () => Array.from({ length: 24 }).map((_, index) => index),
        disabledMinutes: () => Array.from({ length: 60 }).map((_, index) => index),
        disabledSeconds: () => Array.from({ length: 60 }).map((_, index) => index)
      };
    } else if (isSameCurrentDate) {
      // disable hours, minutes, and seconds that are before the current time
      return {
        disabledHours: () => Array.from({ length: now.hour() }).map((_, index) => index),
        disabledMinutes: (selectedHour: number) =>
          selectedHour === now.hour() ? Array.from({ length: now.minute() }).map((_, index) => index) : [],
        disabledSeconds: (selectedHour: number, selectedMinute: number) =>
          selectedHour === now.hour() && selectedMinute === now.minute()
            ? Array.from({ length: now.second() }).map((_, index) => index)
            : []
      };
    }

    // allow all times for future dates
    return {};
  };

  //! Select dropdown menu fetching operations

  //!User fetching
  const { debouncedFetchUserDropdownOptions, userDropdownFetching, userDropdownOptions } = useCustomers();
  const { debouncedFetchProductDropdownOptions, productDropdownFetching, productDropdownOptions } = useProducts();
  const { modelDropdownOptions } = useModels();
  const { brandDropdownOptions } = useBrands();
  const { sellerDropdownOptions, debouncedFetchSellerDropdownOptions, sellerDropdownFetching } = useSellers();
  const { debouncedFetchPosDropdownOptions, posDropdownFetching, posDropdownOptions } = usePos();
  const { debouncedFetchOnlineDropdownOptions, onlineDropdownFetching, onlineDropdownOptions } = useOnlineChannels();

  //! Product fetching hook
  const getRewards = (rewardDetails: any, currency: any) => {
    const { promotionType, appliesTo } = promotionForm.getFieldsValue();
    let modifiedRewardDetails = {};
    if (promotionType === 'BUNDLE_DISC') {
      modifiedRewardDetails = rewardDetails?.map((reward: any) => ({
        // ...reward,
        discount_id: reward?.discount_id,
        discount_type: reward?.discount_type,
        threshold_selection: reward?.threshold_selection,
        discount_value: Number(reward?.discount_value) || 0,
        minimum_value: Number(reward?.minimum_value) || 0,
        cap_value: Number(reward?.cap_value) || 0,
        max_discount_item_count: Number(reward?.max_discount_item_count) || 0,
        applies_to_price: reward?.applies_to_price || null,
        max_discount: Number(reward?.max_discount) || 0,
        // currency: currency,
        product_reward: {
          ...reward.product_reward,
          product_info: reward.product_reward.product_info.map((productInfo: any) => ({
            ...productInfo,
            selection_type: 'BUNDLE',
            identifier_type: 'SKU_ID'
          }))
        }
      }));
    } else if (promotionType === 'BXGY') {
      modifiedRewardDetails = rewardDetails?.map((reward: any) => ({
        discount_id: reward?.discount_id,
        discount_type: reward?.discount_type,
        threshold_selection: reward?.threshold_selection,
        discount_value: reward?.discount_value || 0,
        minimum_value: reward?.minimum_value || 0,
        cap_value: reward?.cap_value || 0,
        max_discount_item_count: reward?.max_discount_item_count || 0,
        applies_to_price: reward?.applies_to_price || null,
        max_discount: reward?.max_discount || 0,
        product_reward: {
          product_info: getBXGYProductInfo(reward?.product_reward?.product_info, reward?.applies_to_price)
        }
      }));
    } else if (promotionType === 'VOLUME_DISC') {
      modifiedRewardDetails = rewardDetails?.map((reward: any) => ({
        // ...reward,
        discount_id: reward?.discount_id,
        discount_type: reward?.discount_type,
        threshold_selection: reward?.threshold_selection,
        discount_value: reward?.discount_value || 0,
        minimum_value: reward?.minimum_value || 0,
        cap_value: reward?.cap_value || reward?.minimum_value || 0,
        applies_to_price: reward?.applies_to_price || null,
        max_discount: reward?.max_discount || 0,
        // currency: currency,
        product_reward: {
          ...reward.product_reward,
          product_info: [
            {
              selection_type: 'VOLUME',
              identifier_type: 'SKU_ID',
              value: reward.product_reward.product_info[0].value
              // quantity: reward.product_reward.product_info[0].quantity
            }
          ]
        }
      }));
    } else if (promotionType === 'SUB_TTL_DIS') {
      modifiedRewardDetails = rewardDetails?.map((reward: any, key: any) => ({
        // ...reward,
        discount_id: reward?.discount_id,
        discount_type: reward?.discount_type,
        threshold_selection: reward?.threshold_selection,
        discount_value: reward?.discount_value || 0,
        minimum_value: reward?.minimum_value || 0,
        cap_value: reward?.cap_value || 0,
        max_discount_item_count: reward?.max_discount_item_count || 0,
        max_discount: reward?.max_discount || 0,
        // currency: currency,
        product_reward: appliesTo === 'LINE_ITEMS' ? getSkuLineItems(reward?.product_reward?.product_info, reward?.applies_to_price) : null
      }));
    } else if (promotionType === 'SHIPPING_DISC') {
      modifiedRewardDetails = rewardDetails?.map((reward: any, key: any) => ({
        // ...reward,
        discount_id: reward?.discount_id,
        discount_type: reward?.discount_type,
        threshold_selection: reward?.threshold_selection,
        discount_value: reward?.discount_value || 0,
        minimum_value: reward?.minimum_value || 0,
        cap_value: reward?.cap_value || 0,
        max_discount_item_count: reward?.max_discount_item_count || 0,
        applies_to_price: reward?.applies_to_price || null,
        max_discount: reward?.max_discount || 0
        // currency: currency
      }));
    }
    return modifiedRewardDetails;
  };

  const getBXGYProductInfo = (productInfoData: any, applies_to_price: any) => {
    let data = productInfoData.map((item: any) => {
      return (
        item?.selection_type && {
          selection_type: item?.selection_type,
          identifier_type: item?.identifier_type,
          value: item?.value,
          quantity:
            item?.selection_type === 'REWARDED' ? (applies_to_price === 'NONE' ? item?.quantity : null) : item?.quantity
        }
      );
    });
    data = data.filter((obj: any) => obj !== null && typeof obj === 'object' && Object.keys(obj).length !== 0);
    return data;
  };

  const getSkuLineItems = (productInfoData: any, applies_to_price: any) => {
    let data = productInfoData.map((item: any) => {
      return (
        item?.selection_type && {
          selection_type: item?.selection_type,
          identifier_type: item?.identifier_type,
          value: item?.value,
          quantity:
            item?.selection_type === 'REWARDED' ? (applies_to_price === 'NONE' ? item?.quantity : null) : item?.quantity
        }
      );
    });
    data = data.filter((obj: any) => obj !== null && typeof obj === 'object' && Object.keys(obj).length !== 0);
    let newData = {
      product_info: data
    }
    return newData;
  };

  return (
    <Form form={promotionForm} scrollToFirstError layout="vertical" onFinish={handleSave}>
      <div
        style={{
          height: 'fit-content',
          width: '100%',
          background: '#9b9b9b29',
          padding: '20px',
          borderRadius: '20px',
          marginBottom: '15px'
        }}
      >
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Title"
              name="title"
              rules={[validationHelpers.getRequiredValidator({ message: 'This field is required' })]}
            >
              <Input size="large" placeholder="Title" disabled={false} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="Status" name="status">
              <Input size="large" defaultValue={'Open'} placeholder="Status" disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Applicability"
              name="applicability"
              rules={[validationHelpers.getRequiredValidator({ message: 'This field is required' })]}
            >
              <Select
                placeholder="Select an option"
                size="large"
                onChange={value => {
                  if (value === 'REGISTRATION') {
                    promotionForm.setFieldsValue({
                      thresholdSelection: 'NO_THRESHOLD'
                    });
                  }
                }}
                options={[
                  { label: 'Order', value: 'CART' },
                  { label: 'Registration', value: 'REGISTRATION' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Trigger"
              name="trigger"
              rules={[validationHelpers.getRequiredValidator({ message: 'This field is required' })]}
            >
              <Select
                onChange={triggerSelectionHandler}
                placeholder="Select an option"
                size="large"
                disabled={true}
                options={[
                  { label: 'Automatic', value: 'AUTOMATIC' },
                  { label: 'Manual', value: 'MANUAL' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Promotion Type"
              name="promotionType"
              rules={[validationHelpers.getRequiredValidator({ message: 'This field is required' })]}
            >
              <Select
                disabled={true}
                placeholder="Select an option"
                size="large"
                optionFilterProp="value"
                options={promotionTypeList}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Country"
              name="country"
              rules={[validationHelpers.getRequiredValidator({ message: 'This field is required' })]}
            >
              <Select
                onChange={changeCountryHandler}
                placeholder="Select an option"
                size="large"
                options={countryList}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Time Zone"
              name="timeZone"
              rules={[validationHelpers.getRequiredValidator({ message: 'This field is required' })]}
            >
              <Select placeholder="Select an option" size="large" options={timeZoneList}></Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Currency"
              name="currency"
              rules={[validationHelpers.getRequiredValidator({ message: 'This field is required' })]}
            >
              <Select placeholder="Select an option" size="large" options={currencyTypeList}></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Form.Item noStyle shouldUpdate>
            {form => {
              const { trigger, promotionType } = form.getFieldsValue();
              return (
                trigger === 'MANUAL' && promotionType !== "LOYT_REWARD" && (
                  <Col xs={24} md={6} className="mt-8">
                    <Form.Item name="next_order_applicable" valuePropName="checked">
                      <Checkbox value={'order_applicable'}>Next Order Applicable ?</Checkbox>
                    </Form.Item>
                  </Col>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {form => {
              const { trigger, next_order_applicable } = form.getFieldsValue();
              return (
                trigger === 'MANUAL' &&
                next_order_applicable && (
                  <Col xs={24} md={6}>
                    <Form.Item
                      name="no_of_next_orders"
                      label="Number Of Next Orders"
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <InputNumber
                        min={0}
                        className="w-full"
                        size="large"
                        placeholder="Number of Next Order"
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                )
              );
            }}
          </Form.Item>
        </Row>
      </div>
      <div
        style={{
          height: 'fit-content',
          width: '100%',
          background: '#9b9b9b29',
          padding: '20px',
          borderRadius: '20px',
          marginBottom: '15px'
        }}
      >
        <Typography.Title level={4}>Qualifying Conditions</Typography.Title>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Applies to Class"
              name="appliesTo"
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder="Select item values"
                size="large"
                allowClear
                disabled={
                  promotionForm.getFieldsValue().promotionType === 'VOLUME_DISC' ||
                  promotionForm.getFieldsValue().promotionType === 'BUNDLE_DISC' ||
                  promotionForm.getFieldsValue().promotionType === 'BXGY' ||
                  promotionForm.getFieldsValue().promotionType === 'SHIPPING_DISC' ||
                  promotionForm.getFieldsValue().promotionType === 'LOYT_REWARD'
                }
                onChange={option => {
                  if (option === 'LOYT_POINTS') {
                    promotionForm.setFieldsValue({ rewardType: 'POINTS' });
                  } else {
                    promotionForm.setFieldsValue({ rewardType: 'DISCOUNT' });
                  }
                }}
                options={
                  promotionForm.getFieldsValue().promotionType === 'SUB_TTL_DIS'
                    ? appliesToClassForSubTotal
                    : appliesToClassForAll
                }
              ></Select>
            </Form.Item>
          </Col>

          <Form.Item noStyle shouldUpdate>
            {form => {
              const { appliesTo } = form.getFieldsValue();
              return (
                appliesTo === 'LOYT_POINTS' && (
                  <>
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Reward Loyalty Name"
                        name="reward_name"
                        rules={[{ required: true, message: "This field can't be empty" }]}
                      >
                        <Input placeholder="Enter reward loyalty name" size="large"></Input>
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Reward Loyalty Points"
                        name="reward_value"
                        rules={[{ required: true, message: "This field can't be empty" }]}
                      >
                        <InputNumber
                          className="w-full"
                          min={0}
                          placeholder="Enter reward loyalty points"
                          size="large"
                        ></InputNumber>
                      </Form.Item>
                    </Col>
                  </>
                )
              );
            }}
          </Form.Item>

          <Col xs={12} md={6}>
            <Form.Item noStyle shouldUpdate>
              {form => {
                const { appliesTo } = form.getFieldsValue();
                const isDisabled = ['LOYT_POINTS', 'ORDER_TOTAL', 'LINE_ITEMS', 'SHIPPING', 'PAYMENTS'].includes(
                  appliesTo
                );
                return (
                  <Form.Item
                    label="Reward Type"
                    name="rewardType"
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Select
                      disabled={isDisabled}
                      placeholder="Select"
                      size="large"
                      options={[
                        { label: 'Products', value: 'PRODUCT' },
                        { label: 'Discount', value: 'DISCOUNT' },
                        { label: 'Volume Discount', value: 'VOLUME_DISCOUNT' },
                        { label: 'Loyalty Points', value: 'POINTS' },
                        { label: 'Bundle Discount', value: 'BUNDLE_DISCOUNT' }
                      ]}
                    ></Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        {/* REDESIGNED PRODUCT SELECTION */}
        <Form.Item noStyle shouldUpdate>
          {form => {
            const { promotionType, applicability } = form.getFieldsValue();
            return promotionType !== 'BXGY' && applicability !== 'REGISTRATION' ? (
              <section>
                <Row>
                  <Col xs={24}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) => {
                        return prevValues.productSelection_RC !== curValues.productSelection_RC;
                      }}
                    >
                      {form => {
                        const { productSelection_RC } = form.getFieldsValue();
                        return productSelection_RC === 'SPECIFIC' ? (
                          <div>
                            <span className="">Product Attribute Filters</span>
                            <div className="my-4">
                              <Form.List name="critereas">
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                      <React.Fragment key={key}>
                                        <Row>
                                          <Col xs={12}>
                                            <Divider orientation="left" plain>
                                              <span className="text-lg">Attribute - {index + 1} </span>
                                            </Divider>
                                          </Col>
                                        </Row>

                                        <Row gutter={12}>
                                          <Col xs={12} md={6}>
                                            <Form.Item noStyle shouldUpdate>
                                              {form => {
                                                const attributes = [
                                                  { label: 'SKU', value: 'SKU' },
                                                  { label: 'Category', value: 'CATEGORY' },
                                                  { label: 'Brand', value: 'BRAND' },
                                                  { label: 'Model', value: 'MODEL' }
                                                ];

                                                const selectedTypes = (form.getFieldsValue()?.critereas || []).map(
                                                  (criterea: any) => {
                                                    return criterea?.attribute_type;
                                                  }
                                                );

                                                const filteredAttributes = attributes.filter(attr => {
                                                  return !selectedTypes.includes(attr.value);
                                                });

                                                return (
                                                  <Form.Item
                                                    {...restField}
                                                    name={[name, 'attribute_type']}
                                                    label="Attribute type"
                                                    rules={[{ required: true, message: 'Attribute Type is required' }]}
                                                  >
                                                    <Select
                                                      size="large"
                                                      placeholder="Select Attribute type"
                                                      options={filteredAttributes}
                                                    ></Select>
                                                  </Form.Item>
                                                );
                                              }}
                                            </Form.Item>
                                          </Col>

                                          <Form.Item noStyle shouldUpdate>
                                            {form => {
                                              const isDataPresent = form.getFieldsValue()?.critereas?.length;

                                              const attributeType = isDataPresent
                                                ? form.getFieldsValue()?.critereas[index]?.attribute_type
                                                : '';

                                              const inputMap: Record<string, any> = {
                                                SKU: {
                                                  name: 'sku',
                                                  label: 'SKUs',
                                                  component: (
                                                    <Select
                                                      mode="multiple"
                                                      allowClear
                                                      size="large"
                                                      onSearch={async searchTerm => {
                                                        if (searchTerm)
                                                          await debouncedFetchProductDropdownOptions(searchTerm);
                                                      }}
                                                      filterOption={false}
                                                      placeholder="Search products"
                                                      notFoundContent={
                                                        productDropdownFetching ? <Spin size="small" /> : null
                                                      }
                                                      options={productDropdownOptions}
                                                    ></Select>
                                                  )
                                                },
                                                CATEGORY: {
                                                  name: 'category',
                                                  label: 'Category',
                                                  component: (
                                                    <Select
                                                      mode="tags"
                                                      allowClear
                                                      options={[]}
                                                      size="large"
                                                      placeholder="Select / Enter Category"
                                                    ></Select>
                                                  )
                                                },
                                                BRAND: {
                                                  name: 'brand',
                                                  label: 'Brands',
                                                  component: (
                                                    <Select
                                                      mode="tags"
                                                      allowClear
                                                      options={brandDropdownOptions.map(value => ({
                                                        label: value,
                                                        value
                                                      }))}
                                                      size="large"
                                                      placeholder="Select / Enter Brands"
                                                    ></Select>
                                                  )
                                                },
                                                MODEL: {
                                                  name: 'model',
                                                  label: 'Model',
                                                  component: (
                                                    <Select
                                                      mode="tags"
                                                      allowClear
                                                      options={modelDropdownOptions.map(value => ({
                                                        label: value,
                                                        value
                                                      }))}
                                                      size="large"
                                                      placeholder="Select / Enter Models"
                                                    ></Select>
                                                  )
                                                }
                                              };

                                              const componentInfo = inputMap[attributeType];
                                              return (
                                                componentInfo && (
                                                  <Col xs={12} md={6}>
                                                    <Form.Item
                                                      {...restField}
                                                      name={[name, componentInfo?.name]}
                                                      label={componentInfo?.label}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: `${componentInfo?.label} is required`
                                                        }
                                                      ]}
                                                    >
                                                      {componentInfo?.component}
                                                    </Form.Item>
                                                  </Col>
                                                )
                                              );
                                            }}
                                          </Form.Item>

                                          <div className="w-full md:w-[50px] md:mx-8 flex justify-center items-center">
                                            <DeleteOutlined
                                              className="text-red-500 text-xl"
                                              title={`Delete Attribute ${index + 1}`}
                                              onClick={() => remove(name)}
                                            />
                                          </div>
                                        </Row>
                                      </React.Fragment>
                                    ))}
                                    <Form.Item>
                                      <Row justify={'start'}>
                                        <Col xs={24} md={4}>
                                          <Button onClick={() => add()} icon={<PlusOutlined />}>
                                            <BoldButtonLabel labelText="Add Attribute" />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                            </div>
                          </div>
                        ) : null;
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </section>
            ) : null;
          }}
        </Form.Item>
        <Typography.Title level={5}>Redeemable Channel</Typography.Title>
        <Row gutter={12}>
          <Col xs={24} className="mb-2">
            <Typography.Text className="font-semibold text-sm">Available On</Typography.Text>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              name="isAvailablePos"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const isAvailableOnline = getFieldValue('isAvailableOnline');
                    const isAvailablePos = value;
                    if (isAvailableOnline || isAvailablePos) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Check one of the above option'));
                  }
                })
              ]}
              valuePropName="checked"
            >
              <Checkbox value={'POS'}>POS</Checkbox>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return prevValues.isAvailablePos !== curValues.isAvailablePos;
              }}
            >
              {form => {
                const { isAvailablePos } = form.getFieldsValue();
                return isAvailablePos ? (
                  <Form.Item label="Specific POS" name={'posList'}>
                    <Select
                      mode="multiple"
                      allowClear
                      size="large"
                      placeholder="Search pos"
                      filterOption={false}
                      options={posDropdownOptions}
                      onSearch={async searchTerm => {
                        if (searchTerm) await debouncedFetchPosDropdownOptions(searchTerm);
                      }}
                      notFoundContent={posDropdownFetching ? <Spin size="small" /> : null}
                    ></Select>
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item name="isAvailableOnline" valuePropName="checked">
              <Checkbox value={'ONLINE'}>E Commerce</Checkbox>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return prevValues.isAvailableOnline !== curValues.isAvailableOnline;
              }}
            >
              {form => {
                const { isAvailableOnline } = form.getFieldsValue();
                return isAvailableOnline ? (
                  <Form.Item label="Specific Online" name={'onlineList'}>
                    <Select
                      mode="multiple"
                      allowClear
                      size="large"
                      placeholder="Search online stores"
                      filterOption={false}
                      options={onlineDropdownOptions}
                      onSearch={async searchTerm => {
                        if (searchTerm) await debouncedFetchOnlineDropdownOptions(searchTerm);
                      }}
                      notFoundContent={onlineDropdownFetching ? <Spin size="small" /> : null}
                    ></Select>
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item noStyle shouldUpdate>
          {form => {
            const { applicability } = form.getFieldsValue();
            return applicability !== 'REGISTRATION' ? (
              <>
                <Typography.Title level={5}>Exclusion Condition</Typography.Title>
                <Row gutter={12}>
                  <Col xs={24} md={6}>
                    <Form.Item label="Excluded Products" name={'excluded_products'}>
                      <Select
                        mode="multiple"
                        allowClear
                        size="large"
                        onSearch={async searchTerm => {
                          if (searchTerm) await debouncedFetchProductDropdownOptions(searchTerm);
                        }}
                        filterOption={false}
                        placeholder="Excluded products"
                        notFoundContent={productDropdownFetching ? <Spin size="small" /> : null}
                        options={productDropdownOptions}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : null;
          }}
        </Form.Item>
        <Typography.Title level={5}>Restriction Condition</Typography.Title>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Only For New User"
              name="onlyForNewUser"
              rules={[validationHelpers.getRequiredValidator({ message: 'This field is required' })]}
            >
              <Select
                placeholder="Select an option"
                size="large"
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item noStyle shouldUpdate>
          {form => {
            const { applicability } = form.getFieldsValue();

            return applicability !== 'REGISTRATION' ? (
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    name={'seller_Selection'}
                    label="Seller Selection"
                    rules={[{ required: false, message: 'Seller selection is required' }]}
                  >
                    <Select
                      options={[
                        { label: 'All', value: 'ALL' },
                        { label: 'Specific', value: 'SPECIFIC' }
                      ]}
                      size="large"
                      placeholder="Select seller selection"
                    ></Select>
                  </Form.Item>
                </Col>
                <Form.Item noStyle shouldUpdate>
                  {form => {
                    const { seller_Selection } = form.getFieldsValue();
                    return seller_Selection === 'SPECIFIC' ? (
                      <Col xs={12} md={6}>
                        <Form.Item label="Seller" name="specific_seller">
                          <Select
                            mode="multiple"
                            filterOption={false}
                            allowClear
                            onSearch={async searchTerm => {
                              if (searchTerm) {
                                await debouncedFetchSellerDropdownOptions(searchTerm);
                              }
                            }}
                            notFoundContent={sellerDropdownFetching ? <Spin size="small" /> : null}
                            size="large"
                            placeholder="Search Sellers"
                            options={sellerDropdownOptions}
                          ></Select>
                        </Form.Item>
                      </Col>
                    ) : null;
                  }}
                </Form.Item>
              </Row>
            ) : null;
          }}
        </Form.Item>
      </div>

      <div
        style={{
          height: 'fit-content',
          width: '100%',
          background: '#9b9b9b29',
          padding: '20px',
          borderRadius: '20px',
          marginBottom: '15px'
        }}
      >
        <Typography.Title level={5}>Usage</Typography.Title>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Limit per user"
              name="limitPerUser"
              rules={[
                { required: true, message: "This field can't be empty" },
                validationHelpers.getNumericValidatorForInput({})
              ]}
            >
              <Input size="large" placeholder="Eg 1" disabled={false} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Max Usage"
              name="maxUsage"
              rules={[
                { required: true, message: "This field can't be empty" },
                validationHelpers.getNumericValidatorForInput({})
              ]}
            >
              <Input size="large" placeholder="Eg 2" disabled={false} />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div
        style={{
          height: 'fit-content',
          width: '100%',
          background: '#9b9b9b29',
          padding: '20px',
          borderRadius: '20px',
          marginBottom: '15px'
        }}
      >
        <Typography.Title level={5}>Schedule</Typography.Title>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Date"
              name="dateRangeInSchedule"
              rules={[{ required: true, message: 'Fill start and end date' }]}
            >
              <DatePicker.RangePicker
                onChange={value => {
                  if (!promotionForm.getFieldsValue()?.dateRangeInDiscount?.length)
                    promotionForm.setFieldsValue({ dateRangeInDiscount: value });
                }}
                size="large"
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            {/* Start Time */}
            <Form.Item
              label="Start Time"
              name="timeRangeInSchedule_startTime"
              rules={[{ required: true, message: 'Fill start time' }]}
            >
              <TimePicker
                className="w-[100%]"
                onChange={value => {
                  if (!promotionForm.getFieldsValue()?.timeRangeInDiscount_StartTime?.length)
                    promotionForm.setFieldsValue({ timeRangeInDiscount_StartTime: value });
                }}
                onBlur={e => {
                  let startingTime = dayjs(e.target.value, 'HH:mm');
                  promotionForm.setFieldsValue({ timeRangeInSchedule_startTime: startingTime });
                }}
                size="large"
                format={'HH:mm'}
                disabledTime={disabledNewTime}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            {/* End Time */}
            <Form.Item
              label="End Time"
              name="timeRangeInSchedule_endTime"
              rules={[{ required: true, message: 'Fill end time' }]}
            >
              <TimePicker
                className="w-[100%]"
                onChange={value => {
                  if (!promotionForm.getFieldsValue()?.timeRangeInDiscount_EndTime?.length)
                    promotionForm.setFieldsValue({ timeRangeInDiscount_EndTime: value });
                }}
                onBlur={e => {
                  let endTime = dayjs(e.target.value, 'HH:mm');
                  promotionForm.setFieldsValue({ timeRangeInSchedule_endTime: endTime });
                }}
                size="large"
                format={'HH:mm'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <div style={{ marginBottom: '15px' }}>
            <Radio.Group onChange={handleValidTimeFrame} value={validTimeFrame}>
              <Radio value={1}>Valid in this timeframe only</Radio>
              <Radio value={2}>Valid on particular days only</Radio>
            </Radio.Group>
          </div>
        </Row>
        {validTimeFrame === 2 && <Typography.Title level={5}>Schedule Filter</Typography.Title>}
        {validTimeFrame === 2 && (
          <Row gutter={12}>
            <div
              style={{
                height: 'fit-content',
                width: '100%',
                background: '#9b9b9b29',
                padding: '20px',
                borderRadius: '20px',
                marginBottom: '15px'
              }}
            >
              <Form.Item name="daily_val" valuePropName="checked">
                <Checkbox value={'daily_val'}>Daily</Checkbox>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.daily_val !== curValues.daily_val;
                }}
              >
                {form => {
                  const { daily_val } = form.getFieldsValue();
                  return daily_val ? (
                    <Row gutter={12}>
                      <Col xs={12} md={6}>
                        <Form.Item
                          label="Date"
                          name="dateRangeInScheduleFilter"
                          rules={[{ required: false, message: 'Fill start and end date' }]}
                        >
                          <DatePicker.RangePicker size="large" disabledDate={disabledDate} />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Item
                          label="Time"
                          name="timeRangeInScheduleFilter"
                          rules={[{ required: false, message: 'Fill start time and end time' }]}
                        >
                          {/* disabledTime={disabledTime} Removed Disabling of time for cirrent */}
                          <RangePicker size="large" format={'HH:mm'} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : null;
                }}
              </Form.Item>
              <Form.Item name="week_val" valuePropName="checked">
                <Checkbox value={'week_val'}>Weekly</Checkbox>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.week_val !== curValues.week_val;
                }}
              >
                {form => {
                  const { week_val } = form.getFieldsValue();
                  return week_val ? (
                    <Row gutter={12}>
                      <Form.Item name="weekValues">
                        <Checkbox.Group>
                          {weeks.map((item: any) => {
                            return (
                              <Col>
                                <Checkbox value={item.value}>{item.label}</Checkbox>
                              </Col>
                            );
                          })}
                        </Checkbox.Group>
                      </Form.Item>
                    </Row>
                  ) : null;
                }}
              </Form.Item>
              <Form.Item name="month_val" valuePropName="checked">
                <Checkbox value={'month_val'}>Monthly</Checkbox>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.month_val !== curValues.month_val;
                }}
              >
                {form => {
                  const { month_val } = form.getFieldsValue();
                  return month_val ? (
                    <Form.Item name={'monthValues'}>
                      <Checkbox.Group>
                        <Row gutter={12}>
                          {months.map((item: any) => {
                            return <Checkbox value={item.value}>{item.label}</Checkbox>;
                          })}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
              <Form.Item name="seasonal_val" valuePropName="checked">
                <Checkbox value={'seasonal_val'}>Seasonaly</Checkbox>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.seasonal_val !== curValues.seasonal_val;
                }}
              >
                {form => {
                  const { seasonal_val } = form.getFieldsValue();
                  return seasonal_val ? (
                    <Form.Item name={'seasonalValues'}>
                      <Row gutter={12}>
                        <Checkbox.Group>
                          {seasons.map((item: any) => {
                            return <Checkbox value={item.value}>{item.label}</Checkbox>;
                          })}
                        </Checkbox.Group>
                      </Row>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
              <Form.Item name="special_day" valuePropName="checked">
                <Checkbox value={'special_day'}>Special Day</Checkbox>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.special_day !== curValues.special_day;
                }}
              >
                {form => {
                  const { special_day } = form.getFieldsValue();
                  return special_day ? (
                    <Form.Item name={'specialdays'}>
                      <Row gutter={12}>
                        <Checkbox.Group>
                          {special_day_list.map((item: any) => {
                            return <Checkbox value={item.value}>{item.label}</Checkbox>;
                          })}
                        </Checkbox.Group>
                      </Row>
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </div>
          </Row>
        )}
      </div>

      {/* REWARDS */}
      <Form.Item noStyle shouldUpdate>
        {form => {
          const { appliesTo } = form.getFieldsValue();
          return (
            appliesTo !== 'LOYT_POINTS' && (
              <>
                <Typography.Title level={4}>Rewards</Typography.Title>{' '}
                <Form.Item noStyle shouldUpdate>
                  {form => {
                    const { rewards_Data, promotionType, rewardType } = form.getFieldsValue();
                    // rewards_Data.forEach((obj: any) => {
                    //   if (obj.hasOwnProperty('cap_value')) {
                    //     obj.isRange = true;
                    //   }
                    // });
                    return (
                      <Form.List name="rewards_Data">
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              {fields.map(({ key, name, ...restField }, index) => {
                                let rwdType = rewards_Data && rewards_Data[key];
                                const isRange = rwdType && rwdType?.isRange;
                                return (
                                  <div
                                    key={key}
                                    style={{
                                      height: 'fit-content',
                                      width: '100%',
                                      background: '#9b9b9b29',
                                      padding: '20px',
                                      borderRadius: '20px',
                                      marginBottom: '15px'
                                    }}
                                  >
                                    <div className="flex justify-end">
                                      <DeleteOutlined
                                        className="text-red-500 text-xl"
                                        title={`Delete Filter ${index + 1}`}
                                        onClick={() => remove(name)}
                                      />
                                    </div>
                                    <Row gutter={12}>
                                      <Col xs={12} md={6}>
                                        <Form.Item
                                          label="Select discount type"
                                          name={[name, 'discount_type']}
                                          rules={[{ required: true, message: "This field can't be empty" }]}
                                        >
                                          <Select
                                            placeholder="Select"
                                            size="large"
                                            options={[
                                              { label: 'Perc Discount', value: 'PERCENTAGE' },
                                              { label: 'Absolute Discount', value: 'FIXED' },
                                              { label: 'Special Price', value: 'SPECIAL-PRICE' }
                                            ]}
                                          ></Select>
                                        </Form.Item>
                                      </Col>
                                      <Col xs={12} md={6}>
                                        <Form.Item
                                          label="Threshold Selection"
                                          name={[name, 'threshold_selection']}
                                          rules={[{ required: true, message: "This field can't be empty" }]}
                                        >
                                          <Select
                                            placeholder="Select"
                                            disabled={promotionType === 'VOLUME_DISC'}
                                            size="large"
                                            options={[
                                              { label: 'None', value: 'NO_THRESHOLD' },
                                              { label: 'MOV', value: 'MOV' },
                                              { label: 'MOQ', value: 'MOQ' },
                                              { label: 'QTY', value: 'QUANTITY' }
                                            ]}
                                          ></Select>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    <Row gutter={12}>
                                      <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, curValues) => {
                                          return prevValues.appliesTo !== curValues.appliesTo;
                                        }}
                                      >
                                        {form => {
                                          return (
                                            rwdType &&
                                            rwdType.appliesTo === 'LINE_ITEMS' && (
                                              <>
                                                <Col xs={24} md={8}>
                                                  <Form.Item
                                                    label="Reward Item"
                                                    name="rewardItem"
                                                    rules={[{ required: true, message: "This field can't be empty" }]}
                                                  >
                                                    <Select
                                                      mode="multiple"
                                                      allowClear
                                                      size="large"
                                                      onSearch={async searchTerm => {
                                                        if (searchTerm)
                                                          await debouncedFetchProductDropdownOptions(searchTerm);
                                                      }}
                                                      filterOption={false}
                                                      placeholder="Rewarded Item"
                                                      notFoundContent={
                                                        productDropdownFetching ? <Spin size="small" /> : null
                                                      }
                                                      options={productDropdownOptions}
                                                    ></Select>
                                                  </Form.Item>
                                                </Col>
                                                <Col xs={24} md={8}>
                                                  <Form.Item
                                                    label="Reward Item Quantity"
                                                    name="qualityReward"
                                                    rules={[{ required: true, message: "This field can't be empty" }]}
                                                  >
                                                    <Input size="large" placeholder="value" disabled={false} />
                                                  </Form.Item>
                                                </Col>
                                              </>
                                            )
                                          );
                                        }}
                                      </Form.Item>
                                    </Row>
                                    <Row gutter={12}>
                                      <Col xs={12} md={6}>
                                        <Form.Item
                                          label="Discount Value"
                                          name={[name, 'discount_value']}
                                          rules={[
                                            { required: true, message: "This field can't be empty" },
                                            validationHelpers.getNumeric2DecimalPointValidatorForInput({})
                                          ]}
                                        >
                                          <Input size="large" placeholder="Title" disabled={false} />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    {(rewardType === 'DISCOUNT' || rewardType === 'VOLUME_DISCOUNT') && (
                                      <Row gutter={12}>
                                        <Form.Item name={[name, 'isRange']} valuePropName="checked">
                                          <Checkbox value={'range'}>Threshold in range?</Checkbox>
                                        </Form.Item>
                                      </Row>
                                    )}
                                    <Row gutter={12}>
                                      <Col xs={12} md={6}>
                                        <Form.Item noStyle shouldUpdate>
                                          {form => {
                                            const thresholdSelection = rwdType && rwdType?.threshold_selection;
                                            const isDisabled =
                                              !thresholdSelection || thresholdSelection === 'NO_THRESHOLD';
                                            const validators = [];

                                            if (thresholdSelection === 'MOV') {
                                              validators.push(
                                                validationHelpers.getNumeric2DecimalPointValidatorForInput({})
                                              );
                                            }
                                            if (thresholdSelection === 'QUANTITY' || thresholdSelection === 'MOQ') {
                                              validators.push(validationHelpers.getNumericValidatorForInput({}));
                                            }

                                            return (
                                              <Form.Item
                                                label="Treshold Minimum Value"
                                                name={[name, 'minimum_value']}
                                                rules={[
                                                  { required: !isDisabled, message: "This field can't be empty" },
                                                  ...validators
                                                ]}
                                              >
                                                <Input size="large" placeholder="value" disabled={isDisabled} />
                                              </Form.Item>
                                            );
                                          }}
                                        </Form.Item>
                                      </Col>
                                      {isRange && (
                                        <Col xs={12} md={6}>
                                          <Form.Item noStyle shouldUpdate>
                                            {form => {
                                              const thresholdSelection = rwdType && rwdType?.threshold_selection;
                                              const isDisabled =
                                                !thresholdSelection || thresholdSelection === 'NO_THRESHOLD';
                                              const validators = [];

                                              if (thresholdSelection === 'MOV') {
                                                validators.push(
                                                  validationHelpers.getNumeric2DecimalPointValidatorForInput({})
                                                );
                                              }
                                              if (thresholdSelection === 'QUANTITY' || thresholdSelection === 'MOQ') {
                                                validators.push(validationHelpers.getNumericValidatorForInput({}));
                                              }

                                              return (
                                                isRange && (
                                                  <Form.Item
                                                    label="Treshold Maximum Value"
                                                    name={[name, 'cap_value']}
                                                    rules={[
                                                      { required: !isDisabled, message: "This field can't be empty" },
                                                      ...validators
                                                    ]}
                                                  >
                                                    <Input size="large" placeholder="value" disabled={isDisabled} />
                                                  </Form.Item>
                                                )
                                              );
                                            }}
                                          </Form.Item>
                                        </Col>
                                      )}
                                      <Col xs={12} md={6}>
                                        <Form.Item
                                          label="Maximum Discount"
                                          name={[name, 'max_discount']}
                                          rules={[
                                            { required: true, message: "This field can't be empty" },
                                            validationHelpers.getNumeric2DecimalPointValidatorForInput({})
                                          ]}
                                        >
                                          <Input size="large" placeholder="Title" disabled={false} />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    <Row gutter={12}>
                                      {promotionType === 'SUB_TTL_DIS' && (
                                        <Col xs={12} md={6}>
                                          <Form.Item
                                            label="Applies To Maximum Item Quantity"
                                            name={[name, 'max_discount_item_count']}
                                            rules={[
                                              { required: false, message: "This field can't be empty" },
                                              validationHelpers.getNumericValidatorForInput({})
                                            ]}
                                          >
                                            <InputNumber
                                              placeholder="Eg : 1"
                                              className="w-full"
                                              min={0}
                                              size="large"
                                            ></InputNumber>
                                          </Form.Item>
                                        </Col>
                                      )}
                                      {promotionType === 'DISCOUNT' && (
                                        <Col xs={12} md={6}>
                                          <Form.Item
                                            label="Applies To Maximum Item Quantity"
                                            name={[name, 'max_discount_item_count']}
                                            rules={[
                                              { required: false, message: "This field can't be empty" },
                                              validationHelpers.getNumericValidatorForInput({})
                                            ]}
                                          >
                                            <InputNumber
                                              placeholder="Eg : 1"
                                              className="w-full"
                                              min={0}
                                              size="large"
                                            ></InputNumber>
                                          </Form.Item>
                                        </Col>
                                      )}
                                    </Row>
                                    {promotionType === 'SUB_TTL_DIS' && appliesTo === 'LINE_ITEMS' && (
                                      <Form.List name={[name, 'product_reward', 'product_info']}>
                                        {(productInfoFields, { add: addProduct, remove: removeProduct }) => (
                                          <>
                                            <Typography.Title level={4}>Tired Discount Product Info</Typography.Title>{' '}
                                            {productInfoFields.map((productInfo, index) => {
                                              return (
                                                <div key={productInfo.key}>
                                                  <div
                                                    style={{
                                                      width: '100%',
                                                      display: 'flex',
                                                      justifyContent: 'flex-end'
                                                    }}
                                                  >
                                                    <DeleteOutlined
                                                      className="text-red-500 text-xl"
                                                      title={`Delete Filter ${index + 1}`}
                                                      onClick={() => removeProduct(index)}
                                                    />
                                                  </div>
                                                  <Row gutter={12}>
                                                    {' '}
                                                    <Col xs={12} md={6}>
                                                      <Form.Item
                                                        label="Select Item"
                                                        name={[productInfo.name, 'value']}
                                                        rules={[
                                                          validationHelpers.getRequiredValidator({
                                                            message: 'This field is required'
                                                          })
                                                        ]}
                                                      >
                                                        <Select
                                                          mode="multiple"
                                                          allowClear
                                                          showSearch
                                                          className="w-full"
                                                          size="large"
                                                          onSearch={async searchTerm => {
                                                            if (searchTerm)
                                                              await debouncedFetchProductDropdownOptions(searchTerm);
                                                          }}
                                                          filterOption={false}
                                                          placeholder="Search Item"
                                                          notFoundContent={
                                                            productDropdownFetching ? <Spin size="small" /> : null
                                                          }
                                                          options={productDropdownOptions}
                                                        ></Select>
                                                      </Form.Item>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              );
                                            })}
                                            <Row justify={'start'}>
                                              <Col xs={24} md={4}>
                                                <Button onClick={() => addProduct()} icon={<PlusOutlined />}>
                                                  <BoldButtonLabel labelText="Add New Product" />
                                                </Button>
                                              </Col>
                                            </Row>
                                          </>
                                        )}
                                      </Form.List>
                                    )}
                                    {promotionType === 'BUNDLE_DISC' && (
                                      <Form.List name={[name, 'product_reward', 'product_info']}>
                                        {(productInfoFields, { add: addProduct, remove: removeProduct }) => (
                                          <>
                                            <Typography.Title level={4}>Bundle Product Selection</Typography.Title>{' '}
                                            {productInfoFields?.map((productInfo, index) => {
                                              return (
                                                <div key={productInfo.key}>
                                                  {' '}
                                                  <div
                                                    style={{
                                                      width: '100%',
                                                      display: 'flex',
                                                      justifyContent: 'flex-end'
                                                    }}
                                                  >
                                                    <DeleteOutlined
                                                      className="text-red-500 text-xl"
                                                      title={`Delete Filter ${index + 1}`}
                                                      onClick={() => removeProduct(index)}
                                                    />
                                                  </div>
                                                  <Row gutter={12}>
                                                    {' '}
                                                    <Col xs={12} md={6}>
                                                      <Form.Item
                                                        label="Select Item"
                                                        name={[productInfo.name, 'value']}
                                                        rules={[
                                                          validationHelpers.getRequiredValidator({
                                                            message: 'This field is required'
                                                          })
                                                        ]}
                                                      >
                                                        <Select
                                                          showSearch
                                                          className="w-full"
                                                          size="large"
                                                          onSearch={async searchTerm => {
                                                            if (searchTerm)
                                                              await debouncedFetchProductDropdownOptions(searchTerm);
                                                          }}
                                                          filterOption={false}
                                                          placeholder="Search Item"
                                                          notFoundContent={
                                                            productDropdownFetching ? <Spin size="small" /> : null
                                                          }
                                                          options={productDropdownOptions}
                                                        ></Select>
                                                      </Form.Item>
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                      <Form.Item name={[productInfo.name, 'quantity']} label="Quantity">
                                                        <InputNumber
                                                          placeholder="Quantity"
                                                          className="w-full"
                                                          min={0}
                                                          size="large"
                                                        ></InputNumber>
                                                      </Form.Item>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              );
                                            })}
                                            <Row justify={'start'}>
                                              <Col xs={24} md={4}>
                                                <Button onClick={() => addProduct()} icon={<PlusOutlined />}>
                                                  <BoldButtonLabel labelText="Add New Product" />
                                                </Button>
                                              </Col>
                                            </Row>
                                          </>
                                        )}
                                      </Form.List>
                                    )}
                                    {promotionType === 'BXGY' && (
                                      <>
                                        <Row gutter={12}>
                                          <Col xs={12} md={6}>
                                            <Form.Item
                                              label="Applies to Price"
                                              name={[name, 'applies_to_price']}
                                              rules={[{ required: true, message: "This field can't be empty" }]}
                                            >
                                              <Select
                                                placeholder="Select"
                                                size="large"
                                                options={[
                                                  { label: 'None', value: 'NONE' },
                                                  { label: 'Lowest', value: 'LOWEST' },
                                                  { label: 'Highest', value: 'HIGHEST' }
                                                ]}
                                              ></Select>
                                            </Form.Item>
                                          </Col>{' '}
                                        </Row>
                                        <Form.List name={[name, 'product_reward', 'product_info']}>
                                          {(productInfoFields, { add: addProduct, remove: removeProduct }) => (
                                            <>
                                              <Typography.Title level={4}>BXGY Selection</Typography.Title>{' '}
                                              <Typography.Title level={5}>Reward On :</Typography.Title>{' '}
                                              {productInfoFields.map((productInfo, index) => {
                                                let rewardTypo =
                                                  rewards_Data[key]?.product_reward.product_info[index]?.selection_type;
                                                return (
                                                  rewardTypo === 'REWARD_ON' && (
                                                    <div key={productInfo.key}>
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          display: 'flex',
                                                          justifyContent: 'flex-end'
                                                        }}
                                                      >
                                                        <DeleteOutlined
                                                          className="text-red-500 text-xl"
                                                          title={`Delete Filter ${index + 1}`}
                                                          onClick={() => removeProduct(index)}
                                                        />
                                                      </div>
                                                      <Row gutter={12}>
                                                        <Col xs={12} md={6}>
                                                          <Form.Item
                                                            label="Selected Product"
                                                            name={[productInfoFields[index]?.name, 'value']}
                                                            rules={[
                                                              validationHelpers.getRequiredValidator({
                                                                message: 'This field is required'
                                                              })
                                                            ]}
                                                          >
                                                            <Select
                                                              showSearch
                                                              size="large"
                                                              onSearch={async searchTerm => {
                                                                if (searchTerm)
                                                                  await debouncedFetchProductDropdownOptions(
                                                                    searchTerm
                                                                  );
                                                              }}
                                                              filterOption={false}
                                                              placeholder="Search products"
                                                              notFoundContent={
                                                                productDropdownFetching ? <Spin size="small" /> : null
                                                              }
                                                              options={productDropdownOptions}
                                                            ></Select>
                                                          </Form.Item>
                                                        </Col>
                                                        <Col xs={12} md={6}>
                                                          <Form.Item
                                                            name={[productInfoFields[index]?.name, 'quantity']}
                                                            label="Quantity"
                                                          >
                                                            <InputNumber
                                                              placeholder="Quantity"
                                                              className="w-full"
                                                              min={0}
                                                              size="large"
                                                            ></InputNumber>
                                                          </Form.Item>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  )
                                                );
                                              })}
                                              <Row justify={'start'}>
                                                <Col xs={24} md={4}>
                                                  <Button
                                                    onClick={() => {
                                                      const newProduct = {
                                                        selection_type: 'REWARD_ON',
                                                        identifier_type: 'SKU_ID',
                                                        value: '',
                                                        quantity: 1
                                                      };
                                                      addProduct(newProduct);
                                                    }}
                                                    icon={<PlusOutlined />}
                                                  >
                                                    <BoldButtonLabel labelText="Add Reward On Product" />
                                                  </Button>
                                                </Col>
                                              </Row>
                                              <Typography.Title level={5} className="mt-5">
                                                REWARDED :
                                              </Typography.Title>{' '}
                                              {productInfoFields.map((productInfo, index) => {
                                                let rewardTypo =
                                                  rewards_Data[key]?.product_reward.product_info[index]?.selection_type;
                                                return (
                                                  rewardTypo === 'REWARDED' && (
                                                    <div key={productInfo.key}>
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          display: 'flex',
                                                          justifyContent: 'flex-end'
                                                        }}
                                                      >
                                                        <DeleteOutlined
                                                          className="text-red-500 text-xl"
                                                          title={`Delete Filter ${index + 1}`}
                                                          onClick={() => removeProduct(index)}
                                                        />
                                                      </div>
                                                      <Row gutter={12}>
                                                        <Col xs={12} md={6}>
                                                          <Form.Item
                                                            label="Rewarded Product"
                                                            name={[productInfoFields[index]?.name, 'value']}
                                                            rules={[
                                                              validationHelpers.getRequiredValidator({
                                                                message: 'This field is required'
                                                              })
                                                            ]}
                                                          >
                                                            <Select
                                                              showSearch
                                                              size="large"
                                                              onSearch={async searchTerm => {
                                                                if (searchTerm)
                                                                  await debouncedFetchProductDropdownOptions(
                                                                    searchTerm
                                                                  );
                                                              }}
                                                              filterOption={false}
                                                              placeholder="Search products"
                                                              notFoundContent={
                                                                productDropdownFetching ? <Spin size="small" /> : null
                                                              }
                                                              options={productDropdownOptions}
                                                            ></Select>
                                                          </Form.Item>
                                                        </Col>
                                                        <Form.Item noStyle shouldUpdate>
                                                          {form => {
                                                            const applies_to_price =
                                                              rwdType && rwdType?.applies_to_price;
                                                            return (
                                                              applies_to_price === 'NONE' && (
                                                                <Col xs={12} md={6}>
                                                                  <Form.Item
                                                                    name={[productInfoFields[index]?.name, 'quantity']}
                                                                    label="Quantity"
                                                                  >
                                                                    <InputNumber
                                                                      placeholder="Quantity"
                                                                      className="w-full"
                                                                      min={0}
                                                                      size="large"
                                                                    ></InputNumber>
                                                                  </Form.Item>
                                                                </Col>
                                                              )
                                                            );
                                                          }}
                                                        </Form.Item>
                                                      </Row>
                                                    </div>
                                                  )
                                                );
                                              })}
                                              <Row justify={'start'}>
                                                <Col xs={24} md={4}>
                                                  <Button
                                                    onClick={() => {
                                                      const newProduct = {
                                                        selection_type: 'REWARDED',
                                                        identifier_type: 'SKU_ID',
                                                        value: '',
                                                        quantity: 1
                                                      };
                                                      addProduct(newProduct);
                                                    }}
                                                    icon={<PlusOutlined />}
                                                  >
                                                    <BoldButtonLabel labelText="Add REWARDED Product" />
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </>
                                          )}
                                        </Form.List>
                                      </>
                                    )}
                                    {promotionType === 'VOLUME_DISC' && (
                                      <Form.List name={[name, 'product_reward', 'product_info']}>
                                        {(productInfoFields, { add: addProduct, remove: removeProduct }) => (
                                          <>
                                            <Typography.Title level={4}>Volume Discount:</Typography.Title>{' '}
                                            <Row gutter={12}>
                                              {' '}
                                              <Col xs={12} md={6}>
                                                <Form.Item
                                                  label="Select Item"
                                                  name={[productInfoFields[0]?.name, 'value']}
                                                  rules={[
                                                    validationHelpers.getRequiredValidator({
                                                      message: 'This field is required'
                                                    })
                                                  ]}
                                                >
                                                  <Select
                                                    // mode="multiple"
                                                    showSearch
                                                    allowClear
                                                    className="w-full"
                                                    size="large"
                                                    onSearch={async searchTerm => {
                                                      if (searchTerm)
                                                        await debouncedFetchProductDropdownOptions(searchTerm);
                                                    }}
                                                    filterOption={false}
                                                    placeholder="Select Item"
                                                    notFoundContent={
                                                      productDropdownFetching ? <Spin size="small" /> : null
                                                    }
                                                    options={productDropdownOptions}
                                                  ></Select>
                                                </Form.Item>
                                              </Col>
                                              {/* <Col xs={12} md={6}>
                                                <Form.Item
                                                  name={[productInfoFields[0]?.name, 'quantity']}
                                                  label="Quantity"
                                                >
                                                  <InputNumber
                                                    placeholder="Quantity"
                                                    className="w-full"
                                                    min={0}
                                                    size="large"
                                                  ></InputNumber>
                                                </Form.Item>
                                              </Col> */}
                                            </Row>
                                          </>
                                        )}
                                      </Form.List>
                                    )}
                                  </div>
                                );
                              })}
                              <Form.Item>
                                <Row justify={'start'}>
                                  <Col xs={24} md={4}>
                                    <Button
                                      onClick={() => {
                                        if (promotionType === 'VOLUME_DISC') {
                                          const newProduct = {
                                            threshold_selection: 'QUANTITY',
                                            product_reward: {
                                              product_info: [{}]
                                            }
                                          };
                                          add(newProduct);
                                        } else {
                                          add();
                                        }
                                      }}
                                      icon={<PlusOutlined />}
                                    >
                                      <BoldButtonLabel labelText="Add Rewards" />
                                    </Button>
                                  </Col>
                                </Row>
                              </Form.Item>
                            </>
                          );
                        }}
                      </Form.List>
                    );
                  }}
                </Form.Item>
              </>
            )
          );
        }}
      </Form.Item>

      <Typography.Title level={4}>Additional Configuration</Typography.Title>
      <Form.Item shouldUpdate noStyle>
        {form => {
          const { appliesTo } = form.getFieldsValue();
          return appliesTo !== 'LOYT_POINTS' ? (
            <div
              style={{
                height: 'fit-content',
                width: '100%',
                background: '#9b9b9b29',
                padding: '20px',
                borderRadius: '20px',
                marginBottom: '15px'
              }}
            >
              <Row gutter={12}>
                <Col xs={12} md={8}>
                  <Form.Item name="applies_to_product_in_sale" valuePropName="checked">
                    <Checkbox>Applies to products in sale?</Checkbox>
                  </Form.Item>
                </Col>

                <Col xs={12} md={8}>
                  <Form.Item name="combine_with_other_promo" valuePropName="checked">
                    <Checkbox>Combine with other promotions?</Checkbox>
                  </Form.Item>
                </Col>

                <Col xs={12} md={8}>
                  <Form.Item name="ignore_price_lock" valuePropName="checked">
                    <Checkbox>Ignore price lock?</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={8}>
                  <Typography.Title level={5}>Visible On PopUp</Typography.Title>
                  <div className="flex">
                    <Radio.Group onChange={onChangeRadio} value={radioValue}>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </div>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Item
                    label="Applies per transaction"
                    name="appliesPerTransaction"
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Select
                      placeholder=" Type"
                      size="large"
                      options={[
                        { label: 'Unlimited', value: 'UNLIMITED' },
                        { label: 'Count', value: 'COUNT' }
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    return prevValues.appliesPerTransaction !== curValues.appliesPerTransaction;
                  }}
                >
                  {form => {
                    const { appliesPerTransaction } = form.getFieldsValue();
                    return appliesPerTransaction === 'COUNT' ? (
                      <Col xs={12} md={8}>
                        <Form.Item
                          label="Count"
                          name="appliesPerTransaction_Count"
                          rules={[
                            { required: true, message: "This field can't be empty" },
                            validationHelpers.getNumericValidatorForInput({})
                          ]}
                        >
                          <Input size="large" placeholder="value" disabled={false} />
                        </Form.Item>
                      </Col>
                    ) : null;
                  }}
                </Form.Item>
              </Row>
            </div>
          ) : null;
        }}
      </Form.Item>

      <div
        style={{
          height: 'fit-content',
          width: '100%',
          background: '#9b9b9b29',
          padding: '20px',
          borderRadius: '20px',
          marginBottom: '15px'
        }}
      >
        <Row gutter={12}>
          <Col xs={12} md={8}>
            <Form.Item
              label="For"
              name="for_generate"
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder=" Type"
                size="large"
                onChange={() => {
                  //!Reset all fields depended on this field
                  promotionForm.setFieldsValue({
                    customerRestriction: null,
                    filters: [],
                    userSelection: null,
                    specific_buyer: []
                  });
                }}
                options={[
                  { label: 'Anonymous user', value: 'ANONYMOUS' },
                  { label: 'Registered user', value: 'REGISTERED_USER' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return prevValues.for_generate !== curValues.for_generate;
            }}
          >
            {form => {
              const { for_generate } = form.getFieldsValue();
              return for_generate === 'REGISTERED_USER' ? (
                <Col xs={12} md={8}>
                  <Form.Item
                    label="Customer Restriction"
                    name="customerRestriction"
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Select
                      placeholder="Select"
                      onChange={() => {
                        //!Reset all fields depended on this field
                        promotionForm.setFieldsValue({
                          userSelection: null,
                          specific_buyer: [],
                          filters: []
                        });
                      }}
                      size="large"
                      options={[
                        { label: 'Everyone', value: 'EVERYONE' },
                        { label: 'Specific', value: 'SPECIFIC' }
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
              ) : null;
            }}
          </Form.Item>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return (
                  prevValues.for_generate !== curValues.for_generate ||
                  prevValues.customerRestriction !== curValues.customerRestriction
                );
              }}
            >
              {form => {
                const { for_generate, customerRestriction } = form.getFieldsValue();
                return for_generate === 'REGISTERED_USER' && customerRestriction === 'EVERYONE' ? (
                  <Row>
                    <Col xs={24}>
                      <div>
                        <span className="text-lg font-medium">Filters</span>
                        <div className="my-4">
                          <Form.List name="filters">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, ...restField }, index) => (
                                  <React.Fragment key={key}>
                                    <Row>
                                      <Col xs={12}>
                                        <Divider orientation="left" plain>
                                          <span className="text-lg">Filter - {index + 1} </span>
                                        </Divider>
                                      </Col>
                                    </Row>

                                    <Row gutter={12}>
                                      <Col xs={12} md={6}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'filter_type']}
                                          label="Filter type"
                                          rules={[{ required: true, message: 'Filter Type is required' }]}
                                        >
                                          <Select
                                            size="large"
                                            placeholder="Select Filter type"
                                            options={filterOption}
                                          ></Select>
                                        </Form.Item>
                                      </Col>

                                      <Form.Item noStyle shouldUpdate>
                                        {form => {
                                          const isDataPresent = form.getFieldsValue()?.filters?.length;

                                          const attributeType = isDataPresent
                                            ? form.getFieldsValue()?.filters[index]?.filter_type
                                            : '';

                                          const inputMap: Record<string, any> = {
                                            TIER: {
                                              name: 'customerTireRestriction',
                                              label: 'Tier Restriction',
                                              component: (
                                                <Select
                                                  mode="tags"
                                                  placeholder="Select"
                                                  size="large"
                                                  options={[
                                                    { label: 'Everyone', value: 'EVERYONE' },
                                                    { label: 'Standard', value: 'Standard' },
                                                    { label: 'Gold', value: 'Gold' },
                                                    { label: 'Silver', value: 'Silver' },
                                                    { label: 'Bronze', value: 'Bronze' }
                                                  ]}
                                                ></Select>
                                              )
                                            },
                                            SEGMENT: {
                                              name: 'segment',
                                              label: 'Segment',
                                              component: (
                                                <Select
                                                  placeholder="Select / Enter"
                                                  mode="tags"
                                                  size="large"
                                                  options={[]}
                                                ></Select>
                                              )
                                            },
                                            RFM_CATEGORY: {
                                              name: 'RFM_CATEGORY',
                                              label: 'RFM Category',
                                              component: (
                                                <Select
                                                  placeholder="Select / Enter"
                                                  mode="tags"
                                                  size="large"
                                                  options={categoryOptions}
                                                ></Select>
                                              )
                                            }
                                          };

                                          const componentInfo = inputMap[attributeType];

                                          return (
                                            componentInfo && (
                                              <Col xs={12} md={6}>
                                                <Form.Item
                                                  {...restField}
                                                  name={[name, componentInfo?.name]}
                                                  label={componentInfo?.label}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: `${componentInfo?.label} is required`
                                                    }
                                                  ]}
                                                >
                                                  {componentInfo?.component}
                                                </Form.Item>
                                              </Col>
                                            )
                                          );
                                        }}
                                      </Form.Item>

                                      <div className="w-full md:w-[50px] md:mx-8 flex justify-center items-center">
                                        <DeleteOutlined
                                          className="text-red-500 text-xl"
                                          title={`Delete Filter ${index + 1}`}
                                          onClick={() => remove(name)}
                                        />
                                      </div>
                                    </Row>
                                  </React.Fragment>
                                ))}
                                <Form.Item>
                                  <Row justify={'start'}>
                                    <Col xs={24} md={4}>
                                      <Button onClick={() => add()} icon={<PlusOutlined />}>
                                        <BoldButtonLabel labelText="Add Filter" />
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : null;
              }}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => {
            return (
              prevValues.for_generate !== curValues.for_generate ||
              prevValues.customerRestriction !== curValues.customerRestriction
            );
          }}
        >
          {form => {
            const { for_generate, customerRestriction } = form.getFieldsValue();
            return for_generate === 'REGISTERED_USER' && customerRestriction === 'SPECIFIC' ? (
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Buyer Selection"
                    name="userSelection"
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Select
                      placeholder=" Type"
                      onChange={() => {
                        //!Reset all fields depended on this field
                        promotionForm.setFieldsValue({
                          specific_buyer: []
                        });
                      }}
                      size="large"
                      options={[
                        { label: 'All', value: 'ALL' },
                        { label: 'Specific', value: 'SPECIFIC' }
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                      return prevValues.userSelection !== curValues.userSelection;
                    }}
                  >
                    {form => {
                      const { userSelection } = form.getFieldsValue();
                      return userSelection === 'SPECIFIC' ? (
                        <Form.Item
                          label="User"
                          name={'specific_buyer'}
                          rules={[{ required: true, message: "This field can't be empty" }]}
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            size="large"
                            filterOption={false}
                            placeholder="Search buyer"
                            notFoundContent={userDropdownFetching ? <Spin size="small" /> : null}
                            onSearch={async searchTerm => {
                              if (searchTerm) await debouncedFetchUserDropdownOptions(searchTerm);
                            }}
                            options={userDropdownOptions}
                          ></Select>
                        </Form.Item>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
              </Row>
            ) : null;
          }}
        </Form.Item>
        {/*! out of scope */}
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => {
            return prevValues.for_generate !== curValues.for_generate;
          }}
        >
          {form => {
            const { for_generate } = form.getFieldsValue();
            return for_generate === 'STAFF' ? (
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Staff Selection"
                    name="sellerSelectionforCoupon"
                    rules={[{ required: false, message: "This field can't be empty" }]}
                  >
                    <Select
                      placeholder=" Type"
                      size="large"
                      options={[
                        { label: 'All', value: 'ALL' },
                        { label: 'Specific', value: 'SPECIFIC' },
                        { label: 'None', value: 'NONE' }
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                      return prevValues.sellerSelectionforCoupon !== curValues.sellerSelectionforCoupon;
                    }}
                  >
                    {form => {
                      const { sellerSelectionforCoupon } = form.getFieldsValue();
                      return sellerSelectionforCoupon === 'SPECIFIC' ? (
                        <Form.Item label="User" name={'sellerUserforCoupon'}>
                          <Select mode="multiple" allowClear size="large" placeholder="User" options={staff}></Select>
                        </Form.Item>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
              </Row>
            ) : null;
          }}
        </Form.Item>
      </div>
      {!isUpdate && (
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => {
            return prevValues.trigger !== curValues.trigger;
          }}
        >
          {form => {
            const { trigger } = form.getFieldsValue();
            return trigger === 'MANUAL' ? (
              <div
                style={{
                  height: 'fit-content',
                  width: '100%',
                  background: '#9b9b9b29',
                  padding: '20px',
                  borderRadius: '20px',
                  marginBottom: '15px'
                }}
              >
                <Typography.Title level={5}>Generate</Typography.Title>
                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={'coupon_usage_limit'}
                      label="Coupon Usage Limit"
                      rules={[
                        { message: 'coupon usage limit Field Required', required: true }
                        // { max: 40, message: 'Max limit is 40 characters' }
                      ]}
                    >
                      <InputNumber
                        min={0}
                        className="w-full"
                        size="large"
                        placeholder="coupon usage limit"
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                </Row>

                <Typography.Title level={5}>Discount Code</Typography.Title>
                <Row gutter={12}>
                  <Col xs={12} md={6} style={{ margin: '0px 0px 8px 4px' }}>
                    <Radio.Group onChange={onChangeRadioS_M} value={singleRadio}>
                      <Radio value={1}>Single</Radio>
                      <Radio value={2}>Multiple</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    {/* <Input size="large" placeholder="<enter>" disabled={false} /> */}
                    <Form.Item
                      name={'code_gen'}
                      label=""
                      rules={[
                        { message: 'Reward name is required', required: true }
                        // { max: 40, message: 'Max limit is 40 characters' }
                      ]}
                    >
                      <Input disabled={false} placeholder="<enter>" size="large" />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Button size="large" onClick={handleGenerateRandomString}>
                      Autogenerate Code
                    </Button>
                  </Col>
                </Row>
                {singleRadio == Number(2) ? (
                  <Row gutter={12}>
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Quantity To Generate"
                        name="quantityToGenerate"
                        rules={[{ required: true, message: "This field can't be empty" }]}
                      >
                        <Input size="large" placeholder="" disabled={false} />
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}

                <Typography.Title level={5}>Valid Until</Typography.Title>
                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Date"
                      name="dateRangeInDiscount"
                      rules={[{ required: true, message: 'Fill start and end date' }]}
                    >
                      <DatePicker.RangePicker size="large" disabledDate={disabledDate} />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Start Time"
                      name="timeRangeInDiscount_StartTime"
                      rules={[{ required: true, message: 'Fill start time and end time' }]}
                    >
                      <TimePicker className="w-[100%]" size="large" format={'HH:mm'} disabledTime={disabledNewTime} />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="End Time"
                      name="timeRangeInDiscount_EndTime"
                      rules={[{ required: true, message: 'Fill start time and end time' }]}
                    >
                      <TimePicker className="w-[100%]" size="large" format={'HH:mm'} disabledTime={disabledNewTime} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ) : null;
          }}
        </Form.Item>
      )}

      {/* <Typography.Title level={5}>Priority</Typography.Title> Why Do we need it??*/}
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            label="Priority"
            name="priority"
            rules={[
              { required: true, message: "This field can't be empty" },
              validationHelpers.getNumericValidatorForInput({})
            ]}
          >
            <Input size="large" placeholder="Enter Priority" disabled={false} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]} className="mt-4">
        <Col xs={12} md={6}>
          <Button
            size="large"
            onClick={() => {
              const backUrl = searchParams.get('backUrl');

              if (backUrl) navigate(backUrl);
              else navigate(`/promotion-engine/promotions`);
            }}
            type="default"
            block
          >
            <BoldButtonLabel labelText="Back"></BoldButtonLabel>
          </Button>
        </Col>

        {isUpdate && !viewOnly && (
          <Col xs={12} md={6}>
            <Button size="large" /*onClick={handleSave}*/ type="primary" block htmlType="submit">
              <BoldButtonLabel labelText="Update"></BoldButtonLabel>
            </Button>
          </Col>
        )}

        {!isUpdate && !viewOnly && (
          <>
            <Col xs={12} md={6}>
              <Button size="large" /*onClick={handleSave}*/ type="primary" block htmlType="submit">
                <BoldButtonLabel labelText="Save"></BoldButtonLabel>
              </Button>
            </Col>
            <Col xs={12} md={6}>
              <Button size="large" type="primary" block onClick={scheduleDiscount}>
                <BoldButtonLabel labelText="Schedule Discount"></BoldButtonLabel>
              </Button>
            </Col>{' '}
          </>
        )}
      </Row>
    </Form>
  );
};

export default PromotionForm;
