import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Steps,
  Table,
  TableColumnsType,
  Tag,
  Timeline,
  Typography
} from 'antd';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import { priceHelpers } from '../../data/intl-data';
import {
  getBaseStatusFromStatus,
  getStatusesWithLowerPrecedence,
  getUpdatedStatusTimeLineWrtStatus,
  getUpdatedStatusTimeLineWrtStatusforPickUp,
  statusIndex,
  statusIndexForPickUp
} from '../../data/order-status';
import _ from '../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import { getFullName } from '../../helpers/utilHelpers';
import PrimaryLayout from '../../layouts/primary-layout';
import { orderService } from '../../services';
import { useLoader } from '../../stores/use-loader';
import { useLocale } from '../../stores/use-locale';

import { formatDateTime } from '../../helpers/date.helpers';
import { fetchReqData } from '../../helpers/file.helpers';
import ItemLevelView from '../../modules/nebula/components/item-level-view';

interface IOrderDetailsProps { }

const OrderDetails: React.FunctionComponent<IOrderDetailsProps> = props => {
  const { orderNumber } = useParams();

  const [showTrackingInfoModal, setShowTrackingInfoModal] = React.useState(false);
  const [showGiftingInstructionModal, setShowGiftingInstructionModal] = React.useState(false);

  const [orderDetails, setOrderDetails] = React.useState<any>({});

  const [fulfillmentOrderDetails, setFulfillmentOrderDetails] = React.useState({} as any);

  //useless changes
  let buyer_Details = fetchReqData(orderDetails?.address, 'address_tag', 'buyer-details')
  let ship_Details = fetchReqData(orderDetails?.address, 'address_tag', 'ship-to-details')
  let bill_To_Details = fetchReqData(orderDetails?.address, 'address_tag', 'bill-to-details')

  React.useEffect(() => {
    fetchOrderDetails();
  }, []);

  const fetchOrderDetails = async () => {
    setLoading(true);
    const { data, errors } = await orderService.getOrderDetails(orderNumber as string);
    const { data: fulfillmentOrderList, errors: fulfillmentErrors } = await orderService.getFulfillmentOrderList({
      offset: 0,
      limit: 200,
      order_number: data?.order_number
    });
    if (_.isEmpty(errors)) {
      setOrderDetails(data);
    }
    if (_.isEmpty(fulfillmentErrors)) {
      setFulfillmentOrderDetails(fulfillmentOrderList);
    } else {
      displayErrorNotifications([...errors, ...fulfillmentErrors]);
    }
    setLoading(false);
  };

  const columns: TableColumnsType<{
    sku_id: string;
    total_amount: number;
    quantity: { number_of_units: number };
    product_name: string;
    product_class: string;
  }> = [
      { title: 'SKU', render: (_, record) => <>{record.sku_id}</>, align: 'center' },
      { title: 'Product Name', render: (_, record) => <>{record.product_name}</>, align: 'center' },
      { title: 'Product Class', render: (_, record) => <>{record.product_class}</>, align: 'center' },
      { title: 'Ordered Quantity', render: (_, record) => <>{record.quantity?.number_of_units}</>, align: 'center' },

      {
        title: 'Product Total',
        render: (_, record) => (
          <>{priceHelpers.formatPriceToLocaleCurrency({ amount: record.total_amount, baseCurrency, userCurrency })}</>
        ),
        align: 'center'
      }
    ];

  const { baseCurrency, userCurrency } = useLocale(({ setUserCurrency, baseCurrency, userCurrency, orderNumber }) => ({
    baseCurrency,
    userCurrency,
    orderNumber
  }));

  const lowerPrecendenceStatus = getStatusesWithLowerPrecedence(
    orderDetails?.status,
    orderDetails.order_sub_type === 'STORE'
  );

  const updateOrderStatus = async (status: string) => {
    setLoading(true);
    const { errors } = await orderService.updateOrderStatus(orderNumber as string, status);
    if (!_.isEmpty(errors)) displayErrorNotifications(errors);
    else {
      displaySuccessNotification({ message: 'Status update success!' });
      fetchOrderDetails();
    }
    setLoading(false);
  };

  const getShippingAddress = (addressObject: any) => {
    return `${addressObject?.address_line1}, ${addressObject?.address_line2}, ${addressObject?.location}, ${addressObject?.state}, ${addressObject?.country} - ${addressObject?.postal_code}`;
  };

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const navigate = useNavigate();

  const grossAmount = orderDetails?.value_details?.gross_amount || 0;
  const totalTax = orderDetails?.value_details?.taxable_value || 0;
  const otherCharges = orderDetails?.value_details?.other_charges || 0;
  const discountValue = orderDetails?.value_details?.discount_value || 0;
  const orderTotal = grossAmount + totalTax + otherCharges - discountValue;
  const [searchParams] = useSearchParams();
  const backUrl = searchParams.get('backUrl') || '/order';

  const updatedStatusTimeLine = getUpdatedStatusTimeLineWrtStatus(orderDetails?.status_description);
  const updatedStatusTimeLineforPickUp = getUpdatedStatusTimeLineWrtStatusforPickUp(orderDetails?.status_description);
  const showPickUp = fulfillmentOrderDetails?.order_list?.some((data: any) => data?.fulfilment_info?.delivery_method === 'PICK_UP')

  const [itemModalInfo, setItemModalInfo] = React.useState({ itemInfo: null, open: false } as {
    itemInfo: any;
    open: boolean;
  });

  const showItemLevelModal = (item: any) => {
    console.log(orderDetails.item_list)
    console.log(item.co_line_id)
    let data = orderDetails.item_list.find((od: any) => od.co_line_id === item.co_line_id)
    let newItemList = {
      ...item,
      orderDetailsItems: data
    }
    setItemModalInfo({ itemInfo: newItemList, open: true });
  };

  const closeItemLevelModal = () => {
    setItemModalInfo({ itemInfo: null, open: false });
  };

  const getCurrentStatus = () => {
    let statusData = getBaseStatusFromStatus(orderDetails?.status_description)
    let returnData = statusIndex['ONLINE'][statusData]?.key
    return returnData
  }

  const getCurrentStatusForPickUp = () => {
    let statusData = getBaseStatusFromStatus(orderDetails?.status_description)
    let returnData = statusIndexForPickUp['ONLINE'][statusData]?.key
    return returnData
  }

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4 relative">
        <Card>
          <div className="flex items-center">
            {/* <GoBackButton backUrl={backUrl} /> */}
            <div className="text-center">
              <Typography.Text className="text-xl font-bold">Order Details</Typography.Text>
            </div>
          </div>
          <div>
            {!_.isEmpty(orderDetails) && (
              <>
                <section>
                  <Descriptions size="small" bordered className="mt-4">
                    <Descriptions.Item span={1} label="Order Number">
                      <span className=""> {orderNumber}</span>
                    </Descriptions.Item>

                    <Descriptions.Item span={1} label="Type">
                      <span className=""> {orderDetails.order_type}</span>
                    </Descriptions.Item>

                    <Descriptions.Item span={1} label="Gross Amount">
                      <span className="">
                        {/* {priceHelpers.formatPriceToLocaleCurrency({
                          amount: grossAmount,
                          baseCurrency,
                          userCurrency: baseCurrency
                        })} */}
                        {`$ ${grossAmount}`}
                      </span>
                    </Descriptions.Item>
                    {/* <Descriptions.Item span={1} label="Taxable Amount">
                      <span className=""> {'0'}</span>
                    </Descriptions.Item> */}
                    <Descriptions.Item span={1} label="Total Tax">
                      <span className="">
                        {/* {priceHelpers.formatPriceToLocaleCurrency({
                          amount: totalTax,
                          baseCurrency,
                          userCurrency: baseCurrency
                        })} */}
                        {`$ ${totalTax}`}
                      </span>
                    </Descriptions.Item>

                    <Descriptions.Item span={1} label="Other Charges">
                      <span className="">
                        {/* {priceHelpers.formatPriceToLocaleCurrency({
                          amount: otherCharges,
                          baseCurrency,
                          userCurrency: baseCurrency
                        })} */}
                        {`$ ${otherCharges}`}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Total Discount">
                      <span className="">
                        {' '}
                        {/* {priceHelpers.formatPriceToLocaleCurrency({
                          amount: discountValue,
                          baseCurrency,
                          userCurrency: baseCurrency
                        })} */}
                        {`$ ${discountValue}`}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Order Total">
                      <span className="">
                        {/* {priceHelpers.formatPriceToLocaleCurrency({
                          amount: orderTotal,
                          baseCurrency,
                          userCurrency: baseCurrency
                        })} */}
                        {`$ ${orderTotal.toFixed(2)}`}
                      </span>
                    </Descriptions.Item>
                  </Descriptions>
                </section>
                <section className="mt-2">
                  <Typography.Title level={4} className="text-[#2e2a5b]">
                    Customer Details
                  </Typography.Title>
                  <Descriptions bordered size="small" className="mt-4">
                    <Descriptions.Item span={1} label="Full Name">
                      <span className="">
                        {getFullName(orderDetails)}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="GSTIN">
                      <span className="">{buyer_Details?.gstin}</span>
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Phone">
                      <span className="">{buyer_Details?.phone_number[0].number}</span>
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Organization">
                      <span className="">{buyer_Details?.organization}</span>
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Department">
                      <span className="">{buyer_Details?.department}</span>
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Email">
                      <span className="">{buyer_Details?.email ? buyer_Details?.email[0]?.email_id : ''}</span>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Shipping Address">
                      <span className="">{getShippingAddress(ship_Details?.address_info)}</span>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Billing Address">
                      <span className="">{getShippingAddress(bill_To_Details?.address_info)}</span>
                    </Descriptions.Item>
                  </Descriptions>
                </section>
                <section className="mt-2">
                  <Typography.Title level={4} className="text-[#2e2a5b]">
                    Payment Details
                  </Typography.Title>
                  <Table
                    pagination={false}
                    bordered
                    className="mt-4"
                    dataSource={orderDetails?.payment_details?.payment_lines}
                    columns={[
                      {
                        title: 'Payment Ref Id',
                        render(value, record, index) {
                          return <>{record?.payment_ref_id}</>;
                        },
                        align: 'center'
                      },
                      {
                        title: 'Transaction Ref',
                        render(value, record, index) {
                          return <>{record?.transaction_id}</>;
                        },
                        align: 'center'
                      },
                      {
                        title: 'Currency',
                        render(value, record, index) {
                          return <>{record?.currency_code}</>;
                        },
                        align: 'center'
                      },
                      {
                        title: 'Amount',
                        render(value, record, index) {
                          let val = record?.amount || 0
                          return <>{`$ ${val?.toFixed(2)}`}</>;
                        },
                        align: 'center'
                      },
                      {
                        title: 'Payment Method',
                        render(value, record, index) {
                          return <>{record?.payment_method}</>;
                        },
                        align: 'center'
                      },
                      // {
                      //   title: 'IFSC Code',
                      //   render(value, record, index) {
                      //     return <>{record?.ifsc_code}</>;
                      //   },
                      //   align: 'center'
                      // },
                      {
                        title: 'Paid Date',
                        render(value, record, index) {
                          return <>{formatDateTime(record?.payment_datetime)}</>;
                        },
                        align: 'center'
                      }
                    ]}
                    scroll={{ x: 1400 }}
                  ></Table>
                </section>
                <section className="my-4 overflow-x-auto custom-scroll-bar-x h-[150px]">
                  <Typography.Title level={4} className="text-[#2e2a5b]">
                    Order Status{' '}
                    {orderDetails.status_description === 'CANCELLED' && <> - {orderDetails.status_description}</>}
                  </Typography.Title>
                  {!showPickUp && <div className="mt-8">
                    {orderDetails.status_description !== 'CANCELLED' && (
                      <Steps
                        progressDot
                        responsive
                        current={getCurrentStatus()}//statusIndex['ONLINE'][getBaseStatusFromStatus(orderDetails.status_description)]?.key
                        items={updatedStatusTimeLine.map(st => ({ title: st.label }))}
                      />
                    )}
                  </div>}
                  {showPickUp && <div className="mt-8">
                    {orderDetails.status_description !== 'CANCELLED' && (
                      <Steps
                        progressDot
                        responsive
                        current={getCurrentStatusForPickUp()}//statusIndex['ONLINE'][getBaseStatusFromStatus(orderDetails.status_description)]?.key
                        items={updatedStatusTimeLineforPickUp.map(st => ({ title: st.label }))}
                      />
                    )}
                  </div>}
                </section>
                <section className="my-4">
                  <Typography.Title level={4} className="text-[#2e2a5b]">
                    Fulfillment Orders
                  </Typography.Title>
                  {fulfillmentOrderDetails?.order_list?.map((order: any) => {

                    function convertToTitleCase(inputString: string): string {
                      // Split the input string at underscores
                      const words = inputString?.split('_');
                      // Capitalize each word
                      const capitalizedWords = words?.map(
                        word => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase()
                      );
                      // Join the capitalized words back together with spaces
                      const result = capitalizedWords?.join(' ');
                      return result;
                    }
                    let estimatedShippingObj = fetchReqData(order?.fulfilment_info?.fulfillment_dates, 'date_type', 'estimated-shipping-date')
                    let promiseDeliveryObj = fetchReqData(order?.fulfilment_info?.fulfillment_dates, 'date_type', 'promise-delivery-date')
                    return (
                      <Card
                        className={'mt-4'}
                        title={
                          <section className="flex gap-4 justify-between">
                            <div className="flex gap-2">
                              <Typography.Text className="text-lg">
                                Fulfillment Order Number : <span className="font-bold text-black">{order?.fo_number}</span>
                              </Typography.Text>{' '}
                              <Tag
                                className="flex justify-center items-center"
                                color={
                                  order?.fulfilment_info?.delivery_method === 'PICK_UP'
                                    ? 'green'
                                    : 'red'
                                }
                              >
                                {convertToTitleCase(order?.fulfilment_info?.delivery_method)}
                              </Tag>
                            </div>
                          </section>
                        }
                      >
                        <div>
                          <section className="flex gap-2 flex-col">
                            <Typography.Text className="text-base">
                              Estimated Shipping Date :{' '}
                              <span className="font-bold text-black">
                                {formatDateTime(estimatedShippingObj?.date_time_stamp)}
                              </span>
                            </Typography.Text>
                            <Typography.Text className="text-base">
                              Estimated Delivery Date :{' '}
                              <span className="font-bold text-black">
                                {' '}
                                {formatDateTime(promiseDeliveryObj?.date_time_stamp)}
                              </span>
                            </Typography.Text>
                          </section>
                          <section>
                            <Table
                              pagination={false}
                              bordered
                              className="mt-4"
                              dataSource={order?.item_list}
                              columns={[
                                {
                                  title: 'SKU',
                                  render(value, record, index) {
                                    return <>{record?.sku_id}</>;
                                  },
                                  align: 'center'
                                },
                                {
                                  title: 'Ordered Quantity',
                                  render(value, record, index) {
                                    return <>{record?.quantity?.number_of_units}</>;
                                  },
                                  align: 'center'
                                },
                                {
                                  title: 'Status',
                                  render(value, record, index) {
                                    return <>{convertToTitleCase(record?.status_description)}</>;
                                  },
                                  align: 'center'
                                },
                                {
                                  title: 'Is Gift?',
                                  render(value, record, index) {
                                    return <>{record?.is_gift ? 'Yes' : 'No'}</>;
                                  },
                                  align: 'center'
                                },
                                // {
                                //   title: 'HSN Code',
                                //   render(value, record, index) {
                                //     return <>{record?.hsn_code}</>;
                                //   },
                                //   align: 'center'
                                // },
                                {
                                  title: 'Unit Price',
                                  render(value, record, index) {
                                    return (
                                      <>
                                        {/* {priceHelpers.formatPriceToLocaleCurrency({
                                          amount: record?.unit_price,
                                          baseCurrency,
                                          userCurrency
                                        })} */}
                                        {`$ ${record?.unit_price.toFixed(2)}`}
                                      </>
                                    );
                                  },
                                  align: 'center'
                                },
                                // {
                                //   title: 'List Price',
                                //   render(value, record, index) {
                                //     return (
                                //       <>
                                //         {/* {priceHelpers.formatPriceToLocaleCurrency({
                                //           amount: record?.list_price,
                                //           baseCurrency,
                                //           userCurrency
                                //         })} */}
                                //         {`$ ${record?.list_price ? record?.list_price : 0}`}
                                //       </>
                                //     );
                                //   },
                                //   align: 'center'
                                // },
                                {
                                  title: 'Gross Amount',
                                  render(value, record, index) {
                                    return (
                                      <>
                                        {/* {priceHelpers.formatPriceToLocaleCurrency({
                                          amount: record?.total_item_value,
                                          baseCurrency,
                                          userCurrency
                                        })} */}
                                        {`$ ${record?.gross_amount.toFixed(2)}`}
                                      </>
                                    );
                                  },
                                  align: 'center'
                                },

                                {
                                  title: 'Total Discount',
                                  render(value, record, index) {
                                    return (
                                      <>
                                        {/* {priceHelpers.formatPriceToLocaleCurrency({
                                          amount: record?.total_discount,
                                          baseCurrency,
                                          userCurrency
                                        })} */}
                                        {`$ ${record?.total_discount.toFixed(2)}`}
                                      </>
                                    );
                                  },
                                  align: 'center'
                                },
                                {
                                  title: 'Total Tax',
                                  render(value, record, index) {
                                    return (
                                      <>
                                        {/* {priceHelpers.formatPriceToLocaleCurrency({
                                          amount: record?.total_tax,
                                          baseCurrency,
                                          userCurrency
                                        })} */}
                                        {`$ ${record?.total_tax.toFixed(2)}`}
                                      </>
                                    );
                                  },
                                  align: 'center'
                                },
                                {
                                  title: 'Total Amount',
                                  render(value, record, index) {
                                    return (
                                      <>
                                        {/* {priceHelpers.formatPriceToLocaleCurrency({
                                          amount: record?.total_item_value,
                                          baseCurrency,
                                          userCurrency
                                        })} */}

                                        {`$ ${record?.total_item_value.toFixed(2)}`}
                                      </>
                                    );
                                  },
                                  align: 'center'
                                },
                                {
                                  title: 'Actions',
                                  render(value, record, index) {
                                    return (
                                      <Button
                                        type="link"
                                        onClick={() => {
                                          showItemLevelModal(record);
                                        }}
                                      >
                                        View
                                      </Button>
                                    );
                                  },
                                  align: 'center'
                                }
                              ]}
                              scroll={{ x: 1400 }}
                            ></Table>
                          </section>
                        </div>
                        <div className="w-[50%]">
                          <Divider />
                        </div>
                      </Card>
                    );
                  })}
                </section>
              </>
            )}
          </div>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={6}>
              <Button size="large" block onClick={() => navigate(backUrl)}>
                <BoldButtonLabel labelText="Back" />
              </Button>
            </Col>
          </Row>
        </Card>
      </div>

      <Modal
        width={400}
        centered
        open={showTrackingInfoModal}
        footer={null}
        onCancel={() => setShowTrackingInfoModal(false)}
      >
        <section>
          <div>
            <span className="font-semibold"> Delivered by -</span>
          </div>
          <div>
            <span className="font-semibold">Tracking Number - AW120000033</span>
          </div>
          <section className="mt-2">
            <div className="my-2">
              <span className="font-semibold">Friday, 2nd June</span>
            </div>
            <Timeline
              items={[
                {
                  children: (
                    <>
                      <div>
                        <span>10:00 AM - Ordered Delivered</span>
                      </div>
                      <div>
                        <span>Estación Central, Chile</span>
                      </div>
                    </>
                  )
                },
                {
                  children: (
                    <>
                      <div>
                        <span>07:00 AM - Out For Delivery</span>
                      </div>
                      <div>
                        <span>Estación Central, Chile</span>
                      </div>
                    </>
                  )
                },
                {
                  children: (
                    <>
                      <div>
                        <span>6:30 AM - Package arrived at final destination</span>
                      </div>
                      <div>
                        <span>Estación Central, Chile</span>
                      </div>
                    </>
                  )
                },
                {
                  children: (
                    <>
                      <div>
                        <span>5:30 AM - Package arrived at - facility</span>
                      </div>
                      <div>
                        <span>Estación Central, Chile</span>
                      </div>
                    </>
                  )
                }
              ]}
            />
            <div className="mb-2 -mt-4">
              <span className="font-semibold">Thursday, 1st June</span>
            </div>
            <Timeline
              items={[
                {
                  children: (
                    <>
                      <div>
                        <span>11:00 PM - Carrier picked up package</span>
                      </div>
                      <div>
                        <span>- facility, Estación Central, Chile</span>
                      </div>
                    </>
                  )
                }
              ]}
            />
          </section>
        </section>
      </Modal>

      <Modal
        width={400}
        centered
        open={showGiftingInstructionModal}
        footer={null}
        onCancel={() => setShowGiftingInstructionModal(false)}
      >
        <Form layout="vertical">
          <Form.Item label="Enter the gifting instructions">
            <Input.TextArea placeholder="Gifting Instruction..." />
          </Form.Item>
          <Button
            block
            onClick={() => {
              displaySuccessNotification({ message: 'Gifting Instructions Updated!' });
              setShowGiftingInstructionModal(false);
            }}
          >
            <BoldButtonLabel labelText="Update" />
          </Button>
        </Form>
      </Modal>

      <Modal
        title={'Item Information'}
        footer={null}
        maskClosable={false}
        centered
        width={1200}
        open={itemModalInfo.open}
        onCancel={() => {
          closeItemLevelModal();
        }}
      >
        <ItemLevelView itemInfo={itemModalInfo.itemInfo} />
      </Modal>
    </PrimaryLayout>
  );
};

export default OrderDetails;
