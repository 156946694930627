import axios, { AxiosError, AxiosResponse } from 'axios';
import { generateUUIDV4 } from '../helpers/uuid.helpers';
// import { STATIC_TOKEN, businessGroupId, tenantID } from '../config/envConfig';
import { useAuth } from '../stores/use-auth';
import { displayErrorNotifications } from '../helpers/toast.helpers';

const CLIENT_ID = 'desktop-app';
const TRACE_ID = generateUUIDV4();
const TRANSACTION_ID = generateUUIDV4();

const AXIOS_TIMEOUT = 10 * 1000;
const axiosInstance = axios.create({
  timeout: AXIOS_TIMEOUT
});

// axiosInstance.defaults.headers.common.x_trace_id = TRACE_ID;
// axiosInstance.defaults.headers.common.trace_id = TRACE_ID;
// axiosInstance.defaults.headers.common.client_id = CLIENT_ID;
axiosInstance.defaults.headers.common['x-client-id'] = CLIENT_ID;
axiosInstance.defaults.headers.common['x-trace-id'] = TRACE_ID;
// axiosInstance.defaults.headers.common.x_transaction_id = TRANSACTION_ID;

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    axiosInstance.defaults.headers.common['x-idempotent-id'] = generateUUIDV4()
    return response;
  },
  (error: AxiosError) => {
    if (error?.response?.status === 401) {
      useAuth?.getState()?.resetAuthStates();
    }
    if (error?.response?.status === 403) {
      // displayErrorNotifications([{ message: 'User not authorized to perform action on this resource' }]);
    }
    return Promise.reject(error);
  }
);

const accessToken = useAuth.getState()?.accessTokenData;
const tenantId = useAuth.getState()?.tenentName;
const businessGroupId = useAuth.getState()?.bussinessGroupName;

//!TEMPORARY HEADER UNTILL AUTH IS SETUP

if (accessToken) axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

axiosInstance.defaults.headers.common['x-tenant-id'] = tenantId;
// axiosInstance.defaults.headers.common.tenant_id = tenantId || '';
axiosInstance.defaults.headers.common['x-business-group-id'] = businessGroupId;
// axiosInstance.defaults.headers.common['business_grp_id'] = businessGroupId || '';

export default axiosInstance;
