import React from 'react';
import { promotionService } from '../../services/promotion.service';
import _ from '../../../../helpers/lodash';

const cache: Record<
  string,
  {
    item_id: string;
    article_id: string;
    is_variant: boolean;
    variant_id: string;
    vendor_code: string;
    product_name: string;
  }[]
> = {};

export const useProducts = () => {
  const [productDropdownFetching, setProductDropdownFetching] = React.useState(false);
  const [productDropdownOptions, setProductDropdownOptions] = React.useState<{ label: string; value: string }[]>([]);

  const fetchUserDropdownOptions = async (searchQuery: string) => {
    setProductDropdownFetching(true);

    if (cache[searchQuery]) {
      const transformedResponse = transformResponse(cache[searchQuery]);
      setProductDropdownOptions(() => {
        return [...transformedResponse];
      });
    } else {
      const { data, errors } = await promotionService.searchProductsByQuery(searchQuery);
      cache[searchQuery] = data;
      if (_.isEmpty(errors)) {
        const transformedResponse = transformResponse(data);
        setProductDropdownOptions(() => {
          return [...transformedResponse];
        });
      }
    }
    setProductDropdownFetching(false);
  };

  const transformResponse = (data: any) => {
    return (data || []).map(
      ({
        item_id,

        product_name
      }: {
        item_id: string;
        article_id: string;
        is_variant: boolean;
        variant_id: string;
        vendor_code: string;
        product_name: string;
      }) => {
        return { label: `${item_id}-${product_name}`, value: item_id };
      }
    );
  };

  const debouncedFetchProductDropdownOptions = _.debounce(fetchUserDropdownOptions, 1000);

  return {
    debouncedFetchProductDropdownOptions,
    productDropdownFetching,
    productDropdownOptions
  };
};
