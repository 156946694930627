import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Select, TimePicker, Typography } from 'antd';
import dayjs from 'dayjs';
import * as React from 'react';
import { stateList } from '../../data';
import { countryList, currencyList, phoneCountryCodeList } from '../../data/intl-data';
import BoldButtonLabel from '../BoldButtonLabel';

import { useLoader } from '../../stores/use-loader';
interface INodeDetailFormProps {
  nodeDetailsForm: any;
  nodeDetails: any;
  handleClickNext: (data: any) => void;
  handleClickBack: (data: any) => void;
  currentStep: number;
  operatorList: any[];
}

const NodeDetailForm: React.FunctionComponent<INodeDetailFormProps> = ({
  nodeDetails,
  nodeDetailsForm,
  handleClickBack,
  handleClickNext,
  operatorList
}) => {
  const [isOperatorManaged, setIsOperatorManaged] = React.useState(false);

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    nodeDetailsForm.setFieldsValue(nodeDetails);
    setLoading(false);
  }, [nodeDetails]);

  const operatorsListSelect = operatorList.map(operator => ({
    label: operator.operator_name,
    value: operator.operator_id
  }));

  return (
    <Form layout="vertical" form={nodeDetailsForm} scrollToFirstError>
      <Row gutter={12}>
        <Col xs={12} md={8} xxl={4}>
          <Form.Item name="node_name" label="Node Name" rules={[{ required: true, message: 'Node name is required' }]}>
            <Input placeholder="Enter Node Name" size="large"></Input>
          </Form.Item>
        </Col>
        <Col xs={12} md={8} xxl={4}>
          <Form.Item name="short_name" label="Short Name">
            <Input placeholder="Enter Node Name" size="large"></Input>
          </Form.Item>
        </Col>
        <Col xs={12} md={8} xxl={4}>
          <Form.Item name="node_type" label="Node Type" rules={[{ required: true, message: 'Node type is required' }]}>
            <Select
              options={[
                { label: 'Warehouse', value: 'WH' },
                { label: 'Store', value: 'STORE' },
                { label: 'Cross dock center', value: 'CROSS_DOCK_CENTER' },
                { label: 'Vendor Facility', value: 'VENDOR_FACILITY' },
                { label: 'Seller Facility', value: 'SELLER_FACILITY' }
              ]}
              placeholder="Select Node type"
              size="large"
            ></Select>
          </Form.Item>
        </Col>

        <Col xs={12} md={8} xxl={4}>
          <Form.Item
            name="is_operator_managed"
            label="Is Node Operator Managed ?"
            rules={[{ required: true, message: 'Is Operator Managed is required' }]}
          >
            <Select
              placeholder="..."
              onChange={value => setIsOperatorManaged(value)}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ]}
              size="large"
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={8} xxl={4}>
          <Form.Item
            name="operator_id"
            label="Operator ID"
            rules={[{ required: isOperatorManaged, message: 'Operator ID is required' }]}
          >
            <Select placeholder="Select Operator ID" options={operatorsListSelect} size="large"></Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={8} xxl={4}>
          <Form.Item
            name="org_code"
            label="Organisation Code"
            rules={[{ required: true, message: 'Org code is required' }]}
          >
            <Input className="w-full" placeholder="Enter Organisation code" size="large"></Input>
          </Form.Item>
        </Col>
        {/* <Col xs={12} md={8} xxl={4}>
          <Form.Item
            name="lead_time_in_days"
            label="Lead time in days"
            rules={[{ required: true, message: 'Lead time in days is required' }]}
          >
            <InputNumber className="w-full" min={0} placeholder="Enter Lead time in days" size="large"></InputNumber>
          </Form.Item>
        </Col> */}
        {/* <Col xs={12} md={8} xxl={4}>
          <Form.Item
            name="shipping_lead_time_in_days"
            label="Shipping Lead time in days"
            rules={[{ required: true, message: 'Shipping Lead time is required' }]}
          >
            <InputNumber
              className="w-full"
              min={0}
              placeholder="Enter Shipping Lead time in days"
              size="large"
            ></InputNumber>
          </Form.Item>
        </Col> */}
        <Col xs={12} md={8} xxl={4}>
          <Form.Item
            initialValue={dayjs.tz.guess()}
            name="timezone"
            label="Timezone"
            rules={[{ required: true, message: 'Timezone is required' }]}
          >
            <Select
              placeholder="Select Timezone"
              options={[
                {
                  label: dayjs.tz.guess(),
                  value: dayjs.tz.guess()
                },
                {
                  label: 'America/Santiago',
                  value: 'America/Santiago'
                },
                {
                  label: 'America/Punta_Arenas',
                  value: 'America/Punta_Arenas'
                },
                {
                  label: 'Pacific/Easter',
                  value: 'Pacific/Easter'
                }
              ]}
              size="large"
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={8} xxl={4}>
          <Form.Item name="currency" label="Currency" rules={[{ required: true, message: 'Currency is required' }]}>
            <Select placeholder="Select Currency" options={currencyList} size="large"></Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={8} xxl={4}>
          <Form.Item
            name="business_email"
            rules={[{ required: true, message: 'Business email is required' }]}
            label="Business Email"
          >
            <Input type="email" placeholder="Enter Business Email" size="large"></Input>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            label="Phone Number"
            name={['phone_number', 'number']}
            rules={[{ required: true, message: 'Phone number is required' }]}
          >
            <Input
              addonBefore={
                <Form.Item
                  initialValue={phoneCountryCodeList[0]?.value}
                  noStyle
                  name={['phone_number', 'country_code']}
                  rules={[{ required: true, message: 'Country code is requiered' }]}
                >
                  <Select style={{ width: 120 }} options={phoneCountryCodeList} size="large"></Select>
                </Form.Item>
              }
              min={0}
              placeholder="Enter Phone Number"
              size="large"
            ></Input>
          </Form.Item>
        </Col>
        <Col xs={12} md={8} xxl={4}>
          <Form.Item
            name="phone_availability"
            label="Phone Availability"
            rules={[{ required: true, message: 'Availability is required' }]}
          >
            <TimePicker.RangePicker
              placeholder={['From', 'To']}
              format={'H:mm A'}
              size="large"
            ></TimePicker.RangePicker>
          </Form.Item>
        </Col>
      </Row>
      <section>
        <Typography.Title level={4} className="text-[#2e2a5b] m-0">
          Address
        </Typography.Title>
        <div>
          <div className="mb-2">
            <span className="text-lg font-medium">Primary Address</span>
          </div>

          <Row gutter={12}>
            <Col xs={12} md={8} xxl={4}>
              <Form.Item
                name={['primary', 'type']}
                label="Address type"
                rules={[{ required: true, message: 'Address Type is required' }]}
              >
                <Select
                  size="large"
                  options={[
                    { label: 'Business', value: 'BUSINESS' },
                    { label: 'Mail', value: 'MAIL' },
                    { label: 'Registered', value: 'REGISTERED' },
                    { label: 'Personal', value: 'PERSONAL' },
                    { label: 'Other', value: 'OTHER' }
                  ]}
                  placeholder="Select Address type"
                ></Select>
              </Form.Item>
            </Col>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return prevValues.primary?.type !== curValues.primary?.type;
              }}
            >
              {() => {
                const addressType = nodeDetailsForm.getFieldsValue()?.primary?.type;

                const showCustomType = addressType === 'OTHER';
                return (
                  showCustomType && (
                    <Col xs={12} md={8} xxl={4}>
                      <Form.Item
                        name={['primary', 'custom_type']}
                        label="Custom Address Type"
                        rules={[{ required: true, message: 'Custom address type is required' }]}
                      >
                        <Input size="large" placeholder="Enter custom address type"></Input>
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
            <Col xs={12} md={8} xxl={4}>
              <Form.Item
                name={['primary', 'address_line1']}
                label="Address Line 1"
                rules={[{ required: true, message: 'Address line 1 is required' }]}
              >
                <Input size="large" placeholder="Enter Address line 1"></Input>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} xxl={4}>
              <Form.Item name={['primary', 'address_line2']} label="Address Line 2">
                <Input size="large" placeholder="Enter Address line 2"></Input>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} xxl={4}>
              <Form.Item name={['primary', 'address_line3']} label="Address Line 3">
                <Input size="large" placeholder="Enter Address line 3"></Input>
              </Form.Item>
            </Col>

            <Col xs={12} md={8} xxl={4}>
              <Form.Item name={['primary', 'landmark']} label="Landmark">
                <Input size="large" placeholder="Enter Landmark"></Input>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} xxl={4}>
              <Form.Item
                name={['primary', 'city']}
                label="City"
                rules={[{ required: true, message: 'City is required' }]}
              >
                <Input size="large" placeholder="Enter City"></Input>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} xxl={4}>
              <Form.Item
                name={['primary', 'postal_code']}
                rules={[{ required: true, message: 'Postal code is required' }]}
                label="Postal Code"
              >
                <Input size="large" placeholder="Enter Postal Code"></Input>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} xxl={4}>
              <Form.Item
                name={['primary', 'coordinates', 'latitude']}
                rules={[{ required: true, message: 'Latitude is required' }]}
                label="Latitude"
              >
                <Input size="large" placeholder="Enter latitude"></Input>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} xxl={4}>
              <Form.Item
                name={['primary', 'coordinates', 'longitude']}
                rules={[{ required: true, message: 'Longitude is required' }]}
                label="Longitude"
              >
                <Input size="large" placeholder="Enter longitude"></Input>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} xxl={4}>
              <Form.Item
                name={['primary', 'country']}
                label="Country"
                rules={[{ required: true, message: 'Country is required' }]}
              >
                <Select
                  size="large"
                  onChange={() => nodeDetailsForm.setFieldValue(['primary', 'state'], null)}
                  options={countryList}
                  placeholder="Select country"
                ></Select>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} xxl={4}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return (
                    prevValues.primary?.country !== curValues.primary?.country ||
                    prevValues.primary?.state !== curValues.primary?.state
                  );
                }}
              >
                {form => {
                  return (
                    <Form.Item
                      name={['primary', 'state']}
                      label="State"
                      rules={[{ required: true, message: 'State is required' }]}
                    >
                      <Select
                        size="large"
                        options={stateList[nodeDetailsForm.getFieldsValue()?.primary?.country]}
                        placeholder="Select State"
                      ></Select>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div>
          <span className="text-lg font-medium">Other Address</span>
          <div className="my-4">
            <Form.List name="other_address">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <React.Fragment key={key}>
                      <Row>
                        <Col xs={12}>
                          <Divider orientation="left" plain>
                            <span className="text-lg">Address - {index + 1} </span>
                          </Divider>
                        </Col>
                      </Row>

                      <Row gutter={12}>
                        <Col xs={12} md={8} xxl={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'type']}
                            label="Address type"
                            rules={[{ required: true, message: 'Address Type is required' }]}
                          >
                            <Select
                              size="large"
                              placeholder="Select Address type"
                              options={[
                                { label: 'Business', value: 'BUSINESS' },
                                { label: 'Mail', value: 'MAIL' },
                                { label: 'Registered', value: 'REGISTERED' },
                                { label: 'Personal', value: 'PERSONAL' },
                                { label: 'Other', value: 'OTHER' }
                              ]}
                            ></Select>
                          </Form.Item>
                        </Col>

                        <Form.Item noStyle shouldUpdate>
                          {() => {
                            const isDataPresent = nodeDetailsForm.getFieldsValue()?.other_address?.length;

                            const addressType = isDataPresent
                              ? nodeDetailsForm.getFieldsValue()?.other_address[index]?.type
                              : '';

                            const showCustomType = addressType === 'OTHER';
                            return (
                              showCustomType && (
                                <Col xs={12} md={8} xxl={4}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'custom_type']}
                                    label="Custom Address Type"
                                    rules={[{ required: true, message: 'Custom address type is required' }]}
                                  >
                                    <Input size="large" placeholder="Enter custom address type"></Input>
                                  </Form.Item>
                                </Col>
                              )
                            );
                          }}
                        </Form.Item>

                        <Col xs={12} md={8} xxl={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'address_line1']}
                            label="Address Line 1"
                            rules={[{ required: true, message: 'Address line 1 is required' }]}
                          >
                            <Input size="large" placeholder="Enter Address line 1"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={8} xxl={4}>
                          <Form.Item {...restField} name={[name, 'address_line2']} label="Address Line 2">
                            <Input size="large" placeholder="Enter Address line 2"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={8} xxl={4}>
                          <Form.Item {...restField} name={[name, 'address_line3']} label="Address Line 3">
                            <Input size="large" placeholder="Enter Address line 3"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={8} xxl={4}>
                          <Form.Item {...restField} name={[name, 'landmark']} label="Landmark">
                            <Input size="large" placeholder="Enter Landmark"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={8} xxl={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'city']}
                            label="City"
                            rules={[{ required: true, message: 'City is required' }]}
                          >
                            <Input size="large" placeholder="Enter City"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={8} xxl={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'postal_code']}
                            label="Postal Code"
                            rules={[{ required: true, message: 'Postal code is required' }]}
                          >
                            <Input size="large" placeholder="Enter Postal Code"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={8} xxl={4}>
                          <Form.Item
                            name={[name, 'coordinates', 'latitude']}
                            rules={[{ required: true, message: 'Latitude is required' }]}
                            label="Latitude"
                          >
                            <Input size="large" placeholder="Enter latitude"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={8} xxl={4}>
                          <Form.Item
                            name={[name, 'coordinates', 'longitude']}
                            rules={[{ required: true, message: 'Longitude is required' }]}
                            label="Longitude"
                          >
                            <Input size="large" placeholder="Enter longitude"></Input>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={8} xxl={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'country']}
                            label="Country"
                            rules={[{ required: true, message: 'Country is required' }]}
                          >
                            <Select
                              options={countryList}
                              onChange={() => {
                                const { other_address, ...rest } = nodeDetailsForm.getFieldsValue();
                                Object.assign(other_address[index], { ...other_address[index], state: null });
                                nodeDetailsForm.setFieldsValue({ ...rest, other_address });
                              }}
                              size="large"
                              placeholder="Select country"
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={8} xxl={4}>
                          <Form.Item noStyle shouldUpdate>
                            {() => {
                              const isDataPresent = nodeDetailsForm.getFieldsValue()?.other_address?.length;
                              const stateValue = isDataPresent
                                ? stateList[nodeDetailsForm.getFieldsValue()?.other_address[index]?.country]
                                : '';
                              return (
                                <Form.Item
                                  {...restField}
                                  name={[name, 'state']}
                                  label="State"
                                  rules={[{ required: true, message: 'State is required' }]}
                                >
                                  <Select options={stateValue} size="large" placeholder="Select State"></Select>
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </Col>
                        <div className="mx-8 flex justify-center items-center">
                          <DeleteOutlined
                            className="text-red-500 text-xl"
                            title="Delete Address"
                            onClick={() => remove(name)}
                          />
                        </div>
                      </Row>
                    </React.Fragment>
                  ))}
                  <Form.Item>
                    <Row justify={'start'}>
                      <Col xs={12} md={8} xxl={4}>
                        <Button onClick={() => add()} icon={<PlusOutlined />}>
                          <BoldButtonLabel labelText="Add Address" />
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        </div>
      </section>
      <Row gutter={[12, 12]}>
        <Col xs={12} md={8} xxl={4}>
          <Button size="large" onClick={() => handleClickBack(nodeDetailsForm.getFieldsValue())} block>
            <BoldButtonLabel labelText="Back"></BoldButtonLabel>
          </Button>
        </Col>
        <Col xs={12} md={8} xxl={4}>
          <Button
            type="primary"
            size="large"
            onClick={async () => {
              await nodeDetailsForm.validateFields();
              handleClickNext(nodeDetailsForm.getFieldsValue());
            }}
            block
          >
            <BoldButtonLabel labelText="Next"></BoldButtonLabel>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default NodeDetailForm;
