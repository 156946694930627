import * as React from 'react';

import { useLoader } from '../../../stores/use-loader';
import { promotionService } from '../services/promotion.service';
import _ from '../../../helpers/lodash';
import { Button, Card, Col, Row, Table, Tooltip, Typography } from 'antd';

import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { TableRowSelection } from 'antd/es/table/interface';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';


const defaultPrio = [
      // { label: 'Basic Promotion', key: 'PROMO', value: 'PROMO', priority: 0 },
      // { label: 'BXGY Promotion', key: 'BXGY', value: 'BXGY', priority: 1 }
      { label: 'Sub Total Discount Promo', key: 'SUB_TTL_DIS', value: 'SUB_TTL_DIS', priority: 0 },
      // { label: 'Next Order Coupons Promo', key: 'NXT_ORDER_COUPONS', value: 'NXT_ORDER_COUPONS', priority: 1 },
      { label: 'Volume Discount Promo', key: 'VOLUME_DISC', value: 'VOLUME_DISC', priority: 1 },
      { label: 'Bundle Discount Promo', key: 'BUNDLE_DISC', value: 'BUNDLE_DISC', priority: 2 },
      { label: 'Shipping Discount Promo', key: 'SHIPPING_DISC', value: 'SHIPPING_DISC', priority: 3 },
      { label: 'Payment Discount Promo', key: 'PAYMENT_DISC', value: 'PAYMENT_DISC', priority: 4 },
      { label: 'BXGY Promotion', key: 'BXGY', value: 'BXGY', priority: 6 }
];

type PromotionType = {
      label: string;
      key: string;
      value: string;
      priority: number;
};

const labelValueMap: Record<string, string> = {
      // PROMO: 'Basic Promotion',
      // COUPON: 'Coupon',
      // BXGY: 'BXGY Promotion',
      // LOYT_REWARD: 'Loyalty Program'
      SUB_TTL_DIS: 'Sub Total Discount',
      // NXT_ORDER_COUPONS: 'Next Order Coupons',
      VOLUME_DISC: 'Volume Discount',
      BUNDLE_DISC: 'Bundle Discount',
      SHIPPING_DISC: 'Shipping Discount',
      PAYMENT_DISC: 'Payment Discount',
      BXGY: 'BXGY'
};
const columns = [
      {
            title: 'Promo Type',
            render(value: any, record: any, index: any) {
                  return <>{record.label}</>;
            }
      },
      {
            title: 'Priority',
            render(value: any, record: any, index: any) {
                  return <>{record.priority}</>;
            }
      }
]


const PromotionSettings: React.FC = () => {
      const [promotionPriorities, setPromotionPriorities] = React.useState([] as PromotionType[]);
      const [originalData, setOriginalData] = React.useState([] as PromotionType[]);
      const [isUpdate, setIsUpdate] = React.useState(false);
      const [selectedRowKeys, setSelectedRowKeys] = React.useState([] as string[]);

      const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
      React.useEffect(() => {
            fetchPromotionPriorities();
      }, []);
      const fetchPromotionPriorities = async () => {
            setLoading(true);
            const { data, errors } = await promotionService.getPromotionPriorities();
            if (_.isEmpty(errors) && data?.length) {
                  const transformedData = data?.map(
                        ({ priority, promotion_type }: { priority: string; promotion_type: string }) => ({
                              priority,
                              label: labelValueMap[promotion_type],
                              key: promotion_type,
                              value: promotion_type
                        })
                  );
                  setOriginalData([...transformedData]);
                  setPromotionPriorities([...transformedData]);
                  setIsUpdate(true);
            } else {
                  setOriginalData([...defaultPrio]);
                  setPromotionPriorities([...defaultPrio]);
            }
            setLoading(false);
      };


      const rowSelection: TableRowSelection<PromotionType> = {
            type: 'radio',
            selectedRowKeys,
            onChange: (keys: any) => {
                  setSelectedRowKeys(keys);
            }
      };

      const moveRow = (value: string, direction: 'up' | 'down') => {
            setPromotionPriorities(promotionPriorities => {
                  const newData = [...promotionPriorities];
                  const index = newData.findIndex(item => item.value === value);

                  if (index === -1) {
                        return promotionPriorities;
                  }
                  const [movedItem] = newData.splice(index, 1);
                  const targetIndex = direction === 'up' ? index - 1 : index + 1;
                  newData.splice(targetIndex, 0, movedItem);
                  const transformedData = newData.map((data, index) => ({ ...data, priority: index }));

                  return transformedData;
            });
            setSelectedRowKeys([value]);
      };

      const findRowIndexByType = (value: string): number => {
            return promotionPriorities.findIndex(item => item.value === value);
      };

      const indexOfSelectedRadio = findRowIndexByType(selectedRowKeys[0]);
      const isUpDisabled = indexOfSelectedRadio === 0;
      const isDownDisabled = indexOfSelectedRadio === promotionPriorities.length - 1;

      const handleReset = () => {
            setPromotionPriorities(originalData);
      };

      const handleSave = async () => {
            setLoading(true);

            const promotion_priorities = promotionPriorities.map(({ priority, value }) => {
                  return {
                        promotion_type: value,
                        priority
                  };
            });

            const request = {
                  promotion_priorities
            };

            const { data, errors } = await (isUpdate
                  ? promotionService.putPromotionPrio(request)
                  : promotionService.createPromotionPrio(request));

            if (!_.isEmpty(errors)) {
                  displayErrorNotifications(errors);
            } else {
                  displaySuccessNotification({ message: 'Promotion Priorities updated' });
                  await fetchPromotionPriorities();
            }

            setLoading(false);
      };


      return <div className="container mx-auto px-4" >
            <Card>
                  <Row justify={'space-between'} className="mb-4">
                        <Col>
                              <Typography.Title level={3} className="text-[#2e2a5b]">
                                    Promotion Settings
                              </Typography.Title>
                        </Col>
                  </Row>
                  <Row gutter={[12, 12]}>
                        <Col xs={22} md={12}>
                              <Table
                                    bordered
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={promotionPriorities || []}
                                    pagination={false}
                              ></Table>
                        </Col>
                        {!_.isEmpty(selectedRowKeys) && (
                              <Col xs={2} md={12}>
                                    <section className="flex flex-col gap-4 h-full justify-center items-start ">
                                          <div>
                                                <Tooltip title="Move up">
                                                      <Button
                                                            disabled={isUpDisabled}
                                                            type="ghost"
                                                            onClick={() => moveRow(selectedRowKeys[0], 'up')}
                                                            icon={<CaretUpOutlined style={{ fontSize: '32px' }} />}
                                                      ></Button>
                                                </Tooltip>
                                          </div>
                                          <div>
                                                <Tooltip title="Move down">
                                                      <Button
                                                            disabled={isDownDisabled}
                                                            type="ghost"
                                                            onClick={() => moveRow(selectedRowKeys[0], 'down')}
                                                            icon={<CaretDownOutlined style={{ fontSize: '32px' }} />}
                                                      ></Button>
                                                </Tooltip>
                                          </div>
                                    </section>
                              </Col>
                        )}
                  </Row>
                  <Row className="mt-8" gutter={[12, 12]}>
                        <Col xs={12} md={4}>
                              <Button type="primary" onClick={handleSave} block size="large">
                                    <BoldButtonLabel labelText={isUpdate ? 'Update' : 'Save'}></BoldButtonLabel>
                              </Button>
                        </Col>
                        <Col xs={12} md={4}>
                              <Button block size="large" onClick={handleReset}>
                                    <BoldButtonLabel labelText="Reset"></BoldButtonLabel>
                              </Button>
                        </Col>
                  </Row>
            </Card>
      </div>


}


export default PromotionSettings;