const appRoutes = {
  inventory: {
    index: '/inventory',
    details: '/inventory/details'
  },
  order: {
    index: '/order'
  }
};

export { appRoutes };
