import { Card, Col, Form, Row, Typography, Input } from 'antd';
import * as React from 'react';
import PrimaryLayout from '../../../../../layouts/primary-layout';
import ServiceNetworkForm from '../../../components/service-network-form';
import { convertQueryStringToObj } from '../../../../../helpers';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { logisticService } from '../../../services';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../../helpers/toast.helpers';
import _ from '../../../../../helpers/lodash';
import dayjs from 'dayjs';

interface IServiceNetworkCreateProps {}

const ServiceNetworkCreate: React.FunctionComponent<IServiceNetworkCreateProps> = props => {
  const { shippingId } = useParams();
  const [createForm] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    loadIntialData();
  }, []);

  const loadIntialData = () => {
    createForm.setFieldsValue({
      shipping_profile_id: shippingId,
      policies: [{ is_active: true }]
    });
  };

  const getTimeString = (time: any, format: string) => {
    let reqTime = dayjs(time).format(format);
    return reqTime;
  };

  const getPolicy = (data: any) => {
    return data.map((item: any) => {
      return {
        cut_off_time: getTimeString(item.cut_off_time,'HH:mm:ss'),
        delivery_option: item.delivery_option,
        is_active: item.is_active,
        max_volume_in_cc: item.max_volume_in_cc,
        max_weight_in_kg: item.max_weight_in_kg,
        min_volume_in_cc: item.min_volume_in_cc,
        min_weight_in_kg: item.min_weight_in_kg,
        service_execution_lead_time_in_hours: 0,
        shipping_mode: 'string',
        tat_time_in_hours: 0
      };
    });
  };

  const handleSubmit = async () => {
    const bodyData = {
      description: createForm.getFieldsValue().description,
      dnn_id: createForm.getFieldsValue().dnn_id,
      policies: getPolicy(createForm.getFieldsValue().policies),
      service_category: createForm.getFieldsValue().service_category,
      shipping_profile_id: createForm.getFieldsValue().shipping_profile_id
    };
    const { errors } = await logisticService.createNewServiceNetwork(bodyData);
    if (_.isEmpty(errors)) {
      if (searchParams.get('backUrl')) {
        navigate(searchParams.get('backUrl') as string);
      } else {
        navigate('/logistics/service-network');
      }
      displaySuccessNotification({ message: 'Service-network created sucessfully!' });
    } else {
      displayErrorNotifications(errors);
    }
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row gutter={[24, 12]} align={'middle'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Create Service Network
              </Typography.Title>
            </Col>
          </Row>

          <ServiceNetworkForm formInstance={createForm} handleSubmit={handleSubmit} />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default ServiceNetworkCreate;
