import React, { useEffect, useState } from 'react';
import { promotionService } from '../../services/promotion.service';
import _ from '../../../../helpers/lodash';

export const useBrands = () => {
  useEffect(() => {
    fetchBrands();
  }, []);
  const [brandDropdownOptions, setBrandDropdownOptions] = useState<string[]>([]);
  const fetchBrands = async () => {
    const { data, errors } = await promotionService.getBrands();
    if (_.isEmpty(errors)) {
      setBrandDropdownOptions(data);
    }
  };

  return { brandDropdownOptions };
};
