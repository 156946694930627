import React, { useEffect, useState } from 'react';
import { promotionService } from '../../services/promotion.service';
import _ from '../../../../helpers/lodash';

export const useModels = () => {
  useEffect(() => {
    fetchModels();
  }, []);
  const [modelDropdownOptions, setModelDropdownOptions] = useState<string[]>([]);
  const fetchModels = async () => {
    const { data, errors } = await promotionService.getModels();
    if (_.isEmpty(errors)) {
      setModelDropdownOptions(data);
    }
  };

  return { modelDropdownOptions };
};
