import { Button, Card, Col, FormInstance, Modal, Row, Typography } from 'antd';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import _ from "../../../../helpers/lodash";
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import { useLoader } from '../../../../stores/use-loader';
import { productService } from '../../services';
import DeliveryDetailsForm from './delivery-details-form';
import ProductCategoryAccordion from './product-category-accordion';
import ShippingBasicDetailsUpdate from './shipping-basic-details-update';
import ShippingChargesForm from './shipping-charges-form';

interface IShippingProfileFormProps {
  shippingProfileResponse?: any;
  isUpdate?: boolean;
  formInstance: FormInstance;
  readOnly?: boolean;
  mandtCreation?: boolean;
  renderState?: boolean;
  basicformInstance: FormInstance;
  shippingformInstance: FormInstance;
  deliveryDetailsForm: FormInstance;
  renderTree?: () => any;
  shippingProfileTree?: () => void;
  onFinish?: () => void;
  availableCategory?: any;
  availableSku?: any;
  handleOnDetailsUpdate?: () => void;
  handleOnCloseCategory?: (product: any, category: any) => void;
  handleOnUpdateCharges?: () => void;
  saveResp?: any
}

const ShippingProfileUpdateForm: React.FunctionComponent<IShippingProfileFormProps> = ({
  //   formInstance,
  readOnly,
  basicformInstance,
  handleOnDetailsUpdate,
  shippingformInstance,
  handleOnUpdateCharges,
  saveResp,
  deliveryDetailsForm
}) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const [showProductModal, setShowProductModal] = React.useState(false);
  const [catagoryRespData, setCategoryRespData] = React.useState({} as any)

  // Function to handle modal close
  const handleModalClose = () => {
    setShowProductModal(false);
  }

  const fetchAllCategories = async () => {
    setLoading(true);
    let params = {
      page: 1,
      search_key: ''
    }
    const { data, errors } = await productService.getAllCategories(params);
    if (_.isEmpty(errors)) {
      let modifyCategory = data?.categories?.map((item: any, index: any) => {
        let presentInResponse = saveResp.categories.includes(item.category)
        return {
          ...item,
          key: index,
          available: presentInResponse,
          children: {}
        }
      })
      let modifiedData = {
        ...data,
        categories: modifyCategory
      }
      setCategoryRespData(modifiedData)
      setShowProductModal(true)
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  }

  const handleOnPageUpdate = (page: number) => {

  }

  return (
    <>
      <ShippingBasicDetailsUpdate
        basicformInstance={basicformInstance}
        handleOnUpdate={handleOnDetailsUpdate}
        readOnly={readOnly}
      />

      <Card>
        <DeliveryDetailsForm readOnly={readOnly} deliveryDetailsForm={deliveryDetailsForm} />
      </Card>

      <Card className="my-2">
        <Typography className="text-[#2e2a5b]">Product & Category Information Edit</Typography>
        <section className="my-2">
          <Row gutter={12}>
            <Col xs={24} md={8} lg={6}>
              <div className="flex gap-2 items-center h-full">
                <Button
                  size="large"
                  onClick={fetchAllCategories}
                // onClick={() => setShowProductModal(true)}
                >
                  <BoldButtonLabel labelText="Add Products / Categories" />
                </Button>
              </div>
            </Col>
          </Row>
        </section>
      </Card>

      <ShippingChargesForm
        shippingFormInstance={shippingformInstance}
        readOnly={readOnly}
        handleOnUpdateCharges={handleOnUpdateCharges}
      />
      <Row gutter={[12, 12]}>
        <Col xs={24} md={8} lg={6}>
          <Button
            size="large"
            disabled={false}
            block
            onClick={() => {
              if (searchParams.get('backUrl')) {
                navigate(searchParams.get('backUrl') as string);
              } else {
                navigate(-1);
              }
            }}
          >
            <BoldButtonLabel labelText="Go Back" />
          </Button>
        </Col>
      </Row>
      <Modal
        maskClosable={false}
        width={900}
        centered
        open={showProductModal}
        onCancel={handleModalClose}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Select Categories & Product</Typography.Title>
          </div>
        }
      >
        <ProductCategoryAccordion catagoryRespData={catagoryRespData} saveResp={saveResp} handleOnPageUpdate={handleOnPageUpdate} />
      </Modal>
    </>
  );
};

export default ShippingProfileUpdateForm;
