import { Rule } from 'antd/es/form';

export const getRequiredValidator = ({ message }: { message: string }): Rule => {
  return {
    required: true,
    message
  };
};

export const getNumericValidatorForInput = ({ message }: { message?: string }): Rule => {
  return {
    validator: (_, value) => {
      if (isNumeric(value) || !value) {
        return Promise.resolve();
      }
      return Promise.reject(message || 'Please enter a valid numeric value');
    }
  };
};

export const getNumeric2DecimalPointValidatorForInput = ({ message }: { message?: string }): Rule => {
  return {
    validator: (_, value) => {
      if (isNumericWithTwoDecimalPlaces(value) || !value) {
        return Promise.resolve();
      }
      return Promise.reject(message || 'Please enter a valid numeric value with only 2 decimal places. Eg: (1.23) ');
    }
  };
};

export const isNumeric = (value: string) => {
  // Use a regular expression to check if the value is numeric
  return /^\d+$/.test(value);
};

const isNumericWithTwoDecimalPlaces = (value: string) => {
  // Use a regular expression to check if the value is numeric with two decimal places
  return /^\d+(\.\d{2})?$/.test(value);
};
