export const cbtProducts = [
  {
    label: 'Nebula',
    domain: 'nebula',
    description:
      'Order Management System adorned with cutting-edge AI (Artificial Intelligence) and ML (Machine Learning) capabilities, poised to revolutionize the realm of efficient business operations and customer satisfaction.'
  },
  {
    label: 'Loyalty Engine',
    domain: 'loyalty',
    description:
      'A powerful platform designed to elevate customer engagement and retention strategies through advanced analytics, personalized rewards, and seamless integration with existing business systems. '
  },
  {
    label: 'Promotion Engine',
    domain: 'promotion',
    description:
      "Unlock the power of targeted campaigns, intelligent segmentation, and real-time analytics to drive conversions, maximize ROI, and propel your brand's promotional strategies to new heights of success. Experience the art and science of promotional excellence in a single, user-friendly platform designed to fuel your marketing efforts and unleash your brand's full potential."
  }
];
