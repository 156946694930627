import { Form, TableColumnsType } from 'antd';
import { useState } from 'react';
import { formatDate } from '../../../helpers/date.helpers';
import _ from '../../../helpers/lodash';
import {
  displayErrorNotifications,
  displayInfoMessage,
  displaySuccessNotification
} from '../../../helpers/toast.helpers';

interface IOrderPickingLine {
  delivery_method? : string
  order_number: string;
  created_date: string;
  fo_number: string;
  ro_number: string;
  node_id: string;
  sku_id: string;
  sku_description: string;
  quantity: any;
  fulfillable_quantity: any;
  editable_pick_quantity: number;
  editable_backorder_quantity: number;
  status: string;
  total_amount: string;
  tag_id: string;
  id: string;
  key: string;
  existing_picked_quantity: any;
  existing_backorder_quantity: any;
  co_line_id: string;
  serial_tag: string;
}

export const useOrderPicking = () => {
  const [orderPickingData, setOrderPickingData] = useState({
    picking_lines: [],
    ro_number: '',
    tenant_id: '',
    business_group_id: ''
  } as {
    picking_lines: IOrderPickingLine[];
    ro_number: string;
    tenant_id: string;
    business_group_id: string;
  });

  const [orderPickingModalVisible, setOrderPickingModalVisible] = useState(false);

  const [pickingQuantityChangeModalVisible, setPickingQuantityChangeModalVisible] = useState(false);

  const [currentEditingId, setCurrentEditingId] = useState<string | null>(null);

  const transformAndSetOrderPickingData = (record: any) => {
    const orderPickingLines: IOrderPickingLine[] = [];
    // console.log({ record });
    let delivery_method = record?.fulfilment_info?.delivery_method
    record?.fo_details.forEach((fo: any) => {
      fo.item_list.forEach((item: any) => {
        item.tags.forEach((tagLevelItem: any) => {
          const existingBackOrderQuantity = tagLevelItem?.statuses.find(
            (statusLevelDetail: any) => statusLevelDetail.status_description === 'BACKORDER_FROM_NODE'
          )?.quantity;

          const existingPickedQuantity = tagLevelItem?.statuses.find(
            (statusLevelDetail: any) => statusLevelDetail.status_description === 'PICK'
          )?.quantity;

          orderPickingLines.push({
            delivery_method: delivery_method,
            order_number: fo.order_number,
            created_date: fo.order_start_trans_datetime,
            fo_number: fo.fo_number,
            ro_number: record.ro_number,
            node_id: record?.delivery_info?.ship_node_name,
            sku_id: item.sku_id,
            sku_description: item.product_desc,
            quantity: tagLevelItem.quantity,
            fulfillable_quantity: tagLevelItem?.fufillable_quantity,
            editable_pick_quantity: 0,
            editable_backorder_quantity: 0,
            status: item.status_description,
            total_amount: item.total_amount,
            tag_id: tagLevelItem.tag_id,
            id: `${item.co_line_id}-${tagLevelItem.tag_id}`,
            key: `${item.co_line_id}-${tagLevelItem.tag_id}`,
            existing_backorder_quantity: existingBackOrderQuantity?.number_of_units || 0,
            existing_picked_quantity: existingPickedQuantity?.number_of_units || 0,
            co_line_id: item.co_line_id,
            serial_tag: item.serial_tag
          });
        });
      });
    });

    setOrderPickingData({
      picking_lines: orderPickingLines,
      ro_number: record.ro_number,
      business_group_id: record.business_group_id,
      tenant_id: record.tenant_id
    });

    //! Need Tag level Details //
  };

  const resetLocalStates = () => {
    setOrderPickingModalVisible(false);
    setPickingQuantityChangeModalVisible(false);
    setCurrentEditingId(null);
    setOrderPickingData({ picking_lines: [], ro_number: '', tenant_id: '', business_group_id: '' });
  };

  const changeOrderPickingModalVisibility = (visiblity: boolean) => {
    setOrderPickingModalVisible(visiblity);
    if (!visiblity) resetLocalStates();
  };

  const validateOrderPickingData = () => {};

  const validateOrderAndSetEditedPickedLine = () => {
    console.log('Called');
    const editedLineId = currentEditingId;
    const { picked_quantity: editedQuantityValue, backorder_quantity: editedBackOrderQuantity } =
      pickQuantityFormInstance.getFieldsValue();
    const lineDetails = orderPickingData?.picking_lines?.find(line => line.id === editedLineId);
    if (_.isEmpty(lineDetails)) return;
    //!Validations

    const fulfillableQuantity = lineDetails.quantity?.number_of_units || 0;
    const alreadyPickedQuantity = lineDetails.existing_picked_quantity;
    const alreadyBackorderedQuantity = lineDetails.existing_backorder_quantity;

    const quantityRemainingToBePicked = fulfillableQuantity - alreadyPickedQuantity - alreadyBackorderedQuantity;

    if (editedQuantityValue + editedBackOrderQuantity > quantityRemainingToBePicked) {
      displayErrorNotifications([
        { message: 'Pick quantity / Backorder quantity cannot be more than fulfillable quantity' }
      ]);

      return;
    }
    if (editedQuantityValue + editedBackOrderQuantity < quantityRemainingToBePicked) {
      displayInfoMessage({
        message:
          'Pick quantity + Backorder quantity sum is less than fulfillable quantity, A new RO will be created with the remaining quantities'
      });
    }

    setOrderPickingData(orderPickingData => {
      const updatedLines = orderPickingData?.picking_lines?.map(line => {
        if (line.id === editedLineId) {
          return {
            ...line,
            editable_pick_quantity: editedQuantityValue || 0,
            editable_backorder_quantity: editedBackOrderQuantity || 0
          };
        } else return line;
      });

      return {
        ...orderPickingData,
        picking_lines: updatedLines
      };
    });

    displaySuccessNotification({ message: 'Pick quantity set successfully!' });

    closePickEditModal();
  };

  const handleClickPickEditQuantity = (id: string) => {
    const currentPickingSkuDetails = orderPickingData.picking_lines?.find(line => line.id === id);
    pickQuantityFormInstance.setFieldsValue({
      picked_quantity: currentPickingSkuDetails?.editable_pick_quantity || 0,
      backorder_quantity: currentPickingSkuDetails?.editable_backorder_quantity || 0
    });
    setCurrentEditingId(id);
    setPickingQuantityChangeModalVisible(true);
  };

  const closePickEditModal = () => {
    pickQuantityFormInstance.resetFields();
    setCurrentEditingId(null);
    setPickingQuantityChangeModalVisible(false);
  };

  const currentPickingSkuDetails = orderPickingData.picking_lines?.find(line => line.id === currentEditingId);

  const [pickQuantityFormInstance] = Form.useForm();

  const getfulFilmentQty = (tags:any) => {
    let tagData = tags?.map((item: any) => {
      return {
        tag_id: item.tag_id,
        tag_type: item.tag_type,
        quantity: item.quantity,
        mark_remaining_as_backorder: false,
        back_order_quantity: item.back_order_quantity
      }
    })

    return tagData
  }

  const getFoLines = (foItem: any) => {
    let foData = foItem.map((item: any) => {
      return {
        co_line_id: item.co_line_id,
        sku_id: item.item.sku_id,
        fulfilled_quantity : getfulFilmentQty(item.item?.fulfilled_quantity)
      }
    })
    return foData
  }

  const getNewLines = (item: any) => {
    const line = item.map((data: any) => {
      return {
        order_number: data?.order_number,
        fo_number: data?.fo_number,
        fo_lines: getFoLines(data.fo_lines)
      }
    })
    return line
  }

  const createPickRequest = () => {
    //! each line {fo, foLines : [fulfilledQty : [tagLevel]]}
    const foGroupedData = _.groupBy(orderPickingData.picking_lines, 'fo_number');

    const foDetails = Object.keys(foGroupedData).map(foNumber => {
      const foLinesTagLevel = foGroupedData[foNumber];
      //!get header level details
      const { fo_number, order_number } = foLinesTagLevel[0];

      const itemsGroupedBySku = _.groupBy(foLinesTagLevel, 'sku_id');

      const fo_lines = Object.keys(itemsGroupedBySku).map(sku => {
        const tagLevelDetails = itemsGroupedBySku[sku];
        const { co_line_id, sku_id } = tagLevelDetails[0];

        const fulfilled_quantity = tagLevelDetails.map(itemTagDetails => {
          return {
            quantity: {
              ...itemTagDetails.quantity,
              number_of_units: itemTagDetails.editable_pick_quantity
            },
            tag_id: itemTagDetails.tag_id,
            serial_id: itemTagDetails.serial_tag,
            mark_remaining_as_backorder: false,
            back_order_quantity: {
              ...itemTagDetails.quantity,
              number_of_units: itemTagDetails.editable_backorder_quantity
            }
          };
        });

        return { co_line_id, item: { sku_id, fulfilled_quantity } };
      });

      return {
        fo_number,
        order_number,
        fo_lines
      };
    });
    const request = {
      business_group_id: orderPickingData.business_group_id,
      tenant_id: orderPickingData.tenant_id,
      ro_number: orderPickingData.ro_number,
      lines: foDetails,
      created_at: new Date().toISOString(),
      status: 'PICKED'
    };

    const newRequest = {
      business_group_id: orderPickingData.business_group_id,
      tenant_id: orderPickingData.tenant_id,
      ro_number: orderPickingData.ro_number,
      lines: getNewLines(foDetails),
      created_at: new Date().toISOString(),
      created_by: 'ui',
      status: 'PICKED',
      status_datetime: new Date().toISOString()
    }
    console.log({ newRequest });

    return newRequest;
  };

  return {
    orderPickingData,
    setOrderPickingData,
    orderPickingModalVisible,
    changeOrderPickingModalVisibility,
    validateOrderPickingData,
    transformAndSetOrderPickingData,
    validateOrderAndSetEditedPickedLine,
    pickingQuantityChangeModalVisible,
    currentPickEditingId: currentEditingId,
    setCurrentPickEditingId: setCurrentEditingId,
    handleClickPickEditQuantity,
    setPickingQuantityChangeModalVisible,
    closePickEditModal,
    currentPickingSkuDetails,
    pickQuantityFormInstance,
    createPickRequest
  };
};
