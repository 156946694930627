import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { Card, Col, Form, Row, Typography } from 'antd';
import { IProducts } from '../../types/product';
import TenantInfo from '../../../../components/TenantIdInfo';
import ProductForm from '../../components/ProductForm';
import { loyaltyService } from '../../services/loyalty.service';
import { useLoader } from '../../../../stores/use-loader';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import _ from '../../../../helpers/lodash';

const EditProduct = () => {
  const { id } = useParams();
  const [editForm] = Form.useForm();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const [buffer, setBuffer] = React.useState(false);

  React.useEffect(() => {
    loadInitialCustomeDetails();
  }, []);

  const handleEditCustomer = async () => {
    setLoading(true);
    const {
      article_id,
      brand,
      branded,
      category,
      country_of_origin,
      department,
      description,
      item_id,
      lyt_earn,
      //   lyt_redeem,
      model,
      product_identifier_type,
      product_name,
      product_type,
      sold_Online,
      sold_Offline,
      supply_type_code,
      vendor_code
    } = editForm.getFieldsValue();
    const bodyData = {
      product_identifier_type: product_identifier_type,
      item_id: item_id,
      article_id: article_id,
      vendor_code: vendor_code,
      supply_type_code: supply_type_code,
      product_name: product_name,
      product_type: product_type,
      department: department,
      category,
      description: description,
      model: model,
      brand: brand,
      country_of_origin: country_of_origin,
      lty_earn: lyt_earn,
      //   lyt_redeem: lyt_redeem,
      is_active: true,
      is_branded: branded === 'TRUE' ? true : false,
      sold_online: sold_Online,
      sold_offline: sold_Offline
    };

    const { errors } = await loyaltyService.putProduct(bodyData);
    if (!_.isEmpty(errors)) displayErrorNotifications(errors);
    else {
      displaySuccessNotification({ message: 'Product updated successfully!' });
    }
    setLoading(false);
  };

  const loadInitialCustomeDetails = async () => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getProductDetails(id as string);
    if (_.isEmpty(errors)) {
      populateEditFormWithData(data);
    }
    setBuffer(true);
    setLoading(false);
  };

  const populateEditFormWithData = (data: IProducts) => {
    editForm.setFieldsValue({
      product_identifier_type: data.product_identifier_type,
      item_id: data.item_id,
      article_id: data.article_id,
      vendor_code: data.vendor_code,
      supply_type_code: data.supply_type_code,
      product_name: data.product_name,
      product_type: data.product_type,
      department: data.department,
      category: data.category,
      description: data.description,
      model: data.model,
      brand: data.brand,
      country_of_origin: data.country_of_origin,
      lyt_earn: data.lty_earn,
      //   lyt_redeem: data.lyt_redeem,
      branded: data.is_branded ? 'TRUE' : 'FALSE',
      sold_Online: data.sold_online,
      sold_Offline: data.sold_offline,
      active_status: data.is_active ? 'TRUE' : 'FALSE'
    });
  };

  const handleGoBack = () => {
    const backUri = searchParams.get('goBackTo');
    if (backUri) {
      navigate(`${backUri}`);
    } else {
      navigate(`/loyalty/products`);
    }
  };

  return (
    <PrimaryLayout>
      {buffer && (
        <div className="container mx-auto px-4">
          <Card>
            <Row justify={'space-between'} className="mb-4">
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  Product
                </Typography.Title>
              </Col>
            </Row>
            <TenantInfo />
            <section className="mt-4">
              <ProductForm
                isUpdate={true}
                formInstance={editForm}
                handleSave={handleEditCustomer}
                handleGoBack={handleGoBack}
                resetData={loadInitialCustomeDetails}
              />
            </section>
          </Card>
        </div>
      )}
    </PrimaryLayout>
  );
};

export default EditProduct;
