import { Button, Card, Col, DatePicker, Form, Row, Select, Space, Table, TableColumnsType, Typography, Spin, Input } from 'antd';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CustomPagination from '../../components/custom-pagination';
import { displayErrorNotifications } from '../../helpers/toast.helpers';
import _ from '../../helpers/lodash';
import PrimaryLayout from '../../layouts/primary-layout';
import { inventoryService } from '../../services';
import { InventoryHistoryItem, InventoryHistoryResponse } from '../../types/inventory.types';
import dayjs from 'dayjs';
import { formatDateTime, resetISOTimeStampTimeToZeroIST } from '../../helpers/date.helpers';
import { useNodes } from '../../hooks/useNodes';
import { useNodeDropdownSearch } from '../../modules/nebula/hooks/drop-down-hooks/use-node-dropdown-search';

interface IInventoryHistoryProps { }
const productIdKey: string = 'productId';

const InventoryHistoryPage: React.FunctionComponent<IInventoryHistoryProps> = props => {
  const navigate = useNavigate();
  const params = useParams();
  const { nodes } = useNodes();


  const [inventoryHistoryData, setInventoryHistoryData] = React.useState<InventoryHistoryResponse>(
    {} as InventoryHistoryResponse
  );
  const [loading, setLoading] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id')

  React.useEffect(() => {
    fetchInitialData();
  }, [searchParams]);

  const { debouncedFetchNodeDropdownOptions, nodeDropdownFetching, nodeDropdownOptions } = useNodeDropdownSearch();

  const fetchInitialData = () => {
    const articleId = params[productIdKey] as string;

    const queryParams: Record<string, any> = getCurrentPageQueryParams();

    if (queryParams.start_datetime && queryParams.end_datetime) {
      filterForm.setFieldValue('date_range', [dayjs(queryParams.start_datetime), dayjs(queryParams.end_datetime)]);
    }

    if (queryParams.node_name) {
      filterForm.setFieldValue('store_id', queryParams.node_name);
    }
    fetchInventoryHistoryDetail(articleId, 1, queryParams);
  };

  const getCurrentPageQueryParams = () => {
    const start_datetime = searchParams.get('start_datetime');
    const end_datetime = searchParams.get('end_datetime');
    const node_name = searchParams.get('node_name');
    const id_type = 'SKU_ID'
    const id = searchParams.get('id')
    const queryParams: Record<string, any> = { node_name, id_type, id, start_datetime, end_datetime };
    return queryParams;
  };

  const fetchInventoryHistoryDetail = async (
    articleId: string,
    pageNumber: number = 1,
    params: Record<string, any>
  ) => {
    setLoading(true);
    const pageSize = 5;
    const { data, errors } = await inventoryService.getInventoryHistory(articleId, pageNumber, pageSize, params);

    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      setInventoryHistoryData(data);
    }
    setLoading(false);
  };

  const columns: TableColumnsType<InventoryHistoryItem> = [
    {
      title: 'Event Datetime',
      width: '300px',
      align: 'center',
      render: (_, record) => {
        return <>{formatDateTime(record.event_datetime)}</>;
      }
    },
    {
      title: 'Node',
      width: '300px',
      align: 'center',
      render: (_, record) => {
        return <>{record.node_name}</>;
      }
    },
    { title: 'Adjustment Type', width: '300px', align: 'center', render: (_, record) => <>{record.type}</> },
    {
      title: 'Unit of measure',
      width: '300px',
      align: 'center',
      render: (_, record) => <>{record?.quantity?.unit_of_measure || 0}</>
    },
    {
      title: 'Quantity',
      width: '300px',
      align: 'center',
      render: (_, record) => <>{record.quantity.number_of_units || 0} </>
    }
  ];

  const handleNext = async (currentPage: number) => {
    const articleId = params[productIdKey] as string;

    const queryParams: Record<string, any> = getCurrentPageQueryParams();
    fetchInventoryHistoryDetail(articleId, currentPage, queryParams);
  };
  const handlePrevious = async (currentPage: number) => {
    const articleId = params[productIdKey] as string;

    const queryParams: Record<string, any> = getCurrentPageQueryParams();
    fetchInventoryHistoryDetail(articleId, currentPage, queryParams);
  };
  const handlePageChange = async (currentPage: number) => {
    const articleId = params[productIdKey] as string;

    const queryParams: Record<string, any> = getCurrentPageQueryParams();
    fetchInventoryHistoryDetail(articleId, currentPage, queryParams);
  };
  const [filterForm] = Form.useForm();

  const handleApplyFilter = (filters: any) => {
    const { store_id, date_range } = filters;
    let fromDateISOString, fromDateReset, toDateISOString;
    if (!_.isEmpty(date_range)) {
      fromDateISOString = dayjs(date_range[0]).toISOString();
      fromDateReset = resetISOTimeStampTimeToZeroIST(fromDateISOString);
      toDateISOString = dayjs(date_range[1]).toISOString();
    }
    const params: Record<string, any> = {
      start_datetime: fromDateReset,
      end_datetime: toDateISOString,
      node_name: store_id
    };
    let filteredQueryParams: Record<string, any> = {};

    Object.keys(params).forEach((key: string) => {
      if (params[key]) {
        filteredQueryParams[key] = params[key];
      }
    });
    const idSku = searchParams.get('id')
    filteredQueryParams = {
      ...filteredQueryParams,
      id: idSku
    }
    setSearchParams(currentParams => {
      const from_query = currentParams.get('from_query') as string;
      return { ...filteredQueryParams, from_query };
    });
  };

  const handleClickBack = () => {
    const params = searchParams.get('from_query');
    const decodedParams = JSON.parse(decodeURIComponent(params as string));
    const pageParams = new URLSearchParams(decodedParams).toString();
    navigate(`/inventory?${pageParams}`);
  };

  return (
    <PrimaryLayout>
      {' '}
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mt-4 mb-1">
            <Col>
              <Typography.Title level={2} className="mb-0 text-[#2e2a5b]">
                Historic View
              </Typography.Title>
            </Col>
            {/* <Col>
            <Space>
              <Button size="large" type="primary">
                Import
              </Button>
              <Button size="large" type="primary">
                Download
              </Button>
            </Space>
          </Col> */}
          </Row>

          <div className="mb-4">
            <Typography.Text className="text-[#424250] text-xl">SKU : {id}</Typography.Text>
          </div>
          <div className="mt-8">
            <Form layout="vertical" form={filterForm} onFinish={handleApplyFilter}>
              <Row gutter={24}>
                <Col xs={24} md={8} lg={6}>
                  <Form.Item label={<>Select Node</>} name="store_id">
                    {/* <Select
                      allowClear
                      size="large"
                      filterOption={false}
                      showSearch
                      placeholder="Search & Select Node"
                      notFoundContent={nodeDropdownFetching ? <Spin size="small" /> : null}
                      onSearch={async searchTerm => {
                        if (searchTerm) await debouncedFetchNodeDropdownOptions(searchTerm);
                      }}
                      options={[...nodeDropdownOptions]}
                    ></Select> */}
                    {/* <Select
                      allowClear
                      placeholder="Select Node"
                      size="large"
                      options={nodes.map(node => ({ label: node.name, value: node.id }))}
                    ></Select> */}
                    <Input size="large" placeholder="Node" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} lg={6}>
                  <Form.Item label={<>Date Range</>} name="date_range">
                    <DatePicker.RangePicker className="w-full" size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={4} className="flex items-center">
                  <Button block type="primary" htmlType="submit" size="large">
                    Apply
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
          <Row justify={'start'}>
            <Col xs={24} lg={24} xl={24}>
              <section>
                {!_.isEmpty(inventoryHistoryData.items) && (
                  <div className="flex justify-end">
                    <CustomPagination
                      currentPage={inventoryHistoryData.page_info.current_page}
                      totalPages={inventoryHistoryData.page_info.total_pages}
                      handleNext={handleNext}
                      handlePageChange={handlePageChange}
                      handlePrevious={handlePrevious}
                    />
                  </div>
                )}

                <Table
                  className="mt-4"
                  pagination={false}
                  loading={loading}
                  // bordered
                  dataSource={inventoryHistoryData.items}
                  columns={columns}
                  scroll={{ x: 1000 }}
                ></Table>
              </section>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className="mt-4" type='primary' onClick={() => handleClickBack()}>
                Back
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default InventoryHistoryPage;
