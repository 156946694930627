import { Button, Card, Col, Form, Input, Row, Select, Table, TableColumnsType, Typography } from 'antd';
import PrimaryLayout from '../../../layouts/primary-layout';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TenantInfo from '../../../components/TenantIdInfo';
import FieldDisplay from '../../../components/FieldDisplay';
import React from 'react';
import { convertQueryStringToObj, objectHelpers } from '../../../helpers';
import { useLoader } from '../../../stores/use-loader';
import CustomPagination from '../../../components/custom-pagination';
import _ from 'lodash';
import { promotionReportService } from '../services/promotionreport.service';

interface IPromotionReportDetails { }

const PromotionReportDetails: React.FunctionComponent<IPromotionReportDetails> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [PromotionReportDetails] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [reportDetails, setReportDetails] = React.useState({} as any);
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
  const { customer_Id, orderId, backUri } = queryStringObj;
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const columns: TableColumnsType<any> = [
    {
      title: 'Order ID',
      render(value, record, index) {
        return <Typography.Text>{record.order_id}</Typography.Text>;
      },
      align: 'center'
    },
    {
      title: 'Redeemed Date',
      render(value, record, index) {
        return <Typography.Text>{record.redeemed_date}</Typography.Text>;
      },
      align: 'center'
    },
    {
      title: 'Redeemed Value',
      render(value, record, index) {
        return <Typography.Text>{record.redeemed_value}</Typography.Text>;
      },
      align: 'center'
    },
    {
      title: 'Status',
      render(value, record, index) {
        return <Typography.Text>{record.status}</Typography.Text>;
      },
      align: 'center'
    }
  ];

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    await handleSearch(offset);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = PromotionReportDetails.getFieldsValue();
    let params = { offset, limit: 10, ...formValues };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    // setSearchParams(filteredParams);
    const { data, errors } = await promotionReportService.getReportDetails(filteredParams, id as any);
    if (_.isEmpty(errors)) {
      setReportDetails(data);
    } else {
      setReportDetails(errors);
    }
    setLoading(false);
  };

  const handleOnBack = () => {
    navigate(-1);
  };

  const handlePageChange = (current_page: number) => {
    setCurrentPage(current_page);
    handleSearch(current_page - 1)
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Form layout="vertical" form={PromotionReportDetails} onFinish={() => handleSearch(0)}>
            <Row justify={'space-between'} className="mb-4">
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  Redemption History Detail
                </Typography.Title>
              </Col>
            </Row>
            <TenantInfo />
            <section className="flex gap-8 my-4 flex-wrap">
              <FieldDisplay label={'Customer'} value={id} />
              <FieldDisplay label={'Reward Name'} value={'reward_name'} />
            </section>
            <section className="mt-4">
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item name="order-id" label="Order Id">
                    <Input placeholder="Order Id" size="large" />
                  </Form.Item>
                </Col>
                {/* <Col xs={12} md={6}>
                  <Form.Item name="date_range" label="Date">
                    <DatePicker.RangePicker size="large" />
                  </Form.Item>
                </Col> */}
                <Col xs={12} md={6}>
                  <Form.Item name="status" label="Status">
                    <Select
                      placeholder="Status"
                      size="large"
                      allowClear
                      options={[
                        { label: 'Reserved', value: 'RESERVED' },
                        { label: 'Active', value: 'ACTIVE' },
                        { label: 'In-Active', value: 'INACTIVE' },
                        { label: 'Redeemed', value: 'REDEEMED' },
                        { label: 'Expired', value: 'EXPIRED' }
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Button htmlType="submit" type="primary" size="large">
                    <BoldButtonLabel labelText="Search" />
                  </Button>
                </Col>
              </Row>
            </section>
            <section className="mt-8">
              {!_.isEmpty(reportDetails.order_details) && (
                <div className="flex justify-end my-2">
                  <CustomPagination
                    currentPage={reportDetails.page_info.current_page}
                    totalPages={reportDetails.page_info.total_pages}
                    handleNext={handlePageChange}
                    handlePageChange={handlePageChange}
                    handlePrevious={handlePageChange}
                  />
                </div>
              )}{' '}
              <Table
                pagination={false}
                bordered
                dataSource={reportDetails.order_details || []} //reportDetails
                columns={columns}
                scroll={{ x: 1000 }}
              ></Table>
            </section>{' '}
            <div className="mt-4">
              <Button size="large" type="primary" onClick={handleOnBack}>
                <BoldButtonLabel labelText="Go back" />
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default PromotionReportDetails;
