import { Button, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select, Typography } from 'antd';
import * as React from 'react';
import { countryList, phoneCountryCodeList } from '../../../../data/intl-data';
import { stateList } from '../../../../data';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { validationHelpers } from '../../../../helpers';

interface ICustomerFormProps {
  initialFormValues?: any;
  isUpdate?: boolean;
  formInstance: FormInstance<any>;
  handleFinish?: () => void;
  handleGoBack?: () => void;
}

const CustomerForm: React.FunctionComponent<ICustomerFormProps> = ({
  initialFormValues,
  formInstance,
  handleFinish,
  isUpdate,
  handleGoBack
}) => {
  return (
    <Form form={formInstance} onFinish={handleFinish} layout="vertical">
      <div
        style={{
          height: 'fit-content',
          background: '#9b9b9b29',
          padding: '20px',
          marginBottom: '1rem',
          borderRadius: '10px'
        }}
      >
        <Typography.Title level={4} className="text-[#2e2a5b] m-0">
          Basic Details
        </Typography.Title>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              name="customer_id"
              label="Customer Id"
              rules={[
                {
                  required: true,
                  message: 'Please enter Customer ID'
                }
              ]}
            >
              <Input disabled={isUpdate} size="large" placeholder="Customer ID" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your first name'
                }
              ]}
            >
              <Input size="large" placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your last name'
                }
              ]}
            >
              <Input size="large" placeholder="Last Name" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: 'Please enter your email'
                },
                {
                  type: 'email',
                  message: 'Invalid email format'
                }
              ]}
            >
              <Input size="large" placeholder="Email" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Phone Number"
              name={['phone_number']}
              rules={[{ required: true, message: 'Phone number is required' }]}
            >
              <Input
                addonBefore={
                  <Form.Item
                    initialValue={phoneCountryCodeList[0]?.value}
                    noStyle
                    name={['dial_code']}
                    rules={[{ required: true, message: 'Country code is requiered' }]}
                  >
                    <Select style={{ width: 120 }} options={phoneCountryCodeList} size="large"></Select>
                  </Form.Item>
                }
                min={0}
                placeholder="Enter Phone Number"
                size="large"
              ></Input>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="date_of_birth" label="Date of Birth">
              <DatePicker size="large" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="sign_up_date" label="Sign-up Date">
              <DatePicker size="large" className="w-full" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="customer_type"
              label="Customer Type"
              rules={[{ required: true, message: 'Customer type is required' }]}
            >
              <Select size="large" placeholder="Select Customer Type">
                <Select.Option value="CUS">Customer</Select.Option>
                <Select.Option value="EMP">Employee</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="refered_by" label="Referred By">
              <Input size="large" placeholder="Enter Referred by" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="supply_type_code" label="Supply Type">
              <Select size="large" placeholder="Select supply type">
                <Select.Option value="B2C">B2C</Select.Option>
                <Select.Option value="B2B">B2B</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              name="channel"
              label="Channel"
              rules={[
                {
                  required: true,
                  message: 'Please enter channel'
                }
              ]}
            >
              <Input size="large" placeholder="Enter Channel" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="country" label="Country">
              <Select options={countryList} placeholder="Select Country" allowClear size="large" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) => {
                return prev.country !== curr.country;
              }}
            >
              {form => {
                const { country } = form.getFieldsValue();

                const stateOptions = stateList[country] || [];

                return (
                  <Form.Item name="state" label="State">
                    <Select size="large" placeholder="Select State" options={stateOptions} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="location" label="Location">
              <Input size="large" placeholder="Enter location" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="postal_code" label="Postal Code">
              <Input size="large" placeholder="Enter Postal Code" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item name="member_type" label="Member Type">
              <Input size="large" placeholder="Enter Member type" />
            </Form.Item>
          </Col>
        </Row>{' '}
      </div>
      <div
        style={{
          height: 'fit-content',
          background: '#9b9b9b29',
          padding: '20px',
          marginBottom: '1rem',
          borderRadius: '10px'
        }}
      >
        <Typography.Title level={4} className="text-[#2e2a5b] m-0">
          Alternate Customer Identitity
        </Typography.Title>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item name="channel1" label="Channel 1">
              <Input size="large" placeholder="Channel 1" />
            </Form.Item>
          </Col>{' '}
          <Col xs={12} md={6}>
            <Form.Item name="alternate_id1" label="Alternate ID 1">
              <Input size="large" placeholder="..." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item name="channel2" label="Channel 2">
              <Input size="large" placeholder="Channel 2" />
            </Form.Item>
          </Col>{' '}
          <Col xs={12} md={6}>
            <Form.Item name="alternate_id2" label="Alternate ID 2">
              <Input size="large" placeholder="..." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item name="channel3" label="Channel 3">
              <Input size="large" placeholder="Channel 3" />
            </Form.Item>
          </Col>{' '}
          <Col xs={12} md={6}>
            <Form.Item name="alternate_id3" label="Alternate ID 3">
              <Input size="large" placeholder="..." />
            </Form.Item>
          </Col>
        </Row>
      </div>
      {!isUpdate && <div
        style={{
          height: 'fit-content',
          background: '#9b9b9b29',
          padding: '20px',
          marginBottom: '1rem',
          borderRadius: '10px'
        }}
      >
        <Typography.Title level={4} className="text-[#2e2a5b] m-0">
          Loyality Reward
        </Typography.Title>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              name="reward_name"
              label="Reward Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter Reward Name'
                }
              ]}
            >
              <Select size="large" placeholder="Select Reward Name">
                <Select.Option value="ON_BOARDING">onboarding</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Reward Points"
              name="reward_points"
              rules={[
                { required: true, message: "This field can't be empty" },
                validationHelpers.getNumericValidatorForInput({})
              ]}
            >
              <InputNumber min={0} className="w-full" size="large" placeholder="Reward Points"/>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Active in days"
              name="active_in_days"
              rules={[
                { required: true, message: "This field can't be empty" },
                validationHelpers.getNumericValidatorForInput({})
              ]}
            >
              <InputNumber min={0} className="w-full" size="large" placeholder="Active in days"/>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label="Expire in days"
              name="expire_in_days"
              rules={[
                { required: true, message: "This field can't be empty" },
                validationHelpers.getNumericValidatorForInput({})
              ]}
            >
              <InputNumber min={0} className="w-full" size="large" placeholder="Expire in days"/>
            </Form.Item>
          </Col>
        </Row>
      </div>}
      <Row gutter={[12, 12]}>
        <Col xs={24} md={6}>
          <Button size="large" block onClick={handleGoBack}>
            <BoldButtonLabel labelText="Go Back" />
          </Button>
        </Col>
        {typeof handleFinish === 'function' && (
          <Col xs={24} md={6}>
            <Button size="large" block type="primary" htmlType="submit">
              <BoldButtonLabel labelText={isUpdate ? 'Update' : 'Create'} />
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default CustomerForm;
