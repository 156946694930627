import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { Button, Card, Col, Modal, Row, Table, TableColumnsType, Typography } from 'antd';
import TenantInfo from '../../../../components/TenantIdInfo';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLoader } from '../../../../stores/use-loader';
import { convertQueryStringToObj } from '../../../../helpers';
import FieldDisplay from '../../../../components/FieldDisplay';
import { IRewardDetailItem, IRewardDetailResponse, IRewardIdDetail } from '../../types/reward-history';
import CustomPagination from '../../../../components/custom-pagination';
import _ from '../../../../helpers/lodash';
import { loyaltyService } from '../../services/loyalty.service';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import { formatDateTime } from '../../../../helpers/date.helpers';
import ActionButton from '../../../../components/ActionButton';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';

interface IRewardHistoryDetailProps {}

const RewardHistoryDetail: React.FunctionComponent<IRewardHistoryDetailProps> = props => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const [rewardDetail, setRewardDetail] = React.useState({} as IRewardDetailResponse);
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const { customer_id, reward_name, trigger_type, applicability_type, status, channel, site, date_from, date_to } =
    queryStringObj;

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async (offset = 0) => {
    setLoading(true);
    const params = {
      'reward-name': reward_name,
      trigger_type,
      applicability_type,
      status,
      channel,
      site,
      date_from,
      date_to
    };
    const { data, errors } = await loyaltyService.getRewardHistoryDetails(customer_id, params);

    if (_.isEmpty(errors)) {
      setRewardDetail(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const columns: TableColumnsType<IRewardDetailItem> = [
    {
      title: 'Reward Value',
      render(value, record, index) {
        return <>{record.reward_value}</>;
      },
      align: 'center'
    },
    {
      title: 'Redeemable Value',
      render(value, record, index) {
        return <>{record.redeemable_value}</>;
      },
      align: 'center'
    },

    {
      title: 'Channel',
      render(value, record, index) {
        return <>{record.channel}</>;
      },
      align: 'center'
    },

    {
      title: 'Site',
      render(value, record, index) {
        return <>{record.site_id}</>;
      },
      align: 'center'
    },

    {
      title: 'Status',
      render(value, record, index) {
        return <>{record.status}</>;
      },
      align: 'center'
    },

    {
      title: 'Reward Date',
      render(value, record, index) {
        return <>{formatDateTime(record.created_date_time)}</>;
      },
      align: 'center'
    },

    {
      title: 'Loyalty Type',
      render(value, record, index) {
        return <>{record.loyality_type}</>;
      },
      align: 'center'
    },
    {
      title: 'Applicability Type',
      render(value, record, index) {
        return <>{record.applicability_type}</>;
      },
      align: 'center'
    },

    {
      title: 'Transaction Type',
      render(value, record, index) {
        return <>{record.transaction_type}</>;
      },
      align: 'center'
    },

    {
      title: <></>,
      render(value, record, index) {
        return (
          <ActionButton
            action="VIEW"
            onClick={() => {
              handleClickRewardDetail(record.id);
            }}
            title="Reward Detail"
          />
        );
      },
      align: 'center'
    }
  ];

  const handleClickRewardDetail = async (rewardId: string) => {
    setLoading(true);

    if (!rewardIdDetailMap[rewardId]) {
      const { data, errors } = await loyaltyService.getRewardIdDetails(rewardId);
      setRewardIdMap(rewardIdMap => ({ ...rewardIdMap, [rewardId]: data }));
      if (!_.isEmpty(errors)) {
        displayErrorNotifications(errors);
        setLoading(false);
        return;
      }
    }

    setSelectedId(rewardId);
    setLoading(false);
  };
  const handlePageChange = (currentPage: number) => {
    loadInitialData(currentPage - 1);
  };

  const [selectedId, setSelectedId] = React.useState<string>('');
  const [rewardIdDetailMap, setRewardIdMap] = React.useState<Record<string, IRewardIdDetail>>({});

  const isModalVisible = !_.isEmpty(rewardIdDetailMap[selectedId]);
  const rewardIdDetail = rewardIdDetailMap[selectedId];
  const navigate = useNavigate();
  const handleBack = () => {
    if (searchParams.get('backUri')) {
      navigate(searchParams.get('backUri') as string);
    } else {
      navigate('/loyalty/reports');
    }
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Customer Reward History Detail
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <section className="flex gap-8 my-4 flex-wrap">
            <FieldDisplay label={'Customer'} value={customer_id} />
            <FieldDisplay label={'Reward Name'} value={reward_name} />
            <FieldDisplay label={'Loyalty Type'} value={trigger_type} />
            <FieldDisplay label={'Applicability Type'} value={applicability_type} />
            <FieldDisplay label={'Status'} value={status} />
            <FieldDisplay label={'Channel'} value={channel} />
            <FieldDisplay label={'Site'} value={site} />
            <FieldDisplay label={'Date from'} value={date_from} />
            <FieldDisplay label={'Date to'} value={date_to} />
          </section>
          <section className="mt-8">
            {!_.isEmpty(rewardDetail.data) && (
              <div className="flex justify-end my-2">
                <CustomPagination
                  currentPage={rewardDetail.page_info.current_page}
                  totalPages={rewardDetail.page_info.total_pages}
                  handleNext={handlePageChange}
                  handlePageChange={handlePageChange}
                  handlePrevious={handlePageChange}
                />
              </div>
            )}{' '}
            <Table
              pagination={false}
              bordered
              dataSource={rewardDetail.data}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>{' '}
          <Button size="large" className="mt-4" type="primary" onClick={handleBack}>
            <BoldButtonLabel labelText="Go Back" />
          </Button>
        </Card>
      </div>
      <Modal
        open={isModalVisible}
        footer={null}
        width={700}
        centered
        onCancel={() => {
          setSelectedId('');
        }}
      >
        <Typography.Title level={4}>Reward Details</Typography.Title>
        <section className="flex flex-col flex-wrap gap-4">
          <FieldDisplay label={'Customer ID'} value={rewardIdDetail?.customer_id} />
          <FieldDisplay label={'Loyalty Type'} value={rewardIdDetail?.loyality_type} />
          <FieldDisplay label={'Applicability Type'} value={rewardIdDetail?.applicability_type} />
          <FieldDisplay label={'Reward Name'} value={rewardIdDetail?.reward_name} />
          <FieldDisplay label={'Reward type'} value={rewardIdDetail?.reward_type} />
          <FieldDisplay label={'Reward Value'} value={rewardIdDetail?.reward_value} />
          <FieldDisplay label={'Max Use'} value={rewardIdDetail?.max_use} />
          <FieldDisplay label={'Channel'} value={rewardIdDetail?.channel} />
          <FieldDisplay label={'Point conversion factor'} value={rewardIdDetail?.point_conversion_factor} />
          <FieldDisplay label={'Redeemable value'} value={rewardIdDetail?.redeemable_value} />
          <FieldDisplay label={'Status'} value={rewardIdDetail?.status} />
          <FieldDisplay label={'Transaction Type'} value={rewardIdDetail?.transaction_type} />
        </section>
      </Modal>
    </PrimaryLayout>
  );
};

export default RewardHistoryDetail;
