import {
  IconCircleCheck,
  IconInfoCircle,
  IconPencilPlus,
  IconPlaystationX,
  IconTool,
  IconTrash,
  IconZoomIn,
  IconTicket,
  IconEye,
  IconSettings,
} from '@tabler/icons';
import { GiftOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import * as React from 'react';

interface IActionButtonProps {
  onClick?: () => void;
  action:
    | 'CREATE'
    | 'CREATE_NEW_VERSION'
    | 'VIEW'
    | 'TEST'
    | 'EXPAND_VERSIONS'
    | 'ACTIVATE'
    | 'DEACTIVATE'
    | 'DELETE'
    | 'GENERATE_COUPON'
    | 'MANAGE'
    | 'REWARD';
  title: string;
  tooltipPlacement?: TooltipPlacement;
}

const iconColor = '#5885af';

const ActionButton: React.FunctionComponent<IActionButtonProps> = props => {
  const iconMap: Record<string, React.ReactNode> = {
    VIEW: <IconEye color={iconColor} />,
    TEST: <IconTool color={iconColor} />,
    CREATE_NEW_VERSION: <IconPencilPlus color={iconColor} />,
    EXPAND_VERSIONS: <IconZoomIn color={iconColor} />,
    ACTIVATE: <IconCircleCheck color={iconColor} />,
    DEACTIVATE: <IconPlaystationX color={iconColor} />,
    DELETE: <IconTrash color={iconColor} />,
    GENERATE_COUPON: <IconTicket color={iconColor} />,
    MANAGE: <IconSettings color={iconColor} />,
    REWARD: <GiftOutlined color={iconColor}/>
  };

  const getIcon = (action: string) => {
    return iconMap[action];
  };

  return (
    <Tooltip placement={props.tooltipPlacement || 'top'} title={props.title}>
      <Button onClick={props.onClick} type="ghost" icon={getIcon(props.action)}></Button>
    </Tooltip>
  );
};

export default ActionButton;
