import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import { Button, Card, Col, Row, Typography } from 'antd';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { useNavigate } from 'react-router-dom';

interface ISubscriptionsPageProps {}

const SubscriptionsPage: React.FunctionComponent<ISubscriptionsPageProps> = props => {
  const navigate = useNavigate();

  return (
    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-2">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                All Subscriptions
              </Typography.Title>
            </Col>
            <Col>
              <Button type="primary" size="large" onClick={() => navigate('/admin/subscriptions/new')}>
                <BoldButtonLabel labelText="+New" />
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default SubscriptionsPage;
