import { PROMOTION_REPORT_URI } from "../../../config/envConfig";
import httpClient from "../../../http/http.client";

export const getRfmData = (params : any) => {
    const url = `${PROMOTION_REPORT_URI}/orders/v1/rfm`;
    return httpClient.get({ url,  params });
};

export const getCategoryData = () => {
    const url = `${PROMOTION_REPORT_URI}/orders/v1/rfm/categories/list`
    return httpClient.get({ url });
}

export const AnalyticsService = {
    getRfmData,
    getCategoryData
}