import * as React from 'react';
import {
  HomeOutlined,
  HomeFilled,
  UsergroupAddOutlined,
  InboxOutlined,
  SettingOutlined,
  WalletOutlined,
  TagsOutlined
} from '@ant-design/icons';
interface ISideMenuIconProps {
  iconKey: string;
}
const commonStyle: React.CSSProperties = { fontSize: 14, color: '#001529' };

const SideMenuIcon: React.FunctionComponent<ISideMenuIconProps> = ({ iconKey }) => {
  const iconKeyMap: Record<string, any> = {
    HOME: <HomeOutlined style={commonStyle} />,
    TENANT_MANAGEMENT: <UsergroupAddOutlined style={commonStyle} />,
    PRODUCT: <InboxOutlined style={commonStyle} />,
    NEBULA_APP: <SettingOutlined style={commonStyle} />,
    LOYALTY_ENGINE: <WalletOutlined style={commonStyle} />,
    PROMOTION_APP: <TagsOutlined style={commonStyle} />
  };

  return iconKeyMap[iconKey] ? iconKeyMap[iconKey] : null;
};

export default SideMenuIcon;
