import { FEATURE_FLAGS } from '../feature-flags';
import { appRoutes } from './routing';

export interface ISideMenuItemType {
  id: string;
  label: string;
  route?: string;
  icon?: any;
  children?: ISideMenuItemType[];
  accessKey?: string;
  hidden?: boolean;
}

const adminSideMenuList: ISideMenuItemType[] = [
  // { id: 'PLANS', label: 'Plan', route: '/', icon: null },
  {
    id: 'TENANT_MANAGEMENT',
    label: 'Tenant Management',
    icon: 'TENANT_MANAGEMENT',
    accessKey: 'ROOT',

    children: [
      { id: 'CO:TENANT-PROFILE', label: 'Tenant Profile', route: '/admin/tenant_Profile' },
      { id: 'CO:MANAGE-USER', label: 'Manage User', route: '/admin/manage_User', icon: null }
    ]
  },
  { id: 'PRODUCT', label: 'Product', route: '/admin/products', icon: 'PRODUCT', accessKey: 'product' }

  // { id: 'FINANCE', label: 'Finance', route: '/', icon: null }
];

const loyaltySideMenuList: ISideMenuItemType[] = [
  // { id: 'PLANS', label: 'Plan', route: '/', icon: null },
  {
    id: 'LOYALTY_ENGINE',
    label: 'Elysian - Loyalty',
    accessKey: 'elysian',
    icon: 'LOYALTY_ENGINE',

    children: [
      { id: 'EL:DASHBOARD-ACCESS', label: 'Dashboard', route: '/loyalty/dashboard' },
      {
        id: 'EL:CONFIGURATIONS-ACCESS',
        label: 'Configuration',

        children: [
          { id: 'LOYALTY_ENGINE_CONFIG_REWARDS', label: 'Rewards Type', route: '/loyalty/config/rewards' },
          {
            id: 'LOYALTY_ENGINE_CONFIG_POINTS',
            label: 'Point Conversion Rules',
            route: '/loyalty/config/point-conversion'
          },
          { id: 'LOYALTY_ENGINE_CONFIG_RULES', label: 'Earn Rules', route: '/loyalty/config/earn-rule' },
          { id: 'LOYALTY_ENGINE_CONFIG_BURN_RULES', label: 'Burn Rules', route: '/loyalty/config/burn-rule' },
          { id: 'LOYALTY_ENGINE_CONFIG_TIER', label: 'Tier Management', route: '/loyalty/config/tiers' }
        ]
      },
      {
        id: 'EL:ANALYTICS-ACCESS',
        label: 'Analytics',
        children: [{ id: 'LOYALTY_ENGINE_ANALYTICS_RFM', label: 'Customer Profiling', route: '/loyalty/analytics/rmf' }]
      },
      { id: 'EL:CUSTOMERS-ACCESS', label: 'Customers', route: '/loyalty/customers' },
      { id: 'EL:PRODUCTS-ACCESS', label: 'Products', route: '/loyalty/products' },
      { id: 'EL:REPORT-ACCESS', label: 'Report', route: '/loyalty/reports' }
    ]
  }
];

const sideMenu = {
  sideMenuList: [
    {
      id: 'HOME',
      label: 'Home',
      icon: 'HOME',
      accessKey: 'home',
      route: '/'
    },
    ...adminSideMenuList,

    {
      id: 'NEBULA_APP',
      label: 'Nebula - OMS',
      accessKey: 'nebula',
      icon: 'NEBULA_APP',
      children: [
        {
          id: 'NE:ORDER-DASHBOARD-ACCESS',
          label: 'Dashboard',
          route: '/nebula/order/dashboard',
          icon: null,
          children: []
        },
        {
          id: 'NE:ORDERS-ACCESS',
          label: 'Orders',
          icon: null,
          children: [
            { route: '/order', id: 'ORDER', label: 'Orders' },
            { route: '/release-order', id: 'RELEASE_ORDER', label: 'Release Orders' }
          ]
        },
        {
          id: 'NE:INVENTORY-ACCESS',
          label: 'Inventory',
          route: '/inventory',
          icon: null,
          children: []
        },
        {
          id: 'NE:PRODUCTS',
          label: 'Products',
          route: '/order-products',
          icon: null,
          children: []
        },
        {
          id: 'NE:NODES-ACCESS',
          label: 'Nodes',
          route: '/nodes',
          icon: null,
          children: []
        },
        {
          id: 'NE:OPERATORS-ACCESS',
          label: 'Operators',
          route: '/operators',
          icon: null,
          children: []
        },
        {
          id: 'NE:LOGISTICS-ACCESS',
          label: 'Logistics',
          // hidden: !FEATURE_FLAGS.ENABLE_LOGISTICS,
          icon: null,
          children: [
            { route: '/logistics/dnn', id: 'DNN', label: 'DNN' },
            { route: '/logistics/snn', id: 'SNN', label: 'SNN' }
            // { route: '/logistics/shipping-profile', id: 'SHIPPING_PROFILE', label: 'Shipping Profile' },
            // { route: '/logistics/service-network', id: 'SERVICE_NETWORK', label: 'Service Network' }
          ]
        },
        { route: '/order-create', id: 'NE:ORDER-CREATION', label: 'Create Order Testing' },
        {
          id: 'NE:PROMISE',
          label: 'Promise',
          icon: null,
          children: [
            { route: '/promise/dashboard', id: 'DASHBOARD', label: 'Dashboard' },
            { route: '/Promise', id: 'Promise', label: 'Promise' },
            // { route: '/Report', id: 'Report', label: 'Report' }
          ]
        }
      ]
    },
    {
      id: 'PROMOTION_APP',
      icon: 'PROMOTION_APP',
      label: 'Opus - Promotion',
      accessKey: 'opus',
      children: [
        { id: 'OP:DASHBOARD-ACCESS', label: 'Dashboard', route: '/promotion-engine/promotions/dashboard' },
        {
          id: 'OP:PROMO-PRIORITY-ACCESS',
          label: 'Promotion Settings',
          route: '/promotion-engine/promotions/priority',
          icon: null
        },
        // {
        //   id: 'PROMOTION_LIST',
        //   label: 'Promotions',
        //   route: '/promotion-engine/promotions',
        //   icon: null,
        // },
        {
          id: 'OP:PROMOTIONS-ACCESS',
          label: 'Promotions',
          icon: null,
          children: [
            {
              route: '/promotion-engine/promotions/sub_total_discount',
              id: 'SUB_TOTAL_DISCOUNT',
              label: 'Sub Total Discount'
            },
            { route: '/promotion-engine/promotions/volume_discount', id: 'VOLUME_DISCOUNT', label: 'Volume Discount' },
            { route: '/promotion-engine/promotions/bundle_discount', id: 'BUNDLE_DISCOUNT', label: 'Bundle Discount' },
            {
              route: '/promotion-engine/promotions/shipping_discount',
              id: 'SHIPPING_DISCOUNT',
              label: 'Shipping Discount'
            },
            { route: '/promotion-engine/promotions/bxgy', id: 'BXGY', label: 'BXGY' },
            { route: '/promotion-engine/promotions/loyalty_reward', id: 'LOYALTY', label: 'Loyalty Reward' }
          ]
        },
        {
          id: 'OP:PRICELIST-ACCESS',
          label: 'Price List Discount',
          route: '/promotion-engine/promotions/price-list',
          icon: null
          // hidden: !FEATURE_FLAGS?.ENABLE_PRICELIST
        },
        {
          id: 'OP:CATALOG-ACCESS',
          label: 'Catalog',
          route: '/promotion-engine/promotions/catalog',
          icon: null
          // hidden: !FEATURE_FLAGS?.ENABLE_PRICELIST
        },
        {
          id: 'OP:REPORT-ACCESS',
          label: 'Report',
          route: '/promotion-engine/promotions/report',
          icon: null
        }
      ]
    },
    ...loyaltySideMenuList

    // {
    //   id: 'ORDER_CREATION',
    //   label: 'Order Creation',
    //   route: '/order-create',
    //   icon: null,
    //   children: [1,00,000.00]
    // }
  ]
};

const routeToMenuKeyMap = {
  '/': {
    menuKey: 'HOME'
  },
  [appRoutes.inventory.index]: {
    menuKey: 'INVENTORY'
  },
  [appRoutes.inventory.details]: {
    menuKey: 'INVENTORY'
  },
  [appRoutes.order.index]: {
    menuKey: 'ORDER'
  },
  '/order-create': {
    menuKey: 'ORDER_CREATION'
  },
  '/order-dashboard': {
    menuKey: 'ORDER_DASHBOARD'
  },
  '/nodes': {
    menuKey: 'NODES'
  },
  '/nodes/create': {
    menuKey: 'NODES'
  },
  '/operators': {
    menuKey: 'OPERATORS'
  },
  '/products': {
    menuKey: 'PRODUCT'
  },
  '/admin/subscriptions': {
    menuKey: 'SUBSCRIPTION_MANAGEMENT'
  },
  '/promotion-engine/promotions': {
    menuKey: 'PROMOTION_LIST'
  }
};

export { sideMenu, routeToMenuKeyMap };
