import { IconPencilPlus } from '@tabler/icons';
import { Avatar, Button, Col, Form, Input, List, Row, Select, Typography } from 'antd';
import React, { useEffect } from 'react';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { IProfileDetailsResponse } from '../../../types/profile.types';
import _ from '../../../helpers/lodash';
import { countryCodeMap, countryStateMap, getCountryNameByCountry, stateList } from '../../../data';
import { countryList } from '../../../data/intl-data';
import { profile } from 'console';
import { useLoader } from '../../../stores/use-loader';
import { profileService } from '../../../services';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';

interface IOrganizationInfo {
  profileDetails: IProfileDetailsResponse;
  fetchProfileInfo: () => void;
}

const OrganizationInfo: React.FunctionComponent<IOrganizationInfo> = ({ profileDetails, fetchProfileInfo }) => {
  const [addressDetailsDisablity, setAddressDetailsDisablity] = React.useState(true);

  const [OrganizationForm] = Form.useForm();

  useEffect(() => {
    if (!_.isEmpty(profileDetails)) {
      fillFormWithProfileDetails();
    }
  }, [profileDetails]);

  const fillFormWithProfileDetails = () => {
    const addressDetail = profileDetails?.contact_details?.address_detail || {};
    const domain_url = profileDetails?.tenant_details?.domain_url;
    const business_group_name = profileDetails?.tenant_details?.business_group_name;
    const business_type = profileDetails?.tenant_details?.business_type;
    const { address_line1, address_line2, country, city, state, pincode, location } = addressDetail;
    OrganizationForm.setFieldsValue({
      addressLine1: address_line1,
      addressLine2: address_line2,
      country,
      city,
      state,
      domain_url,
      business_group_name,
      business_type,
      pincode,
      location
    });
  };

  // "country_details": [
  //   {
  //     "country_code": "cl",
  //     "base_currency": "CLP",
  //     "other_supported_currencies": [
  //       {
  //         "supported_currency": "INR",
  //         "conversion_exists": true
  //       }
  //     ]
  //   }
  // ],

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const handleAddressSave = async () => {
    setLoading(true);
    const previousAddressDetails = profileDetails.contact_details.address_detail;
    const { addressLine1, addressLine2, country, city, state, pincode, location } = OrganizationForm.getFieldsValue();
    const countryDetailsExist = countryCodeMap[country] && countryStateMap[country];
    const stateDetailsExist = countryStateMap[country] && countryStateMap[country][state];
    const addressEditRequest = {
      tenant_name: profileDetails.tenant_details.tenant_name,
      business_group_name: profileDetails.tenant_details.business_group_name,

      contact_type: 'PRIMARY',
      address_detail: {
        address_type: previousAddressDetails?.address_type,
        address_line1: addressLine1,
        address_line2: addressLine2,
        country,
        state_code: stateDetailsExist ? countryStateMap[country][state].state_code : state,
        country_code: countryDetailsExist ? countryCodeMap[country] : country,
        location,
        coordinates: {
          latitude: '',
          longitude: ''
        },
        city,
        state,

        pincode,
        timezone: previousAddressDetails?.timezone
      }
    };

    const { errors } = await profileService.editUserAddress(addressEditRequest);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Address successfully edited!' });
      fetchProfileInfo();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <div className="w-full mx-auto px-2">
      <Form form={OrganizationForm} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            {/* Content for the first column */}
            <div style={{ background: '#fafafa', padding: '20px' }}>
              <Typography.Title level={5} className="text-[#2e2a5b]">
                Organization Details
              </Typography.Title>
              <Row gutter={24}>
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Business Group Name"
                    name="business_group_name"
                    rules={[{ required: false, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Business Group Name" disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Domain Url"
                    name="domain_url"
                    rules={[{ required: false, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Domain Url" disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Organization Type"
                    name="business_type"
                    rules={[{ required: false, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Organization Type" disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} md={12}>
            {/* Content for the second column */}
            <div style={{ background: '#fafafa', padding: '20px' }}>
              <div className="flex justify-between">
                <Typography.Title level={5} className="text-[#2e2a5b]">
                  Address Details
                </Typography.Title>
                <Button
                  type="ghost"
                  onClick={() => setAddressDetailsDisablity(prev => !prev)}
                  icon={<IconPencilPlus color={'#5885af'} />}
                ></Button>
              </div>
              <Row gutter={24}>
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Address Line 1"
                    name="addressLine1"
                    rules={[{ required: false, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Address Line 1" disabled={addressDetailsDisablity} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Address Line 2"
                    name="addressLine2"
                    rules={[{ required: false, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Address Line 2" disabled={addressDetailsDisablity} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    label="Country"
                    name="country"
                    rules={[{ required: false, message: "This field can't be empty" }]}
                  >
                    <Select
                      onChange={() => {
                        OrganizationForm.setFieldsValue({ state: null });
                      }}
                      options={countryList}
                      size="large"
                      placeholder="Country"
                      disabled={addressDetailsDisablity}
                    />
                  </Form.Item>
                </Col>{' '}
                <Col xs={24} md={8}>
                  <Form.Item noStyle shouldUpdate>
                    {form => {
                      const { country } = form.getFieldsValue();
                      const options = stateList[country] || [];
                      return (
                        <Form.Item
                          label="State"
                          name="state"
                          rules={[{ required: false, message: "This field can't be empty" }]}
                        >
                          <Select
                            options={options}
                            size="large"
                            placeholder="State"
                            disabled={addressDetailsDisablity}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[{ required: false, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="City" disabled={addressDetailsDisablity} />
                  </Form.Item>
                </Col>{' '}
                <Col xs={24} md={8}>
                  <Form.Item
                    label="Pincode"
                    name="pincode"
                    rules={[{ required: false, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Pincode" disabled={addressDetailsDisablity} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    label="Location"
                    name="location"
                    rules={[{ required: false, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Location" disabled={addressDetailsDisablity} />
                  </Form.Item>
                </Col>
                {!addressDetailsDisablity && (
                  <Col xs={24} md={24}>
                    <Button
                      onClick={handleAddressSave}
                      disabled={addressDetailsDisablity}
                      type="primary"
                      size="large"
                      htmlType="submit"
                    >
                      <BoldButtonLabel labelText="Save Details" />
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="mt-4">
          <Col xs={24} md={12}>
            {/* Content for the second column */}
            <div style={{ background: '#fafafa', padding: '20px' }}>
              <div className="flex justify-between">
                <Typography.Title level={5} className="text-[#2e2a5b]">
                  Country Details
                </Typography.Title>
                {/* <IconPencilPlus color={'#5885af'} onClick={() => setAddressDetailsDisablity(prev => !prev)} /> */}
              </div>
              <Row>
                <Col xs={24}>
                  <List
                    className="demo-loadmore-list"
                    loading={false}
                    bordered
                    itemLayout="horizontal"
                    dataSource={profileDetails?.tenant_details?.country_details || []}
                    renderItem={(item, index) => {
                      return (
                        <List.Item>
                          <List.Item.Meta
                            title={
                              <Typography.Title level={5}>
                                {getCountryNameByCountry(item.country_code) || getCountryNameByCountry('CHL')}
                              </Typography.Title>
                            }
                            description={
                              <>
                                <span> Base Currency - {item.base_currency}</span>
                                {!_.isEmpty(item?.other_supported_currencies) && (
                                  <div>
                                    <span> Other Supported Currencies - </span>

                                    {item?.other_supported_currencies
                                      ?.map(({ supported_currency }) => {
                                        return supported_currency;
                                      })
                                      .join(', ')}
                                  </div>
                                )}
                              </>
                            }
                          />
                        </List.Item>
                      );
                    }}
                  ></List>
                </Col>
              </Row>{' '}
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default OrganizationInfo;
