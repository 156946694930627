import { PRICING_URI } from '../config/envConfig';
import httpClient from '../http/http.client';

const PRICE_BASE_URI = PRICING_URI;

const wait = () => {
  return new Promise(resolve => {
    setTimeout(() => resolve('Done'), 2000);
  });
};

const getPricesForSkus = async (skus: any[], priceListId: string) => {
  const url = `${PRICE_BASE_URI}/customers/v1/price-lists/${priceListId}/prices`;
  return httpClient.post({ url, body: { line_items: skus } });
};

const getUnitPricesForProducts = async () => {
  const url = ``;
  //   const { data, errors } = await httpClient.get({ url });

  return {
    data: {
      count: 0,
      data: [
        {
          price_master_id: '123e4567-e89b-12d3-a456-426614174001',
          item_id: 'SKU1',
          cost_price: 0.0,
          unit_price: 70,
          minimum_markup_percent: 0.0,
          maximum_markup_percent: 0.0,
          lock_price: false
        },
        {
          price_master_id: '123e4567-e89b-12d3-a456-426614174001',
          item_id: 'SKU2',
          cost_price: 0.0,
          unit_price: 71,
          minimum_markup_percent: 0.0,
          maximum_markup_percent: 0.0,
          lock_price: false
        },
        {
          price_master_id: '123e4567-e89b-12d3-a456-426614174001',
          item_id: 'SKU3',
          cost_price: 0.0,
          unit_price: 72,
          minimum_markup_percent: 0.0,
          maximum_markup_percent: 0.0,
          lock_price: false
        },
        {
          price_master_id: '123e4567-e89b-12d3-a456-426614174001',
          item_id: 'SKU4',
          cost_price: 0.0,
          unit_price: 73,
          minimum_markup_percent: 0.0,
          maximum_markup_percent: 0.0,
          lock_price: false
        },
        {
          price_master_id: '123e4567-e89b-12d3-a456-426614174001',
          item_id: 'SKU5',
          cost_price: 0.0,
          unit_price: 74,
          minimum_markup_percent: 0.0,
          maximum_markup_percent: 0.0,
          lock_price: false
        }
      ],
      page_info: {
        current_page: 0,
        total_pages: 0
      }
    },
    errors: []
  };
};

const getPricelistForCustomer = async (customerId: string) => {
  const params = {
    channel: 'ONLINE',
    site_id: 'S2001',
    customer_id: customerId
  };
  const url = `${PRICING_URI}/customers/v1/price-lists`;

  return httpClient.post({ url, body: params });
};

export const priceService = {
  getPricesForSkus,
  getUnitPricesForProducts,
  getPricelistForCustomer
};
