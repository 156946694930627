import { Button, Card, Col, Form, Row, Select, Table, TableColumnsType, Typography } from "antd"
import PrimaryLayout from "../../../../layouts/primary-layout"
import BoldButtonLabel from "../../../../components/BoldButtonLabel"
import TenantInfo from "../../../../components/TenantIdInfo"
import React from "react"
import { useLoader } from "../../../../stores/use-loader"
import { useNavigate, useSearchParams } from "react-router-dom"
import { convertQueryStringToObj, objectHelpers } from "../../../../helpers"
import { loyaltyService } from "../../services/loyalty.service"
import { displayErrorNotifications, displaySuccessNotification } from "../../../../helpers/toast.helpers"
import _ from "../../../../helpers/lodash"
import { createRuleTriggerTypeData, triggerTypeSelectOptions } from "../../data"
import ActionButton from "../../../../components/ActionButton"

interface IBurnRuleListingProps { }

const statusList = [
    { value: 'OPEN', label: 'Open' },
    { value: 'ACTIVE', label: 'Active' },
    { value: 'EXPIRED', label: 'Expired' },
    { value: 'DEFFERED', label: 'Deferred' },
    { value: 'ON_HOLD', label: 'On Hold' },
    { value: 'CLOSED', label: 'Closed' }
]

const BurnRuleListing: React.FC<IBurnRuleListingProps> = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [burnRuleListing, setBurnRuleListing] = React.useState({} as any)

    const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

    const queryString = searchParams.toString();
    const queryStringObj = convertQueryStringToObj(queryString);
    const [searchForm] = Form.useForm();
    const navigate = useNavigate();

    const columns: TableColumnsType<any> = [
        {
            title: 'Rule Name',
            render(value, record, index) {
                return <>{record.name}</>;
            },
            align: 'center'
        },
        {
            title: 'Version',
            render(value, record, index) {
                return <>{record.version}</>;
            },
            align: 'center'
        },
        {
            title: 'Reward Name',
            render(value, record, index) {
                return <>{record.reward_name}</>;
            },
            align: 'center'
        },
        {
            title: 'Template Type',
            render(value, record, index) {
                return <>{record.burn_template_type}</>;
            },
            align: 'center'
        },
        {
            title: 'Status',
            render(value, record, index) {
                return <>{record.status}</>;
            },
            align: 'center'
        },
        {
            title: 'Action',
            render(value, { id }, index) {
                return (
                    <section className="flex gap-2 justify-center">
                        <ActionButton
                            action="VIEW"
                            title="View rule"
                            // onClick={() => navigate(`/loyalty/config/earn-rule/${id}`)}
                            onClick={() => navigate(`/loyalty/config/burn-rule/view/${id}`)}
                        />
                        <ActionButton
                            onClick={() => navigate(`/loyalty/config/burn-rule/${id}/new`)}
                            title="Create New Version"
                            action="CREATE_NEW_VERSION"
                        />
                    </section>
                );
            },
            align: 'center'
        },
        {
            title: 'Status update',
            render(value, record, index) {
                let actions: { label: string; action: string }[] = [];

                if (record.status === 'OPEN') {
                    actions.push({ label: 'Activate', action: 'ACTIVE' });
                }
                if (record.status === 'ACTIVE') {
                    actions.push(
                        ...[
                            {
                                label: 'On Hold',
                                action: 'ON_HOLD'
                            },

                            {
                                label: 'Defer',
                                action: 'DEFFERED'
                            }
                        ]
                    );
                }

                if (record.status === 'ON_HOLD') {
                    actions.push(
                        ...[
                            { label: 'Activate', action: 'ACTIVE' },
                            {
                                label: 'Defer',
                                action: 'DEFFERED'
                            }
                        ]
                    );
                }
                return (
                    <section className="flex justify-center">
                        {actions.map(({ label, action }) => (
                            <Button key={action} type="link" onClick={() => handleStatusChange(action, record.id)}>
                                {label}
                            </Button>
                        ))}
                    </section>
                );
            },
            align: 'center'
        }
    ];

    const handleStatusChange = async (status: string, id: string) => {
        setLoading(true);
        const { data, errors } = await loyaltyService.changeBurnRuleStatus(id, status);
        if (!_.isEmpty(errors)) {
            displayErrorNotifications(errors);
        } else {
            displaySuccessNotification({ message: 'Rule update success' });
            const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;

            await Promise.allSettled([handleFilterSearch(offset)]);
        }
        setLoading(false);
    };

    const loadInitialData = async () => {
        const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
        await handleFilterSearch(offset);
    };

    React.useEffect(() => {
        loadInitialData();
    }, []);

    const handleFilterSearch = async (offset = 0) => {
        setLoading(true);
        const formValues = searchForm.getFieldsValue();
        const params = { ...formValues, offset };
        const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
        setSearchParams(filteredParams);
        const { data, errors } = await loyaltyService.getRuleBurnList({
            limit: 10,
            offset,
            ...formValues
        });

        if (!_.isEmpty(errors)) {
            displayErrorNotifications(errors);
        } else {
            setBurnRuleListing(data);
        }
        setLoading(false);
    };

    const handleClickCreate = () => {
        navigate(`/loyalty/config/burn-rule/create`);
    };

    return (
        <PrimaryLayout>
            <div className="container mx-auto px-4">
                <Card>
                    <Row justify={'space-between'} className="mb-4">
                        <Col>
                            <Typography.Title level={3} className="text-[#2e2a5b]">
                                Burn Rules
                            </Typography.Title>
                        </Col>
                        <Col>
                            <Button type="primary" size="large" onClick={handleClickCreate}>
                                <BoldButtonLabel labelText="Create"></BoldButtonLabel>
                            </Button>
                        </Col>
                    </Row>
                    <TenantInfo />
                    <section className="mt-4">
                        <Form layout="vertical" form={searchForm} onFinish={() => handleFilterSearch(0)}>
                            <Row gutter={12}>
                                <Col xs={24} md={6}>
                                    <Form.Item name="burn_template_type" label="Template Type">
                                        <Select
                                            allowClear
                                            placeholder="Select Template Type"
                                            size="large"
                                            options={[
                                                { label: 'Customer Order', value: 'CUSTOMER_ORDER' },
                                                { label: 'Gift Card', value: 'GIFT_CARD' },
                                                { label: 'Transfer Reward', value: 'TRANSFER_REWARD' },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="status" label="Status">
                                        <Select allowClear placeholder="Status" size="large" options={statusList} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="h-full flex justify-center items-center">
                                        <Button type="primary" htmlType="submit" size="large" block>
                                            <BoldButtonLabel labelText="Search"></BoldButtonLabel>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Table
                                loading={false}
                                pagination={false}
                                bordered
                                dataSource={burnRuleListing?.data}
                                columns={columns}
                                scroll={{ x: 1000 }}
                            ></Table>
                        </Form>
                    </section>
                </Card>
            </div>
        </PrimaryLayout>
    )
}

export default BurnRuleListing