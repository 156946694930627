// export const INVENTORY_BASE_URI = '';
export const PROMOTION_NEW_DASHBOARD = process.env.REACT_APP_PROMOTION_DASHBOARD
export const INVENTORY_BASE_URI = process.env.REACT_APP_INVENTORY_BASE_URI;
export const ORDERS_BASE_URI = process.env.REACT_APP_ORDERS_BASE_URI;
export const ORCHESTRATION_URI = process.env.REACT_APP_ORCHESTRATION_BASE_URI;
export const NODE_URI = process.env.REACT_APP_NODE_BASE_URI;
export const OPERATOR_URI = process.env.REACT_APP_OPERATOR_BASE_URI;
export const PROMOTIONS_URI = process.env.REACT_APP_PROMOTIONS_URI;
export const LOYALTY_URI = process.env.REACT_APP_LOYALTY_URI;
export const COUPONS_URI = process.env.REACT_APP_COUPONS_URI;
export const LOGIN_URI = process.env.REACT_APP_LOGIN_URI;
export const INTROSPECT_URI = process.env.REACT_APP_INTROSPECT_URI;
export const PROFILE_URI = process.env.REACT_APP_INTROSPECT_URI;
export const KEYCLOCK_URI = process.env.REACT_APP_KEYCLOCK_URI;
export const PRICING_URI = process.env.REACT_APP_PRICING_URI;
export const CREATE_PRICE_URI = process.env.REACT_APP_PRICELIST_URI;
export const PRICE_URI = process.env.REACT_APP_PRICELIST_URI_ITEM;
export const CUSTOMER_PRODUCT_URI = process.env.REACT_APP_CUSTOMER_PRODUCT_URI;
export const STATIC_TOKEN =
  'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI3Z29SQnVvOVVtU2d1TXExUFMzZklpdHN0U2VNdlZTb1NQMXIwSEFiMlA4In0.eyJleHAiOjE2OTMzNDg1NjMsImlhdCI6MTY5MzMxMjU2MywianRpIjoiNTE1ZGJmYjktNGViMi00OWU3LWIwYzktOGY5NzE2NzQxZTRmIiwiaXNzIjoiaHR0cHM6Ly9hcGktcm1nLmNsb3ZlcnNoYWt0aS5jb206ODQ0My9yZWFsbXMvc3BhcnRhIiwic3ViIjoiYzgxMTdiYTctMDBhZi00MjUxLWJjZDctMmMyZjY0MTVjZjZjIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic3BhcnRhIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImRlZmF1bHQtcm9sZXMtc3BhcnRhIiwib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfSwic3BhcnRhIjp7InJvbGVzIjpbInVtYV9wcm90ZWN0aW9uIiwibG5wX2FjY2VzcyJdfX0sInNjb3BlIjoib3BlbmlkIHByb2ZpbGVfaW5mbyIsImNsaWVudEhvc3QiOiI0OS4zNy4xNjEuMTc5IiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJzZXJ2aWNlLWFjY291bnQtc3BhcnRhIiwibWVtYmVyX29mIjpbXSwiY2xpZW50QWRkcmVzcyI6IjQ5LjM3LjE2MS4xNzkiLCJjbGllbnRfaWQiOiJzcGFydGEifQ.tTR_kpI0tbqK8ypF-xfml1SjNwKHOC2LwVP7zzgwsM8XxbiAl_OGTU2AtPByOEaJie0NJZzLlOSY0x7Vu9ldyZ3IjwRNQoJG4Nf1QOTUrygesGu0zbFbKy_BqaG11phfpMig57uHeR8OJYA53DYoYPtTDjMf7OQUTe1GCPqpx_BdTLjSgV7g4Wk7WTaqfk_3WuY5EZSoSfVILYkLAAK13OErPMDBKV6mNTmwKcVPSBNhAnXBkFArhL07SMX7Tq0c2c65kmjoc7n_GlAMbdgn_Lt_jUGrQZaBcztI48lDcbFq-iBVy0v4rJ2QATxZRul8pcnSuXo4euHHqisYcDOBCg';

export const TEMPORARY_CLIENT_SECRET = process.env.REACT_APP_TEMPORARY_CLIENT_SECRET;
export const TEMPORARY_BUSINESS_GROUP_ID = process.env.REACT_APP_TEMPORARY_BUSINESS_GROUP;
export const TEMPORARY_RETAILER_AUTOFILL = process.env.REACT_APP_TEMPORARY_RETAILER_AUTOFILL;
export const ENV = process.env.REACT_APP_WEB_ENV;
export const DASHBOARD_URI = process.env.REACT_APP_LOYALTY_DASHBOARD_URI;
export const TENANT_DOMAIN_INDEX = process.env.REACT_APP_TENANT_DOMAIN_INDEX;
export const GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_SITE_KEY;
export const PROMOTION_REPORT_URI = process.env.REACT_APP_PROMOTION_REPORT;
export const MASTER_CATALOG = process.env.REACT_APP_MASTER_CATALOG;
export const LOGISTICS = process.env.REACT_APP_LOGISTICS;
export const PRODUCT = process.env.REACT_APP_PRODUCTS;
export const SHIPMENTS_BASE_URI = process.env.REACT_APP_SHIPMENTS_BASE_URI;
export const OPERATOR = process.env.REACT_APP_OPERATOR;
export const PROMOTION_DASHBOARD = process.env.REACT_APP_PROMOTION_DASHBOARD_URI;
export const ASSETS = process.env.REACT_APP_ASSETS;
export const OMS_DASHBOARD = process.env.REACT_APP_OMS_DASHBOARD;
export const OMS_PROMISE_DASHBOARD = process.env.REACT_APP_OMS_PROMISE_DASHBOARD;
export const PROMISE = process.env.REACT_APP_PROMISE;
export const INVOICE_URL = process.env.REACT_APP_INVOICE_URL;
export const DOC_GEN_URL = process.env.REACT_APP_DOC_GEN_URL;
export const REWARD_URL = process.env.REACT_APP_REWARD;
export const PROMISE_CATEGORY_URL = process.env.REACT_APP_PROMISE_PRODUCTS


export const ASSETS_NEBULA = process.env.REACT_APP_BUCKET_ASSETS
