import { Card, Tabs, Typography } from 'antd';
import PrimaryLayout from '../../../layouts/primary-layout';
import AddUser from './add-user';
import ManageUserListing from './manage-user-listing';

interface IManageUserProps {}

const ManageUser: React.FunctionComponent<IManageUserProps> = props => {
  const { TabPane } = Tabs;
  return (
    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card>
          <Typography.Title level={3} className="text-[#2e2a5b]">
            Manage Users
          </Typography.Title>
          <ManageUserListing />
          {/* <Tabs defaultActiveKey="1" type="card" className='mt-[30px]'>
            <TabPane tab="Manage User List" key="1">
              <ManageUserListing />              
            </TabPane>
            <TabPane tab="Add Users" key="2">
              <AddUser />
            </TabPane>
          </Tabs> */}
        </Card>
      </div>
    </PrimaryLayout>
  );
};
export default ManageUser;
