export const productList2 = [
  {
    categories: 'Test',
    product_id: 'Product1',
    id: '',
    article_id: 'Article1',
    sku_id: '10000001',
    country_origin: 'China',
    ean: '10000001',
    barcode: '10000001',
    mrp: 10000,
    unit_price: 10000,
    hsn_code: '901123',
    tax_rate: 9,
    retail_price: 10000,
    product_class: 'Cycle',
    product_name: 'TREK X-CALIBER 9 MTB BIKE ORANGE 2022',
    product_desc: `The X-Caliber 9 is the top model of the X-Caliber family. This is a cross country mountain bike equipped with components designed for racing. A RockShox Judy Silver lockout fork, Shimano XT/SLX 1x12 drivetrain, and wide-ratio cassette make it a great choice for riders looking to hit singletrack fast and want a great bike that's ready to go. compete from the start.,`,
    seller_info: {
      seller_id: 'SellerID127', //!Product master with seller id input
      seller_name: 'Best Seller',
      seller_sku_id: 'SellerSKU127'
    }
  },
  {
    categories: 'Test',
    product_id: 'Product2',
    id: '',
    article_id: 'Article2',
    sku_id: '10000002',
    country_origin: 'China',
    ean: '10000002',
    barcode: '10000002',
    hsn_code: '901123',
    tax_rate: 9,
    mrp: 1099,
    unit_price: 1099,
    retail_price: 1099,
    product_class: 'Clothing',
    product_name: `MEN'S RUNNING T-SHIRT NIKE SPORTSWEAR CIRCA WHITE`,
    product_desc: `This relaxed, lightweight cotton tee highlights our past with an enlarged, printed version of the tongue label found on early iterations of our Cortez running shoe. Put it on and live your past, present and future.`,
    seller_info: {
      seller_id: 'SellerID127', //!Product master with seller id input
      seller_name: 'Best Seller',
      seller_sku_id: 'SellerSKU127'
    }
  },
  {
    categories: 'Test',
    product_id: 'Product3',
    id: '',
    article_id: 'Article3',
    sku_id: '10000003',
    country_origin: 'China',
    ean: '10000003',
    barcode: '10000003',
    hsn_code: '901123',
    tax_rate: 9,
    mrp: 30999,
    unit_price: 30999,
    retail_price: 30999,
    product_class: 'Fitness',
    product_name: `PROFORM CARBON TL TREADMILL | SMART`,
    product_desc: `The ProForm Carbon TL treadmill is the perfect home solution for intense training without leaving home. Technologically advanced and Bluetooth® enabled, it features 20 preset programs, along with digital speed and incline control.`,
    seller_info: {
      seller_id: 'SellerID127', //!Product master with seller id input
      seller_name: 'Best Seller',
      seller_sku_id: 'SellerSKU127'
    }
  },

  {
    categories: 'Test',
    product_id: 'Product4',
    id: '',
    article_id: 'Article4',
    sku_id: '10000004',
    country_origin: 'China',
    ean: '10000004',
    barcode: '10000004',
    mrp: '6000',
    unit_price: '6000',
    retail_price: '6000',
    product_class: 'SNEAKERS',
    hsn_code: '901123',
    tax_rate: 9,
    product_name: `WOMEN'S RUNNING SHOES NEW BALANCE 1080 V12 BLACK`,
    product_desc: `The 1080 is the top offering of New Balance's line of runners. A combination of top-of-the-line performance technology and broad accessibility gives runners at all intensity levels more comfort over more distance. Simply put, there's no other shoe like the 1080. The Fresh Foam X 1080v12 represents a steady progression of the model's signature qualities. The pinnacle cushioning experience transitions smoothly underfoot and is precisely tuned with updated midsole mapping, which applies more foam to wider areas of the midsole and increases flexibility in the tightest spots. The ultra-modern outlook is also reflected in the upper construction of the 1080. The v12 offers a supportive second-skin style fit with an engineered Hypoknit upper`,
    seller_info: {
      seller_id: 'SellerID127', //!Product master with seller id input
      seller_name: 'Best Seller',
      seller_sku_id: 'SellerSKU127'
    }
  },
  {
    categories: 'Test',
    product_id: 'Product5',
    id: '',
    article_id: 'Article5',
    hsn_code: '901123',
    tax_rate: 9,
    sku_id: '10000005',
    country_origin: 'China',
    ean: '10000005',
    barcode: '10000005',
    mrp: '9000',
    unit_price: '9000',
    retail_price: '9000',
    product_class: 'SPORTS',
    product_name: 'BLU FIT BENCH COMBO 80 LBS',
    product_desc:
      'Designed for intensive home use for training during the week. It is very easy to assemble to give you the possibility to carry out your routines in the comfort of your home.',
    seller_info: {
      seller_id: 'SellerID127', //!Product master with seller id input
      seller_name: 'Best Seller',
      seller_sku_id: 'SellerSKU127'
    }
  }
];

// export const productDetailsMap: Record<string, any> = {
//   A1001: productList[0],
//   A1002: productList[1],
//   A1003: productList[2],
//   A1004: productList[3],
//   A1005: productList[4]
// };

export const productList = [
  {
    categories: 'Test',
    product_id: '11420',
    is_price_lock: false,
    id: '',
    article_id: '11420',
    hsn_code: '901123',
    tax_rate: 9,
    sku_id: '11420',
    country_origin: 'CHL',
    ean: '11420',
    barcode: '11420',
    mrp: 507,
    unit_price: 507,
    retail_price: 507,
    product_class: 'Pet food',
    product_name: 'Belcando water buffalo 400 GR',
    product_desc: 'Belcando water buffalo 400 GR',
    seller_info: {
      seller_id: 'Churu', //!Product master with seller id input
      seller_name: 'Churu',
      seller_sku_id: 'Churu'
    }
  },
  {
    categories: 'Test',
    product_id: '11487',
    is_price_lock: false,
    id: '',
    article_id: '11487',
    hsn_code: '901123',
    tax_rate: 9,
    sku_id: '11487',
    country_origin: 'CHL',
    ean: '11487',
    barcode: '11487',
    mrp: 645,
    unit_price: 645,
    retail_price: 645,
    product_class: 'Pet Supplies',
    product_name: 'Hueso Masticable Con Textura Regular',
    product_desc: 'Hueso Masticable Con Textura Regular',
    seller_info: {
      seller_id: 'Churu', //!Product master with seller id input
      seller_name: 'Churu',
      seller_sku_id: 'Churu'
    }
  },
  {
    categories: 'Test',
    product_id: '8055',
    is_price_lock: false,
    id: '',
    article_id: '8055',
    hsn_code: '901123',
    tax_rate: 9,
    sku_id: '8055',
    country_origin: 'CHL',
    ean: '8055',
    barcode: '8055',
    mrp: 276,
    unit_price: 276,
    retail_price: 276,
    product_class: 'Pet Supplies',
    product_name: 'Churu tuna/chicken snack for cats 56 GR',
    product_desc: 'Churu tuna/chicken snack for cats 56 GR',
    seller_info: {
      seller_id: 'Churu', //!Product master with seller id input
      seller_name: 'Churu',
      seller_sku_id: 'Churu'
    }
  },
  {
    categories: 'Test',
    product_id: 'de938b8a-39f3-11ee-be56-0242ac120002',
    is_price_lock: false,
    id: '',
    article_id: 'A1001',
    sku_id: 'SKU1',
    country_origin: 'Chile',
    ean: '10000001',
    barcode: '10000001',
    mrp: 100.0,
    unit_price: 100.0,
    hsn_code: '71131920',
    tax_rate: 5,
    retail_price: 100.0,
    product_class: 'Jewelry',
    product_name: 'Pendant Bracelet',
    product_desc: 'Pendant Bracelet',
    seller_info: {
      seller_id: 'SL1001', //!Product master with seller id input
      seller_name: 'OM JEWELLS',
      seller_sku_id: 'VSKU1'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  },
  {
    categories: 'Test',
    product_id: 'de938d06-39f3-11ee-be56-0242ac120002',
    is_price_lock: false,
    id: '',
    article_id: 'A1002',
    sku_id: 'SKU2',
    country_origin: 'Chile',
    ean: '10000002',
    barcode: '10000002',
    mrp: 80.0,
    unit_price: 80.0,
    hsn_code: '3304',
    tax_rate: 5,
    retail_price: 80.0,
    product_class: 'Cosmetics',
    product_name: 'Bootzie Maui Perfume Oil',
    product_desc: 'Bootzie Maui Perfume Oil',
    seller_info: {
      seller_id: 'SL1002', //!Product master with seller id input
      seller_name: 'TAC',
      seller_sku_id: 'VSKU2'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  },

  {
    categories: 'Test',
    product_id: 'de939242-39f3-11ee-be56-0242ac120002',
    is_price_lock: false,
    id: '',
    article_id: 'A1003',
    sku_id: 'SKU3',
    country_origin: 'Chile',
    ean: '10000003',
    barcode: '10000003',
    mrp: 120.0,
    unit_price: 120.0,
    hsn_code: '62114300',
    tax_rate: 5,
    retail_price: 120.0,
    product_class: 'Clothing',
    product_name: 'Low Rise',
    product_desc: 'Low Rise',
    seller_info: {
      seller_id: 'SL1003', //!Product master with seller id input
      seller_name: 'Cocoblu Retail',
      seller_sku_id: 'VSKU3'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  },

  {
    categories: 'Test',
    product_id: 'de9393b4-39f3-11ee-be56-0242ac120002',
    is_price_lock: false,
    id: '',
    article_id: 'A1004',
    sku_id: 'SKU4',
    country_origin: 'Chile',
    ean: '10000004',
    barcode: '10000004',
    mrp: 88.0,
    unit_price: 88.0,
    hsn_code: '62114300',
    tax_rate: 5,
    retail_price: 88.0,
    product_class: 'Clothing',
    product_name: 'Original Rise',
    product_desc: 'Original Rise',
    seller_info: {
      seller_id: 'SL1003', //!Product master with seller id input
      seller_name: 'Cocoblu Retail',
      seller_sku_id: 'VSKU4'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  },

  {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002',
    is_price_lock: false,
    id: '',
    article_id: 'A1005',
    sku_id: 'SKU5',
    country_origin: 'Chile',
    ean: '10000005',
    barcode: '10000005',
    mrp: 83.0,
    unit_price: 83.0,
    hsn_code: '62114100',
    tax_rate: 5,
    retail_price: 83.0,
    product_class: 'Clothing',
    product_name: 'Sweater',
    product_desc: 'Sweater',
    seller_info: {
      seller_id: 'SL1004', //!Product master with seller id input
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU5'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  },
  {
    categories: 'Electronics',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002111',
    is_price_lock: false,
    id: '',
    article_id: 'A1006',
    sku_id: 'SKU6',
    country_origin: 'IN',
    ean: '10000006',
    barcode: '10000006',
    mrp: 91905.65,
    unit_price: 91905.65,
    hsn_code: '62114100',
    tax_rate: 5,
    retail_price: 91905.65,
    product_class: 'Mobile',
    product_name: 'Phone 14 Pro 128GB',
    product_desc: 'Phone 14 Pro 128GB',
    seller_info: {
      seller_id: 'SL1004', //!Product master with seller id input
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  },
  {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002112',
    is_price_lock: false,
    id: '',
    article_id: 'A1007',
    sku_id: 'SKU7',
    country_origin: 'IN',
    ean: '10000007',
    barcode: '10000007',
    mrp: 21006.33,
    unit_price: 21006.33,
    hsn_code: '62114100',
    tax_rate: 5,
    retail_price: 21006.33,
    product_class: 'Mobile',
    product_name: 'Charging case',
    product_desc: 'Charging case',
    seller_info: {
      seller_id: 'SL1004', //!Product master with seller id input
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  },
  {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002113',
    is_price_lock: false,
    id: '',
    article_id: 'A1008',
    sku_id: 'SKU8',
    country_origin: 'IN',
    ean: '10000008',
    barcode: '10000008',
    mrp: 61270.14,
    unit_price: 61270.14,
    hsn_code: '62114100',
    tax_rate: 5,
    retail_price: 61270.14,
    product_class: 'Gaming',
    product_name: 'Consola PS5',
    product_desc: 'Consola PS5',
    seller_info: {
      seller_id: 'SL1004', //!Product master with seller id input
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  },
  {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002114',
    is_price_lock: false,
    id: '',
    article_id: 'A1009',
    sku_id: 'SKU9',
    country_origin: 'IN',
    ean: '10000009',
    barcode: '10000009',
    mrp: 4375.63,
    unit_price: 4375.63,
    hsn_code: '62114100',
    tax_rate: 5,
    retail_price: 4375.63,
    product_class: 'Gaming',
    product_name: 'Headset Gamer',
    product_desc: 'Headset Gamer',
    seller_info: {
      seller_id: 'SL1004', //!Product master with seller id input
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  },
  {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002115',
    is_price_lock: false,
    id: '',
    article_id: 'A1010',
    sku_id: 'SKU10',
    country_origin: 'IN',
    ean: '10000010',
    barcode: '10000010',
    mrp: 899,
    unit_price: 899,
    hsn_code: '62114100',
    tax_rate: 5,
    retail_price: 899,
    product_class: 'OTT',
    product_name: 'OTT: Super - 899 per year',
    product_desc: 'OTT: Super - 899 per year',
    seller_info: {
      seller_id: 'SL1004', //!Product master with seller id input
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  },
  {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002116',
    is_price_lock: false,
    id: '',
    article_id: 'A1011',
    sku_id: 'SKU11',
    country_origin: 'IN',
    ean: '10000011',
    barcode: '10000011',
    mrp: 1499,
    unit_price: 1499,
    hsn_code: '62114100',
    tax_rate: 5,
    retail_price: 1499,
    product_class: 'Gaming',
    product_name: 'OTT: Premium - 1499 per year',
    product_desc: '899 per year',
    seller_info: {
      seller_id: 'SL1004', //!Product master with seller id input
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  }, {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002117',
    is_price_lock: false,
    id: '',
    article_id: 'A1012',
    sku_id: 'SKU12',
    country_origin: 'IN',
    ean: '10000012',
    barcode: '10000012',
    mrp: 79.990,
    unit_price: 79.990,
    hsn_code: '62114101',
    tax_rate: 5,
    retail_price: 79.990,
    product_class: 'Appral',
    product_name: 'ZAPATILLAS DC COURT GRAFFIK DE HOMBRE',
    product_desc: '79.990',
    seller_info: {
      seller_id: 'SL1004',
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  }, {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002118',
    is_price_lock: false,
    id: '',
    article_id: 'A1013',
    sku_id: 'SKU13',
    country_origin: 'IN',
    ean: '10000013',
    barcode: '10000013',
    mrp: 24.990,
    unit_price: 24.990,
    hsn_code: '62114102',
    tax_rate: 5,
    retail_price: 24.990,
    product_class: 'Appral',
    product_name: 'JOCKEY HOMBRE REYNOTTS',
    product_desc: '24.990',
    seller_info: {
      seller_id: 'SL1004',
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  }, {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002119',
    is_price_lock: false,
    id: '',
    article_id: 'A1014',
    sku_id: 'SKU14',
    country_origin: 'IN',
    ean: '10000014',
    barcode: '10000014',
    mrp: 39.990,
    unit_price: 39.990,
    hsn_code: '62114103',
    tax_rate: 5,
    retail_price: 39.990,
    product_class: 'Appral',
    product_name: 'POLERON HOMBRE REBEL PH',
    product_desc: '39.990',
    seller_info: {
      seller_id: 'SL1004',
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  }, {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002120',
    is_price_lock: false,
    id: '',
    article_id: 'A1015',
    sku_id: 'SKU15',
    country_origin: 'IN',
    ean: '10000015',
    barcode: '10000015',
    mrp: 19.990,
    unit_price: 19.990,
    hsn_code: '62114104',
    tax_rate: 5,
    retail_price: 39.990,
    product_class: 'Self Care',
    product_name: 'Pure Wonder body lotion',
    product_desc: '19.990',
    seller_info: {
      seller_id: 'SL1004',
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  }, {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002121',
    is_price_lock: false,
    id: '',
    article_id: 'A1016',
    sku_id: 'SKU16',
    country_origin: 'IN',
    ean: '10000016',
    barcode: '10000016',
    mrp: 8.990,
    unit_price: 8.990,
    hsn_code: '62114105',
    tax_rate: 5,
    retail_price: 39.990,
    product_class: 'Self Care',
    product_name: 'Black Cherry Merlot Soap',
    product_desc: '8.990',
    seller_info: {
      seller_id: 'SL1004',
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  }, {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002122',
    is_price_lock: false,
    id: '',
    article_id: 'A1017',
    sku_id: 'SKU17',
    country_origin: 'IN',
    ean: '10000017',
    barcode: '10000017',
    mrp: 12.990,
    unit_price: 12.990,
    hsn_code: '62114106',
    tax_rate: 5,
    retail_price: 12.990,
    product_class: 'Self Care',
    product_name: 'Black Soap Holder',
    product_desc: '12.990',
    seller_info: {
      seller_id: 'SL1004',
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  }, {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002123',
    is_price_lock: false,
    id: '',
    article_id: 'A1018',
    sku_id: 'SKU18',
    country_origin: 'IN',
    ean: '10000018',
    barcode: '10000018',
    mrp: 4988,
    unit_price: 4988,
    hsn_code: '62114107',
    tax_rate: 5,
    retail_price: 4988,
    product_class: 'Travel Ticket',
    product_name: 'Mumbai Indigo flight',
    product_desc: '4988',
    seller_info: {
      seller_id: 'SL1005',
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  }, {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002124',
    is_price_lock: false,
    id: '',
    article_id: 'A1019',
    sku_id: 'SKU19',
    country_origin: 'IN',
    ean: '10000019',
    barcode: '10000019',
    mrp: 4988,
    unit_price: 4988,
    hsn_code: '62114109',
    tax_rate: 5,
    retail_price: 4988,
    product_class: 'Travel Ticket',
    product_name: 'Delhi Indigo flight',
    product_desc: '4988',
    seller_info: {
      seller_id: 'SL1006',
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  }, {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002125',
    is_price_lock: false,
    id: '',
    article_id: 'A1020',
    sku_id: 'SKU20',
    country_origin: 'IN',
    ean: '10000019',
    barcode: '10000018',
    mrp: 5000,
    unit_price: 5000,
    hsn_code: '62114107',
    tax_rate: 5,
    retail_price: 5000,
    product_class: 'Travel Ticket',
    product_name: 'lounge access card',
    product_desc: '5000',
    seller_info: {
      seller_id: 'SL1007',
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  }, {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002126',
    is_price_lock: false,
    id: '',
    article_id: 'A1021',
    sku_id: 'SKU21',
    country_origin: 'IN',
    ean: '10000020',
    barcode: '10000019',
    mrp: 7988,
    unit_price: 7988,
    hsn_code: '62114108',
    tax_rate: 5,
    retail_price: 7988,
    product_class: 'Travel Ticket',
    product_name: 'Bengaluru - Pune Indigo',
    product_desc: '7988',
    seller_info: {
      seller_id: 'SL1007',
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  }, {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002127',
    is_price_lock: false,
    id: '',
    article_id: 'A1022',
    sku_id: 'SKU22',
    country_origin: 'IN',
    ean: '10000021',
    barcode: '10000020',
    mrp: 6988,
    unit_price: 6988,
    hsn_code: '62114109',
    tax_rate: 5,
    retail_price: 6988,
    product_class: 'Travel Ticket',
    product_name: 'Kerala - Pune Indigo',
    product_desc: '6988',
    seller_info: {
      seller_id: 'SL1007',
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  }, {
    categories: 'Test',
    product_id: 'de9394f4-39f3-11ee-be56-0242ac120002127',
    is_price_lock: false,
    id: '',
    article_id: 'A1023',
    sku_id: 'SKU23',
    country_origin: 'IN',
    ean: '10000022',
    barcode: '10000021',
    mrp: 7000,
    unit_price: 7000,
    hsn_code: '62114110',
    tax_rate: 5,
    retail_price: 7000,
    product_class: 'Travel Ticket',
    product_name: 'Mumbai - Manglore',
    product_desc: '7000',
    seller_info: {
      seller_id: 'SL1007',
      seller_name: 'Kaalt ',
      seller_sku_id: 'VSKU6'
    },
    loyalty_earned_per_unit: '',
    loyalty_redeemed_per_unit: ''
  }
];
export const productDetailsMap: Record<string, any> = {
  11420: productList[0],
  11487: productList[1],
  8055: productList[2],
  A1001: productList[3],
  A1002: productList[4],
  A1003: productList[5],
  A1004: productList[6],
  A1005: productList[7],
  A1006: productList[8],
  A1007: productList[9],
  A1008: productList[10],
  A1009: productList[11],
  A1010: productList[12],
  A1011: productList[13],
  A1012: productList[14],
  A1013: productList[15],
  A1014: productList[16],
  A1015: productList[17],
  A1016: productList[18],
  A1017: productList[19],
  A1018: productList[20],
  A1019: productList[21],
  A1020: productList[22],
  A1021: productList[23],
  A1022: productList[24],
  A1023: productList[25],
};
