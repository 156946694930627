import React from 'react';
import _ from '../../../../helpers/lodash';
import { logisticService } from '../../../nebula/services';

const cache: Record<
    string,
    {
        customer_id: string;
        email: string;
        first_name: string;
        last_name: string;
    }[]
> = {};

export const useLogisticsNodes = () => {
    const [nodeDropdownFetching, setNodeDropdownFetching] = React.useState(false);
    const [nodeDropdownOptions, setNodeDropDownOptions] = React.useState<{ label: string; value: string }[]>([]);

    const fetchUserDropdownOptions = async (searchQuery: string) => {
        setNodeDropdownFetching(true);

        if (cache[searchQuery]) {
            const transformedResponse = transformResponse(cache[searchQuery]);
            setNodeDropDownOptions(() => {
                return [...transformedResponse];
            });
        } else {
            let params = {
                "node-name": searchQuery
            }
            const { data, errors } = await logisticService.searchNodeByQuery(params);
            cache[searchQuery] = data;
            if (_.isEmpty(errors)) {
                if (Array.isArray(data.nodes)) {
                    // const transformedResponse = transformResponse(data.nodes);     
                    const transformedResponse = data.nodes.map(
                        ({
                            node_name,
                            node_id,
                        }: {
                            node_name: string;
                            node_id: string
                        }) => {
                            return { label: node_name, value: node_id };
                        }
                    );           
                    if (Array.isArray(transformedResponse)) {
                        setNodeDropDownOptions(() => {
                            return [...transformedResponse];
                        });
                    }
                }
            }
        }
        setNodeDropdownFetching(false);
    };

    const transformResponse = (arrData: any) => {
        return (arrData.nodes || []).map(
            ({
                node_name,
                node_id,
            }: {
                node_name: string;
                node_id: string
            }) => {
                return { label: node_name, value: node_id };
            }
        );
    };

    const debouncedFetchUserDropdownOptions = _.debounce(fetchUserDropdownOptions, 1000);

    return {
        debouncedFetchUserDropdownOptions,
        nodeDropdownFetching,
        nodeDropdownOptions
    };
};
