import * as React from 'react';
import { promotionService } from '../services/promotion.service';
import _ from '../../../helpers/lodash';
import { Button, Card, Checkbox, Col, Form, } from 'antd';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { useLoader } from "../../../stores/use-loader";
const ControlSettings: React.FC = () => {
      const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
      const [controlForm] = Form.useForm();
      const [controlSettings, setControlSettings] = React.useState({});

      React.useEffect(() => {
            getControlSettings();
      }, []);

      const getControlSettings = async () => {
            setLoading(true)
            const { data, errors } = await promotionService.getControlSettings();
            let result = {};
            if (_.isEmpty(errors)) {
                  result = data.reduce((acc: any, item: any) => {
                        acc[item.param_name] = item.param_value === 'true' ? true : false;
                        return acc;
                  }, {});
            }
            setControlSettings(result);
            controlForm.setFieldsValue(result);
            setLoading(false)
      };

      const onFinish = async (values: any) => {
            setLoading(true)
            const body = [
                  {
                        param_name: 'enable_automatic_promotions',
                        param_value: controlForm.getFieldValue('enable_automatic_promotions') ? true : false,
                  },
                  {
                        param_name: 'enable_manual_promotions',
                        param_value: controlForm.getFieldValue('enable_manual_promotions') ? true : false,
                  },
            ];
            if (Object.keys(controlSettings).length) {
                  const { errors} = await promotionService.putControlSettings(body);
                  if (_.isEmpty(errors))
                        displaySuccessNotification({ message: 'Control Settings updated' });

                  else
                        displayErrorNotifications(errors);



            } else {
                  const { data, errors } = await promotionService.postControlSettings(body);
                  if (_.isEmpty(errors)) {
                        let result = {};

                        result = data.reduce((acc: any, item: any) => {
                              acc[item.param_name] = item.param_value === 'true' ? true : false;
                              return acc;
                        }, {});

                        setControlSettings(result);
                        displaySuccessNotification({ message: 'Control Settings updated' });
                  }
                  else {
                        displayErrorNotifications(errors);
                  }
            }
            setLoading(false)
      };

      return (
            <Card>
                  <Form layout="vertical" onFinish={onFinish} form={controlForm}>
                        <Form.Item
                              name="enable_automatic_promotions"
                              valuePropName="checked"
                              wrapperCol={{ offset: 2, span: 16 }}
                        >
                              <Checkbox>Enable Automatic Promotions</Checkbox>
                        </Form.Item>
                        <Form.Item
                              name="enable_manual_promotions"
                              valuePropName="checked"
                              wrapperCol={{ offset: 2, span: 16 }}
                        >
                              <Checkbox>Enable Manual Promotions</Checkbox>
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
                              <Button type="primary" htmlType="submit">
                                    SAVE
                              </Button>
                        </Form.Item>
                  </Form>
            </Card>
      );
};

export default ControlSettings;