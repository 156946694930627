import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  TableColumnsType,
  Typography,
  UploadFile
} from 'antd';
import TenantInfo from '../../../../components/TenantIdInfo';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Dragger from 'antd/es/upload/Dragger';
import { CloudUploadOutlined } from '@ant-design/icons';
import _ from '../../../../helpers/lodash';
import { useLoader } from '../../../../stores/use-loader';
import { convertQueryStringToObj, fileHelpers, objectHelpers } from '../../../../helpers';
import { loyaltyService } from '../../services/loyalty.service';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import CustomPagination from '../../../../components/custom-pagination';
import { IProductListingResponse, IProducts } from '../../types/product';
import { RcFile } from 'antd/es/upload';
import { EditOutlined } from '@ant-design/icons';

interface IProductListingPageProps {}

const ProductListing: React.FunctionComponent<IProductListingPageProps> = props => {
  const navigate = useNavigate();
  const [searchForm] = Form.useForm();
  const [uploadModalVisible, setUploadModalVisible] = React.useState(false);
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [productListingResponse, setProductListingResponse] = React.useState({} as IProductListingResponse);
  const [searchParams, setSearchParams] = useSearchParams();

  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);

  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const columns: TableColumnsType<IProducts> = [
    {
      title: 'Product ID',
      align: 'center',
      render(value, record, index) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Vendor Code',
      align: 'center',
      render(value, record, index) {
        return <>{record?.vendor_code}</>;
      }
    },
    {
      title: 'Product Name',
      align: 'center',
      render(value, record, index) {
        return <>{record?.product_name}</>;
      }
    },
    {
      title: 'Product Type',
      align: 'center',
      render(value, record, index) {
        return <>{record?.product_type === '' ? '-' : record?.product_type}</>;
      }
    },
    {
      title: 'Supply Type',
      align: 'center',
      render(value, record, index) {
        return <>{record?.supply_type_code}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value, record, index) {
        return (
          <section className="flex justify-center items-center">
            <Switch
              size="small"
              checked={record?.is_active ? true : false}
              onChange={(value: boolean) => handleOnChaneStatus(value, Number(record?.item_id))}
            />
            &nbsp;&nbsp;
            <div className="cursor-pointer">
              <EditOutlined
                className="cursor-pointer"
                onClick={() => navigate(`/loyalty/products/${record.item_id}`)}
              />
            </div>
          </section>
        );
      }
    }
  ];

  const handleOnChaneStatus = async (value: boolean, itemId: number) => {
    setLoading(true);
    const { errors } = await loyaltyService.updateProductStatus(itemId, value);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Product update success' });
      loadInitialData();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    if (!_.isEmpty(queryStringObj)) {
      let formParams: any = { ...queryStringObj };
      // if (queryStringObj.sign_up_date_from) {
      //   const date_range = [dayjs(queryStringObj.sign_up_date_from), dayjs(queryStringObj.sign_up_date_to)];
      //   formParams = { ...formParams, date_range };
      // }
      searchForm.setFieldsValue(formParams);
    }
    const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
    await handleSearch(offset);
  };

  const handleClickUploadCustomers = () => {
    setUploadModalVisible(true);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    let params = { ...formValues, offset, limit: 10 };
    //DO WE NEED DATE CHECK ONCE
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);
    const { data, errors } = await loyaltyService.searchProduct(filteredParams);
    if (_.isEmpty(errors)) {
      setProductListingResponse(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handlePageChange = (currrentPage: number) => {
    handleSearch(currrentPage - 1);
  };

  const handleDownloadTemplate = async () => {
    const { data, errors } = await loyaltyService.downloadSampleProductUploadFile();

    if (_.isEmpty(errors)) {
      // fileHelpers.triggerFileDownload({ data, fileName: 'product_upload_template', extension: '.xlsx' });
    }
  };

  const handleProductUpload = async () => {
    setLoading(true);
    if (_.isEmpty(fileList)) return;

    const formData = new FormData();
    formData.set('file', fileList[0] as RcFile);

    const { errors } = await loyaltyService.uploadProduct(formData);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Product uploaded successfully!' });

      loadInitialData();

      setUploadModalVisible(false);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Products
              </Typography.Title>
            </Col>

            <Col>
              <div className="flex gap-4">
                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    const backUrl = window.location?.pathname;
                    navigate(`/loyalty/products/create?goBackTo=${backUrl}`);
                  }}
                >
                  <BoldButtonLabel labelText="Create Products" />
                </Button>
                <Button size="large" type="primary" onClick={handleClickUploadCustomers}>
                  <BoldButtonLabel labelText="Upload Products" />
                </Button>
              </div>
            </Col>
          </Row>
          <TenantInfo />
          <section className="mt-4">
            <Form layout="vertical" onFinish={() => handleSearch(0)} form={searchForm}>
              <Row gutter={12} align={'middle'}>
                <Col xs={12} md={6}>
                  <Form.Item name="itemId" label="Item ID">
                    <Input size="large" placeholder="Itme ID" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name="vendor_code" label="Vendor Code">
                    <Input size="large" placeholder="Vendor Code" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name="productName" label="Product Name">
                    <Input size="large" placeholder="Product Name" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name="product_type" label="Product Type">
                    <Input size="large" placeholder="Product Type" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12} align={'middle'}>
                <Col xs={12} md={6}>
                  <Form.Item label="Status" name="active">
                    <Select
                      placeholder="Status"
                      size="large"
                      allowClear
                      options={[
                        { label: 'Active', value: true },
                        { label: 'Inactive', value: false }
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item label="Supply Type" name="supply_type">
                    <Select
                      placeholder="Supply Type"
                      size="large"
                      allowClear
                      options={[
                        { label: 'B2B', value: 'B2B' },
                        { label: 'B2C', value: 'B2C' }
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Button htmlType="submit" type="primary" size="large">
                  <BoldButtonLabel labelText="Search" />
                </Button>
              </Row>
            </Form>
          </section>
          <section className="mt-4">
            {!_.isEmpty(productListingResponse.data) && (
              <div className="flex justify-end my-2">
                <CustomPagination
                  currentPage={productListingResponse.page_info.current_page}
                  totalPages={productListingResponse.page_info.total_pages}
                  handleNext={handlePageChange}
                  handlePageChange={handlePageChange}
                  handlePrevious={handlePageChange}
                />
              </div>
            )}{' '}
            <Table
              loading={false}
              pagination={false}
              bordered
              dataSource={productListingResponse.data}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </Card>

        <Modal
          centered
          open={uploadModalVisible}
          onCancel={() => {
            setFileList([]);
            setUploadModalVisible(false);
          }}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>Upload Products</Typography.Title>
            </div>
          }
        >
          <section className="flex flex-col justify-center">
            <Button type="link" className="mb-4" onClick={handleDownloadTemplate}>
              Download Sample
            </Button>
            <Dragger
              beforeUpload={file => {
                setFileList([file]);
                return false;
              }}
              onRemove={() => {
                setFileList([]);
              }}
              fileList={fileList}
              name="file"
              accept={'.csv,.xlsx'}
            >
              <p>
                <CloudUploadOutlined className="text-9xl text-[#5885AF]" />
              </p>
              <p>Click or Drag Customers Excel File to upload</p>
            </Dragger>

            <Button
              disabled={_.isEmpty(fileList)}
              block
              size="large"
              type="primary"
              onClick={handleProductUpload}
              className="mt-4"
            >
              <BoldButtonLabel labelText="Upload" />
            </Button>
          </section>
        </Modal>
      </div>
    </PrimaryLayout>
  );
};

export default ProductListing;
