import { Button, Card, Col, Form, Input, Row, Select, Table, TableColumnsType, Typography } from 'antd';
import * as React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../components/ActionButton';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import FieldDisplay from '../../../components/FieldDisplay';
import CustomPagination from '../../../components/custom-pagination';
import { convertQueryStringToObj, fileHelpers, objectHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { couponService } from '../services/coupon.service';
import { ICoupon, ICouponListingResponse } from '../types/coupon';

interface ICouponListingProps {}

const CouponListing: React.FunctionComponent<ICouponListingProps> = props => {
  const [couponListingSearchForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
  const { reward_type, title, promotion_type, id } = queryStringObj;
  const [currentPage, setCurrentPage] = React.useState(offset + 1);
  const navigate = useNavigate();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const [couponListResponse, setCouponListResponse] = React.useState({} as ICouponListingResponse);

  const columns: TableColumnsType<ICoupon> = [
    {
      title: 'Code',
      render(value, record, index) {
        return <>{record.code}</>;
      },
      align: 'center'
    },
    {
      title: 'Start',
      render(value, record, index) {
        return <>{record.valid_from_date + ' ' + record.valid_from_time}</>;
      },
      align: 'center'
    },
    {
      title: 'End',
      render(value, record, index) {
        return <>{record.valid_to_date + ' ' + record.valid_to_time}</>;
      },
      align: 'center'
    },
    {
      title: 'Program',
      render(value, record, index) {
        return <>{record.promotion_type}</>;
      },
      align: 'center'
    },

    {
      title: 'State',
      render(value, record, index) {
        return <>{record.status}</>;
      },
      align: 'center'
    },
    // {
    //   title: 'Report',
    //   render(value, record, index) {
    //     return (
    //       <Typography.Text
    //         className="font-medium text-blue-600 cursor-pointer underline"
    //         onClick={() => handleOndownloadReport(record)}
    //       >{`Show Report`}</Typography.Text>
    //     );
    //   },
    //   align: 'center'
    // },
    {
      title: 'Redemption',
      render(value, record, index) {
        return (
          <>
            <Typography.Text
              className="font-medium text-blue-500"
              // onClick={() => handleOnRedemptionClick(record)}
            >{`${record?.coupon_used_count}`}</Typography.Text>
            {'-'}
            <Typography.Text
              className="font-medium text-blue-600 cursor-pointer underline"
              onClick={() => handleOndownloadReport(record)}
            >{`Show Report`}</Typography.Text>
          </>
        );
      },
      align: 'center'
    },
    {
      title: 'Audit',
      render(value, record, index) {
        return (
          <Typography.Text
            className="font-medium text-blue-500 cursor-pointer underline"
            onClick={() => handleOnAuditReportClick(record)}
          >{`Show Audit Report`}</Typography.Text>
        );
      },
      align: 'center'
    },
    {
      title: 'Action',
      render(value, record, index) {
        const label = record.status === 'INACTIVE' ? 'Activate' : 'Deactivate';
        const action = record.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE';
        const actionIcon = record.status === 'INACTIVE' ? 'ACTIVATE' : 'DEACTIVATE';
        return (
          <ActionButton
            action={actionIcon}
            title={label}
            onClick={() => handleStatusChange(action, record.id, record.code, record.config_id)}
          ></ActionButton>
        );
      },
      align: 'center'
    }
  ];

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const handleStatusChange = async (action: string, id: number, code: string, config_id: number) => {
    const { errors } = await couponService.patchCouponStatus(action, id, code, config_id);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Coupon status updated successfully!' });
      await handleSearch(currentPage - 1);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };
  const loadInitialData = async () => {
    couponListingSearchForm.setFieldsValue({ order_by: 'desc' });
    await handleSearch(offset);
  };

  const handleOnDownloadCoupons = async (offset = 0) => {
    setLoading(true);
    offset = 0;
    const params = {
      limit: 1000,
      offset
    };
    let configId = couponListResponse?.data[0]?.config_id;
    const { data, errors } = await couponService.getCoupons(configId, params);

    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'discount_coupon', extension: '.xlsx' });
      displaySuccessNotification({
        message: 'Downloaded Success! For downloading more than 1 Lakh record, Please contact Admin!'
      });
    }
    setLoading(false);
  };

  const handleOndownloadReport = async (row: ICoupon) => {
    const { code } = row;
    // const backUrl = urlHelpers.getBackUrl(searchParams);
    const params = {
      reward_type,
      promotion_type,
      title,
      offset: '0',
      code: code,
      id: id
      // backUrl
    };

    const paramString = new URLSearchParams(params).toString();
    navigate(`/promotion-engine/coupons/report?${paramString}`);
  };

  // const handleOnRedemptionClick = (row: ICoupon) => {
  //   const { code } = row;
  //   const backUrl = urlHelpers.getBackUrl(searchParams);
  //   const params = {
  //     reward_type,
  //     promotion_type,
  //     title,
  //     offset: '0',
  //     code: code,
  //     id: id
  //     // backUrl
  //   };

  //   const paramString = new URLSearchParams(params).toString();
  //   navigate(`/promotion-engine/coupons/redemption?${paramString}`);
  // };

  const handleOnAuditReportClick = (row: ICoupon) => {
    const { code } = row;
    // const backUrl = urlHelpers.getBackUrl(searchParams);
    const params = {
      reward_type,
      promotion_type,
      title,
      offset: '0',
      code: code,
      id: id
      // backUrl
    };

    const paramString = new URLSearchParams(params).toString();
    navigate(`/promotion-engine/coupons/audit?${paramString}`);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const configId = searchParams.get('id');
    const formValues = couponListingSearchForm.getFieldsValue();
    const sortKey = formValues?.order_by;
    const sortValue = formValues?.sort_by?.toString();
    const dataObject = {
      [sortKey]: sortValue,
      ...formValues
    };
    delete dataObject.order_by
    delete dataObject.sort_by
    const filteredFormValues = { ...objectHelpers.deleteUndefinedValuesFromObject(dataObject) };
    const Params = {
      config_id: configId,
      deleted: false,
      ...filteredFormValues,
      offset,
      limit: 10
    };
    const { data, errors } = await couponService.getCouponsList(Params);
    if (_.isEmpty(errors)) {
      setCouponListResponse(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const { search } = useLocation();

  const handlePageChange = async (current: number) => {
    setCurrentPage(current);
    await handleSearch(current - 1);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Form layout="vertical" form={couponListingSearchForm} onFinish={() => handleSearch(0)}>
          <Card>
            <Row justify={'space-between'} gutter={[12, 12]} className="mb-4">
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  Coupons
                </Typography.Title>
              </Col>
            </Row>
            <Row justify="space-between" gutter={[12, 12]}>
              <Col xs={24} md={12}>
                <section className="flex gap-4 flex-wrap">
                  <FieldDisplay label="Reward Type" value={reward_type} />
                  <FieldDisplay label="Promotion Type" value={promotion_type} />
                  <FieldDisplay label="Program Title" value={title} />
                </section>
              </Col>
              <Col className="flex gap-[10px]">
                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    navigate(`/promotion-engine/promotions/${id}/generate-coupons${search}`);
                  }}
                >
                  <BoldButtonLabel labelText="Generate Coupon" />
                </Button>
                <Button size="large" type="primary" onClick={() => handleOnDownloadCoupons()}>
                  <BoldButtonLabel labelText="Download Coupons" />
                </Button>
              </Col>
            </Row>
            <Row className="mt-4" gutter={12}>
              <Col xs={12} md={6}>
                {' '}
                <Form.Item name="code" label="Coupon Code">
                  <Input size="large" placeholder="Coupon Code" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                {' '}
                <Form.Item name="status" label="Status">
                  <Select
                    placeholder="Status"
                    size="large"
                    allowClear
                    options={[
                      { label: 'Reserved', value: 'RESERVED' },
                      { label: 'Active', value: 'ACTIVE' },
                      { label: 'In-Active', value: 'INACTIVE' },
                      { label: 'Redeemed', value: 'REDEEMED' },
                      { label: 'Expired', value: 'EXPIRED' }
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Button htmlType="submit" type="primary" size="large">
                <BoldButtonLabel labelText="Search" />
              </Button>
            </Row>
            <Row gutter={12} className="my-4">
              <Col xs={24} md={6}>
                {' '}
                <Form.Item label="Sort By" name={'sort_by'} className="mb-0">
                  <Select
                    mode="multiple"
                    size="large"
                    placeholder="Sort By"
                    // defaultValue={'valid_from_date'}
                    options={[
                      { label: 'Code', value: 'code' },
                      { label: 'Start Date', value: 'start_date' },
                      { label: 'End Date', value: 'end_date' },
                      { label: 'Status', value: 'status' }
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                {' '}
                <Form.Item label="Order By" name={'order_by'} className="mb-0">
                  <Select
                    size="large"
                    placeholder="Order By"
                    options={[
                      { label: 'Ascending order', value: 'asc' },
                      { label: 'Descending order', value: 'desc' }
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Button htmlType="submit" type="primary" size="large">
                <BoldButtonLabel labelText="Sort" />
              </Button>
            </Row>
            <section className="mt-2">
              {!_.isEmpty(couponListResponse?.data) && (
                <Row className="my-2" justify={'end'} gutter={[12, 12]}>
                  <Col>
                    <CustomPagination
                      currentPage={currentPage}
                      totalPages={Math.ceil(couponListResponse?.count / 10)}
                      handleNext={handlePageChange}
                      handlePageChange={handlePageChange}
                      handlePrevious={handlePageChange}
                    />
                  </Col>
                </Row>
              )}
            </section>
            <Table
              loading={false}
              bordered
              pagination={false}
              className="mt-4"
              dataSource={couponListResponse?.data || []}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>

            <div className="mt-4">
              <Button
                size="large"
                type="primary"
                onClick={() =>
                  navigate(`/promotion-engine/promotions?promotion_type=COUPON&offset=0&promo_header_type=MANUAL`)
                }
              >
                <BoldButtonLabel labelText="Go back to Promotions" />
              </Button>
            </div>
          </Card>
        </Form>
      </div>
    </PrimaryLayout>
  );
};

export default CouponListing;
