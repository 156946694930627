import { Form, Steps } from 'antd';
import * as React from 'react';

import NodeDetailForm from './node-detail-form';
import NodeConfigForm from './node-config-form';
import NodeContactForm from './node-contact-form';
import { nodeService, operatorService } from '../../services';
import _ from '../../helpers/lodash';

import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import { useLocale } from '../../stores/use-locale';

import { mapNodeFormValuesToRequestDto } from '../../helpers/node.helpers';
import { useLoader } from '../../stores/use-loader';
import { domHelpers } from '../../helpers/dom.helpers';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
interface INodeCreationFormProps {
  fetchedNodeDetails?: any;
  loading?: boolean;
}

const NodeCreationForm: React.FunctionComponent<INodeCreationFormProps> = ({ fetchedNodeDetails }) => {
  //!Create 3 Form Instances

  const [nodeDetailsForm] = Form.useForm();
  const [nodeConfigForm] = Form.useForm();
  const [nodeContactForm] = Form.useForm();

  const [formData, setFormData] = React.useState({});

  const [isUpdate, setIsUpdate] = React.useState(false);

  const [operatorList, setOperatorList] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { userCurrency } = useLocale(({ userCurrency }) => ({
    userCurrency
  }));

  const handleSubmit = async (formValues: any) => {
    //!Http call
    if (!isUpdate) {
      const createRequest = mapNodeFormValuesToRequestDto(formValues, userCurrency);
      await handleCreateNode(createRequest);
    } else {
      const updateRequest = mapNodeFormValuesToRequestDto(formValues, userCurrency);

      handleUpdateNode(updateRequest);
    }
  };

  const { nodeId } = useParams();

  const handleUpdateNode = async (request: any) => {
    const { data, errors } = await nodeService.putNodeByNodeId(nodeId as string, request);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Node updated successfully!' });
      domHelpers.scrollMainContentToTop();
      setCurrentStep(0);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const handleCreateNode = async (request: any) => {
    const { data, errors } = await nodeService.createNode(request);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Node created successfully!' });
      setFormData({});
      nodeConfigForm.resetFields();
      nodeContactForm.resetFields();
      nodeDetailsForm.resetFields();
      setCurrentStep(0);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const [currentStep, setCurrentStep] = React.useState(0);

  const handleClickNext = async (currentFormValues: any) => {
    domHelpers.scrollMainContentToTop();
    setFormData(formData => ({ ...formData, ...currentFormValues }));
    const formValues = { ...formData, ...currentFormValues };
    if (currentStep < 2) {
      setCurrentStep(current => current + 1);
    }

    if (currentStep === 2) {
      await handleSubmit(formValues);
    }
  };

  const handleClickBack = (currentFormValues: any) => {
    if (currentStep === 0) {
      const backUrl = searchParams.get('backUrl');
      return backUrl ? navigate(backUrl) : navigate('/nodes');
    }

    domHelpers.scrollMainContentToTop();
    window.scrollTo(0, 0);
    setFormData(formData => ({ ...formData, ...currentFormValues }));

    if (currentStep > 0) setCurrentStep(current => current - 1);
  };

  React.useEffect(() => {
    fetchOperatorList();
  }, []);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    setLoading(true);
    if (!_.isEmpty(fetchedNodeDetails)) {
      setIsUpdate(true);
      setFormData(fetchedNodeDetails);
      nodeConfigForm.setFieldsValue(fetchedNodeDetails);
      nodeDetailsForm.setFieldsValue(fetchedNodeDetails);
      nodeContactForm.setFieldsValue(fetchedNodeDetails);
    }
    setLoading(false);
  }, [fetchedNodeDetails]);

  const fetchOperatorList = async () => {
    const { data, errors } = await operatorService.getOperatorList(1, undefined, 9999999);

    if (_.isEmpty(errors)) setOperatorList(data?.operators);
  };

  return (
    <>
      <div className="mt-8">
        <Steps
          responsive
          current={currentStep}
          items={[
            {
              title: 'Node Details'
            },
            {
              title: 'Configuration'
            },
            {
              title: 'Contact Details'
            }
          ]}
        />

        <div className="pt-6">
          {currentStep === 0 && (
            <>
              <NodeDetailForm
                operatorList={operatorList}
                nodeDetailsForm={nodeDetailsForm}
                nodeDetails={formData}
                currentStep={currentStep}
                handleClickNext={handleClickNext}
                handleClickBack={handleClickBack}
              />
            </>
          )}
          {currentStep === 1 && (
            <NodeConfigForm
              nodeConfigForm={nodeConfigForm}
              nodeDetails={formData}
              currentStep={currentStep}
              handleClickNext={handleClickNext}
              handleClickBack={handleClickBack}
            />
          )}
          {currentStep === 2 && (
            <NodeContactForm
              isUpdate={isUpdate}
              nodeContactForm={nodeContactForm}
              nodeDetails={formData}
              currentStep={currentStep}
              handleClickNext={handleClickNext}
              handleClickBack={handleClickBack}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default NodeCreationForm;
