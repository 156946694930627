import * as React from 'react';
import { sideMenuConfig } from '../config';

import { MenuProps, Typography, Layout, Menu, Drawer, Dropdown, Button, Tooltip, Select, Spin } from 'antd';
import { ISideMenuItemType, routeToMenuKeyMap } from '../config/sideMenuConfig';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IconHelp, IconInfoCircle, IconLogout, IconMenu, IconUser } from '@tabler/icons';
import { localeEjjeList, localeList } from '../data/intl-data';
import { useLocale } from '../stores/use-locale';
import { LoadingOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useLoader } from '../stores/use-loader';
import { Footer } from 'antd/es/layout/layout';
import { useAuth, useTenant } from '../stores/use-auth';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useSideMenu } from '../stores/use-sidemenu';
import SideMenuIcon from '../components/SideMenuIcon';

import _ from '../helpers/lodash';
import { FEATURE_FLAGS } from '../feature-flags';
import { images } from '../utils/images';
import { ENV, TENANT_DOMAIN_INDEX } from '../config/envConfig';
import { urlHelpers } from '../helpers';
const { Header, Content, Sider } = Layout;

interface IPrimaryLayoutProps {
  children: React.ReactNode;
}

const PrimaryLayout: React.FunctionComponent<IPrimaryLayoutProps> = ({ children }) => {
  const location = useLocation();
  const urlPart = location.pathname.split('/');
  //!First element is "/"
  const url = '/' + urlPart[1];

  //!Need to refactor Demo specific

  const selectedKey = routeToMenuKeyMap[url]?.menuKey;
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const { locale, setUserLocale } = useLocale(({ locale, setUserLocale }) => ({ locale, setUserLocale }));
  const { loading } = useLoader(({ loading }) => ({ loading }));
  const { sideMenuCollapsedDesktop, toggleDesktopSideMenuExpanded } = useSideMenu();
  const { isLoggedIn, resetAuthStates } = useAuth(({ isLoggedIn, resetAuthStates }) => ({
    isLoggedIn,
    resetAuthStates
  }));
  const navigate = useNavigate();

  const { tenentName } = useAuth(({ tenentName }) => ({
    tenentName
  }));

  const { userRoles, member_of, resourceAccess } = useTenant(({ userRoles, setUserRole, member_of, resourceAccess }) => ({
    userRoles,
    setUserRole,
    member_of,
    resourceAccess
  }));

  const detailedUserRole = (userRoles || []).filter((cv: string) => {
    return !(cv?.includes('default') || cv?.includes('offline') || cv?.includes('uma'));
  });

  React.useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
      navigate(0);
    }
  }, [isLoggedIn]);

  const getAccessKeysFromMemberOf = (memberOf: string[]): string[] => {
    if (_.isEmpty(memberOf)) return [];
    let accessKeys: string[] = ['home'];
    memberOf.forEach(str => {
      const parts = str.split('/');
      if (Array.isArray(parts) && parts[2]) accessKeys.push(parts[2]); // Assuming it is  present in second part
    });

    return [...accessKeys];
  };

  const accessKeys = [...getAccessKeysFromMemberOf(member_of), ...(userRoles || [])];

  const { activeKeys, setActiveKeys } = useSideMenu(({ activeKeys, setActiveKeys }) => ({ activeKeys, setActiveKeys }));
  const onOpenChange: MenuProps['onOpenChange'] = keys => {
    setActiveKeys(keys);
  };

  const recursivelyRenderMenuItem = (
    { label, id, route, children, icon, hidden }: ISideMenuItemType,
    level = 0,
    forMobile = false
  ): ItemType => {
    //!Check for children -->
    if (hidden) return null;
    let childMenuItems: ItemType[] = [];

    if (children?.length) {
      children.forEach(({ id, label, route, children, icon, hidden }) => {
        childMenuItems.push(
          recursivelyRenderMenuItem({ label, id, route, children, icon, hidden }, level + 1, forMobile)
        );
      });
    }

    const baseComponent = (
      <div className="h-full flex items-center">
        {route && (
          <Link to={route}>
            <Typography
              className={`text-${sideMenuCollapsedDesktop && !children?.length && level === 0 && !forMobile ? 'white' : 'black'
                } font-[Roboto] text-[14px] font-[400] leading-[22px] tracking-[0em] text-[left]`}
            >
              {label}
            </Typography>
          </Link>
        )}

        {!route && <Typography className='font-[Roboto] text-[14px] font-[400] leading-[22px] tracking-[0em] text-[left]'>{label}</Typography>}
      </div>
    );

    return {
      key: id,

      icon: icon ? (
        <Tooltip placement="top" overlayInnerStyle={{ fontWeight: 'bolder' }} title={label}>
          <div>
            <SideMenuIcon iconKey={icon} />
          </div>
        </Tooltip>
      ) : null,
      label: !sideMenuCollapsedDesktop ? (
        <Tooltip placement="right" title={label}>
          {baseComponent}
        </Tooltip>
      ) : (
        <>{baseComponent}</>
      ),
      ...(childMenuItems.length && { children: childMenuItems })
    };
  };

  const sideMenuItems: MenuProps['items'] = sideMenuConfig.sideMenu.sideMenuList.map(
    ({ label, id, route, children, icon, accessKey, hidden }) => {
      if (hidden) return null;

      const url = `${window.location.host}`.replace('www.', '');
      const urlParts = url.split('.');
      const tenantIndex = parseInt(TENANT_DOMAIN_INDEX as string);
      const tenantId = urlParts[tenantIndex];

      if (tenantId === 'ejje') {
        children?.forEach((data: any) => {
          if (resourceAccess && (resourceAccess?.ejje?.roles || [])?.includes(data?.id)) {
            data.hidden = false
          } else {
            data.hidden = true
          }
        })
      } else if (tenantId === 'rmg') {
        children?.forEach((data: any) => {
          if (resourceAccess && (resourceAccess?.rmg?.roles || [])?.includes(data?.id)) {
            data.hidden = false
          } else {
            data.hidden = true
          }
        })
      } else if (tenantId === 'bumblegifts' || tenantId === 'localhost:3000') {
        children?.forEach((data: any) => {
          if (resourceAccess && (resourceAccess?.bumblegifts?.roles || [])?.includes(data?.id)) {
            data.hidden = false
          } else {
            data.hidden = true
          }
        })
      }

      if (ENV === 'PRODUCTION') {
        let accessData = (accessKeys || []).includes(accessKey || '' as string)
        if (!FEATURE_FLAGS.ENABLE_SIDE_MENU_AUTHORIZATION && accessData) {
          return recursivelyRenderMenuItem({ id, label, route, children, icon, hidden });
        }
        else {
          return null;
        }
      }
      if (!FEATURE_FLAGS.ENABLE_SIDE_MENU_AUTHORIZATION || accessKeys.includes(accessKey as string) || !accessKey)
        return recursivelyRenderMenuItem({ id, label, route, children, icon, hidden });
      else return null;
    }
  );

  const sideMenuItemsForMobile = sideMenuConfig.sideMenu.sideMenuList.map(
    ({ label, id, route, children, icon, accessKey, hidden }) => {
      if (hidden) return null;

      //before Production release
      // if (!FEATURE_FLAGS.ENABLE_SIDE_MENU_AUTHORIZATION && accessKeys.includes(accessKey as string) && !accessKey)
      //   return recursivelyRenderMenuItem({ id, label, route, children, icon, hidden }, 0, true);
      // else return null;

      if (ENV === 'PRODUCTION') {
        let accessData = (accessKeys || []).includes(accessKey || '' as string)
        if (!FEATURE_FLAGS.ENABLE_SIDE_MENU_AUTHORIZATION && accessData) {
          return recursivelyRenderMenuItem({ id, label, route, children, icon, hidden });
        }
        else {
          return null;
        }
      }
      if (!FEATURE_FLAGS.ENABLE_SIDE_MENU_AUTHORIZATION || accessKeys.includes(accessKey as string) || !accessKey)
        return recursivelyRenderMenuItem({ id, label, route, children, icon, hidden });
      else return null;
    }
  );

  const handleOnLogOut = () => {
    // setAccessToken(null);
    // setIsLoggedIn(false);
    // setTenent('');
    // setUserRole([]);
    resetAuthStates();
  };

  const { tenantId } = urlHelpers.getTenantIdAndBusinessGroupIdFromUrl();

  const profileComponent = (
    <Dropdown
      trigger={['click']}
      menu={{
        items: [
          {
            key: '33',
            label: <>Retailer - {tenentName}</>
          },

          {
            key: '332',
            label: <>Roles - {detailedUserRole?.join(', ')}</>
          },
          {
            key: '1',
            label: (
              <div
                className="flex items-center gap-2"
                onClick={() => {
                  navigate('/My-Account');
                }}
              >
                <div className="mt-[5px]">
                  <IconUser size={14} />
                </div>{' '}
                <div> My Account</div>
              </div>
            )
          },
          {
            key: '2',
            label: (
              <div className="flex items-center gap-2">
                <div className="mt-[5px]">
                  <IconHelp size={14} />
                </div>{' '}
                <div>Help</div>
              </div>
            )
          },
          {
            key: '3',
            label: (
              <div className="flex items-center gap-2" role="button" onClick={() => handleOnLogOut()}>
                <div className="mt-[5px]">
                  <IconLogout size={14} />
                </div>{' '}
                <div>Logout</div>
              </div>
            )
          }
        ]
      }}
    >
      <div>
        <span className="text-base font-semibold">
          {' '}
          <Button type="ghost">
            <IconUser color="white" />
          </Button>
        </span>{' '}
      </div>
    </Dropdown>
  );

  const selectLocale = (
    <Select value={locale} onChange={value => setUserLocale(value)} className="w-[90px]" options={tenantId === 'rmg' ? localeList : localeEjjeList} />
  );

  return (
    <>
      <Header className="header-nebula">
        <section className="hidden lg:flex header__wrapper">
          <div className="flex items-center gap-5">
            {/* <img src={images?.newCBTIcon} alt="logo" style={{ height: '50px' }} /> */}
            <div className="header__logo">
              <Link to="/" style={{ textDecoration: 'none' }}>
                <span className="text-3xl text-white">Cloverbay Labs</span>
              </Link>
            </div>
          </div>
          <div className="header__items">
            <div>
              <span className="text-base font-semibold">
                <Tooltip title="About Us">
                  <Button type="ghost">
                    <IconInfoCircle color="white" />
                  </Button>
                </Tooltip>
              </span>
            </div>
            {profileComponent}

            {selectLocale}
          </div>
        </section>

        <section className="lg:hidden w-full">
          <div className="h-full flex items-center justify-between w-full">
            <div className="my-auto ml-4" role={'button'} onClick={() => setMobileDrawerOpen(true)}>
              <IconMenu color="white" size={32} />
            </div>
            <div className="flex items-center gap-5">
              <img src={images?.newCBTIcon} alt="logo" style={{ height: '30px' }} />
              <div className="header__logo">
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <span className="text-xl text-white">Cloverbay Labs</span>
                </Link>
              </div>
            </div>
            <div className="mr-4">{profileComponent}</div>
          </div>
          <Drawer
            width={380}
            title={
              <div className="flex justify-between">
                <div>
                  <h2>Clover Bay Labs</h2>
                </div>
                {selectLocale}
              </div>
            }
            placement="left"
            onClose={() => setMobileDrawerOpen(false)}
            open={mobileDrawerOpen}
          >
            <Menu
              defaultSelectedKeys={[selectedKey]}
              className="sidemenu__menu-mobile"
              mode="inline"
              selectedKeys={[routeToMenuKeyMap[url]?.menuKey]}
              theme="light"
              items={sideMenuItemsForMobile}
            />
          </Drawer>
        </section>
      </Header>

      <div className="flex">
        <Sider
          width={207}
          collapsible
          trigger={
            <section className="bg-[#001529] h-[50px]">
              <Button
                block
                onClick={() => {
                  toggleDesktopSideMenuExpanded();
                }}
                className="w-full rounded-none py-4"
                type="ghost"
                title={sideMenuCollapsedDesktop ? 'Minimize' : 'Maximize'}
                icon={
                  sideMenuCollapsedDesktop ? (
                    <RightOutlined style={{ color: 'white', fontSize: '16px' }} />
                  ) : (
                    <LeftOutlined style={{ color: 'white', fontSize: '16px' }} />
                  )
                }
              ></Button>
            </section>
          }
          collapsed={sideMenuCollapsedDesktop}
          theme="light"
          style={{ height: `calc(100vh - 3rem)` }}
          className="hidden overflow-y-auto overflow-x-hidden lg:flex flex-col justify-between relative"
        >
          {/* <div>Test</div> */}
          <div className="h-[calc(100vh-140px)] overflow-auto">
            <Menu
              // defaultSelectedKeys={[selectedKey]}
              className="py-2"
              onOpenChange={onOpenChange}
              mode="inline"
              selectedKeys={[routeToMenuKeyMap[url]?.menuKey]}
              openKeys={activeKeys}
              theme="light"
              items={sideMenuItems}
            />
          </div>
        </Sider>

        <Content
          id="main-content"
          style={{
            height: `calc(100vh - 3rem)`,
            background: location.pathname === '/' ? '#18162c' : '#f0f2f5',
            overflowY: 'auto',
            overflowX: 'hidden',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingTop: '1rem'
          }}
        >
          {children}
          {loading && (
            <div
              className="absolute top-0 left-0 h-full flex justify-center items-center w-full bg-black opacity-40"
              style={{ zIndex: 1001 }}
            >
              <Spin size="large" spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />} />
            </div>
          )}
          <Footer style={{ textAlign: 'center', padding: '2rem 0 1rem 0', textAlignLast: 'end' }}>
            <div className="mr-4">⚡ <span className='mix-blend-difference text-[#f0f8ff] font-extralight text-[16px]'>Powered By Clover Bay</span></div>
          </Footer>
        </Content>
      </div>
    </>
  );
};

export default PrimaryLayout;
