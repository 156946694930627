import * as React from 'react';
import {
  Card,
  Col,
  Row,
  Typography,
  Form,
  Input,
  Select,
  InputNumber,
  Button,
  Divider,
  FormInstance,
  Radio,
  Spin
} from 'antd';
import { currencyList } from '../../../../data/intl-data';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLogisticsNodes } from '../../../promotion-engine/hooks/drop-down-hooks/use-logistics-nodes';

const shippingChargeTypes: { label: string; value: string }[] = [
  { label: 'Free', value: 'FREE' },
  { label: 'Flat', value: 'FLAT' },
  { label: 'Base Weight', value: 'BASE_WEIGHT' },
  { label: 'Slab Weight', value: 'SLAB_WEIGHT' },
  { label: 'Order Price', value: 'ORDER_PRICE' }
];
interface ISnnCreationFormProps {
  handleSubmit?: () => void;
  formInstance: FormInstance;
  isUpdate?: boolean;
  readOnly?: boolean;
}

const SnnCreationForm: React.FunctionComponent<ISnnCreationFormProps> = ({
  handleSubmit,
  formInstance,
  isUpdate,
  readOnly
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { debouncedFetchUserDropdownOptions, nodeDropdownFetching, nodeDropdownOptions } = useLogisticsNodes();

  return (
    <Form layout="vertical" onFinish={handleSubmit} form={formInstance} disabled={readOnly}>
      <Row gutter={12}>
        <Col xs={24} md={8} lg={6}>
          <Form.Item name={'snn_name'} label="SNN Name">
            <Input size="large" placeholder="SNN Name"></Input>
          </Form.Item>
        </Col>
        <Col xs={24} md={8} lg={6}>
          <Form.Item name={'node_group'} label="Node Group">
            <Select
              mode="multiple"
              allowClear
              size="large"
              filterOption={false}
              placeholder="Search nodes"
              notFoundContent={nodeDropdownFetching ? <Spin size="small" /> : null}
              onSearch={async searchTerm => {
                if (searchTerm) await debouncedFetchUserDropdownOptions(searchTerm);
              }}
              options={nodeDropdownOptions}
              // options={[
              //   { label: 'CS-Bangalore-1', value: 'bff22a92-d469-45a9-ba1e-77b46dac14e5' },
              //   { label: 'CS-Bangalore-23', value: '6e8ede55-0221-46db-8205-e0233fc64e6a' },
              //   { label: 'Chennai-2', value: '7ed2bc66-6571-4b3b-9aa6-57add3b019af' },
              //   { label: 'Bangalore-4', value: '487d9cfd-b52f-48c3-b2d0-cdeb0bcbcb35' },
              //   { label: 'Pune-3', value: 'f07dfa45-2a7a-4324-9f47-03dc55b83511' },
              //   { label: 'Pune-2', value: 'e36ac665-73d1-45d7-9c17-e950c76ae393' }
              // ]}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={8} lg={6}>
          <Form.Item name={'is_active'} label="Status">
            <Radio.Group>
              <Radio value={true}>Active ?</Radio>
              <Radio value={false}>InActive ?</Radio>
            </Radio.Group>
            {/* <Select
              size="large"
              placeholder="Status"
              options={[
                { label: 'Active', value: true },
                { label: 'In Active', value: false }
              ]}
            ></Select> */}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={24} md={16} lg={12}>
          <Form.Item name={'description'} label="Description">
            <Input.TextArea size="large" rows={2} placeholder="Enter description"></Input.TextArea>
          </Form.Item>
        </Col>
      </Row>
      <section>
        <Typography.Title level={5} className="text-[#2e2a5b]">
          Shipping Charges
        </Typography.Title>

        <Row gutter={12}>
          <Col xs={24} md={8} lg={6}>
            <Form.Item name={['shipping_charge', 'name']} label="Charge Name">
              <Input size="large" placeholder="SNN Name"></Input>
            </Form.Item>
          </Col>

          <Col xs={24} md={8} lg={6}>
            <Form.Item name={['currency']} label="Currency">
              <Select allowClear size="large" placeholder="Select currency" options={currencyList}></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={8} lg={6}>
            <Form.Item name={['shipping_charge', 'shipping_charge_type']} label="Shipping Charge Type">
              <Select allowClear size="large" placeholder="Select type" options={shippingChargeTypes}></Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) =>
            prev?.shipping_charge?.shipping_charge_type !== curr.shipping_charge?.shipping_charge_type
          }
        >
          {form => {
            const { shipping_charge } = form.getFieldsValue();
            const chargeType = shipping_charge?.shipping_charge_type;

            if (chargeType === 'FLAT') {
              return (
                <>
                  <Typography.Title level={5} className="text-[#2e2a5b]">
                    Flat charge configuration
                  </Typography.Title>
                  <Row gutter={12}>
                    <Col xs={24} md={8} lg={6}>
                      <Form.Item name={['flat_charge_amount']} label="Flat charge amount">
                        <InputNumber size="large" className="w-full" placeholder="Enter Amount" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={8} lg={6}>
                      <Form.Item name={['flat_charge_fraction']} label="Flat charge Fraction">
                        <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            }

            if (chargeType === 'BASE_WEIGHT') {
              return (
                <>
                  <Typography.Title level={5} className="text-[#2e2a5b]">
                    Base weight charge configuration
                  </Typography.Title>
                  <>
                    <Row gutter={12}>
                      <Col xs={24} md={8} lg={6}>
                        <Form.Item name={['base_weight_in_kg']} label="Base weight in KG">
                          <InputNumber size="large" className="w-full" placeholder="Enter weight" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} lg={6}>
                        <Form.Item name={['base_weight_charge_amount']} label="Amount">
                          <InputNumber size="large" className="w-full" placeholder="Enter Amount" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} lg={6}>
                        <Form.Item name={['base_weight_charge_fraction']} label="Fraction">
                          <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col xs={24} md={8} lg={6}>
                        <Form.Item name={['additional_weight_in_kg']} label="Additional weight in KG">
                          <InputNumber size="large" className="w-full" placeholder="Enter weight" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} lg={6}>
                        <Form.Item name={['additional_weight_charge_amount']} label="Amount">
                          <InputNumber size="large" className="w-full" placeholder="Enter Amount" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} lg={6}>
                        <Form.Item name={['additional_base_weight_charge_fraction']} label="Fraction">
                          <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                </>
              );
            }

            if (chargeType === 'SLAB_WEIGHT') {
              return (
                <>
                  <Typography.Title level={5} className="text-[#2e2a5b]">
                    Slab weight charge configuration
                  </Typography.Title>
                  <Form.List name="slab_weight_conditions">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => {
                          return (
                            <React.Fragment key={key}>
                              <Row>
                                <Col xs={12}>
                                  <Divider orientation="left" plain>
                                    <span className="text-lg">Condition - {index + 1} </span>
                                  </Divider>
                                </Col>
                              </Row>

                              <Row gutter={12}>
                                <Col xs={12} md={5}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'min_weight_in_kg']}
                                    rules={[{ required: true, message: 'Min weight in KG is required' }]}
                                    label="Min weight in KG"
                                  >
                                    <Input className="w-full" size="large" placeholder="Enter weight"></Input>
                                  </Form.Item>
                                </Col>
                                <Col xs={12} md={5}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'max_weight_in_kg']}
                                    rules={[{ required: true, message: 'Max weight in KG is required' }]}
                                    label="Max weight in KG"
                                  >
                                    <Input className="w-full" size="large" placeholder="Enter weight"></Input>
                                  </Form.Item>
                                </Col>{' '}
                                <Col xs={12} md={5}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'cost']}
                                    rules={[{ required: true, message: 'Cost is required' }]}
                                    label="Cost"
                                  >
                                    <Input className="w-full" size="large" placeholder="Enter cost"></Input>
                                  </Form.Item>
                                </Col>
                                <Col xs={24} md={8} lg={6}>
                                  <Form.Item name={[name, 'fraction']} label="Base charge Fraction">
                                    <InputNumber size="large" className="w-full" placeholder="Enter Fraction" />
                                  </Form.Item>
                                </Col>
                                <div className="ml-2 my-2 flex justify-center items-center">
                                  <DeleteOutlined
                                    className="text-red-500 text-xl"
                                    title="Delete condition"
                                    onClick={() => remove(name)}
                                  />
                                </div>
                              </Row>
                            </React.Fragment>
                          );
                        })}

                        <Form.Item>
                          <Row justify={'start'}>
                            <Col xs={24} md={4}>
                              <Button onClick={() => add()} icon={<PlusOutlined />}>
                                <BoldButtonLabel labelText="Add condition" />
                              </Button>
                            </Col>
                          </Row>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </>
              );
            }

            if (chargeType === 'ORDER_PRICE') {
              return (
                <>
                  <Typography.Title level={5} className="text-[#2e2a5b]">
                    Order price charge configuration
                  </Typography.Title>

                  <Form.List name="order_price_conditions">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => {
                          return (
                            <React.Fragment key={key}>
                              <Row>
                                <Col xs={12}>
                                  <Divider orientation="left" plain>
                                    <span className="text-lg">Condition - {index + 1} </span>
                                  </Divider>
                                </Col>
                              </Row>

                              <Row gutter={12}>
                                <Col xs={12} md={5}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'min_price']}
                                    rules={[{ required: true, message: 'Min order price is required' }]}
                                    label="Minimum Order Price"
                                  >
                                    <Input className="w-full" size="large" placeholder="Enter price"></Input>
                                  </Form.Item>
                                </Col>
                                <Col xs={12} md={5}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'max_price']}
                                    rules={[{ required: true, message: 'Max order price is required' }]}
                                    label="Maximum Order Price"
                                  >
                                    <Input className="w-full" size="large" placeholder="Enter price"></Input>
                                  </Form.Item>
                                </Col>{' '}
                                <Col xs={12} md={5}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'cost']}
                                    rules={[{ required: true, message: 'Cost is required' }]}
                                    label="Cost"
                                  >
                                    <Input className="w-full" size="large" placeholder="Enter cost"></Input>
                                  </Form.Item>
                                </Col>
                                <Col xs={12} md={5}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'fraction']}
                                    rules={[{ required: true, message: 'Fraction is required' }]}
                                    label="Fraction"
                                  >
                                    <Input className="w-full" size="large" placeholder="Enter Fraction"></Input>
                                  </Form.Item>
                                </Col>
                                <div className="ml-2 my-2 flex justify-center items-center">
                                  <DeleteOutlined
                                    className="text-red-500 text-xl"
                                    title="Delete condition"
                                    onClick={() => remove(name)}
                                  />
                                </div>
                              </Row>
                            </React.Fragment>
                          );
                        })}

                        <Form.Item>
                          <Row justify={'start'}>
                            <Col xs={24} md={4}>
                              <Button onClick={() => add()} icon={<PlusOutlined />}>
                                <BoldButtonLabel labelText="Add condition" />
                              </Button>
                            </Col>
                          </Row>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </>
              );
            }

            return null;
          }}
        </Form.Item>
      </section>
      <Row gutter={[12, 12]}>
        {!readOnly && (
          <Col xs={24} md={8} lg={6}>
            <Button size="large" htmlType="submit" block type="primary">
              <BoldButtonLabel labelText={isUpdate ? 'Update' : 'Create'} />
            </Button>
          </Col>
        )}
        <Col xs={24} md={8} lg={6}>
          <Button
            size="large"
            disabled={false}
            block
            onClick={() => {
              if (searchParams.get('backUrl')) {
                navigate(searchParams.get('backUrl') as string);
              } else {
                navigate('/logistics/snn');
              }
            }}
          >
            <BoldButtonLabel labelText="Go Back" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SnnCreationForm;
