import * as React from 'react';
import { useAuth } from '../../stores/use-auth';
import { Link, useNavigate } from 'react-router-dom';
import { IconInfoCircle } from '@tabler/icons';
import { Header } from 'antd/es/layout/layout';
import { Button, Card, Form, Input, Select, Tabs, Tooltip } from 'antd';
import { localeEjjeList, localeList } from '../../data/intl-data';
import { useLocale } from '../../stores/use-locale';
import BoldButtonLabel from '../../components/BoldButtonLabel';

import { loggingIn } from '../../services/login.services';
import _ from '../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';

import { urlHelpers } from '../../helpers';
import { images } from '../../utils/images';
import './login.css'
import type { TabsProps } from 'antd';
import { InfoCircleOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';

interface ILoginProps { }

const Login: React.FunctionComponent<ILoginProps> = () => {
  const [loginForm] = Form.useForm();
  const { isLoggedIn, setIsLoggedIn, accessTokenData, setAccessToken, setBussinessGroupName, setTenent, tenantSecret } =
    useAuth(
      ({
        isLoggedIn,
        setIsLoggedIn,
        accessTokenData,
        setAccessToken,
        setBussinessGroupName,
        setTenent,
        tenantSecret
      }) => ({
        isLoggedIn,
        setIsLoggedIn,
        accessTokenData,
        setAccessToken,
        setBussinessGroupName,
        setTenent,
        tenantSecret
      })
    );
  const { locale, setUserLocale } = useLocale(({ locale, setUserLocale }) => ({ locale, setUserLocale }));

  const navigate = useNavigate();

  const { businessGroupId, tenantId } = urlHelpers.getTenantIdAndBusinessGroupIdFromUrl();


  const TabContent1 = () => {
    return (
      <Form form={loginForm} layout="vertical" onFinish={handleOnLogin}>
        <Form.Item
          label={<span className="font-semibold">Retailer Code</span>}
          name={'tenant_id'}
          initialValue={tenantId}
          required
          className='mb-2'
          rules={[{ required: true, message: "This field can't be empty" }]}
        >
          <Input placeholder="Retailer Code" size="large" prefix={<UserOutlined style={{ color: '#1890FF' }} />} />
        </Form.Item>
        <Form.Item
          label={<span className="font-semibold">Username</span>}
          name={'username'}
          required
          className='mb-2'
          rules={[{ required: true, message: "This field can't be empty" }]}
        >
          <Input placeholder="                        Username" size="large" prefix={<UserOutlined style={{ color: '#1890FF' }} />} />
        </Form.Item>
        <Form.Item
          label={<span className="font-semibold">Password</span>}
          name={'password'}
          className="mb-0"
          required
          rules={[{ required: true, message: "This field can't be empty" }]}
        >
          {/* <LockOutlined /> */}
          <Input.Password type="password" placeholder="                         Password" size="large" prefix={<LockOutlined style={{ color: '#1890FF' }} />} />
        </Form.Item>
        <div className="flex items-center justify-end mb-4 mt-2">
          {/* <Button type="link">Forgot Password ?</Button> */}
        </div>
        <div className="w-[114px]">
          <Button type="primary" block size="large" htmlType="submit" >
            <BoldButtonLabel labelText="Sign In" />
          </Button>
        </div>
      </Form>
    )
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Login',
      children: <TabContent1 />,
    },
  ];

  const handleOnLogin = async () => {
    const { tenant_id, username, password } = loginForm.getFieldsValue();
    const body = {
      // realm: "string",
      client_secret: tenantSecret,
      scope: 'openid-connect',
      username: username,
      password: password,
      grant_type: 'password'
    };
    const { data, errors } = await loggingIn.getLogin(body, tenant_id, businessGroupId);
    if (_.isEmpty(errors)) {
      setIsLoggedIn(true);
      setAccessToken(data.access_token);
      //!We need to set tenant id and business group id  ->

      setTenent(tenant_id);
      //!This is to be set using sub domain
      setBussinessGroupName(businessGroupId);

      navigate(0);
      displaySuccessNotification({ message: 'Login Success' });
      navigate('/');
    } else {
      let errorData = [
        {
          message: 'Either Retailer code or Username or Password is Incorrect'
        }
      ];
      displayErrorNotifications(errorData);
    }
  };

  React.useEffect(() => {
    if (isLoggedIn) navigate('/');
  }, [isLoggedIn]);

  const onTabChange = (key: string) => {
    console.log(key);
  };

  return (
    <>
      <div className="main">
        <div className='absolute top-10 right-10 md: right-5'>
          {/* <img src={images.PlanetButton} alt='location' /> */}
          <div>
            <Select value={locale} onChange={value => setUserLocale(value)} className="w-[90px]" options={tenantId === 'rmg' ? localeList : localeEjjeList} />
          </div>
        </div>
        <div className='flex flex-col gap-8 mb-8'>
          <div className='flex flex-col items-center'>
            <img src={images.cbt_background} alt='cbt_logo' className="h-[77px] w-[156px]" />
          </div>
          <div className="w-[360px]">
            <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />
          </div>
        </div>
      </div>
      <div className='absolute bottom-10 right-10'>
        <img src={images.Helpicon} alt='Help' />
      </div>
    </>
  );
};

export default Login;
