import httpClient from '../http/http.client';

const nodeUrls = {
  getPlansUri: () => `${'x'}/v1/plans`,
  createNodeUri: () => `${'x'}/v1/node`
};

const mockPlanList: any = {
  nebula: [
    {
      name: 'Plan 1',

      description: 'Test Plan',

      interval: 'MONTHLY',

      interval_count: '1',

      currency: 'INR',

      amount: 100000,

      trial_days: 10,

      taxable: true,

      tax_percent: '3',

      overage: '0.01',

      api_limit: '20000',

      domain: 'domain1',

      'business_ grp_id': '',

      tenant_id: '',

      plan_feature: [
        {
          feature_name: 'Earn & Burn'
        },

        {
          feature_name: 'Action Oriented'
        },

        {
          feature_name: 'Tiered'
        }
      ]
    }
  ]
};

const getAllPlansGroupedByDomain = async (domain?: string) => {
  const url = nodeUrls.getPlansUri();

  return {
    data: mockPlanList,
    errors: []
  };
};

export const cbtProductService = {
  getAllPlansGroupedByDomain
};
