import * as React from 'react';
import ShippingProfileForm from '../../../components/shipping-profile-form';
import { Card, Col, Form, Row, Typography } from 'antd';
import PrimaryLayout from '../../../../../layouts/primary-layout';
import { useLoader } from '../../../../../stores/use-loader';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../../helpers/toast.helpers';
import { ShippingService } from '../../../services';
import _ from 'lodash';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

interface IShippingProfileCreateProps {}

const ShippingProfileCreate: React.FunctionComponent<IShippingProfileCreateProps> = props => {
  const [createForm] = Form.useForm();
  const { setLoading } = useLoader();
  const { id } = useParams();
  const navigate = useNavigate()
  const [selectedProduct, setSelectedProduct] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>([]);
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    setIntialLoadForm();
  }, []);

  const setIntialLoadForm = () => {
    createForm.setFieldsValue({
      shipping_profile_type: 'CUSTOM',
      is_active: true,
      is_local_delivery_available: true
    });
  };

  const getSkus = (skuData: any) => {
    let data = skuData?.map((item: any) => ({
      category: item?.category,
      sku_id: item?.sku_id
    }));
    return data || [];
  };

  const createBodyData = (form: any) => {
    const {
      shipping_profile_name,
      node_group,
      shipping_charge,
      is_active,
      description,
      flat_charge_amount,
      currency,
      flat_charge_fraction,
      base_weight_in_kg,
      base_weight_charge_amount,
      base_weight_charge_fraction,
      additional_weight_in_kg,
      additional_weight_charge_amount,
      additional_base_weight_charge_fraction,
      slab_weight_conditions,
      order_price_conditions,
      is_local_delivery_available
    } = form;
    let shippingType = shipping_charge?.shipping_charge_type;
    let request = {};
    if (shippingType === 'FREE') {
      request = {
        snn_id: id,
        is_local_delivery_available:is_local_delivery_available,
        categories: selectedCategory,
        skus: getSkus(selectedProduct),
        description: description,
        shipping_profile_name: shipping_profile_name,
        node_group: node_group,
        shipping_charge: {
          name: shipping_charge.name,
          shipping_charge_type: shipping_charge.shipping_charge_type
        },
        is_active: is_active
      };
    } else if (shippingType === 'FLAT') {
      request = {
        snn_id: id,
        is_local_delivery_available:is_local_delivery_available,
        categories: selectedCategory,
        skus: getSkus(selectedProduct),
        description: description,
        shipping_profile_name: shipping_profile_name,
        node_group: node_group,
        shipping_charge: {
          name: shipping_charge.name,
          shipping_charge_type: shipping_charge.shipping_charge_type,
          flat_charge_config: {
            amount: parseInt(flat_charge_amount),
            currency: currency,
            fraction: parseInt(flat_charge_fraction)
          }
        },
        is_active: is_active
      };
    } else if (shippingType === 'BASE_WEIGHT') {
      request = {
        snn_id: id,
        is_local_delivery_available:is_local_delivery_available,
        categories: selectedCategory,
        skus: getSkus(selectedProduct),
        description: description,
        shipping_profile_name: shipping_profile_name,
        node_group: node_group,
        shipping_charge: {
          name: shipping_charge.name,
          shipping_charge_type: shipping_charge.shipping_charge_type,
          base_weight_charge_config: {
            additional_weight: {
              additional_weight_in_kg: additional_weight_in_kg,
              cost: {
                amount: additional_weight_charge_amount,
                currency: currency,
                fraction: additional_base_weight_charge_fraction
              }
            },
            base_weight: {
              base_weight_in_kg: base_weight_in_kg,
              cost: {
                amount: base_weight_charge_amount,
                currency: currency,
                fraction: base_weight_charge_fraction
              }
            }
          }
        },
        is_active: is_active
      };
    } else if (shippingType === 'SLAB_WEIGHT') {
      request = {
        snn_id: id,
        is_local_delivery_available:is_local_delivery_available,
        categories: selectedCategory,
        skus: getSkus(selectedProduct),
        description: description,
        shipping_profile_name: shipping_profile_name,
        node_group: node_group,
        shipping_charge: {
          name: shipping_charge.name,
          shipping_charge_type: shipping_charge.shipping_charge_type,
          slab_weight_slab_charge_config: getSlabWeightData(slab_weight_conditions, currency)
        },
        is_active: is_active
      };
    } else if (shippingType === 'ORDER_PRICE') {
      request = {
        snn_id: id,
        is_local_delivery_available:is_local_delivery_available,
        categories: selectedCategory,
        skus: getSkus(selectedProduct),
        description: description,
        shipping_profile_name: shipping_profile_name,
        node_group: node_group,
        shipping_charge: {
          name: shipping_charge.name,
          shipping_charge_type: shipping_charge.shipping_charge_type,
          order_price_slab_charge_config: getOrderPriceData(order_price_conditions, currency)
        },
        is_active: is_active
      };
    }

    return request;
  };

  const getSlabWeightData = (slabData: any, currency: any) => {
    let returnSlab = slabData?.map((data: any) => {
      return {
        cost: {
          amount: parseInt(data.cost),
          currency: currency,
          fraction: data.fraction
        },
        max_weight_in_kg: parseInt(data.max_weight_in_kg),
        min_weight_in_kg: parseInt(data.min_weight_in_kg)
      };
    });
    return returnSlab;
  };

  const getOrderPriceData = (orderPriceData: any, currency: any) => {
    let returnOrder = orderPriceData?.map((data: any) => {
      return {
        cost: {
          amount: parseInt(data.cost),
          currency: currency,
          fraction: parseInt(data.fraction)
        },
        currency: currency,
        max_price: parseInt(data.max_price),
        min_price: parseInt(data.min_price)
      };
    });
    return returnOrder;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const bodyData = createBodyData(createForm.getFieldsValue());
    const { errors } = await ShippingService.createShippingCharges(bodyData);
    if (_.isEmpty(errors)) {
      if (searchParams.get('backUrl')) {
        navigate(searchParams.get('backUrl') as string);
      } else {
        navigate(-1);
      }
      displaySuccessNotification({ message: 'SNN Created Successfully!' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnCloseCategory = async (product: any, category: any) => {
    setLoading(true);
    setSelectedProduct(product);
    setSelectedCategory(category);
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row gutter={[24, 12]} align={'middle'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Create Shipping Profile
              </Typography.Title>
            </Col>
          </Row>

          <ShippingProfileForm
            formInstance={createForm}
            onFinish={handleSubmit}
            handleOnCloseCategory={handleOnCloseCategory}
            mandtCreation
          />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default ShippingProfileCreate;
