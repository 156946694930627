import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  FormInstance,
  InputNumber,
  Radio,
  Row,
  Select,
  Table,
  Tabs,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../components/ActionButton';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import TenantInfo from '../../../components/TenantIdInfo';
import CustomPagination from '../../../components/custom-pagination';
import { convertQueryStringToObj, objectHelpers, urlHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { promotionService } from '../services/promotion.service';
import { IPromotionItem, IPromotionsResponse } from '../types/promotion-types';
import './promotion.css';
import TabPane from 'antd/es/tabs/TabPane';

interface IPromotionListingPageProps {
  promo: string;
  promoName: string;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof IPromotionItem;
  record: IPromotionItem;
  handleSave: (record: IPromotionItem) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = React.useState(false);
  const inputRef = React.useRef<any>(null);
  const form = React.useContext(EditableContext)!;

  React.useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (dataIndex === 'priority') form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    if (dataIndex === 'start_date' || dataIndex === 'end_date') {
      form.setFieldsValue({ [dataIndex]: dayjs(record[dataIndex]) });
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    if (editing) {
      if (dataIndex === 'priority')
        childNode = (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`
              }
            ]}
          >
            <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} min={0} />
          </Form.Item>
        );
      else if (dataIndex === 'start_date' || dataIndex === 'end_date') {
        childNode = (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`
              }
            ]}
          >
            <DatePicker ref={inputRef} onBlur={save} />
          </Form.Item>
        );
      }
    } else {
      childNode = (
        <div className="cursor-pointer" onClick={toggleEdit}>
          {children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

const rewardTypeMap: Record<string, any[]> = {
  PROMO: [
    { label: 'Discounts', value: 'DISCOUNT' },
    { label: 'Volume Discounts', value: 'VOLUME_DISCOUNT' },
    { label: 'Points', value: 'POINTS' }
  ],
  COUPON: [{ label: 'Discounts', value: 'DISCOUNT' }],
  BXGY: [{ label: 'Products', value: 'PRODUCT' }]
};

const PromotionListingPage: React.FunctionComponent<IPromotionListingPageProps> = ({ promo, promoName }) => {
  const navigate = useNavigate();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const [promotionListResponse, setPromotionListResponse] = React.useState({} as IPromotionsResponse);
  const [promotionFilterForm] = Form.useForm();
  let keyData = promo === 'LOYT_REWARD' ? 'MANUAL' : 'AUTOMATIC'
  const [key, setKey] = React.useState(keyData)
  const columns: any = [
    {
      title: 'Title',
      dataIndex: 'title',
      align: 'center',
      sorter: (a: any, b: any) => a.title.localeCompare(b.title)
    },
    {
      title: 'Trigger',
      dataIndex: 'trigger',
      align: 'center',
      sorter: (a: any, b: any) => a.trigger.localeCompare(b.trigger)
    },
    {
      title: 'Applies To',
      dataIndex: 'application_type',
      align: 'center',
      sorter: (a: any, b: any) => a.application_type.localeCompare(b.application_type)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      sorter: (a: any, b: any) => a.status.localeCompare(b.status)
    },
    {
      title: 'Rank',
      width: '10%',
      align: 'center',
      editable: true,
      dataIndex: 'priority',
      sorter: (a: any, b: any) => a.priority - b.priority,
      render(value: any, record: any, index: any) {
        return (
          <>
            {record.priority} <EditOutlined style={{ color: '#5885af', fontSize: '16px' }} />
          </>
        );
      }
    },
    {
      title: 'Reward Type',
      dataIndex: 'reward_type',
      align: 'center',
      sorter: (a: any, b: any) => a.reward_type.localeCompare(b.reward_type)
    },
    {
      title: 'POS',
      dataIndex: 'is_pos',
      // sorter: (a: any, b: any) => a.is_pos - b.is_pos,
      render(value: any, record: any, index: any) {
        return <>{record.is_pos + ''}</>;
      },
      align: 'center'
    },
    {
      title: 'Website',
      render(value: any, record: any, index: any) {
        return <>{record.is_online + ''}</>;
      },
      align: 'center'
    },
    {
      title: 'Start Date',
      width: '16%',
      dataIndex: 'start_date',
      render(value: any, record: any, index: any) {
        return (
          <>
            {record.start_date} <EditOutlined style={{ color: '#5885af', fontSize: '16px' }} />
          </>
        );
      },
      align: 'center',
      editable: true
    },
    {
      title: 'End Date',
      width: '16%',
      dataIndex: 'end_date',
      render(value: any, record: any, index: any) {
        return (
          <>
            {record.end_date} <EditOutlined style={{ color: '#5885af', fontSize: '16px' }} />
          </>
        );
      },
      align: 'center',
      editable: true
    },
    {
      title: 'Items',
      render(value: any, record: any, index: any) {
        return (
          key === "MANUAL" && (<Button type="link" onClick={() => handleClickCouponList(record)}>
            {record.coupon_quantity_to_generate} Coupons
          </Button>)
        );
      },
      align: 'center'
    },
    {
      title: 'Action',
      width: '15%',
      render(value: any, record: any, index: any) {
        return (
          <section className="flex flex-wrap justify-center">
            <ActionButton action="VIEW" title="View" onClick={() => handleViewPromotion(record.id)}></ActionButton>
            {searchParams.get('promotion_type') === 'COUPON' && (
              <ActionButton
                action="GENERATE_COUPON"
                title="Generate Coupon"
                onClick={() => {
                  handleClickCouponGenerate(record);
                }}
              />
            )}

            <ActionButton
              action="CREATE_NEW_VERSION"
              title="Edit"
              onClick={() => handleEditPromotion(record.id)}
            ></ActionButton>
          </section>
        );
      },
      align: 'center'
    },
    {
      title: 'Status Update',
      width: '15%',
      render(value: any, record: IPromotionItem, index: any) {
        let actions: { label: string; action: string }[] = [];

        if (record.status === 'OPEN') {
          actions.push({ label: 'Activate', action: 'ACTIVE' });
        }
        if (record.status === 'ACTIVE') {
          actions.push(
            ...[
              {
                label: 'On Hold',
                action: 'ON_HOLD'
              },
              { label: 'Close', action: 'CLOSED' }
            ]
          );
        }
        if (record.status === 'SCHEDULED') {
          actions.push(
            ...[
              {
                label: 'Open',
                action: 'OPEN'
              }
            ]
          );
        }

        if (record.status === 'ON_HOLD') {
          actions.push({ label: 'Activate', action: 'ACTIVE' });
        }
        return (
          <section className="flex flex-wrap justify-center">
            {actions.map(({ action, label }) => (
              <Button key={action} type="link" onClick={() => handleStatusChange(action, record.id)}>
                {label}
              </Button>
            ))}
          </section>
        );
      },
      align: 'center'
    }
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);

  const getPageNumberFromOffset = (offset: number) => {
    if (offset === 0) return 1;
    let pageNumber = offset / 10 + 1;
    return pageNumber || 1;
  };

  const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;

  const loadAllPromotions = async () => {
    setLoading(true);
    promotionFilterForm.setFieldsValue({
      promotion_type: promo,
      order_by: 'desc',
      promo_header_type: promo !== 'LOYT_REWARD' ? 'AUTOMATIC' : 'MANUAL',
      next_order_applicable: false
    });
    handleSearch();
    setLoading(false);
  };

  React.useEffect(() => {
    if (_.isEmpty(queryStringObj)) loadAllPromotions();
    else loadPromotionsBasedOnQuery();
  }, []);

  const loadPromotionsBasedOnQuery = async () => {
    promotionFilterForm.setFieldsValue(queryStringObj);
    handleSearch(offset);
  };
  const handleEditPromotion = (id: string) => {
    const backUrl = urlHelpers.getBackUrl(searchParams);
    const params = new URLSearchParams({ backUrl });
    navigate(`/promotion-engine/promotions/${id}/edit?${params.toString()}`);
  };
  const handleStatusChange = async (status: string, promotionId: number) => {
    setLoading(true);
    const { errors } = await promotionService.patchPromotionStatus(promotionId, status);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Promotion status updated successfully!' });
      await handleSearch(offset);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleTabChange = async (offset = 0, keyVal: any) => {
    setLoading(true);
    const formValues = promotionFilterForm.getFieldsValue();
    setPromotionListResponse({} as IPromotionsResponse);
    const sortKey = formValues?.order_by;
    const sortValue = formValues?.sort_by?.toString();
    const dataObject = {
      [sortKey]: sortValue,
      promo_header_type: keyVal,
      type: promo,
      ...formValues
    };
    delete dataObject.order_by;
    delete dataObject.sort_by;
    const filteredFormValues = { ...objectHelpers.deleteUndefinedValuesFromObject(dataObject), offset: offset + '' };
    setSearchParams(filteredFormValues);

    const params = {
      ...filteredFormValues,
      offset,
      limit: 10
    };
    const { data, errors } = await promotionService.getPromotionList(params);
    if (_.isEmpty(errors)) {
      const transformedData = data?.data?.map((item: any) => {
        return {
          ...item,
          key: item?.id
        };
      });
      const transformedResponse: any = {
        ...data,
        data: {
          data: transformedData,
          count: data.count
        }
      };

      setPromotionListResponse(transformedResponse);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = promotionFilterForm.getFieldsValue();
    setPromotionListResponse({} as IPromotionsResponse);
    const sortKey = formValues?.order_by;
    const sortValue = formValues?.sort_by?.toString();
    const dataObject = {
      [sortKey]: sortValue,
      promo_header_type: promo !== 'LOYT_REWARD' ? key : 'MANUAL',
      type: promo,
      ...formValues
    };
    delete dataObject.order_by;
    delete dataObject.sort_by;
    const filteredFormValues = { ...objectHelpers.deleteUndefinedValuesFromObject(dataObject), offset: offset + '' };
    setSearchParams(filteredFormValues);

    const params = {
      ...filteredFormValues,
      offset,
      limit: 10
    };
    const { data, errors } = await promotionService.getPromotionList(params);
    if (_.isEmpty(errors)) {
      const transformedData = data?.data?.map((item: any) => {
        return {
          ...item,
          key: item?.id
        };
      });
      const transformedResponse: any = {
        ...data,
        data: {
          data: transformedData,
          count: data.count
        }
      };

      setPromotionListResponse(transformedResponse);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleViewPromotion = async (id: string) => {
    navigate(`/promotion-engine/promotions/${id}`);
  };

  const handlePageChange = async (current_page: number) => {
    setCurrentPage(current_page);
    const offsetValue = (current_page - 1) * 10;
    await handleSearch(offsetValue);
  };

  const [currentPage, setCurrentPage] = React.useState(getPageNumberFromOffset(offset));

  const getEditedKey = (previousRowValues: IPromotionItem, currentRowValues: IPromotionItem): string => {
    for (const key in previousRowValues) {
      if (previousRowValues[key as keyof IPromotionItem] !== currentRowValues[key as keyof IPromotionItem]) {
        return key;
      }
    }

    return '';
  };

  const editingActions: Record<string, any> = {
    priority: (id: string, value: string) => promotionService.patchPromotionPriority(id, value),
    start_date: (id: string, value: string) => promotionService.patchPromotionStartDate(id, value),
    end_date: (id: string, value: string) => promotionService.patchPromotionEndDate(id, value)
  };

  const handleSave = async (row: IPromotionItem) => {
    const transformedRow = { ...row };
    if (typeof transformedRow.start_date !== 'string')
      transformedRow.start_date = dayjs(transformedRow.start_date).format('YYYY-MM-DD');

    if (typeof transformedRow.end_date !== 'string')
      transformedRow.end_date = dayjs(transformedRow.end_date).format('YYYY-MM-DD');

    const newData = [...promotionListResponse.data.data];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];

    const promotionId = row.id;
    const editedKey = getEditedKey(item, transformedRow);

    if (editedKey === '') return;

    setLoading(true);
    const { errors } = await editingActions[editedKey](promotionId, transformedRow[editedKey as keyof IPromotionItem]);
    setLoading(false);

    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
      return;
    }

    newData.splice(index, 1, {
      ...item,
      ...transformedRow
    });
    setPromotionListResponse(response => {
      return {
        ...response,
        data: {
          ...response.data,
          data: newData
        }
      };
    });

    displaySuccessNotification({ message: 'Promotion update success' });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };
  const columnsEdited = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IPromotionItem) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    };
  });

  const handleClickCouponList = (row: IPromotionItem) => {
    const { reward_type, promotion_type, title, id } = row;
    const params = {
      reward_type,
      promotion_type,
      title,
      offset: '0',
      id: id + ''
    };

    const paramString = new URLSearchParams(params).toString();

    navigate(`/promotion-engine/coupons?${paramString}`);
  };

  const handleClickCouponGenerate = (row: IPromotionItem) => {
    const { promotion_type, id } = row;
    const params = {
      promotion_type,
      back_to_promotion_listing: 'true',
      offset: '0',
      id: id + ''
    };

    const paramString = new URLSearchParams(params).toString();

    navigate(`/promotion-engine/promotions/${row.id}/generate-coupons?${paramString}`);
  };

  const handleOnTabClick = (val: any) => {
    setKey(val)
    handleTabChange(0, val)
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card className="shadow-md">
          <Form form={promotionFilterForm} onFinish={() => handleSearch(0)} layout="vertical">
            {promo !== 'LOYT_REWARD' ? <Tabs defaultActiveKey={key} type="card" onChange={handleOnTabClick}>
              <TabPane tab={<BoldButtonLabel labelText="Automatic" />} key="AUTOMATIC">
              </TabPane>
              <TabPane tab={<BoldButtonLabel labelText="Manual" />} key="MANUAL">
              </TabPane>
            </Tabs> : <Tabs defaultActiveKey={key} type="card">
              <TabPane tab={<BoldButtonLabel labelText="Manual" />} key="MANUAL">
              </TabPane></Tabs>}
            <Card>
              <TenantInfo />
              <Row justify={'space-between'} className="flex items-center mt-4">
                <Col>
                  <Typography.Title level={5} className="text-[#2e2a5b]">
                    Promotion : {promoName}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={6}>
                  <Button
                    type="primary"
                    block
                    size="large"
                    onClick={() => {
                      const backUrl = urlHelpers.getBackUrl(searchParams);
                      let promotion_type = promo;
                      const paramString = new URLSearchParams({
                        promotion_type,
                        promo_header_type: promo === 'LOYT_REWARD' ? 'MANUAL' : key,
                        backUrl
                      }).toString();

                      navigate(`/promotion-engine/promotions/create?${paramString}`);
                    }}
                  >
                    <BoldButtonLabel labelText="Create Promotion" />
                  </Button>
                </Col>
              </Row>
              <Row gutter={12} className="mt-4">
                <Col xs={24} md={6}>
                  <Form.Item name={'status'} label="Status">
                    <Select
                      allowClear
                      placeholder="Select status"
                      size="large"
                      options={[
                        { label: 'Open', value: 'OPEN' },
                        { label: 'Active', value: 'ACTIVE' },
                        { label: 'Cancelled', value: 'CANCELLED' },
                        { label: 'Scheduled', value: 'SCHEDULED' },
                        { label: 'Expired', value: 'EXPIRED' },
                        {
                          label: 'On Hold',
                          value: 'ON_HOLD'
                        }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currValues) => {
                      return prevValues.promotion_type !== currValues.promotion_type;
                    }}
                  >
                    {form => {
                      const { promotion_type } = form.getFieldsValue();
                      const options = rewardTypeMap[promotion_type] || [];
                      return (
                        <Form.Item name={'reward_type'} label="Reward Type">
                          <Select allowClear size="large" placeholder="Select Reward type" options={options} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col xs={24} md={6}>
                  <Button block htmlType="submit" type="primary" size="large">
                    <BoldButtonLabel labelText="Search" />
                  </Button>
                </Col>
              </Row>
            </Card>
            <Card className="mt-4">
              <section>
                {promotionListResponse?.data?.data.length > 0 && (
                  <div>
                    <Row className="my-2" justify={'end'} gutter={[12, 12]} align={'middle'}>
                      <Col>
                        <CustomPagination
                          currentPage={currentPage}
                          totalPages={Math.ceil(promotionListResponse.data.count / 10)}
                          handleNext={handlePageChange}
                          handlePageChange={handlePageChange}
                          handlePrevious={handlePageChange}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </section>
              <Table
                loading={false}
                bordered
                components={components}
                pagination={false}
                className="mt-4"
                dataSource={promotionListResponse.data?.data || []}
                columns={columnsEdited}
                scroll={{ x: 1000 }}
              ></Table>
            </Card>
          </Form>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default PromotionListingPage;
