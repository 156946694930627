import { Card, Col, Row, Typography } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import PromotionForm from '../components/promotion-form';
import { promotionServiceCreate } from '../services/promotionCreate.service';

interface IEditPromotionPageProps {}

const EditPromotionPage: React.FunctionComponent<IEditPromotionPageProps> = props => {
  const { id } = useParams();
  const [promotionResponse, setPromotionResponse] = React.useState({} as any);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  React.useEffect(() => {
    loadPromotionDetails();
  }, []);
  const loadPromotionDetails = async () => {
    setLoading(true);
    const { data, errors } = await promotionServiceCreate.getPromotionByPromotionId(id as string);

    if (_.isEmpty(errors)) {
      setPromotionResponse(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };
  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Promotion - {promotionResponse?.title}
              </Typography.Title>
            </Col>
          </Row>

          <PromotionForm promotionFormInitialValues={promotionResponse} isUpdate />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default EditPromotionPage;
