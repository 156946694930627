import {
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Select
} from 'antd';
import * as React from 'react';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';

interface IProductFormProps {
  // initialFormValues?: any;
  isUpdate: boolean;
  formInstance: FormInstance<any>;
  handleSave: () => void;
  handleGoBack: () => void;
  resetData: () => void;
}

const ProductForm: React.FunctionComponent<IProductFormProps> = ({
  formInstance,
  isUpdate,
  handleSave,
  handleGoBack,
  resetData
}) => {
  const [, setIsBranded] = React.useState('TRUE');
  const [, setSoldPlatForm] = React.useState('TRUE');
  const [, setActive] = React.useState('TRUE');

  const handleOnBrandChange = (e: RadioChangeEvent) => {
    setIsBranded(e.target.value);
  };

  const handleOnSoldPlatform = (e: RadioChangeEvent) => {
    setSoldPlatForm(e.target.value);
  };

  const handleOnActive = (e: RadioChangeEvent) => {
    setActive(e.target.value);
  };

  return (
    <Form form={formInstance} layout="vertical" onFinish={handleSave}>
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            label="Product Identifier Type"
            name="product_identifier_type"
            rules={[
              {
                required: true,
                message: 'Please enter Product Identifier Type'
              }
            ]}
          >
            <Select
              placeholder="Product Identifier Type"
              size="large"
              allowClear
              options={[
                { label: 'SKU', value: 'SKU' },
                { label: 'GTIN', value: 'GTIN' },
                { label: 'UPC', value: 'UPC' },
                { label: 'EAN', value: 'EAN' },
                { label: 'MPN', value: 'MPN' }
              ]}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="item_id"
            label="Item ID"
            rules={[
              {
                required: true,
                message: 'Please enter Product Item ID'
              }
            ]}
          >
            <Input size="large" placeholder="Item ID" />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="article_id"
            label="Article ID"
            rules={[
              {
                required: true,
                message: 'Please enter Product Article ID'
              }
            ]}
          >
            <Input size="large" placeholder="Article ID" />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="vendor_code"
            label="Vendor Code"
            rules={[
              {
                required: true,
                message: 'Please enter Product Vendor Code'
              }
            ]}
          >
            <Input size="large" placeholder="Vendor Code" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            label="Supply Type Code"
            name="supply_type_code"
            rules={[
              {
                required: true,
                message: 'Please enter Supply Type Code'
              }
            ]}
          >
            <Select
              placeholder="Supply Type Code"
              size="large"
              allowClear
              options={[
                { label: 'B2B', value: 'B2B' },
                { label: 'B2C', value: 'B2C' }
              ]}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="product_name"
            label="Product Name"
            rules={[
              {
                required: true,
                message: 'Please enter Product Name'
              }
            ]}
          >
            <Input size="large" placeholder="Product Name" />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="product_type"
            label="Product Type"
            rules={[
              {
                required: false,
                message: 'Please enter Product Type'
              }
            ]}
          >
            <Input size="large" placeholder="Product Type" />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="department"
            label="Department"
            rules={[
              {
                required: false,
                message: 'Please enter Department'
              }
            ]}
          >
            <Input size="large" placeholder="Department" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            name="category"
            label="Category"
            rules={[
              {
                required: false,
                message: 'Please enter Category'
              }
            ]}
          >
            <Input size="large" placeholder="Category" />
          </Form.Item>
        </Col>

        <Col xs={12} md={6}>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: 'Please enter Description'
              }
            ]}
          >
            <Input size="large" placeholder="Description" />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="model"
            label="Model"
            rules={[
              {
                required: false,
                message: 'Please enter Model'
              }
            ]}
          >
            <Input size="large" placeholder="Model" />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="brand"
            label="Brand"
            rules={[
              {
                required: false,
                message: 'Please enter Brand'
              }
            ]}
          >
            <Input size="large" placeholder="Brand" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            name="country_of_origin"
            label="Country Of Origin"
            rules={[
              {
                required: true,
                message: 'Please enter Country Of Origin'
              }
            ]}
          >
            <Input size="large" placeholder="Country Of Origin" />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="lyt_earn"
            label="Earn"
            rules={[
              {
                required: false,
                message: 'Please enter Earn'
              }
            ]}
          >
            <InputNumber className="w-full" size="large" placeholder="Earn" />
          </Form.Item>
        </Col>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Row>
          <div style={{ marginTop: '40px', display: 'flex' }}>
            <Form.Item name="sold_Online" valuePropName="checked">
              <Checkbox> Sold Online </Checkbox>
            </Form.Item>{' '}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Form.Item name="sold_Offline" valuePropName="checked">
              <Checkbox>Sold Offline</Checkbox>
            </Form.Item>
          </div>
        </Row>
      </Row>
      <Row gutter={12} className="py-4">
        <Col xs={12} md={6}>
          <Form.Item name="branded" label="Branded">
            <Radio.Group onChange={handleOnBrandChange}>
              <Radio value={'TRUE'}>Yes</Radio>
              <Radio value={'FALSE'}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        {/* <Col xs={12} md={6}> */}
        {/* <Form.Item name="sold_Online" label="Sold Online" >
            <Radio.Group onChange={handleOnSoldPlatform}>
              <Radio value={'TRUE'}>Yes</Radio>
              <Radio value={'FALSE'}>No</Radio>
            </Radio.Group>
          </Form.Item> */}

        {/* </Col> */}
        <Col xs={12} md={6}>
          <Form.Item name="active_status" label="Active">
            <Radio.Group onChange={handleOnActive}>
              <Radio value={'TRUE'}>Yes</Radio>
              <Radio value={'FALSE'}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={6}>
          <Button size="large" block type="primary" htmlType="submit">
            <BoldButtonLabel labelText={isUpdate ? 'Update' : 'Create'} />
          </Button>
        </Col>{' '}
        {isUpdate && (
          <Col xs={24} md={6}>
            <Button size="large" block onClick={resetData}>
              <BoldButtonLabel labelText={'Reset'} />
            </Button>
          </Col>
        )}
        <Col xs={24} md={6}>
          <Button size="large" block onClick={handleGoBack}>
            <BoldButtonLabel labelText="Go Back" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ProductForm;
