import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import { Button, Card, Col, Form, Input, InputNumber, Row, Select, Typography } from 'antd';
import TenantInfo from '../../../components/TenantIdInfo';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { useLoader } from '../../../stores/use-loader';
import { loyaltyService } from '../services/loyalty.service';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { ICustomerLevelInfo } from '../types/earn-rules';
import { IRewardListItem } from '../types/rewards';
import { PlusOutlined } from '@ant-design/icons';

interface ICustomerTierProps {}

const CustomerTier: React.FunctionComponent<ICustomerTierProps> = props => {
  const [form] = Form.useForm();

  const [customerLevelsData, setCustomerLevelsData] = React.useState({} as ICustomerLevelInfo);

  const [rewardTypeMap, setRewardTypeMap] = React.useState<Record<string, IRewardListItem[]>>({});

  React.useEffect(() => {
    fetchCustomerLevelInfo();
    form.setFieldsValue({
      default_name: 'Standard',
      default_level_threshold: 0
    });
  }, []);

  React.useEffect(() => {
    fetchRewardNames();
  }, []);

  const fetchRewardNames = async () => {
    setLoading(true);
    await Promise.allSettled([fetchRewardNamesByPoints(), fetchRewardNameByCash()]);
    setLoading(false);
  };

  const fetchRewardNamesByPoints = async () => {
    const { data, errors } = await loyaltyService.getRewardsByType('POINTS');
    setRewardTypeMap(map => ({ ...map, POINTS: data }));
  };

  const fetchRewardNameByCash = async () => {
    const { data, errors } = await loyaltyService.getRewardsByType('CASH');
    setRewardTypeMap(map => ({ ...map, CASH: data }));
  };

  const fetchCustomerLevelInfo = async () => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getCustomerLevel();

    if (_.isEmpty(errors)) {
      setCustomerLevelsData(data);
      form.setFieldsValue(data);
      form.setFieldValue('total_level', data?.levels?.length);
    }

    setLoading(false);
  };

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const handleFormSubmit = async () => {
    setLoading(true);
    const { level_type, interval_count, interval, total_level, levels, reward_type, reward_name, default_name } =
      form.getFieldsValue();

    const request = {
      level_type,
      total_level,
      registration_level: default_name,
      interval,
      interval_count,
      levels,
      reward_type,
      reward_name
    };

    const updatedLevels = levels.map((level: any, index: number) => {
      return { ...level, hierarchy_level: index + 1 };
    });

    request.levels = updatedLevels;

    const { errors } = await loyaltyService.createCustomerLevel(request);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: `Customer levels ${isCreated ? 'Updated' : 'Created'}` });
      fetchCustomerLevelInfo();
    } else {
      displayErrorNotifications(errors);
    }

    setLoading(false);
  };

  const isCreated = !_.isEmpty(customerLevelsData);

  const labelText = isCreated ? 'Update' : 'Create';

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Customer Levels
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />

          <Form form={form} className="mt-4" onFinish={handleFormSubmit} layout="vertical">
            <div
              style={{
                height: 'fit-content',
                background: '#9b9b9b29',
                padding: '20px',
                marginBottom: '1rem',
                borderRadius: '10px'
              }}
            >
              <div>
                <Typography.Title level={5}>General Details</Typography.Title>
              </div>
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    name="level_type"
                    label="Level Type"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Select
                      size="large"
                      placeholder="Select level type"
                      options={[
                        { label: 'Reward', value: 'REWARD' },
                        { label: 'Purchase', value: 'PURCHASE' }
                      ]}
                    />
                  </Form.Item>
                </Col>{' '}
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    return prevValues.level_type !== curValues.level_type;
                  }}
                >
                  {form => {
                    const { level_type } = form.getFieldsValue();
                    return (
                      level_type === 'REWARD' && (
                        <>
                          <Col xs={12} md={6}>
                            <Form.Item
                              name="reward_type"
                              label="Reward Type"
                              rules={[{ required: true, message: 'This field is required' }]}
                            >
                              <Select
                                size="large"
                                placeholder="Select reward type"
                                options={[
                                  { label: 'Points', value: 'POINTS' },
                                  { label: 'Cash', value: 'CASH' }
                                ]}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) => {
                                return prevValues.reward_type !== curValues.reward_type;
                              }}
                            >
                              {form => {
                                const { reward_type } = form.getFieldsValue();
                                const rewardList = rewardTypeMap[reward_type] || [];
                                const options = rewardList.map(({ name }) => ({ label: name, value: name }));
                                return (
                                  <Form.Item
                                    name="reward_name"
                                    label="Reward Name"
                                    rules={[{ required: true, message: 'This field is required' }]}
                                  >
                                    <Select size="large" placeholder="Select reward name" options={options || []} />
                                  </Form.Item>
                                );
                              }}
                            </Form.Item>
                          </Col>
                        </>
                      )
                    );
                  }}
                </Form.Item>
                {/* <Col xs={12} md={6}>
                  <Form.Item
                    name="registration_level"
                    label="Registration Level"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Input placeholder="Registration Level" size="large" />
                  </Form.Item>
                </Col> */}
              </Row>
            </div>
            <div
              style={{
                height: 'fit-content',
                background: '#9b9b9b29',
                padding: '20px',
                marginBottom: '1rem',
                borderRadius: '10px'
              }}
            >
              <div>
                <Typography.Title level={5}>Configure Hierarchy</Typography.Title>
              </div>
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    name="interval"
                    label="Interval"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Select
                      placeholder="Select interval"
                      options={[
                        { label: 'Quaterly', value: 'QUATERLY' },
                        { label: 'Monthly', value: 'MONTHLY' },
                        { label: 'Yearly', value: 'YEARLY' }
                      ]}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    name="interval_count"
                    label="Interval count"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <InputNumber size="large" min={0} className="w-full" placeholder="Enter interval count" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div
              style={{
                height: 'fit-content',
                background: '#9b9b9b29',
                padding: '20px',
                marginBottom: '1rem',
                borderRadius: '10px'
              }}
            >
              <div>
                <Typography.Title level={5}>Default Level Hierarchy</Typography.Title>
              </div>
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    name="default_name"
                    label="Name"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    name={'default_level_threshold'}
                    label={`Level Threshold`}
                    rules={[{ required: true, message: 'Level Threshold is required' }]}
                  >
                    <InputNumber min={0} className="w-full" size="large" placeholder="..."></InputNumber>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div
              style={{
                height: 'fit-content',
                background: '#9b9b9b29',
                padding: '20px',
                marginBottom: '1rem',
                borderRadius: '10px'
              }}
            >
              <div>
                <Typography.Title level={5}>Configure Additional Hierarchy</Typography.Title>
              </div>
              <Row gutter={12} className='mt-4'>
                <Col xs={12} md={6}>
                  <Form.Item
                    name="total_level"
                    label="Configure Additional Hierarchy Levels"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <InputNumber
                      max={5}
                      // onChange={value => {
                      //   if (value) {
                      //     if (value > 5) return;
                      //     const levels = form.getFieldValue('levels') || [];
                      //     const hierarchyLevels = levels?.length || 0;
                      //     if (hierarchyLevels > value) {
                      //       levels.splice(-(hierarchyLevels - value));
                      //       form.setFieldsValue({ levels: [...levels] });
                      //     } else {
                      //       const levelsToBeAdded = (value || 0) - hierarchyLevels;

                      //       const fields = Array.from({ length: levelsToBeAdded || 0 }, (_, i) => ({
                      //         name: '',
                      //         level_threshold: ''
                      //       }));
                      //       form.setFieldsValue({ levels: [...levels, ...fields] });
                      //     }
                      //   }
                      // }}
                      size="large"
                      min={0}
                      className="w-full"
                      placeholder="Configure Additional Hierarchy Levels"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={4} className="mt-8">
                  <Button
                    onClick={() => {
                      let value = form.getFieldsValue().total_level;
                      if (value) {
                        if (value > 5) return;
                        const levels = form.getFieldValue('levels') || [];
                        const hierarchyLevels = levels?.length || 0;
                        if (hierarchyLevels > value) {
                          levels.splice(-(hierarchyLevels - value));
                          form.setFieldsValue({ levels: [...levels] });
                        } else {
                          const levelsToBeAdded = (value || 0) - hierarchyLevels;

                          const fields = Array.from({ length: levelsToBeAdded || 0 }, (_, i) => ({
                            name: '',
                            level_threshold: ''
                          }));
                          form.setFieldsValue({ levels: [...levels, ...fields] });
                        }
                      }
                    }}
                    icon={<PlusOutlined />}
                  >
                    <BoldButtonLabel labelText="Set Hierarchy " />
                  </Button>
                </Col>
              </Row>

              <Form.List name="levels">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <React.Fragment key={index}>
                        <div>
                          <Typography.Title level={5}>Level Hierarchy {index + 1}</Typography.Title>
                        </div>
                        <Row key={index} gutter={12}>
                          <Col xs={12} md={6}>
                            <Form.Item
                              {...restField}
                              name={[name, 'name']}
                              label={`Name`}
                              rules={[{ required: true, message: 'Name is required' }]}
                            >
                              <Input className="w-full" size="large" placeholder="..."></Input>
                            </Form.Item>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Item
                              {...restField}
                              name={[name, 'level_threshold']}
                              label={`Level Threshold`}
                              rules={[{ required: true, message: 'Level Threshold is required' }]}
                            >
                              <InputNumber min={0} className="w-full" size="large" placeholder="..."></InputNumber>
                            </Form.Item>
                          </Col>
                        </Row>
                      </React.Fragment>
                    ))}
                    <Form.ErrorList className="text-[#ff4d4f]" errors={errors} />
                  </>
                )}
              </Form.List>
            </div>

            <Row gutter={12}>
              <Col xs={12} md={6}>
                <Button block type="primary" htmlType="submit">
                  <BoldButtonLabel labelText={labelText} />
                </Button>
              </Col>
              {isCreated && (
                <Col xs={12} md={6}>
                  <Button
                    block
                    type="default"
                    onClick={() => {
                      form.setFieldsValue(customerLevelsData);
                      form.setFieldValue('total_level', customerLevelsData.levels?.length || 0);
                    }}
                  >
                    <BoldButtonLabel labelText="Reset" />
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CustomerTier;
