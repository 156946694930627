import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import Card from 'antd/es/card/Card';
import { Col, Form, Row, Typography } from 'antd';
import TenantInfo from '../../../../components/TenantIdInfo';
import ProductForm from '../../components/ProductForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loyaltyService } from '../../services/loyalty.service';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import _ from '../../../../helpers/lodash';

interface CreateProd {}
const CreateProduct: React.FunctionComponent<CreateProd> = props => {
  const [createProductForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleOnCreateProduct = async () => {
    const {
      active_status,
      article_id,
      brand,
      branded,
      category,
      country_of_origin,
      department,
      description,
      item_id,
      lyt_earn,
      lyt_redeem,
      model,
      product_identifier_type,
      product_name,
      product_type,
      sold_Online,
      supply_type_code,
      vendor_code,
      sold_Offline
    } = createProductForm.getFieldsValue();
    const bodyData = {
      product_identifier_type: product_identifier_type,
      item_id: item_id,
      article_id: article_id,
      vendor_code: vendor_code,
      supply_type_code: supply_type_code,
      product_name: product_name,
      product_type: product_type,
      department: department,
      category,
      description: description,
      model: model,
      brand: model,
      country_of_origin: country_of_origin,
      is_branded: branded === 'TRUE' ? true : false,
      sold_online: sold_Online,
      sold_offline: sold_Offline,
      lty_earn: Number(lyt_earn),
      lty_redeem: Number(lyt_redeem),
      is_active: active_status === 'TRUE' ? true : false
    };
    const { errors } = await loyaltyService.createProduct(bodyData);
    if (!_.isEmpty(errors)) displayErrorNotifications(errors);
    else {
      displaySuccessNotification({ message: 'Product created successfully!' });
    }
  };

  const handleOnGoBack = () => {
    const backUri = searchParams.get('goBackTo');
    if (backUri) {
      navigate(`${backUri}`);
    } else {
      navigate(`/loyalty/customers`);
    }
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Add Product
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <section className="mt-4">
            <ProductForm
              isUpdate={false}
              formInstance={createProductForm}
              handleSave={handleOnCreateProduct}
              handleGoBack={handleOnGoBack}
              resetData={() => {}}
            />
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CreateProduct;
