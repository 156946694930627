export const indianStateStateCodeMap: Record<string, any> = {
  'Andhra Pradesh': {
    state_name: 'Andhra Pradesh',
    state_code: 'AP'
  },
  'Arunachal Pradesh': {
    state_name: 'Arunachal Pradesh',
    state_code: 'AR'
  },
  Assam: {
    state_name: 'Assam',
    state_code: 'AS'
  },
  Bihar: {
    state_name: 'Bihar',
    state_code: 'BR'
  },
  Chhattisgarh: {
    state_name: 'Chhattisgarh',
    state_code: 'CG'
  },
  Goa: {
    state_name: 'Goa',
    state_code: 'GA'
  },
  Gujarat: {
    state_name: 'Gujarat',
    state_code: 'GJ'
  },
  Haryana: {
    state_name: 'Haryana',
    state_code: 'HR'
  },
  'Himachal Pradesh': {
    state_name: 'Himachal Pradesh',
    state_code: 'HP'
  },
  Jharkhand: {
    state_name: 'Jharkhand',
    state_code: 'JH'
  },
  Karnataka: {
    state_name: 'Karnataka',
    state_code: 'KA'
  },
  Kerala: {
    state_name: 'Kerala',
    state_code: 'KL'
  },
  'Madhya Pradesh': {
    state_name: 'Madhya Pradesh',
    state_code: 'MP'
  },
  Maharashtra: {
    state_name: 'Maharashtra',
    state_code: 'MH'
  },
  Manipur: {
    state_name: 'Manipur',
    state_code: 'MN'
  },
  Meghalaya: {
    state_name: 'Meghalaya',
    state_code: 'ML'
  },
  Mizoram: {
    state_name: 'Mizoram',
    state_code: 'MZ'
  },
  Nagaland: {
    state_name: 'Nagaland',
    state_code: 'NL'
  },
  Odisha: {
    state_name: 'Odisha',
    state_code: 'OD'
  },
  Punjab: {
    state_name: 'Punjab',
    state_code: 'PB'
  },
  Rajasthan: {
    state_name: 'Rajasthan',
    state_code: 'RJ'
  },
  Sikkim: {
    state_name: 'Sikkim',
    state_code: 'SK'
  },
  'Tamil Nadu': {
    state_name: 'Tamil Nadu',
    state_code: 'TN'
  },
  Telangana: {
    state_name: 'Telangana',
    state_code: 'TS'
  },
  Tripura: {
    state_name: 'Tripura',
    state_code: 'TR'
  }
};

export const chileStateCodeMap: Record<string, any> = {
  'Arica y Parinacota': {
    state_name: 'Arica y Parinacota',
    state_code: 'AP'
  },
  Tarapacá: {
    state_name: 'Tarapacá',
    state_code: 'TA'
  },
  Antofagasta: {
    state_name: 'Antofagasta',
    state_code: 'AN'
  },
  Atacama: {
    state_name: 'Atacama',
    state_code: 'AT'
  },
  Coquimbo: {
    state_name: 'Coquimbo',
    state_code: 'CO'
  },
  Valparaíso: {
    state_name: 'Valparaíso',
    state_code: 'VS'
  },
  'Metropolitana de Santiago': {
    state_name: 'Metropolitana de Santiago',
    state_code: 'RM'
  },
  "Libertador General Bernardo O'Higgins": {
    state_name: "Libertador General Bernardo O'Higgins",
    state_code: 'OH'
  },
  Maule: {
    state_name: 'Maule',
    state_code: 'ML'
  },
  Ñuble: {
    state_name: 'Ñuble',
    state_code: 'NB'
  },
  Biobío: {
    state_name: 'Biobío',
    state_code: 'BI'
  },
  'La Araucanía': {
    state_name: 'La Araucanía',
    state_code: 'AR'
  },
  'Los Ríos': {
    state_name: 'Los Ríos',
    state_code: 'LR'
  },
  'Los Lagos': {
    state_name: 'Los Lagos',
    state_code: 'LL'
  },
  'Aysén del General Carlos Ibáñez del Campo': {
    state_name: 'Aysén del General Carlos Ibáñez del Campo',
    state_code: 'AI'
  },
  'Magallanes y de la Antártica Chilena': {
    state_name: 'Magallanes y de la Antártica Chilena',
    state_code: 'MA'
  }
};

export const countries = ['Chile', 'India'];

export const countryCodeMap: Record<string, string> = {
  Chile: 'CHL',
  India: 'IN'
};

export const countryCodeMapToEjje: Record<string, string> = {
  Chile: 'CL',
  India: 'IN'
};

export const countryStateMap: Record<string, any> = {
  Chile: chileStateCodeMap,
  India: indianStateStateCodeMap
};

export const indianStates = Object.keys(indianStateStateCodeMap).map(key => ({ label: key, value: key }));

export const chileStates = Object.keys(chileStateCodeMap).map(key => ({ label: key, value: key }));

export const stateList: Record<string, any> = {
  Chile: chileStates,
  India: indianStates
};

export const getStateNameByCountryAndStateCode = (country: string, stateCode: string): string | undefined => {
  const countryStates: Record<string, Record<string, any>> = countryStateMap[country];

  if (countryStates) {
    const stateNameEntry = Object.entries(countryStates).find(([, stateData]) => stateData.state_code === stateCode);

    let dataState = stateNameEntry?.[1]?.state_name;

    return dataState ? dataState : stateCode    
  }
  return undefined;
};

export const getCountryNameByCountry = (countryCode: string): string | undefined => {
  const countryEntry = Object.entries(countryCodeMapToEjje).find(([, code]) => code === countryCode);
  return countryEntry?.[0];
};
