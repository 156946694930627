import * as React from 'react';
import { Button, Card, Col, Form, Input, Row, Select, Typography } from 'antd';

import PrimaryLayout from '../../../../../layouts/primary-layout';
import BoldButtonLabel from '../../../../../components/BoldButtonLabel';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLoader } from '../../../../../stores/use-loader';
import { logisticService } from '../../../services';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../../helpers/toast.helpers';
import _ from '../../../../../helpers/lodash';

interface IDNNEditProps {}

const DNNEdit: React.FunctionComponent<IDNNEditProps> = props => {
  const [createForm] = Form.useForm();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    loadIntialValues();
  }, []);

  const setDnnData = (data: any) => {
    const { dnn_name, operator_id, country_code, description } = data;
    createForm.setFieldsValue({
      dnn_name: dnn_name,
      operator_id: operator_id,
      country_code: country_code,
      description: description
    });
  };

  const loadIntialValues = async () => {
    setLoading(true);
    const { data, errors } = await logisticService.getDNNbyDNNId(id as string);
    if (_.isEmpty(errors)) {
      setDnnData(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const { dnn_name, country_code, description } = createForm.getFieldsValue();
    const bodyData = {
      dnn_name: dnn_name,
      country_code: country_code,
      description: description
    };
    const { errors } = await logisticService.editDNN(bodyData, id as string);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'updated successfully' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row gutter={[24, 12]} align={'middle'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Distribution Node Network
              </Typography.Title>
            </Col>
          </Row>

          <Form layout="vertical" onFinish={handleSubmit} form={createForm}>
            <Row gutter={12}>
              <Col xs={24} md={8} lg={6}>
                <Form.Item name={'dnn_name'} label="DNN Name">
                  <Input size="large" placeholder="DNN Name"></Input>
                </Form.Item>
              </Col>
              <Col xs={24} md={8} lg={6}>
                <Form.Item name={'operator_id'} label="Operator ID">
                  <Select disabled size="large" placeholder="Select Operator ID"></Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8} lg={6}>
                <Form.Item name={'country_code'} label="Country">
                  <Select disabled size="large" placeholder="Select Country"></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={12}>
                <Form.Item name={'description'} label="Description">
                  <Input.TextArea size="large" placeholder="Enter Description"></Input.TextArea>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col xs={24} md={8} lg={6}>
                <Button block type="primary" htmlType="submit" size="large">
                  <BoldButtonLabel labelText="Update" />
                </Button>
              </Col>{' '}
              <Col xs={24} md={8} lg={6}>
                <Button
                  block
                  size="large"
                  onClick={() => {
                    if (searchParams.get('backUrl')) {
                      navigate(searchParams.get('backUrl') as string);
                    } else {
                      navigate('/logistics/dnn');
                    }
                  }}
                >
                  <BoldButtonLabel labelText="Go Back" />
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default DNNEdit;
