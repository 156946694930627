import { Card, Col, Form, Row, Typography } from 'antd';
import _ from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../../layouts/primary-layout';
import { useLoader } from '../../../../../stores/use-loader';
import ShippingProfileUpdateForm from '../../../components/shipping-profile-form/shipping-profile-update-form';
import { ShippingService, logisticService, productService } from '../../../services';

interface IShippingProfileViewProps { }

interface SkuData {
  sku_id: string;
  category: string;
}

interface TransformedItem {
  key: number;
  label: string;
  children: any;
}

const ShippingProfileView: React.FunctionComponent<IShippingProfileViewProps> = props => {
  const [viewForm] = Form.useForm();
  const [basicDetailsForm] = Form.useForm();
  const [shippingChargesForm] = Form.useForm();
  const [deliveryDetailsForm] = Form.useForm()
  const { id } = useParams();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const [saveResp, setSaveResp] = React.useState({} as any);
  const [category, setCategory] = React.useState([] as any);
  const [productsOfSpecificCategory, setProductsOfSpecificCategory] = React.useState([] as any);

  React.useEffect(() => {
    getShippingProfileById();
  }, []);

  const getShippingProfileById = async () => {
    setLoading(true);
    let profileId = id;
    const { data, errors } = await logisticService.getShippingChargesIdDetails(profileId as string);
    if (_.isEmpty(errors)) {
      setShippingProfileDetails(data);
      setSaveResp(data);
      const itemsMap: { [key: string]: TransformedItem } = {};
      data?.skus?.forEach((item: SkuData) => {
        if (!itemsMap[item.category]) {
          itemsMap[item.category] = {
            key: Object.keys(itemsMap).length + 1,
            label: item.category,
            children: []
          };
        }
        itemsMap[item.category].children.push(item.sku_id);
      });

      const items: TransformedItem[] = Object.values(itemsMap).map((item: TransformedItem) => {
        if (Array.isArray(item.children)) {
          item.children = item.children.length > 1 ? item.children.join(', ') : item.children[0];
        }
        return item;
      });
      setCategory(data.categories);
      setProductsOfSpecificCategory(items);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const setShippingProfileDetails = (data: any) => {
    const { shipping_profile_name, shipping_profile_type, description, shipping_charge, snn_id } = data;
    basicDetailsForm.setFieldsValue({
      shipping_profile_name: shipping_profile_name,
      shipping_profile_type: shipping_profile_type,
      description: description,
      shipping_profile_id: snn_id
    });

    if (shipping_charge) {
      const {
        shipping_charge: {
          name,
          shipping_charge_type,
          order_price_slab_charge_config,
          flat_charge_config,
          base_weight_charge_config,
          slab_weight_slab_charge_config
        }
      } = data;
      if (shipping_charge_type === 'SLAB_WEIGHT') {
        shippingChargesForm.setFieldsValue({
          currency: slab_weight_slab_charge_config[0].cost.currency,
          shipping_charge: {
            name: name,
            shipping_charge_type: shipping_charge_type
          },
          slab_weight_conditions: slab_weight_slab_charge_config
            ? setOrderPriceConditions(slab_weight_slab_charge_config, shipping_charge_type)
            : null
        });
      } else if (shipping_charge_type === 'BASE_WEIGHT') {
        shippingChargesForm.setFieldsValue({
          currency: base_weight_charge_config.base_weight.cost.currency,
          shipping_charge: {
            name: name,
            shipping_charge_type: shipping_charge_type
          },
          base_weight_in_kg: base_weight_charge_config.base_weight.base_weight_in_kg,
          base_weight_charge_amount: base_weight_charge_config.base_weight.cost.amount,
          base_weight_charge_fraction: base_weight_charge_config.base_weight.cost.fraction,
          additional_weight_in_kg: base_weight_charge_config.additional_weight.additional_weight_in_kg,
          additional_weight_charge_amount: base_weight_charge_config.additional_weight.cost.amount,
          additional_base_weight_charge_fraction: base_weight_charge_config.additional_weight.cost.fraction
        });
      } else if (shipping_charge_type === 'FREE') {
        shippingChargesForm.setFieldsValue({
          currency: 'INR',
          shipping_charge: {
            name: name,
            shipping_charge_type: shipping_charge_type
          }
        });
      } else if (shipping_charge_type === 'FLAT') {
        shippingChargesForm.setFieldsValue({
          currency: 'INR',
          shipping_charge: {
            name: name,
            shipping_charge_type: shipping_charge_type
          },
          flat_charge_amount: flat_charge_config.amount,
          flat_charge_fraction: flat_charge_config.fraction
        });
      } else if (shipping_charge_type === 'ORDER_PRICE') {
        shippingChargesForm.setFieldsValue({
          currency: order_price_slab_charge_config[0].cost.currency,
          shipping_charge: {
            name: name,
            shipping_charge_type: shipping_charge_type
          },
          order_price_conditions: order_price_slab_charge_config
            ? setOrderPriceConditions(order_price_slab_charge_config, shipping_charge_type)
            : null
        });
      }
    }
  };

  const setOrderPriceConditions = (data: any, type: any) => {
    let returnData = [];

    if (type === 'ORDER_PRICE') {
      returnData = data.map((item: any) => {
        return {
          max_price: item.max_price,
          min_price: item.min_price,
          cost: item.cost.amount,
          fraction: item.cost.fraction
        };
      });
    } else if (type === 'SLAB_WEIGHT') {
      returnData = data.map((item: any) => {
        return {
          max_weight_in_kg: item.max_weight_in_kg,
          min_weight_in_kg: item.min_weight_in_kg,
          cost: item.cost.amount,
          fraction: item.cost.fraction
        };
      });
    }
    return returnData;
  };

  const handleOnCloseCategory = async (product: any, category: any) => {
    setLoading(true);
    console.log('selected Products', product);
    console.log('selected category', category);
    let filteredProducts = product.filter((product: any) => {
      if (category.includes(product.category)) {
        return;
      } else {
        return product;
      }
    });
    let skuData = filteredProducts.map((item: any) => {
      return {
        category: item.category,
        sku_id: item.sku_id
      };
    });
    const bodyData = {
      categories: category,
      skus: skuData
    };
    const { errors } = await productService.patchCategoryAndProduct(id as string, bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Category / Products updated successfully !' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnDetailsUpdate = async () => {
    setLoading(true);
    const { shipping_profile_name, shipping_profile_type, description } = basicDetailsForm.getFieldsValue();
    const bodyData = {
      description: description,
      shipping_profile_name: shipping_profile_name
    };
    const { errors } = await ShippingService.updateShippingProfileBasicDetailsById(id as string, bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Details Updated Success' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnUpdateCharges = async () => {
    setLoading(true);
    let bodyData = getChargesBodyData(shippingChargesForm.getFieldsValue());
    const { errors } = await ShippingService.updateShippingChargesDetailsByID(id as string, bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Shipping Charges got updated Successfully!!' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const getSlabWeightData = (slabData: any, currency: any) => {
    let returnSlab = slabData?.map((data: any) => {
      return {
        cost: {
          amount: parseInt(data.cost),
          currency: currency,
          fraction: data.fraction
        },
        max_weight_in_kg: parseInt(data.max_weight_in_kg),
        min_weight_in_kg: parseInt(data.min_weight_in_kg)
      };
    });
    return returnSlab;
  };

  const getOrderPriceData = (orderPriceData: any, currency: any) => {
    let returnOrder = orderPriceData?.map((data: any) => {
      return {
        cost: {
          amount: parseInt(data.cost),
          currency: currency,
          fraction: parseInt(data.fraction)
        },
        currency: currency,
        max_price: parseInt(data.max_price),
        min_price: parseInt(data.min_price)
      };
    });
    return returnOrder;
  };

  const getChargesBodyData = (form: any) => {
    let request = {};

    if (form.shipping_charge.shipping_charge_type === 'SLAB_WEIGHT') {
      request = {
        name: form.shipping_charge.name,
        shipping_charge_type: form.shipping_charge.shipping_charge_type,
        slab_weight_slab_charge_config: getSlabWeightData(
          form.slab_weight_conditions,
          form.shipping_charge.shipping_charge_type
        )
      };
    } else if (form.shipping_charge.shipping_charge_type === 'BASE_WEIGHT') {
      request = {
        name: form.shipping_charge.name,
        shipping_charge_type: form.shipping_charge.shipping_charge_type,
        base_weight_charge_config: {
          additional_weight: {
            additional_weight_in_kg: form.additional_weight_in_kg,
            cost: {
              amount: form.additional_weight_charge_amount,
              currency: form.currency,
              fraction: form.additional_base_weight_charge_fraction
            }
          },
          base_weight: {
            base_weight_in_kg: form.base_weight_in_kg,
            cost: {
              amount: form.base_weight_charge_amount,
              currency: form.currency,
              fraction: form.base_weight_charge_fraction
            }
          }
        }
      };
    } else if (form.shipping_charge.shipping_charge_type === 'FREE') {
      request = {
        name: form.shipping_charge.name,
        shipping_charge_type: form.shipping_charge.shipping_charge_type
      };
    } else if (form.shipping_charge.shipping_charge_type === 'FLAT') {
      request = {
        name: form.shipping_charge.name,
        shipping_charge_type: form.shipping_charge.shipping_charge_type,
        flat_charge_config: {
          amount: form.flat_charge_amount,
          currency: form.currency,
          fraction: form.flat_charge_fraction
        }
      };
    } else if (form.shipping_charge.shipping_charge_type === 'ORDER_PRICE') {
      request = {
        name: form.shipping_charge.name,
        shipping_charge_type: form.shipping_charge.shipping_charge_type,
        order_price_slab_charge_config: getOrderPriceData(form.order_price_conditions, form.currency)
      };
    }
    return request;
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row gutter={[24, 12]} align={'middle'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Shipping Profile - {saveResp.shipping_profile_name}
              </Typography.Title>
            </Col>
          </Row>
          <ShippingProfileUpdateForm
            formInstance={viewForm}
            isUpdate
            availableCategory={category}
            availableSku={productsOfSpecificCategory}
            basicformInstance={basicDetailsForm}
            deliveryDetailsForm={deliveryDetailsForm}
            shippingformInstance={shippingChargesForm}
            handleOnDetailsUpdate={handleOnDetailsUpdate}
            handleOnCloseCategory={handleOnCloseCategory}
            handleOnUpdateCharges={handleOnUpdateCharges}
          />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default ShippingProfileView;
