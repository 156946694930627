import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import { Card, Col, Row, Typography } from 'antd';
import PromotionForm from '../components/promotion-form';

interface ICreatePromotionPageProps {}

const CreatePromotionPage: React.FunctionComponent<ICreatePromotionPageProps> = props => {
  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Create Promotion
              </Typography.Title>
            </Col>
          </Row>

          <PromotionForm />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CreatePromotionPage;
