import * as React from 'react';
import PrimaryLayout from '../../layouts/primary-layout';
import { Col, Row, Typography } from 'antd';
import './home.css';
import { images } from '../../utils/images';

interface IOrderDashboardProps { }

const OrderDashboard: React.FunctionComponent<IOrderDashboardProps> = props => {
  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4 ">
        <div className="home_bG pt-20">
          <div className="mb-16 flex xs: flex-col md:flex-row justify-evenly" >
            <div className="flex flex-col gap-8 items-center">
              <Typography className="font-inter text-3xl font-bold leading-11 tracking-[0em] text-left text-white w-[307px]"> Welcome Back to <span className="text-[#ff66c4]"> Excellence</span> with Cloverbay Labs</Typography>
              <Typography className="font-inter text-sm text-[#9890C1] font-normal leading-[24px] tracking-[0em] text-left w-[307px]">Discover tailored solutions with Cloverbay Labs: Unmatched loyalty rewards, seamless promotions, and elite OMS. Elevate your business efficiency today. </Typography>
              {/* <div>
                See More...
              </div> */}
            </div>
            <div className="items-center flex flex-col mt-10">
              <img src={images.home_1} alt='logo' className='w-[270.45px] h-[191.43px] top-[192px] left-[456.22px]' />
            </div>
          </div>
          <div className='home_p2 mb-16'>
            <div className='flex xs: flex-col md:flex-row justify-evenly p-6'>
              <div className="items-center flex flex-col">
                <div className='relative top-[-50px] flex gap-4'>
                  <img src={images.home_r1} alt='l1' className='' />
                  <img src={images.home_r2} alt='l1' className='' />
                </div>
                <div className='relative top-[-40px] flex gap-4'>
                  <img src={images.home_r3} alt='l1' />
                  <img src={images.home_r4} alt='l1' />
                </div>
              </div>
              <div className='items-center flex flex-col gap-4 '>
                <Typography className='font-inter text-[24px] font-bold leading-[29px] tracking-[0em] text-left text-[#EBF0F6] w-[294px]'>Empowering Global
                  Business with Innovation</Typography>
                <Typography className='font-inter text-[12px] font-normal leading-[24px] tracking-[0em] text-left text-[#EBF0F6] w-[294px]'>Cloverbay Labs fuels growth and transformation
                  with cutting-edge software. Exceeding
                  expectations, fostering collaboration, and unlocking
                  success. Be the change in the digital world with our
                  commitment to excellence and customer focus.</Typography>
              </div>
            </div>
          </div>
          <div className='flex justify-center mb-6'>
            <Typography className='font-inter text-[24px] font-bold leading-[29px] tracking-[0em] text-left text-[#EBF0F6]'>Our Offerings</Typography>
          </div>
          <div className='mb-4'>
            <Row gutter={12} justify="center">
              <Col xs={24} md={8} className='flex justify-center items-center'>
                <div className='flex flex-col items-center gap-4'>
                  <Typography className='w-[117px] text-[#CFC7F8] font-inter text-base font-bold leading-[19px] tracking-normal text-center'>Elysian Loyalty
                    Service</Typography>
                  <Typography className='font-inter w-[186px] text-[#CFC7F8] text-[12px] font-normal leading-6 tracking-normal text-center'>
                    Elevate customer loyalty with
                    our innovation SaaS platform.
                    Seamlessly manage rewards,
                    incentives, and engagement
                    strategies tailored to your brand,
                  </Typography>
                </div>
              </Col>
              <Col xs={24} md={8} className='flex justify-center items-center sm:mt-5'>
                <div className='flex flex-col items-center gap-4'>
                  <Typography className='w-[185px] text-[#CFC7F8] font-inter text-base font-bold leading-[19px] tracking-normal text-center'>Opus Promotion
                    Service</Typography>
                  <Typography className='font-inter w-[186px] text-[#CFC7F8] text-[12px] font-normal leading-6 tracking-normal text-center'>
                    Revolutionize promotions with
                    our dynamic SaaS solution.
                    Effortlessly create, manage, and
                    track customized discounts and
                    offers tailored to your audience.
                  </Typography>
                </div>
              </Col>
              <Col xs={24} md={8} className='flex justify-center items-center sm:mt-5'>
                <div className='flex flex-col items-center gap-4'>
                  <Typography className='w-[117px] text-[#CFC7F8] font-inter text-base font-bold leading-[19px] tracking-normal text-center'>Nebula OMS</Typography>
                  <Typography className='font-inter w-[186px] text-[#CFC7F8] text-[12px] font-normal leading-6 tracking-normal text-center'>
                    Empower your operations with our
                    cutting-edge OMS SaaS solution.
                    Streamline order management,
                    inventory control, and fulfillment
                    processes seamlessly.
                  </Typography>
                </div>
              </Col>
            </Row>
          </div>
        </div>

      </div>
    </PrimaryLayout>
  );
};

export default OrderDashboard;
