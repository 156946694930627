import * as React from 'react';
import { IProfileDetailsResponse } from '../../../types/profile.types';
import { Tabs, TabsProps, Typography } from 'antd';
import _ from '../../../helpers/lodash';
import NebulaConfiguration from './app-configurations/nebula-configuration';
import BoldButtonLabel from '../../../components/BoldButtonLabel';

interface ITenantConfigurationProps {
  profileDetails: IProfileDetailsResponse;
}

const TenantConfiguration: React.FunctionComponent<ITenantConfigurationProps> = ({ profileDetails }) => {

  const subscribedApps = profileDetails?.tenant_details?.subscriptions?.subscription_info || [];
  const showTabs = !_.isEmpty(subscribedApps);

  const getTabItems = () => {
    const nebulaTab: { id: string; tab: any } = {
      id: 'nebula',
      tab: {
        key: 'nebula',
        children: <NebulaConfiguration profileDetails={profileDetails} />,
        tabKey: 'nebula',
        label: <BoldButtonLabel labelText="Nebula" />
      }
    };
    const tabs: TabsProps['items'] = [];
    subscribedApps.forEach(({ is_enabled, product_id }) => {
      if (!is_enabled) return;
      if (product_id === 'NEBULA' || product_id === "nebula") tabs.push(nebulaTab.tab);
    });

    return tabs;
  };

  const tabItems: TabsProps['items'] = getTabItems();
  return showTabs ? (
    <Tabs defaultActiveKey="1" items={tabItems} />
  ) : (
    <Typography.Text>You have not subscribed to any of our apps</Typography.Text>
  );
};

export default TenantConfiguration;
