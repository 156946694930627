import * as React from 'react';
import { IPointConversionListingItem, IPointConversionListingType } from '../types/point-conversion';
import { Button, Card, Col, Form, Row, Select, Table, TableColumnsType, Typography } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { convertQueryStringToObj, objectHelpers } from '../../../helpers';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import TenantInfo from '../../../components/TenantIdInfo';
import CustomPagination from '../../../components/custom-pagination';
import { loyaltyService } from '../services/loyalty.service';
import _ from '../../../helpers/lodash';
import { useRewardNames } from '../hooks/use-reward-names';
import { useLoader } from '../../../stores/use-loader';
import ActionButton from '../../../components/ActionButton';

interface IPointConversionRulesListingProps {}

const statusList = [
  { value: 'OPEN', label: 'Open' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'DEFFERED', label: 'Deferred' },
  { value: 'ON_HOLD', label: 'On Hold' }
];

const PointConversionRulesListing: React.FunctionComponent<IPointConversionRulesListingProps> = props => {
  const [listingResponse, setListingResponse] = React.useState({} as IPointConversionListingType);

  const [expandedRowData, setExpandedRowData] = React.useState({} as Record<string, IPointConversionListingItem[]>);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([] as string[]);

  const columns: TableColumnsType<IPointConversionListingItem> = [
    {
      title: 'Rule Name',
      render(value, record, index) {
        return <>{record.name}</>;
      },
      align: 'center'
    },
    {
      title: 'Status',
      render(value, record, index) {
        return <>{record.status}</>;
      },
      align: 'center'
    },
    {
      title: 'Reward Name',
      render(value, record, index) {
        return <>{record.reward_name}</>;
      },
      align: 'center'
    },
    {
      title: 'Version',
      render(value, record, index) {
        return <>{record.version}</>;
      },
      align: 'center'
    },
    {
      title: 'Action',
      render(value, record, index) {
        return (
          <section className="flex justify-center gap-4">
            <ActionButton
              action="VIEW"
              title="View Point conversion rule"
              onClick={() => navigate(`/loyalty/config/point-conversion/${record.id}`)}
            ></ActionButton>

            <ActionButton
              action="CREATE_NEW_VERSION"
              title="Create New version of this rule"
              onClick={() => navigate(`/loyalty/config/point-conversion/${record.id}/new?id=${record.id}`)}
            ></ActionButton>

            <ActionButton
              action="EXPAND_VERSIONS"
              title="View Versions"
              onClick={() => handleTableExpand(record)}
            ></ActionButton>
          </section>
        );
      },
      align: 'center'
    },

    {
      title: 'Status Update',
      render(value, record, index) {
        let actions: { label: string; action: string }[] = [];

        if (record.status === 'OPEN') {
          actions.push({ label: 'Activate', action: 'ACTIVE' });
        }
        if (record.status === 'ACTIVE') {
          actions.push(
            ...[
              {
                label: 'On Hold',
                action: 'ON_HOLD'
              },

              {
                label: 'Defer',
                action: 'DEFFERED'
              }
            ]
          );
        }

        if (record.status === 'ON_HOLD') {
          actions.push(
            ...[
              { label: 'Activate', action: 'ACTIVE' },
              {
                label: 'Defer',
                action: 'DEFFERED'
              }
            ]
          );
        }

        return (
          <section className="flex justify-center">
            {actions.map(({ label, action }) => (
              <Button
                key={action}
                type="link"
                onClick={() =>
                  handleStatusChange(action, record.id, {
                    reward_id: record.reward_id,
                    version: record.version
                  })
                }
              >
                {label}
              </Button>
            ))}
          </section>
        );
      },
      align: 'center'
    }
  ];

  const handleStatusChange = async (
    status: string,
    id: string,
    rewardDetails: { reward_id: string; version: number }
  ) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.changePointConversionRuleStatus(
      id,
      status,
      rewardDetails.reward_id,
      rewardDetails.version
    );
    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      displaySuccessNotification({ message: 'Rule update success' });
      const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
      await Promise.allSettled([handleFilterSearch(offset), fetchRuleVersions(id)]);
    }
    setLoading(false);
  };

  const expandedRowRender = () => {
    const columns: TableColumnsType<IPointConversionListingItem> = [
      {
        title: 'Rule Name',
        render(value, record, index) {
          return <>{record.name}</>;
        },
        align: 'center'
      },
      {
        title: 'Status',
        render(value, record, index) {
          return <>{record.status}</>;
        },
        align: 'center'
      },

      {
        title: 'Version',
        render(value, record, index) {
          return <>{record.version}</>;
        },
        align: 'center'
      },
      {
        title: 'Action',
        render(value, { id }, index) {
          return (
            <Button type="link" onClick={() => navigate(`/loyalty/config/point-conversion/${id}`)}>
              View
            </Button>
          );
        },
        align: 'center'
      },
      {
        title: 'Action',
        /* 
  
  open -> active -> differed
  
  open -> active -> expired
  
  open -> active -> on_hold -> active
  
  open -> active -> on_hold -> differed*/
        render(value, record, index) {
          let actions: { label: string; action: string }[] = [];

          if (record.status === 'OPEN') {
            actions.push({ label: 'Activate', action: 'ACTIVE' });
          }
          if (record.status === 'ACTIVE') {
            actions.push(
              ...[
                {
                  label: 'On Hold',
                  action: 'ON_HOLD'
                },

                {
                  label: 'Defer',
                  action: 'DEFFERED'
                }
              ]
            );
          }

          if (record.status === 'ON_HOLD') {
            actions.push(
              ...[
                { label: 'Activate', action: 'ACTIVE' },
                {
                  label: 'Defer',
                  action: 'DEFFERED'
                }
              ]
            );
          }
          return (
            <section className="flex justify-center">
              {actions.map(({ label, action }) => (
                <Button
                  key={action}
                  type="link"
                  onClick={() =>
                    handleStatusChange(action, record.id, {
                      reward_id: record.reward_id,
                      version: record.version
                    })
                  }
                >
                  {label}
                </Button>
              ))}
            </section>
          );
        },
        align: 'center'
      }
    ];
    return <Table bordered dataSource={expandedRowData[expandedRowKeys[0]]} pagination={false} columns={columns} />;
  };

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const [searchForm] = Form.useForm();
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const loadInitialData = async () => {
    if (!_.isEmpty(queryStringObj)) {
      searchForm.setFieldsValue(queryStringObj);
    }
    const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
    await handleFilterSearch(offset);
  };

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const { rewardNameSelectOptions } = useRewardNames();

  const handleFilterSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    const params = { ...formValues, offset };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);

    const { data, errors } = await loyaltyService.getPointConversion({
      limit: 10,
      offset,
      rewardId: formValues.reward_id,
      status: formValues.status
    });

    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      const transformedItems = data?.data?.map((item: any) => ({ ...item, key: item.id }));
      const transformedData = { ...data, data: transformedItems };
      setListingResponse(transformedData);
    }
    setLoading(false);
  };

  const handleClickCreate = () => {
    navigate('/loyalty/config/point-conversion/create');
  };

  const handlePageChange = (currentPage: number) => {
    const offset = (currentPage - 1) * 10;
    handleFilterSearch(offset);
  };

  const handleTableExpand = async (record: IPointConversionListingItem) => {
    if (expandedRowKeys.includes(record.id)) {
      return setExpandedRowKeys([]);
    }

    if (_.isEmpty(expandedRowData[record.id])) {
      await fetchRuleVersions(record.id);
    }

    setExpandedRowKeys([record.id]);
  };

  const fetchRuleVersions = async (id: string) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getPointConversionVersions(id);
    if (_.isEmpty(errors)) {
      setExpandedRowData(rowData => ({ ...rowData, [id]: data }));
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Point Conversion
              </Typography.Title>
            </Col>
            <Col>
              <Button type="primary" size="large" onClick={handleClickCreate}>
                <BoldButtonLabel labelText="Create"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
          <TenantInfo />
          <div className="my-4">
            <Form onFinish={() => handleFilterSearch(0)} form={searchForm} layout="vertical">
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item name={'reward_id'} label="Reward Name">
                    <Select placeholder="Reward Name" size="large" options={rewardNameSelectOptions} />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name={'status'} label="Status">
                    <Select placeholder="Status" size="large" options={statusList} />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <div className="h-full flex justify-center items-center">
                    <Button type="primary" htmlType="submit" size="large" block>
                      <BoldButtonLabel labelText="Search"></BoldButtonLabel>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
          <section className="mt-4">
            {!_.isEmpty(listingResponse.data) && (
              <div className="flex justify-end my-2">
                <CustomPagination
                  currentPage={listingResponse.page_info.current_page}
                  totalPages={listingResponse.page_info.total_pages}
                  handleNext={handlePageChange}
                  handlePageChange={handlePageChange}
                  handlePrevious={handlePageChange}
                />
              </div>
            )}{' '}
            <Table
              loading={false}
              expandable={{ expandedRowRender, expandedRowKeys, showExpandColumn: false }}
              pagination={false}
              bordered
              dataSource={listingResponse.data}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default PointConversionRulesListing;
