import { envConfig } from '../config';
import { dashboardResponse } from '../data/dashboard';

import httpClient from '../http/http.client';
import { IOrderListQueryType } from '../types/order.types';

export const fetchOrderList = async (params: IOrderListQueryType) => {
  const url = `${envConfig.ORDERS_BASE_URI}/v1/orders-list`;

  const { data, errors } = await httpClient.get({ url, params });
  // await sleep();
  return { data, errors };
};

export const createOrder = async (request: any) => {
  const url = `${envConfig.ORCHESTRATION_URI}/v1/order`;
  return httpClient.post({ url, body: request });
};
function sleep(ms = 1000) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export const getOrderDashbordSummary = async () => {
  await sleep();
  // const url = `${envConfig.ORCHESTRATION_URI}/v1/summary`;
  return { data: dashboardResponse, errors: [] };
};

export const getOrderDetails = async (orderNumber: string) => {
  const url = `${envConfig.ORDERS_BASE_URI}/v1/order`;
  const params = { order_number: orderNumber };
  return httpClient.get({ url, params });
};

export const updateOrderStatus = async (orderNumber: string, status: string) => {
  const url = `${envConfig.ORCHESTRATION_URI}/v1/fulfillment-order/status`;
  const params = {
    fo_number: `FO${orderNumber}`,
    order_number: orderNumber,
    status
  };

  return httpClient.put({ url, params });
};

export const getFulfillmentOrderList = (params: any) => {
  const url = `${envConfig.ORDERS_BASE_URI}/v1/fulfillment-order/search`;
  return httpClient.get({ url, params });
};

// export const cancelFo = (request: any) => {
//   const url = `${envConfig.ORDERS_BASE_URI}/v1/fulfillment-order/cancel`;
//   return httpClient.patch({ url, body: request });
// };

export const cancelROFO = (request: any) => {
  const url = `${envConfig.ORCHESTRATION_URI}/v1/orders/cancel`;
  return httpClient.post({ url, body: request });
};

export const getRosFromCoNumber = (order_number: string) => {
  const url = `${envConfig.ORDERS_BASE_URI}/v1/release-order/search`;
  const params = {
    order_number,
    offset: 0,
    limit: 100
  };

  return httpClient.get({ url, params });
};

export const getRosFromFoNumber = (fo_number: string) => {
  const url = `${envConfig.ORDERS_BASE_URI}/v1/release-order/search`;
  const params = {
    fo_number,
    offset: 0,
    limit: 100
  };

  return httpClient.get({ url, params });
};
