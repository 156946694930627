import { envConfig } from '../config';
import httpClient from '../http/http.client';
import { ManualStockUpdateRequest } from '../types/inventory.types';

const inventoryUrls = {
  getInventoryListingUrl: () => `${envConfig.INVENTORY_BASE_URI}/v2/inventory`,
  getInventoryDetailsUrl: (articleId: string) => `${envConfig.INVENTORY_BASE_URI}/v1/inventory/${articleId}`,
  getInventoryHistoryUri: (articleId: string) => `${envConfig.INVENTORY_BASE_URI}/v2/transaction`,
  getStockUpdateUrl: () => `${envConfig.INVENTORY_BASE_URI}/v1/supply`
};

export const getInventoryListing = async (query: any) => {
  const url = inventoryUrls.getInventoryListingUrl();
  const { data, errors } = await httpClient.get({
    url,
    params: { page: query.page_number, size: query.page_size, node_id: query.node_id, sku: query.sku }
  });
  return { data, errors };
};

export const getInventoryDetails = async (article_id: string, pageNumber: number, limit: number, nodeId?: string, newParams?:any) => {
  const url = `${envConfig.INVENTORY_BASE_URI}/v2/inventory/item` //?id_type=SKU_ID&id=SKU3&page=1&size=5`;
  const params = { page: pageNumber, size: limit, node_name: nodeId ? nodeId : undefined, id_type: 'SKU_ID', id: newParams.id ? newParams.id : undefined};
  const { data, errors } = await httpClient.get({ url, params });


  return { data, errors };
};
export const getInventoryHistory = async (
  article_id: string,
  pageNumber: number,
  pageSize: number,
  queryParams: Record<string, any>
) => {
  const url = inventoryUrls.getInventoryHistoryUri(article_id);
  const params = {
    page: pageNumber,
    size: pageSize,
    id_type: 'SKU',
    ...queryParams
  };
  const { data, errors } = await httpClient.get({ url, params });
  return { data, errors };
};

export const updateInventory = async (manualStockUpdationRequest: ManualStockUpdateRequest) => {
  const url = inventoryUrls.getStockUpdateUrl();
  return httpClient.post({ url, body: manualStockUpdationRequest });
};
