import { Card, Col, Form, Row, Typography } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useLoader } from '../../../../stores/use-loader';
import SnnUpdateForm from '../../components/snn-update-form';
import { logisticService } from '../../services';

interface ISnnEditProps {}

const SnnEdit: React.FunctionComponent<ISnnEditProps> = props => {
  const [chargesForm] = Form.useForm();
  const [basicDetailsForm] = Form.useForm();
  const { id } = useParams();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    loadSSNData();
  }, []);

  const loadSSNData = async () => {
    setLoading(true);
    const { data, errors } = await logisticService.getSNNById(id as string);
    if (_.isEmpty(errors)) {
      setSNNBasicDatilsForm(data);
      setSNNChargeForm(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const setSNNBasicDatilsForm = (data: any) => {
    const {
      snn_name,
      node_group,
      is_active,
      description,
      shipping_charge: { name, shipping_charge_type }
    } = data;
    basicDetailsForm.setFieldsValue({
      snn_name: snn_name,
      node_group: node_group,
      is_active: is_active,
      description: description,
      shipping_charge: {
        name: name,
        shipping_charge_type: shipping_charge_type
      }
    });
  };

  const setSNNChargeForm = (data: any) => {
    const {
      shipping_charge: {
        name,
        shipping_charge_type,
        order_price_slab_charge_config,
        flat_charge_config,
        base_weight_charge_config,
        slab_weight_slab_charge_config
      }
    } = data;

    if (shipping_charge_type === 'SLAB_WEIGHT') {
      chargesForm.setFieldsValue({
        currency: slab_weight_slab_charge_config[0].cost.currency,
        shipping_charge: {
          name: name,
          shipping_charge_type: shipping_charge_type
        },
        slab_weight_conditions: slab_weight_slab_charge_config
          ? setOrderPriceConditions(slab_weight_slab_charge_config, shipping_charge_type)
          : null
      });
    } else if (shipping_charge_type === 'BASE_WEIGHT') {
      chargesForm.setFieldsValue({
        currency: base_weight_charge_config.base_weight.cost.currency,
        shipping_charge: {
          name: name,
          shipping_charge_type: shipping_charge_type
        },
        base_weight_in_kg: base_weight_charge_config.base_weight.base_weight_in_kg,
        base_weight_charge_amount: base_weight_charge_config.base_weight.cost.amount,
        base_weight_charge_fraction: base_weight_charge_config.base_weight.cost.fraction,
        additional_weight_in_kg: base_weight_charge_config.additional_weight.additional_weight_in_kg,
        additional_weight_charge_amount: base_weight_charge_config.additional_weight.cost.amount,
        additional_base_weight_charge_fraction: base_weight_charge_config.additional_weight.cost.fraction
      });
    } else if (shipping_charge_type === 'FREE') {
      chargesForm.setFieldsValue({
        currency: 'INR',
        shipping_charge: {
          name: name,
          shipping_charge_type: shipping_charge_type
        }
      });
    } else if (shipping_charge_type === 'FLAT') {
      chargesForm.setFieldsValue({
        currency: 'INR',
        shipping_charge: {
          name: name,
          shipping_charge_type: shipping_charge_type
        },
        flat_charge_amount: flat_charge_config.amount,
        flat_charge_fraction: flat_charge_config.fraction
      });
    } else if (shipping_charge_type === 'ORDER_PRICE') {
      chargesForm.setFieldsValue({
        currency: order_price_slab_charge_config[0].cost.currency,
        shipping_charge: {
          name: name,
          shipping_charge_type: shipping_charge_type
        },
        order_price_conditions: order_price_slab_charge_config
          ? setOrderPriceConditions(order_price_slab_charge_config, shipping_charge_type)
          : null
      });
    }
  };

  const setOrderPriceConditions = (data: any, type: any) => {
    let returnData = [];

    if (type === 'ORDER_PRICE') {
      returnData = data.map((item: any) => {
        return {
          max_price: item.max_price,
          min_price: item.min_price,
          cost: item.cost.amount,
          fraction: item.cost.fraction
        };
      });
    } else if (type === 'SLAB_WEIGHT') {
      returnData = data.map((item: any) => {
        return {
          max_weight_in_kg: item.max_weight_in_kg,
          min_weight_in_kg: item.min_weight_in_kg,
          cost: item.cost.amount,
          fraction: item.cost.fraction
        };
      });
    }
    return returnData;
  };

  const getChargesBodyData = (form: any) => {
    let request = {};

    if (form.shipping_charge.shipping_charge_type === 'SLAB_WEIGHT') {
      request = {
        name: form.shipping_charge.name,
        shipping_charge_type: form.shipping_charge.shipping_charge_type,
        slab_weight_slab_charge_config: getSlabWeightData(
          form.slab_weight_conditions,
          form.shipping_charge.shipping_charge_type
        )
      };
    } else if (form.shipping_charge.shipping_charge_type === 'BASE_WEIGHT') {
      request = {
        name: form.shipping_charge.name,
        shipping_charge_type: form.shipping_charge.shipping_charge_type,
        base_weight_charge_config: {
          additional_weight: {
            additional_weight_in_kg: form.additional_weight_in_kg,
            cost: {
              amount: form.additional_weight_charge_amount,
              currency: form.currency,
              fraction: form.additional_base_weight_charge_fraction
            }
          },
          base_weight: {
            base_weight_in_kg: form.base_weight_in_kg,
            cost: {
              amount: form.base_weight_charge_amount,
              currency: form.currency,
              fraction: form.base_weight_charge_fraction
            }
          }
        }
      };
    } else if (form.shipping_charge.shipping_charge_type === 'FREE') {
      request = {
        name: form.shipping_charge.name,
        shipping_charge_type: form.shipping_charge.shipping_charge_type
      };
    } else if (form.shipping_charge.shipping_charge_type === 'FLAT') {
      request = {
        name: form.shipping_charge.name,
        shipping_charge_type: form.shipping_charge.shipping_charge_type,
        flat_charge_config: {
          amount: form.flat_charge_amount,
          currency: form.currency,
          fraction: form.flat_charge_fraction
        }
      };
    } else if (form.shipping_charge.shipping_charge_type === 'ORDER_PRICE') {
      request = {
        name: form.shipping_charge.name,
        shipping_charge_type: form.shipping_charge.shipping_charge_type,
        order_price_slab_charge_config: getOrderPriceData(form.order_price_conditions, form.currency)
      };
    }
    return request;
  };

  const getBasicsBodyData = (basicData: any) => {
    return {
      snn_name: basicData.snn_name,
      description: basicData.description || '',
      node_group: basicData.node_group,
      is_active: basicData.is_active
    };
  };

  const getBasicNodeGroup = (basicData: any) => {
    return {
      node_group: basicData.node_group,
    }
  }

  const getSlabWeightData = (slabData: any, currency: any) => {
    let returnSlab = slabData?.map((data: any) => {
      return {
        cost: {
          amount: parseInt(data.cost),
          currency: currency,
          fraction: data.fraction
        },
        max_weight_in_kg: parseInt(data.max_weight_in_kg),
        min_weight_in_kg: parseInt(data.min_weight_in_kg)
      };
    });
    return returnSlab;
  };

  const getOrderPriceData = (orderPriceData: any, currency: any) => {
    let returnOrder = orderPriceData?.map((data: any) => {
      return {
        cost: {
          amount: parseInt(data.cost),
          currency: currency,
          fraction: parseInt(data.fraction)
        },
        currency: currency,
        max_price: parseInt(data.max_price),
        min_price: parseInt(data.min_price)
      };
    });
    return returnOrder;
  };

  const handleSubmitCharges = async () => {
    setLoading(true);
    let bodyData = getChargesBodyData(chargesForm.getFieldsValue());
    const { errors } = await logisticService.updateSSNChargesById(id as any, bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Charges Data Updated Successfully !' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleSubmitBasic = async () => {
    setLoading(true);
    const basicBodyData = getBasicsBodyData(basicDetailsForm.getFieldsValue());
    const { errors } = await logisticService.updateSSNBasicById(id as any, basicBodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Basic details Updated Successfully !' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnStatusUpdate = async (e: any) => {
    setLoading(true);
    const bodyData = {
      is_active: e.target.value
    };
    const { errors } = await logisticService.updateSNNState(id as string, bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'status changed!' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnNodeChange = async(data:any) => {
    setLoading(true)
    const nodeBodyData = getBasicNodeGroup(basicDetailsForm.getFieldsValue())
    const { errors } = await logisticService.nodeUpdateChanges(id as any, nodeBodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Node details Updated Successfully !' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false)
  }

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row gutter={[24, 12]} align={'middle'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Source Node Network
              </Typography.Title>
            </Col>
          </Row>

          <SnnUpdateForm
            formInstance={chargesForm}
            basicformInstance={basicDetailsForm}
            handleOnNodeChange={handleOnNodeChange}
            handleSubmitBasic={handleSubmitBasic}
            handleSubmitCharges={handleSubmitCharges}
            handleOnStatusUpdate={handleOnStatusUpdate}
            isUpdate
          />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default SnnEdit;
