import { Button, Card, Col, Row, Typography, Form, Select, TableColumnsType, Table } from 'antd';
import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import TenantInfo from '../../../components/TenantIdInfo';
import { IRewardListItem, IRewardListResponse } from '../types/rewards';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomPagination from '../../../components/custom-pagination';
import _ from '../../../helpers/lodash';
import { convertQueryStringToObj, objectHelpers, urlHelpers } from '../../../helpers';
import { loyaltyService } from '../services/loyalty.service';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { useLoader } from '../../../stores/use-loader';
import ActionButton from '../../../components/ActionButton';

interface IRewardListPageProps {}

const RewardListPage: React.FunctionComponent<IRewardListPageProps> = props => {
  const [rewardListResponse, setRewardListResponse] = React.useState({} as IRewardListResponse);

  const columns: TableColumnsType<IRewardListItem> = [
    {
      title: 'Reward Name',
      render(value, record, index) {
        return <>{record.name}</>;
      },
      align: 'center'
    },
    {
      title: 'Reward Type',
      render(value, record, index) {
        return <>{record.reward_type}</>;
      },
      align: 'center'
    },
    {
      title: 'Status',
      render(value, record, index) {
        return <>{record.active ? 'Active' : 'Inactive'}</>;
      },
      align: 'center'
    },
    {
      title: 'Action',
      render(value, record, index) {
        return (
          <section className="flex justify-center gap-4">
            <ActionButton
              action="VIEW"
              onClick={() => {
                const backUrl = urlHelpers.getBackUrl(searchParams);
                navigate(`/loyalty/config/rewards/${record.id}?backUrl=${backUrl}`);
              }}
              title="View Reward"
            />
            <ActionButton
              action={record.active ? 'DEACTIVATE' : 'ACTIVATE'}
              onClick={() => handleStatusChange(!record.active, record)}
              title={record.active ? 'Deactivate' : 'Activate'}
            />
            <ActionButton
              action="DELETE"
              title={'Delete reward'}
              onClick={() => handleRewardDelete(record)}
            ></ActionButton>
          </section>
        );
      },
      align: 'center'
    }
  ];
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchForm] = Form.useForm();

  const handleStatusChange = async (isActive: boolean, record: IRewardListItem) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.changeRewardStatus(record.id, isActive);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Reward updated successfully!' });
      await loadInitialData();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };
  const handleRewardDelete = async (record: IRewardListItem) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.deleteReward(record.id);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Reward deleted successfully!' });
      await loadInitialData();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const loadInitialData = async () => {
    const queryString = searchParams.toString();
    const queryStringObj = convertQueryStringToObj(queryString);
    if (!_.isEmpty(queryStringObj)) {
      searchForm.setFieldsValue(queryStringObj);
    }
    const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
    await handleFilterSearch(offset);
  };

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const handleFilterSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    const params = { ...formValues, offset };

    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);

    let active = formValues.status;

    if (active) {
      active = active === 'ACTIVE';
    }

    const { data, errors } = await loyaltyService.getRewardsList({
      limit: 10,
      offset,
      rewardType: formValues.reward_type,
      active
    });

    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      setRewardListResponse(data);
    }
    setLoading(false);
  };
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const handleClickCreate = () => {
    const backUrl = urlHelpers.getBackUrl(searchParams);
    navigate(`/loyalty/config/rewards/create?backUrl=${backUrl}`);
  };

  const handlePageChange = (currentPage: number) => {
    const offset = currentPage - 1;
    handleFilterSearch(offset);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Rewards
              </Typography.Title>
            </Col>
            <Col>
              <Button type="primary" size="large" onClick={handleClickCreate}>
                <BoldButtonLabel labelText="Create"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
          <TenantInfo />
          <div className="my-4">
            <Form onFinish={() => handleFilterSearch(0)} form={searchForm} layout="vertical">
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item name={'reward_type'} label="Reward Type">
                    <Select
                      placeholder="Reward type"
                      size="large"
                      options={[
                        { label: 'Points', value: 'POINTS' },
                        { label: 'Cash', value: 'CASH' }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name={'status'} label="Status">
                    <Select
                      placeholder="Status"
                      size="large"
                      options={[
                        { label: 'Active', value: 'ACTIVE' },
                        { label: 'Inactive', value: 'INACTIVE' }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <div className="h-full flex justify-center items-center">
                    <Button type="primary" htmlType="submit" size="large" block>
                      <BoldButtonLabel labelText="Search"></BoldButtonLabel>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
          <section className="mt-4">
            {!_.isEmpty(rewardListResponse.data) && (
              <div className="flex justify-end my-2">
                <CustomPagination
                  currentPage={rewardListResponse.page_info.current_page}
                  totalPages={rewardListResponse.page_info.total_pages}
                  handleNext={handlePageChange}
                  handlePageChange={handlePageChange}
                  handlePrevious={handlePageChange}
                />
              </div>
            )}{' '}
            <Table
              loading={false}
              pagination={false}
              bordered
              dataSource={rewardListResponse.data}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default RewardListPage;
