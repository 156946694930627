import { create } from "zustand";
// import { persist } from "zustand/middleware";

interface IUseOrder {
    productData: null
    setProductData: (productData: any) => void,
}

export const useOrder = create<IUseOrder>(set => ({
    productData: {} as any,
    setProductData: productData => set({ productData })
}))