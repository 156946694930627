import * as React from 'react';
import PrimaryLayout from '../../layouts/primary-layout';
import { Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import { useAuth } from '../../stores/use-auth';
import { EditOutlined } from '@ant-design/icons';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import { loggingIn } from '../../services/login.services';
import _ from '../../helpers/lodash';

const UserProfile: React.FunctionComponent<any> = () => {
  const [profileDetailsForm] = Form.useForm();
  const [updatePwd, setUpdatePwd] = React.useState(true);
  const { tenentName, profileName, userMail, bussinessGroupName, userName } = useAuth(
    ({ tenentName, profileName, userMail, bussinessGroupName, userName }) => ({
      tenentName,
      profileName,
      userMail,
      bussinessGroupName,
      userName
    })
  );

  React.useEffect(() => {
    setUserProfileDetails();
  }, []);

  const setUserProfileDetails = async () => {
    const fName = profileName.split(' ')[0];
    const lName = profileName.split(' ')[1];
    profileDetailsForm.setFieldsValue({
      userName: tenentName,
      firstName: fName,
      lastName: lName,
      email: userMail
    });
  };

  const handleOnPasswordUpdate = async () => {
    const { currentPassword, updatePassword, confirmPassword } = profileDetailsForm.getFieldsValue();
    if (updatePassword !== confirmPassword) {
      displayErrorNotifications([{ message: 'Update-password & Confirm-password does not match' }]);
    } else {
      const bodyData = {
        realm: bussinessGroupName,
        client_id: useAuth?.getState()?.tenentName,
        current_password: currentPassword,
        password: updatePassword,
        confirm_password: confirmPassword,
        temporary: false,
        username: userName
      };
      const { errors } = await loggingIn.setResetPassword(bodyData);
      if (_.isEmpty(errors)) {
        displaySuccessNotification({ message: 'Password Updated Successfully' });
        profileDetailsForm.setFieldsValue({
          currentPassword: '',
          updatePassword: '',
          confirmPassword: ''
        });
        setUpdatePwd(true);
      } else {
        displayErrorNotifications(errors);
      }
    }
  };

  return (
    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card>
          <Form form={profileDetailsForm} layout="vertical" onFinish={handleOnPasswordUpdate}>
            <Row gutter={[5, 10]}>
              <Col xs={26} lg={12}>
                <div className="column-content min-w-90 p-2">
                  <Typography className={`font-bold mb-4`}>Profile Details</Typography>
                  <Row gutter={8}>
                    <Col xs={24} lg={12}>
                      <Form.Item label="First Name" name="firstName">
                        <Input size="large" placeholder="First Name" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Form.Item label="Last Name" name="lastName">
                        <Input size="large" placeholder="Last Name" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Form.Item label="Email" name="email">
                        <Input size="large" placeholder="Email" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Form.Item label="User Name" name="userName">
                        <Input size="large" placeholder="User Name" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={26} lg={12}>
                <div className="column-content min-w-90 p-2">
                  <div className="flex justify-between">
                    <Typography className={`font-bold mb-4`}>Change Password:</Typography>
                    <EditOutlined className="cursor-pointer" onClick={() => setUpdatePwd(false)} />
                  </div>
                  <Col xs={24}>
                    <Form.Item
                      label="Current Password"
                      name="currentPassword"
                      rules={[{ required: true, message: "Current Password can't be empty" }]}
                    >
                      <Input.Password size="large" placeholder="Current Password" disabled={updatePwd} />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="Update Password"
                      name="updatePassword"
                      rules={[{ required: true, message: "Update Password can't be empty" }]}
                    >
                      <Input.Password size="large" placeholder="Update Password" disabled={updatePwd} />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="Confirm Password"
                      name="confirmPassword"
                      rules={[{ required: true, message: "Confirm Password can't be empty" }]}
                    >
                      <Input.Password size="large" placeholder="Confirm Password" disabled={updatePwd} />
                    </Form.Item>
                  </Col>
                  {!updatePwd && (
                    <div className="flex flex-row-reverse gap-5">
                      <Button type="primary" size="large" htmlType="submit">
                        Update
                      </Button>
                      <Button size="large" onClick={() => setUpdatePwd(true)}>
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default UserProfile;
