import { CREATE_PRICE_URI, CUSTOMER_PRODUCT_URI, PROMOTIONS_URI } from "../../../config/envConfig";
import { objectHelpers } from "../../../helpers";
import httpClient from "../../../http/http.client";
import { useAuth } from "../../../stores/use-auth";
import { IPromotionPrioritiesResponse, IPromotionPriority } from "../types/promotion-types";

export const getPromotions = async () => {
	const url = `${PROMOTIONS_URI}/promotions`;

	const { data, errors } = await httpClient.get({ url });

	// return { data, errors };

	return {
		data: {
			success: true,
			message: "Got  paged promotions",
			data: {
				data: [
					{
						id: 8,
						business_group_id: "123",
						priority: 0,
						tenant_id: "123",
						code_type: "SINGLE",
						coupon_quantity_to_generate: 0,
						code: "ert33e2",
						status: "OPEN",
						application_type: "ORDER",
						application: "CART",
						max_usage: 999,
						limit_per_user: 1,
						combine_with_other_promo: false,
						currency: null,
						start_date: "2023-10-12",
						end_date: "2023-10-12",
						timezone: "CHILE_CONTINENTAL",
						is_pos: true,
						is_online: true,
						tier_restriction: "everyone",
						applied_count: 0,
						buyer_selection: "ALL",
						seller_selection: "ALL",
						visibility: "ANONYMOUS",
						title: "te3h",
						created_at: 1691653712496,
						trigger: "MANUAL",
						reward_type: "DISCOUNT",
						reward_name: "",
						reward_value: 0,
						promotion_type: "COUPON",
						product_selection: "ALL",
						new_buyer: true,
						visible_on_popup: true,
						valid_on_given_timeframe: false,
						schedule: {
							daily: true,
							daily_info: {
								from_date: "2023-10-10",
								to_date: "2023-10-10",
								from_time: "12:00:00",
								to_time: "17:00:00",
								from_date_time_utc: "2023-10-10T15:00",
								to_date_time_utc: "2023-10-10T20:00",
							},
							weekly: true,
							weekly_info: ["MON", "WED"],
							monthly: false,
							monthly_info: null,
							seasonal: false,
							seasonal_info: null,
						},
					},
					{
						id: 7,
						business_group_id: "123",
						priority: 0,
						tenant_id: "123",
						code_type: "SINGLE",
						coupon_quantity_to_generate: 0,
						code: "ert3e2",
						status: "OPEN",
						application_type: "ORDER",
						application: "CART",
						max_usage: 999,
						limit_per_user: 1,
						combine_with_other_promo: false,
						currency: null,
						start_date: "2023-10-12",
						end_date: "2023-10-12",
						timezone: "CHILE_CONTINENTAL",
						is_pos: true,
						is_online: true,
						tier_restriction: "everyone",
						applied_count: 0,
						buyer_selection: "ALL",
						seller_selection: "ALL",
						visibility: "ANONYMOUS",
						title: "teh",
						created_at: 1691653573042,
						trigger: "MANUAL",
						reward_type: "DISCOUNT",
						reward_name: "",
						reward_value: 0,
						promotion_type: "COUPON",
						product_selection: "ALL",
						new_buyer: true,
						visible_on_popup: true,
						valid_on_given_timeframe: false,
						schedule: {
							daily: true,
							daily_info: {
								from_date: "2023-10-10",
								to_date: "2023-10-10",
								from_time: "12:00:00",
								to_time: "17:00:00",
								from_date_time_utc: "2023-10-10T15:00",
								to_date_time_utc: "2023-10-10T20:00",
							},
							weekly: true,
							weekly_info: ["MON", "WED"],
							monthly: false,
							monthly_info: null,
							seasonal: false,
							seasonal_info: null,
						},
					},
					{
						id: 6,
						business_group_id: "123",
						priority: 0,
						tenant_id: "123",
						code_type: "SINGLE",
						coupon_quantity_to_generate: 0,
						code: "ert32",
						status: "OPEN",
						application_type: "ORDER",
						application: "CART",
						max_usage: 999,
						limit_per_user: 1,
						combine_with_other_promo: false,
						currency: null,
						start_date: "2023-10-12",
						end_date: "2023-10-12",
						timezone: "CHILE_CONTINENTAL",
						is_pos: true,
						is_online: true,
						tier_restriction: "everyone",
						applied_count: 0,
						buyer_selection: "ALL",
						seller_selection: "ALL",
						visibility: "ANONYMOUS",
						title: "th",
						created_at: 1691653504731,
						trigger: "MANUAL",
						reward_type: "DISCOUNT",
						reward_name: "",
						reward_value: 0,
						promotion_type: "COUPON",
						product_selection: "ALL",
						new_buyer: true,
						visible_on_popup: true,
						valid_on_given_timeframe: true,
						schedule: null,
					},
				],
				count: 3,
			},
		} as any,
		errors: [],
	};
};

export const getPromotionByStatusFilter = async (status: string) => {
	const url = `${PROMOTIONS_URI}/promotions/projection`;
	const params = {
		status,
		limit: 100000,
	};
	const { data, errors } = await httpClient.get({ url, params });
	return {
		data: {
			success: true,
			message: "Got  paged promotions",
			data: {
				data: [
					{
						id: 8,
						business_group_id: "123",
						priority: 0,
						tenant_id: "123",
						code_type: "SINGLE",
						coupon_quantity_to_generate: 0,
						code: "ert33e2",
						status: "OPEN",
						application_type: "ORDER",
						application: "CART",
						max_usage: 999,
						limit_per_user: 1,
						combine_with_other_promo: false,
						currency: null,
						start_date: "2023-10-12",
						end_date: "2023-10-12",
						timezone: "CHILE_CONTINENTAL",
						is_pos: true,
						is_online: true,
						tier_restriction: "everyone",
						applied_count: 0,
						buyer_selection: "ALL",
						seller_selection: "ALL",
						visibility: "ANONYMOUS",
						title: "te3h",
						created_at: 1691653712496,
						trigger: "MANUAL",
						reward_type: "DISCOUNT",
						reward_name: "",
						reward_value: 0,
						promotion_type: "COUPON",
						product_selection: "ALL",
						new_buyer: true,
						visible_on_popup: true,
						valid_on_given_timeframe: false,
						schedule: {
							daily: true,
							daily_info: {
								from_date: "2023-10-10",
								to_date: "2023-10-10",
								from_time: "12:00:00",
								to_time: "17:00:00",
								from_date_time_utc: "2023-10-10T15:00",
								to_date_time_utc: "2023-10-10T20:00",
							},
							weekly: true,
							weekly_info: ["MON", "WED"],
							monthly: false,
							monthly_info: null,
							seasonal: false,
							seasonal_info: null,
						},
					},
					{
						id: 7,
						business_group_id: "123",
						priority: 0,
						tenant_id: "123",
						code_type: "SINGLE",
						coupon_quantity_to_generate: 0,
						code: "ert3e2",
						status: "OPEN",
						application_type: "ORDER",
						application: "CART",
						max_usage: 999,
						limit_per_user: 1,
						combine_with_other_promo: false,
						currency: null,
						start_date: "2023-10-12",
						end_date: "2023-10-12",
						timezone: "CHILE_CONTINENTAL",
						is_pos: true,
						is_online: true,
						tier_restriction: "everyone",
						applied_count: 0,
						buyer_selection: "ALL",
						seller_selection: "ALL",
						visibility: "ANONYMOUS",
						title: "teh",
						created_at: 1691653573042,
						trigger: "MANUAL",
						reward_type: "DISCOUNT",
						reward_name: "",
						reward_value: 0,
						promotion_type: "COUPON",
						product_selection: "ALL",
						new_buyer: true,
						visible_on_popup: true,
						valid_on_given_timeframe: false,
						schedule: {
							daily: true,
							daily_info: {
								from_date: "2023-10-10",
								to_date: "2023-10-10",
								from_time: "12:00:00",
								to_time: "17:00:00",
								from_date_time_utc: "2023-10-10T15:00",
								to_date_time_utc: "2023-10-10T20:00",
							},
							weekly: true,
							weekly_info: ["MON", "WED"],
							monthly: false,
							monthly_info: null,
							seasonal: false,
							seasonal_info: null,
						},
					},
					{
						id: 6,
						business_group_id: "123",
						priority: 0,
						tenant_id: "123",
						code_type: "SINGLE",
						coupon_quantity_to_generate: 0,
						code: "ert32",
						status: "OPEN",
						application_type: "ORDER",
						application: "CART",
						max_usage: 999,
						limit_per_user: 1,
						combine_with_other_promo: false,
						currency: null,
						start_date: "2023-10-12",
						end_date: "2023-10-12",
						timezone: "CHILE_CONTINENTAL",
						is_pos: true,
						is_online: true,
						tier_restriction: "everyone",
						applied_count: 0,
						buyer_selection: "ALL",
						seller_selection: "ALL",
						visibility: "ANONYMOUS",
						title: "th",
						created_at: 1691653504731,
						trigger: "MANUAL",
						reward_type: "DISCOUNT",
						reward_name: "",
						reward_value: 0,
						promotion_type: "COUPON",
						product_selection: "ALL",
						new_buyer: true,
						visible_on_popup: true,
						valid_on_given_timeframe: true,
						schedule: null,
					},
				],
				count: 3,
			},
		} as any,
		errors: [],
	};
	// return { data, errors };
};

const getPromotionPriorities = () => {
	const url = `${PROMOTIONS_URI}/promo-config/v2/types/priority`;

	return httpClient.get({ url });
};

const createPromotionPrio = (request: IPromotionPrioritiesResponse) => {
	const url = `${PROMOTIONS_URI}/promo-config/v2/types/priority`;

	return httpClient.post({ url, body: request });
};

const putPromotionPrio = (request: IPromotionPrioritiesResponse) => {
	const url = `${PROMOTIONS_URI}/promo-config/v2/types/priority`;

	return httpClient.put({ url, body: request });
};

const getPromotionList = (params: any) => {
	const url = `${PROMOTIONS_URI}/promo-config/v3`;
	return httpClient.get({ url, params: params });
};

const patchPromotionPriority = (id: string, priority: string) => {
	const url = `${PROMOTIONS_URI}/promo-config/v2/${id}/priority/${priority}`;
	return httpClient.patch({ url });
};

const patchPromotionStartDate = (id: string, startDate: string) => {
	const url = `${PROMOTIONS_URI}/promo-config/v2/${id}/start_date/${startDate}`;
	return httpClient.patch({ url });
};

const patchPromotionEndDate = (id: string, endDate: string) => {
	const url = `${PROMOTIONS_URI}/promo-config/v2/${id}/end_date/${endDate}`;
	return httpClient.patch({ url });
};

const patchPromotionStatus = (id: number, status: string) => {
	const url = `${PROMOTIONS_URI}/promo-config/v2/${id}/status/${status}`;
	return httpClient.patch({ url });
};

const createCouponCodes = (id: string, request: any) => {
	const url = `${PROMOTIONS_URI}/promo-config/v2/${id}/coupons/generate`;
	return httpClient.post({ url, body: request });
};

const getPriceList = (params: any) => {
	const snakeCaseParams = objectHelpers.convertObjectKeysSnakeToCamel(params);
	const url = `${CREATE_PRICE_URI}/price-lists/v1`;
	const headers = {
		"x-tenant-id": useAuth?.getState()?.tenentName,
		"x-client-id": "webui",
		"x-business-group-id": useAuth?.getState()?.bussinessGroupName,
		"x-trace-id": "trace",
		Tenant_id: useAuth?.getState()?.tenentName,
		Business_grp_id: useAuth?.getState()?.bussinessGroupName,
	};

	return httpClient.get({ url, params: snakeCaseParams, headers });
};

const searchUsersByQuery = (query: string) => {
	const url = `${CUSTOMER_PRODUCT_URI}/customers/v1/search`;
	const params = { q: query };

	return httpClient.get({ url, params });
};

const searchProductsByQuery = (query: string) => {
	const url = `${CUSTOMER_PRODUCT_URI}/products/v1/search`;
	const params = { q: query };

	return httpClient.get({ url, params });
};

const getSellers = (query: string) => {
	const url = `${CUSTOMER_PRODUCT_URI}/sellers/v1`;
	const params = { q: query };
	return httpClient.get({ url, params });
};

const getModels = () => {
	const url = `${CUSTOMER_PRODUCT_URI}/models/v1`;

	return httpClient.get({ url });
};

const getBrands = () => {
	const url = `${CUSTOMER_PRODUCT_URI}/brands/v1`;

	return httpClient.get({ url });
};

const getLocations = (query: string, locationType: string) => {
	const url = `${CUSTOMER_PRODUCT_URI}/locations/v1/type/search`;
	const params = { q: query, "node-type": locationType };

	return httpClient.get({ url, params });
};

const getControlSettings = () => {
	const url = `${PROMOTIONS_URI}/promo-config/v1/control-settings`;
	return httpClient.get({ url });
};

const postControlSettings = (body: any) => {
	const url = `${PROMOTIONS_URI}/promo-config/v1/control-settings`;
	return httpClient.post({ url, body });
};
const putControlSettings = (body: any) => {
	const url = `${PROMOTIONS_URI}/promo-config/v1/control-settings`;
	return httpClient.put({ url, body });
};

export const promotionService = {
	getPromotionByStatusFilter,
	getPromotions,
	getPromotionPriorities,
	createPromotionPrio,
	putPromotionPrio,
	getPromotionList,
	patchPromotionPriority,
	patchPromotionStartDate,
	patchPromotionEndDate,
	patchPromotionStatus,
	createCouponCodes,
	getPriceList,
	searchUsersByQuery,
	searchProductsByQuery,
	getSellers,
	getBrands,
	getModels,
	getLocations,
	getControlSettings,
	postControlSettings,
	putControlSettings,
};
