import PrimaryLayout from '../../../layouts/primary-layout';
import { Tabs, Card } from 'antd';
import OrganizationInfo from './organization-info';
import UploadDocuments from './upload-documents';
import { useProfile } from '../../../hooks/use-profile';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import TenantConfiguration from './tenant-configuration';

interface ITenentProfileProps {}

const TenentProfile: React.FunctionComponent<ITenentProfileProps> = props => {
  const { TabPane } = Tabs;
  const { profileDetails, fetchProfileInfo } = useProfile();

  return (
    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card>
          <Tabs defaultActiveKey="1" type="card">
            <TabPane tab={<BoldButtonLabel labelText="Organization Details" />} key="1">
              <OrganizationInfo profileDetails={profileDetails} fetchProfileInfo={fetchProfileInfo} />
            </TabPane>
            <TabPane tab={<BoldButtonLabel labelText="Configuration" />} key="3">
              <TenantConfiguration profileDetails={profileDetails} />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </PrimaryLayout>
  );
};
export default TenentProfile;
