export const currencyConverter = (base: string, amount: number) => {};

export const conversionRates: Record<string, any> = {
  INR: {
    USD: 0.012,
    INR: 1,
    CLP: 10.83
  },
  USD: {
    INR: 82.81,
    USD: 1,
    CLP: 798.575
  },
  CLP: {
    INR: 0.092,
    CLP: 1,
    USD: 0.0012
  }
};

export const convertBaseToUserCurrency = (base: string, amount: number, userCurrency: string) => {
  return ((amount || 0) * conversionRates[base][userCurrency]).toFixed(2);
};

export const formatPriceToLocaleCurrency = ({
  amount,
  baseCurrency,
  userCurrency
}: {
  baseCurrency: string;
  userCurrency: string;
  amount: number;
}) => {
  const convertedAmount = (amount || 0) * conversionRates[baseCurrency][userCurrency];

  const formatAmount = convertedAmount < 0 ? 0 : convertedAmount;

  return new Intl.NumberFormat(currencyToIntlLocaleMap[userCurrency] || 'en-US', {
    style: 'currency',
    currency: userCurrency
  }).format(formatAmount);
};

export const priceHelpers = {
  formatPriceToLocaleCurrency
};

const currencyToIntlLocaleMap: Record<string, string> = {
  INR: 'en-IN',
  CLP: 'es-CL'
};

export const currencies = {
  INR: 'INR',
  USD: 'USD',
  CLP: 'CLP'
};

export const currencySymbolMap: Record<string, string> = {
  USD: '$',
  INR: '₹',
  CLP: '$'
};

export const localeList = [
  { label: '🇮🇳 IND', value: 'IND' },
  { label: '🇨🇱 CHL', value: 'CHL' }
];

export const localeEjjeList = [
  { label: '🇨🇱 CHL', value: 'CHL' }
];

export const localeToIntlLocaleMap: Record<string, string> = {
  IND: 'en-IN',
  CLP: 'es-CL'
};

export const currencyList = [
  { label: '🇮🇳 INR', value: 'INR', country: 'India' },
  { label: '🇨🇱 CLP', value: 'CLP', country: 'Chile' },
  { label: '🇺🇸 USD', value: 'USD', country: 'USA' }
];

export const localeCurrencyMap: Record<string, string> = {
  IND: 'INR',
  CHL: 'CLP'
};

export const phoneCountryCodeList = [
  { label: '🇮🇳 (+91)', value: '+91', title: 'India (+91)' },
  { label: '🇨🇱 (+56)', value: '+56', title: 'Chile (+56)' }
];

export const countryList = [
  { label: '🇮🇳 India', value: 'India' },
  { label: '🇨🇱 Chile', value: 'Chile' },
  {
    label: '🇺🇸 USA',
    value: 'USA'
  }
];

//!Final Configuration Object for country data
