import { Button, Card, Col, Form, Input, Row, Select, Spin, Table, TableColumnsType, Typography, Radio, InputNumber, RadioChangeEvent } from "antd"
import { TableRowSelection } from "antd/es/table/interface"
import React from "react"
import BoldButtonLabel from "../../../../components/BoldButtonLabel"
import { validationHelpers } from "../../../../helpers"
import _ from "../../../../helpers/lodash"
import { displayErrorNotifications, displayWarningMessage } from "../../../../helpers/toast.helpers"
import PrimaryLayout from "../../../../layouts/primary-layout"
import { promise } from "../../../../services/promise.service"
import { useLoader } from "../../../../stores/use-loader"
import { useProduct } from "../../hooks/drop-down-hooks/use-product"
import { productService } from "../../services"
import { DeleteOutlined } from "@ant-design/icons"
import CustomPagination from "../../../../components/custom-pagination"
import { objectHelpers } from '../../../../helpers';

interface IOMSPromise { }

const OMSPromise: React.FunctionComponent<IOMSPromise> = props => {
    const [OMSPromise] = Form.useForm()
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<any[]>([]);
    const [productResponse, setProductResponse] = React.useState({} as any);
    const [categoryData, setCategoryData] = React.useState(null as any)
    const [pdpData, setPdpData] = React.useState(null as any)
    const [currentPage, setCurrentPage] = React.useState(1);
    const [selectedSku, setSelectedSku] = React.useState({} as any)
    const [quantity, setQuantity] = React.useState<number>(1);

    const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

    const columns: TableColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'id',
            align: 'center',
            render(value: any, record: any, index: any) {
                return <>{record.article_basic_dtls?.name}</>;
            }
        }, {
            title: 'Category',
            dataIndex: 'id',
            align: 'center',
            render(value: any, record: any, index: any) {
                return <>{record.article_basic_dtls?.category}</>;
            }
        }, {
            title: 'SKU Id',
            dataIndex: 'id',
            align: 'center',
            render(value: any, record: any, index: any) {
                return <>{record?.sku_id}</>;
            }
        }
    ]

    React.useEffect(() => {
        handleOnSearchProducts()
    }, [])

    React.useEffect(() => {
        if (selectedSku && OMSPromise.getFieldsValue().country && OMSPromise.getFieldsValue().pin_code) {
            setPdpData(null);
            getPdpFullFilmentOptions();
        }

    }, [selectedSku])

    const handleOnSearchProducts = async (offset = 0) => {
        setLoading(true)
        const { category_name, product_name, sku } = OMSPromise.getFieldsValue()
        const bodyData = {
            name: product_name || "",
            categories: category_name,
            "sku_ids": sku
        }
        const formValues = OMSPromise.getFieldsValue();
        const filteredFormValues = { ...objectHelpers.deleteUndefinedValuesFromObject(formValues), offset: offset + '' };
        const params = {
            ...filteredFormValues,
            offset,
            limit: 10
        };
        const { data, errors } = await productService.getAllProductsFromCategoriesPromise(bodyData, params);
        if (_.isEmpty(errors)) {
            setProductResponse(data)
            // setCategoryData(null)
            // setPdpData(null)
            // if (data.products.length === 0) {
            //     handleOnRemove()
            // }
        } else {
            displayErrorNotifications(errors);
        }
        setLoading(false)
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        let currentSku = newSelectedRowKeys[0]
        setSelectedSku(currentSku)
        setSelectedRowKeys(newSelectedRowKeys);
        getCategoryDataNew(newSelectedRowKeys)
        setPdpData(null)
    };

    const rowSelection: TableRowSelection<any> = {
        type: 'radio',
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const getPdpBodyData = (data: any) => {
        let bodyData = {
            "channel": "ONLINE", //[ ONLINE, STORE ] - mandat
            "currency_code": OMSPromise.getFieldsValue().country, // - mandat
            "product": {
                "quantity": {
                    "number_of_units": quantity || '',//check - mandat
                    "unit_of_measure": categoryData?.article_basic_dtls?.unit_of_measure || '',//check - mandat
                    "unit_size": 1,//check - mandat
                    "unit_size_fraction": 1//check - mandat
                },
                "sku_id": selectedSku.sku_id // - mandat
            },
            "requested_by": "string",//check // - mandat
            "ship_to_address": {
                "address_id": "string",//check // - mandat
                "country": "string",//Not Mandat
                "country_code": OMSPromise.getFieldsValue().country, // - mandat
                "phone_numbers": [
                    {
                        "availability": "string",//Not Mandat
                        "country_code": OMSPromise.getFieldsValue().country,// - mandat
                        "number": "9876543210",// - mandat
                        "type": "PERSONAL"// - mandat
                    }
                ],
                "postal_code": OMSPromise.getFieldsValue().pin_code,// - mandat
                "postcode_id": OMSPromise.getFieldsValue().pin_code,//Not Mandat
            }
        }
        return bodyData
    }

    const getCategoryDataNew = async (selectedProduct: any) => {
        setLoading(true)
        const getArticleId = selectedProduct[0]?.article_id
        const { data, errors } = await promise.getQuantityOfCategory(getArticleId)
        if (_.isEmpty(errors)) {
            setCategoryData(data)
            setQuantity(data?.fulfillment_settings_config?.min_order_quantity)
            // getPdpFullFilmentOptions(data)
        } else {
            displayErrorNotifications(errors)
            setLoading(false)
        }
        setLoading(false)
    }

    const getPdpFullFilmentOptions = async () => {
        if (selectedRowKeys.length === 0) {
            displayWarningMessage({ message: 'Select a product to get Fullfilable Details ' })
        } else {
            setLoading(true)
            const bodyData = getPdpBodyData(categoryData)
            const { data, errors } = await promise.getPdpFulFilmentOption(bodyData)
            if (_.isEmpty(errors)) {
                setPdpData(data)
            } else {
                displayErrorNotifications(errors)
                setLoading(false)
            }
            setLoading(false)
        }
    }

    const handleOnClear = () => {
        OMSPromise.setFieldsValue({
            product_name: null,
            category_name: [],
            sku: [],
            pin_code: null,
            country: []
        })
        // setProductResponse({})
        setCategoryData(null)
        setPdpData(null)
    }

    const handleOnRemove = () => {
        setCategoryData(null)
        setPdpData(null)
    }

    const handlePageChange = (current_page: number) => {
        setCurrentPage(current_page);
    };

    const handlePageChangeNext = (current_page: number) => {
        setCurrentPage(current_page);
        handleOnSearchProducts(current_page - 1);
    };

    const handlePageChangePrevious = (current_page: number) => {
        setCurrentPage(current_page);
        handleOnSearchProducts(current_page - 1);
    };
    const handleVariantChange = (e: RadioChangeEvent) => {
        setSelectedSku({
            sku_id: e.target.value
        }
        )

    }
    const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        const min = categoryData?.fulfillment_settings_config?.min_order_quantity;
        const max = categoryData?.fulfillment_settings_config?.max_order_quantity;
        if (value && value < min) {
            value = min;
            displayWarningMessage({ message: `Quantity cannot be less than minimum of ${min}` })
        }

        if (value && max && value > max) {
            displayWarningMessage({ message: `Quantity cannot be more than maximum of ${max}` })
            value = max;
        }
        setQuantity(value || min)
        setPdpData(null)

    }

    const { debouncedFetchProductDropdownOptions,
        productDropDownFetching,
        productDropDownOptions } = useProduct();
    return (
        <PrimaryLayout>
            <div className="container mx-auto px-4">
                <Card>
                    <Form form={OMSPromise} layout="vertical" onFinish={() => getPdpFullFilmentOptions()}>
                        <Row justify={'space-between'} className="mt-4">
                            <Col>
                                <Typography.Title level={2} className="text-[#2e2a5b]">
                                    Promise
                                </Typography.Title>
                            </Col>
                        </Row>

                        <Row gutter={24} className="mt-4">
                            <Col xs={24} md={6}>
                                <Form.Item
                                    name="product_name"
                                    label="Product"
                                >
                                    <Input allowClear size="large" placeholder="Enter Product" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item
                                    name="category_name"
                                    label="Category"
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        mode="tags"
                                        size="large"
                                        filterOption={false}
                                        placeholder="Search Category"
                                        notFoundContent={productDropDownFetching ? <Spin size="small" /> : null}
                                        onSearch={async searchTerm => {
                                            if (searchTerm) await debouncedFetchProductDropdownOptions(searchTerm);
                                        }}
                                        options={productDropDownOptions}
                                    ></Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item
                                    name="sku"
                                    label="Sku"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Enter Sku"
                                        mode="tags"
                                        size="large"
                                        options={[]}
                                    ></Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={12}>
                            <Col xs={12} md={2}>
                                <Button type="primary" size="large" className="mb-4" onClick={() => handleOnSearchProducts(0)}>
                                    <BoldButtonLabel labelText="Search" />
                                </Button>
                            </Col>
                            <Col xs={12} md={2}>
                                <Button type="primary" size="large" className="mb-4" onClick={handleOnClear}>
                                    <BoldButtonLabel labelText="Clear All" />
                                </Button>
                            </Col>
                        </Row>
                        {productResponse?.skus?.length > 0 && <><Row gutter={24} className="mt-4">
                            <Col xs={24} md={6}>
                                <Typography.Title level={5} className="text-[#2e2a5b]">
                                    Products
                                </Typography.Title>
                            </Col>
                        </Row>
                            <Row>
                                {!_.isEmpty(productResponse?.skus) && (
                                    <Row className="my-2 w-full" justify={'end'} gutter={[12, 12]} align={'middle'}>
                                        <Col>
                                            <CustomPagination
                                                currentPage={currentPage}
                                                totalPages={Math.ceil(productResponse?.total_pages)}
                                                handleNext={handlePageChangeNext}
                                                handlePageChange={handlePageChange}
                                                handlePrevious={handlePageChangePrevious}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                <Table
                                    rowSelection={rowSelection}
                                    loading={false}
                                    pagination={false}
                                    bordered
                                    dataSource={productResponse?.skus || []}
                                    columns={columns}
                                    rowKey={(record: any) => record}
                                    scroll={{ x: 1000, y: 1000 }}
                                ></Table>
                            </Row>
                            <div className="flex flex-col md:flex-row justify-between mt-6">
                                <div className="md:w-[40%] xs:w-[100%]">
                                    {categoryData && <Card
                                        className="my-6"
                                        bodyStyle={{ padding: '.5rem' }}
                                        bordered
                                        title={<div className="text-center h-full">Selected Product Summary</div>}
                                    >
                                        <div className="flex flex-row-reverse">
                                            <DeleteOutlined
                                                className="text-red-500 text-xl"
                                                title="Delete"
                                                onClick={handleOnRemove}
                                            />
                                        </div>
                                        <section className="flex gap-4 bg-[#fafafa] px-4 py-4 rounded-lg">
                                            <div className="w-[100px]">
                                                <Typography.Text className="font-bold">Basic Details</Typography.Text>
                                            </div>
                                            <section className="flex flex-col gap-2">
                                                <div className="flex gap-2">
                                                    <div>
                                                        <Typography.Text className="text-[#898c98]">Name: </Typography.Text>
                                                    </div>
                                                    <div className="font-semibold">{categoryData?.article_basic_dtls?.name}</div>
                                                </div>
                                                <div className="flex gap-2">
                                                    <div>
                                                        <Typography.Text className="text-[#898c98]">Description: </Typography.Text>
                                                    </div>
                                                    <div className="font-semibold">{categoryData?.article_basic_dtls?.description}</div>
                                                </div>
                                                <div className="flex gap-2">
                                                    <div>
                                                        <Typography.Text className="text-[#898c98]">Category: </Typography.Text>
                                                    </div>
                                                    <div className="font-semibold">{categoryData?.article_basic_dtls?.category}</div>
                                                </div>
                                                <div className="flex gap-2">
                                                    <div>
                                                        <Typography.Text className="text-[#898c98]">Country Of Origin: </Typography.Text>
                                                    </div>
                                                    <div className="font-semibold">{categoryData?.article_basic_dtls?.country_of_origin}</div>
                                                </div>
                                            </section>
                                        </section>
                                        <section className="flex gap-4 bg-[#fafafa] px-4 py-4 rounded-lg">
                                            <div className="w-[100px]">
                                                <Typography.Text className="font-bold">Min Order Quantity</Typography.Text>
                                            </div>
                                            <section className="flex flex-col gap-2">
                                                <Typography.Text className="text-[#898c98]">Quantity: </Typography.Text><InputNumber placeholder="Quantity" value={quantity} onBlur={handleQuantityChange}></InputNumber>
                                                <div className="flex gap-2">
                                                    <div>
                                                        <Typography.Text className="text-[#898c98]">Unit Of Measure: </Typography.Text>
                                                    </div>
                                                    <div className="font-semibold">{categoryData?.article_basic_dtls?.unit_of_measure || ''}</div>
                                                </div>
                                            </section>
                                        </section>
                                        {categoryData?.has_variants && <section className="gap-4 bg-[#fafafa] px-4 py-4 rounded-lg max-h-500 overflow-auto">
                                            <div className="w-[100px]">
                                                <Typography.Text className="font-bold">Variants</Typography.Text>
                                            </div>
                                            <Radio.Group onChange={handleVariantChange} value={selectedSku.sku_id} className="w-full" >

                                                {categoryData?.variants?.map((item: any) => {
                                                    return (
                                                        <Card className="mb-2 hover:bg-gray-100" >
                                                            <Radio value={item?.sku_id} className="w-full">
                                                                <div className="flex gap-2">
                                                                    <div>
                                                                        <Typography.Text className="text-[#898c98]">SKU Id: </Typography.Text>
                                                                    </div>
                                                                    <div className="font-semibold">{item?.sku_id}</div>
                                                                </div>
                                                                <div className="flex gap-2">
                                                                    <div>
                                                                        <Typography.Text className="text-[#898c98]">Variant Name: </Typography.Text>
                                                                    </div>
                                                                    <div className="font-semibold">{item?.variant_name}</div>
                                                                </div>
                                                                <div className="flex gap-2">
                                                                    <div>
                                                                        <Typography.Text className="text-[#898c98]">Variant Description: </Typography.Text>
                                                                    </div>
                                                                    <div className="font-semibold">{item?.variant_description}</div>
                                                                </div>
                                                            </Radio>
                                                        </Card>
                                                    )
                                                })}
                                            </Radio.Group>
                                        </section>}
                                    </Card>}
                                </div>
                                <div className="md:w-[40%] xs:w-[100%]">
                                    {pdpData
                                        &&
                                        <Card className="my-6 "
                                            bodyStyle={{ padding: '.5rem' }}
                                            bordered
                                            title={<div className="text-center h-full">Fulfillable Options</div>}>
                                            {pdpData?.promise_groups?.map((data: any) => {
                                                let linesFulfillableOptions = data?.lines[0]?.fulfillable_state
                                                let promiseOption = data?.promise_options
                                                return linesFulfillableOptions ? (
                                                    <div>
                                                        {promiseOption.map((item: any) => {
                                                            return (
                                                                <section className="flex flex-col gap-2">
                                                                    <div className="flex gap-2">
                                                                        <div>
                                                                            <Typography.Text className="text-[#898c98]">Ship Node Name: </Typography.Text>
                                                                        </div>
                                                                        <div className="font-semibold">{item?.ship_node_name}</div>
                                                                    </div>
                                                                    <div className="flex gap-2">
                                                                        <div>
                                                                            <Typography.Text className="text-[#898c98]">Delivery Method: </Typography.Text>
                                                                        </div>
                                                                        <div className="font-semibold">{item?.delivery_method}</div>
                                                                    </div>
                                                                    <div className="flex gap-2">
                                                                        <div>
                                                                            <Typography.Text className="text-[#898c98]">Delivery Type: </Typography.Text>
                                                                        </div>
                                                                        <div className="font-semibold">{item?.delivery_speed}</div>
                                                                    </div>
                                                                    <div className="flex gap-2">
                                                                        <div>
                                                                            <Typography.Text className="text-[#898c98]">Delivery Charges: </Typography.Text>
                                                                        </div>
                                                                        <div className="font-semibold">{!item?.delivery_cost ? 'Free' : item?.delivery_cost?.amount}</div>
                                                                    </div>
                                                                    <div className="flex gap-2">
                                                                        <div>
                                                                            <Typography.Text className="text-[#898c98]">Estimated Delivery Date: </Typography.Text>
                                                                        </div>
                                                                        <div className="font-semibold">{new Date(item?.promise_delivery_date).toLocaleString()}</div>
                                                                    </div>
                                                                    <hr />
                                                                </section>
                                                            )
                                                        })}

                                                    </div>
                                                ) : (
                                                    <div>
                                                        No Options Available
                                                    </div>
                                                )
                                            })}
                                        </Card>}
                                </div>
                            </div>
                            <Row gutter={12} className="mt-6">
                                <Col xs={12} md={6} lg={12} xl={8} xxl={6}>
                                    <Form.Item
                                        name={'country'}
                                        label="Country"
                                        rules={[{ required: true, message: 'Country Field is required' }]}
                                    >
                                        <Select
                                            size="large"
                                            options={[
                                                { label: '🇮🇳 India', value: 'IN' },
                                                { label: '🇨🇱 Chile', value: 'CL' }
                                            ]}
                                            placeholder="Select Country"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        label="Pin Code"
                                        name="pin_code"
                                        rules={[
                                            { required: true, message: "This field can't be empty" },
                                            validationHelpers.getNumericValidatorForInput({})
                                        ]}
                                    >
                                        <Input size="large" placeholder="Pin Code" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col>
                                    <Button htmlType="submit" type="primary" size="large" className="mt-4">
                                        <BoldButtonLabel labelText="View Fulfillable Options" />
                                    </Button>
                                </Col>
                            </Row> </>}
                    </Form>
                </Card>
            </div>
        </PrimaryLayout>
    )
}

export default OMSPromise