import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import { Button, Card, Col, Form, Input, Row, Select, Typography } from 'antd';
import DmnModeler from 'dmn-js/lib/Modeler';
import TenantInfo from '../../../components/TenantIdInfo';
import { useRewardNames } from '../hooks/use-reward-names';

import '../styles/custom-create-point-conversion.scoped.css';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { useNavigate } from 'react-router-dom';
import { loyaltyService } from '../services/loyalty.service';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';

interface ICreatePointConversionRuleProps {}

const CreatePointConversionRule: React.FunctionComponent<ICreatePointConversionRuleProps> = props => {
  const { rewardNameSelectOptions } = useRewardNames();

  let modeller: any = null;

  const modellerRef = React.useRef<any>(null);

  const initialXML =
    '<?xml version="1.0" encoding="UTF-8"?>\r\n<definitions xmlns="https://www.omg.org/spec/DMN/20191111/MODEL/" id="definitions_07uqaar" name="definitions" namespace="http://camunda.org/schema/1.0/dmn" exporter="dmn-js (https://demo.bpmn.io/dmn)" exporterVersion="14.1.1">\r\n  <decision id="decision_11mjt9j" name="Point Conversion Rule">\r\n    <decisionTable id="decisionTable_0k1wdl7">\r\n      <input id="input1" label="Tier">\r\n        <inputExpression id="inputExpression1" typeRef="string">\r\n          <text>customer.tier_type</text>\r\n        </inputExpression>\r\n      </input>\r\n      <output id="output1" label="Factor" name="factor" typeRef="string" />\r\n    </decisionTable>\r\n  </decision>\r\n</definitions>';

  React.useEffect(() => {
    if (modeller === null) initializeDiagram();

    return () => {
      if (modeller !== null) {
        modeller.detach();
        modeller = null;
      }
    };
  }, []);
  const initializeDiagram = async () => {
    modeller = new DmnModeler({
      container: '#pointConversionCanvas'
    });
    modellerRef.current = modeller;

    try {
      const { warnings } = await modeller.importXML(initialXML);

      if (warnings.length) {
        console.log('import with warnings', warnings);
      } else {
        console.log('import successful');
      }
    } catch (err) {
      console.log('something went wrong:', err);
    }
  };

  const [loading, setLoading] = React.useState(false);

  const handleCreateConversionRule = async () => {
    setLoading(true);
    const { reward_id, name } = createForm.getFieldsValue();

    const { xml } = await modellerRef.current.saveXML();

    const request = {
      reward_id,
      xml,
      name
    };

    const { errors } = await loyaltyService.createPointRule(request);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Point conversion rule created' });
      navigate(`/loyalty/config/point-conversion?reward_id=${reward_id}&offset=0`);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const navigate = useNavigate();

  const [createForm] = Form.useForm();

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Point Conversion Rule
              </Typography.Title>
            </Col>
          </Row>
          <div className="flex gap-4">
            <TenantInfo />
            <Typography.Text className="text-[#898c98]">
              Reward Type - <span className="font-bold text-black">Points</span>{' '}
            </Typography.Text>
          </div>
          <section className="mt-4">
            <Form layout="vertical" form={createForm} onFinish={handleCreateConversionRule}>
              <>
                <Row gutter={12}>
                  <Col xs={24} md={6}>
                    <Form.Item
                      name="reward_id"
                      rules={[{ message: 'Reward name is required', required: true }]}
                      label="Reward Name"
                    >
                      <Select placeholder="Select Reward Type" size="large" options={rewardNameSelectOptions} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      rules={[{ message: 'Rule name is required', required: true }]}
                      name="name"
                      label="Conversion Rule Name"
                    >
                      <Input size="large" placeholder="Enter Rule Name" />
                    </Form.Item>
                  </Col>
                </Row>
                <div id="pointConversionCanvas" className="h-[400px]"></div>
                <Row gutter={12}>
                  <Col xs={24} md={8} lg={6}>
                    <Button loading={loading} block htmlType="submit" size="large" type="primary">
                      <BoldButtonLabel labelText={'Create'} />
                    </Button>
                  </Col>
                  <Col xs={24} md={8} lg={6}>
                    <Button block size="large" onClick={() => navigate(`/loyalty/config/point-conversion`)}>
                      <BoldButtonLabel labelText={'Cancel'} />
                    </Button>
                  </Col>
                </Row>{' '}
              </>
            </Form>{' '}
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CreatePointConversionRule;
