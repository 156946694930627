import { PROFILE_URI } from '../config/envConfig';
import httpClient from '../http/http.client';

export const getProfileDetails = () => {
  const url = `${PROFILE_URI}/profiles/v1/my`;
  return httpClient.get({ url });
};

export const getUserList = (params: any) => {
  const url = `${PROFILE_URI}/identity/v1/users`;
  return httpClient.get({ url, params });
};

export const editUserAddress = (request: any) => {
  const url = `${PROFILE_URI}/contacts/v1`;
  return httpClient.put({ url, body: request });
};

export const getTenantSecret = ({ businessGroupId, tenantId }: { tenantId: string; businessGroupId: string }) => {
  const url = `${PROFILE_URI}/identity/v1/client`;

  const headers = {
    'x-tenant-id': tenantId,
    'x-business-group-id': businessGroupId
  };
  return httpClient.get({ url, headers });
};
