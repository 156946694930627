import * as React from 'react';
import _ from '../../../helpers/lodash';
import { Card, Tabs, } from 'antd';
import PrimaryLayout from '../../../layouts/primary-layout';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import TabPane from 'antd/es/tabs/TabPane';
import PromotionSettings from './set-priority-promotion-settings'
import ControlSettings from './set-priority-control-settings'
interface ISetPriorityProps { }




const SetPriority: React.FunctionComponent<ISetPriorityProps> = props => {


  return (


    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card>
          <Tabs defaultActiveKey="1" type="card">
            <TabPane tab={<BoldButtonLabel labelText="Promotion Priority" />} key="1">
              <PromotionSettings />
            </TabPane>
            <TabPane tab={<BoldButtonLabel labelText="Control Settings" />} key="3">
              <ControlSettings />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </PrimaryLayout>

  );
};

export default SetPriority;





