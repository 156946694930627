import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import { Button, Card, Col, Form, Row, Select, Typography } from 'antd';
import BoldButtonLabel from '../../../components/BoldButtonLabel';

interface IAddSubscriptionPageProps {}

const AddSubscriptionPage: React.FunctionComponent<IAddSubscriptionPageProps> = props => {
  const [businessGroupList, setBusinsesGroupList] = React.useState([]);
  const [tenantList, setTenantList] = React.useState([]);

  const [subscriptionForm] = Form.useForm();

  const businessGroupListOptions: any = [];
  const tenantListOptions: any = [];

  return (
    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-2">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                New Subscription
              </Typography.Title>
            </Col>
          </Row>
          <div className="mt-4">
            <Form form={subscriptionForm}>
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item name="business_group_id">
                    <Select size="large" placeholder="Select Business Group ID" options={businessGroupListOptions} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="tenant_id">
                    <Select size="large" placeholder="Select Tenant ID" options={tenantListOptions} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>{' '}
          </div>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default AddSubscriptionPage;
