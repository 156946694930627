import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { Card, Col, Row, Typography, Form } from 'antd';
import SnnCreationForm from '../../components/snn-creation-form';
import { useLoader } from '../../../../stores/use-loader';
import { logisticService } from '../../services';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import _ from 'lodash';

interface ISnnCreateProps {}

const SnnCreate: React.FunctionComponent<ISnnCreateProps> = props => {
  const [createForm] = Form.useForm();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    createForm.setFieldsValue({
      is_active: true
    })
  },[])

  const createBodyData = (form: any) => {
    const {
      snn_name,
      node_group,
      shipping_charge,
      is_active,
      description,
      flat_charge_amount,
      currency,
      flat_charge_fraction,
      base_weight_in_kg,
      base_weight_charge_amount,
      base_weight_charge_fraction,
      additional_weight_in_kg,
      additional_weight_charge_amount,
      additional_base_weight_charge_fraction,
      slab_weight_conditions,
      order_price_conditions
    } = form;
    let shippingType = shipping_charge?.shipping_charge_type;
    let request = {};
    if (shippingType === 'FREE') {
      request = {
        description:description,
        snn_name: snn_name,
        node_group: node_group,
        shipping_charge: {
          name: shipping_charge.name,
          shipping_charge_type: shipping_charge.shipping_charge_type
        },
        is_active: is_active
      };
    } else if (shippingType === 'FLAT') {
      request = {
        description:description,
        snn_name: snn_name,
        node_group: node_group,
        shipping_charge: {
          name: shipping_charge.name,
          shipping_charge_type: shipping_charge.shipping_charge_type,
          flat_charge_config: {
            amount: parseInt(flat_charge_amount),
            currency: currency,
            fraction: parseInt(flat_charge_fraction)
          }
        },
        is_active: is_active
      };
    } else if (shippingType === 'BASE_WEIGHT') {
      request = {
        description:description,
        snn_name: snn_name,
        node_group: node_group,
        shipping_charge: {
          name: shipping_charge.name,
          shipping_charge_type: shipping_charge.shipping_charge_type,
          base_weight_charge_config: {
            additional_weight: {
              additional_weight_in_kg: additional_weight_in_kg,
              cost: {
                amount: additional_weight_charge_amount,
                currency: currency,
                fraction: additional_base_weight_charge_fraction
              }
            },
            base_weight: {
              base_weight_in_kg: base_weight_in_kg,
              cost: {
                amount: base_weight_charge_amount,
                currency: currency,
                fraction: base_weight_charge_fraction
              }
            }
          }
        },
        is_active: is_active
      };
    } else if (shippingType === 'SLAB_WEIGHT') {
      request = {
        description:description,
        snn_name: snn_name,
        node_group: node_group,
        shipping_charge: {
          name: shipping_charge.name,
          shipping_charge_type: shipping_charge.shipping_charge_type,
          slab_weight_slab_charge_config: getSlabWeightData(slab_weight_conditions, currency)
        },
        is_active: is_active
      };
    } else if (shippingType === 'ORDER_PRICE') {
      request = {
        description:description,
        snn_name: snn_name,
        node_group: node_group,
        shipping_charge: {
          name: shipping_charge.name,
          shipping_charge_type: shipping_charge.shipping_charge_type,
          order_price_slab_charge_config: getOrderPriceData(order_price_conditions, currency)
        },
        is_active: is_active
      };
    }

    return request;
  };

  const getSlabWeightData = (slabData: any, currency: any) => {
    let returnSlab = slabData?.map((data: any) => {
      return {
        cost: {
          amount: parseInt(data.cost),
          currency: currency,
          fraction: data.fraction
        },
        max_weight_in_kg: parseInt(data.max_weight_in_kg),
        min_weight_in_kg: parseInt(data.min_weight_in_kg)
      };
    });
    return returnSlab;
  };

  const getOrderPriceData = (orderPriceData: any, currency: any) => {
    let returnOrder = orderPriceData?.map((data: any) => {
      return {
        cost: {
          amount: parseInt(data.cost),
          currency: currency,
          fraction: parseInt(data.fraction)
        },
        currency: currency,
        max_price: parseInt(data.max_price),
        min_price: parseInt(data.min_price)
      };
    });
    return returnOrder;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const bodyData = createBodyData(createForm.getFieldsValue());
    const { errors } = await logisticService.createSNN(bodyData as ISnnPostRequest);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'SNN Created Successfully!' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row gutter={[24, 12]} align={'middle'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Create Source Node Network
              </Typography.Title>
            </Col>
          </Row>

          <SnnCreationForm formInstance={createForm} handleSubmit={handleSubmit} />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default SnnCreate;
