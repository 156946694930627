import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  TableColumnsType,
  Tooltip,
  Typography,
  UploadFile
} from 'antd';
import TenantInfo from '../../../../components/TenantIdInfo';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Dragger from 'antd/es/upload/Dragger';
import { CloudUploadOutlined, GiftOutlined } from '@ant-design/icons';
import { countryList } from '../../../../data/intl-data';
import { stateList } from '../../../../data';
import { ICustomer, ICustomerListingResponse } from '../../types/customer';
import CustomPagination from '../../../../components/custom-pagination';
import _ from '../../../../helpers/lodash';
import ActionButton from '../../../../components/ActionButton';
import { useLoader } from '../../../../stores/use-loader';
import { convertQueryStringToObj, fileHelpers, objectHelpers } from '../../../../helpers';
import dayjs from 'dayjs';
import { loyaltyService } from '../../services/loyalty.service';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import { RcFile } from 'antd/es/upload';
interface ICustomerListingPageProps {}

const CustomerListingPage: React.FunctionComponent<ICustomerListingPageProps> = props => {
  const navigate = useNavigate();

  const [uploadModalVisible, setUploadModalVisible] = React.useState(false);
  const [rewardModal, setRewardModal] = React.useState(false);
  const [rewardForm] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [rewardData, setRewardData] = React.useState([] as any);

  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [customerListingResponse, setCustomerListingResponse] = React.useState({} as ICustomerListingResponse);

  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    if (!_.isEmpty(queryStringObj)) {
      let formParams: any = { ...queryStringObj };
      if (queryStringObj.sign_up_date_from) {
        const date_range = [dayjs(queryStringObj.sign_up_date_from), dayjs(queryStringObj.sign_up_date_to)];
        formParams = { ...formParams, date_range };
      }
      searchForm.setFieldsValue(formParams);
    }
    const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
    await handleSearch(offset);
  };

  const rewardComplete = () => {};

  const columns: TableColumnsType<ICustomer> = [
    {
      title: 'Customer ID',
      align: 'center',
      render(value, record, index) {
        return <>{record.customer_id}</>;
      }
    },
    {
      title: 'Customer Name',
      align: 'center',
      render(value, record, index) {
        return <>{(record.first_name || '') + ' ' + (record.last_name || '')}</>;
      }
    },
    {
      title: 'Customer Type',
      align: 'center',
      render(value, record, index) {
        return <>{record.customer_type}</>;
      }
    },
    {
      title: 'Customer Tier',
      align: 'center',
      render(value, record, index) {
        return <>{record.member_type}</>;
      }
    },

    {
      title: 'Action',
      align: 'center',
      render(value, record, index) {
        return (
          <section className="flex justify-center items-center gap-4">
            <ActionButton
              action="CREATE_NEW_VERSION"
              onClick={() => {
                navigate(`/loyalty/customers/${record.customer_id}`);
              }}
              title="View & Edit Customer Details"
            />
            <Popconfirm title="Are you sure?" onConfirm={() => handleCustomerDelete(record.customer_id)}>
              <ActionButton tooltipPlacement="bottom" action="DELETE" title="Delete Customer" />
            </Popconfirm>
            <div>
              <Tooltip title={'Rewards'}>
                <GiftOutlined
                  style={{ color: '#5885af', fontSize: '20px' }}
                  onClick={() => handleOnClickReward(record.customer_id)}
                />
              </Tooltip>
            </div>
          </section>
        );
      }
    }
  ];

  const handleOnClickReward = async (customerId: string) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getReward(customerId);
    if (_.isEmpty(errors)) {
      setRewardData(data)
      setRewardModal(true);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleCustomerDelete = async (customerId: string) => {
    setLoading(true);

    const { errors } = await loyaltyService.deleteCustomer(customerId);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Customer deleted successfully!' });
      await loadInitialData();
    } else {
      displayErrorNotifications(errors);
    }

    setLoading(false);
  };

  const handleClickUploadCustomers = () => {
    setUploadModalVisible(true);
  };

  const handleDownloadTemplate = async () => {
    const { data, errors } = await loyaltyService.downloadSampleCustomerUploadFile();

    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'customer_upload_template', extension: '.xlsx' });
    }
  };

  const handleCustomerUpload = async () => {
    setLoading(true);
    if (_.isEmpty(fileList)) return;

    const formData = new FormData();
    formData.set('file', fileList[0] as RcFile);

    const { errors } = await loyaltyService.uploadCustomers(formData);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Customers uploaded successfully!' });

      loadInitialData();

      setUploadModalVisible(false);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    let params = { ...formValues, offset, limit: 10 };
    if (formValues.date_range) {
      let sign_up_date_from = formValues.date_range[0].format('YYYY-MM-DD');
      let sign_up_date_to = formValues.date_range[1].format('YYYY-MM-DD');
      delete params.date_range;
      params = { ...params, sign_up_date_from, sign_up_date_to };
    }
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);

    setSearchParams(filteredParams);
    const { data, errors } = await loyaltyService.searchCustomer(filteredParams);
    if (_.isEmpty(errors)) {
      setCustomerListingResponse(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handlePageChange = (currrentPage: number) => {
    handleSearch(currrentPage - 1);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Customers
              </Typography.Title>
            </Col>

            <Col>
              <div className="flex gap-4">
                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    const backUrl = window.location?.pathname + `?${searchParams.toString()}`;
                    const params = new URLSearchParams({ goBackTo: backUrl });
                    navigate(`/loyalty/customers/create?${params.toString()}`);
                  }}
                >
                  <BoldButtonLabel labelText="Create Customer" />
                </Button>
                <Button size="large" type="primary" onClick={handleClickUploadCustomers}>
                  <BoldButtonLabel labelText="Upload Customers" />
                </Button>
              </div>
            </Col>
          </Row>
          <TenantInfo />
          <section className="mt-4">
            <Form layout="vertical" onFinish={() => handleSearch(0)} form={searchForm}>
              <Row gutter={12} align={'middle'}>
                <Col xs={24} md={6}>
                  <Form.Item name="customer_id" label="Customer ID">
                    <Input size="large" placeholder="Customer ID" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="first_name" label="First Name">
                    <Input size="large" placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="last_name" label="Last Name">
                    <Input size="large" placeholder="Last Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="phone_number" label="Phone Number">
                    <Input size="large" placeholder="Phone Number" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="tier_type" label="Tier Type">
                    <Input size="large" placeholder="Tier Type" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="customer_type" label="Customer Type">
                    <Select size="large" placeholder="Customer Type" />
                  </Form.Item>
                </Col>{' '}
                <Col xs={12} md={6}>
                  <Form.Item name="country" label="Country">
                    <Select options={countryList} placeholder="Select Country" allowClear size="large" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, curr) => {
                      return prev.country !== curr.country;
                    }}
                  >
                    {form => {
                      const { country } = form.getFieldsValue();

                      const stateOptions = stateList[country] || [];

                      return (
                        <Form.Item name="state" label="State">
                          <Select allowClear size="large" placeholder="Select State" options={stateOptions} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>{' '}
                <Col xs={24} md={6}>
                  <Form.Item name="date_range" label="Sign Up Date">
                    <DatePicker.RangePicker size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Button htmlType="submit" type="primary" block size="large">
                    <BoldButtonLabel labelText="Search" />
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>{' '}
          <section className="mt-4">
            {!_.isEmpty(customerListingResponse.data) && (
              <div className="flex justify-end my-2">
                <CustomPagination
                  currentPage={customerListingResponse.page_info.current_page}
                  totalPages={customerListingResponse.page_info.total_pages}
                  handleNext={handlePageChange}
                  handlePageChange={handlePageChange}
                  handlePrevious={handlePageChange}
                />
              </div>
            )}{' '}
            <Table
              loading={false}
              pagination={false}
              bordered
              dataSource={customerListingResponse.data}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </Card>

        <Modal
          centered
          open={uploadModalVisible}
          onCancel={() => {
            setFileList([]);
            setUploadModalVisible(false);
          }}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>Upload Customers</Typography.Title>
            </div>
          }
        >
          <section className="flex flex-col justify-center">
            <Button type="link" className="mb-4" onClick={handleDownloadTemplate}>
              Download Sample File
            </Button>
            <Dragger
              beforeUpload={file => {
                setFileList([file]);
                return false;
              }}
              onRemove={() => {
                setFileList([]);
              }}
              fileList={fileList}
              name="file"
              accept={'.csv,.xlsx'}
            >
              <p>
                <CloudUploadOutlined className="text-9xl text-[#5885AF]" />
              </p>
              <p>Click or Drag Customers Excel File to upload</p>
            </Dragger>

            <Button
              disabled={_.isEmpty(fileList)}
              block
              size="large"
              type="primary"
              onClick={handleCustomerUpload}
              className="mt-4"
            >
              <BoldButtonLabel labelText="Upload" />
            </Button>
          </section>
        </Modal>

        <Modal
          centered
          open={rewardModal}
          onCancel={() => {
            setRewardModal(false);
          }}
          width={700}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>Rewards:</Typography.Title>
            </div>
          }
        >
          <Form form={rewardForm} layout="vertical" onFinish={rewardComplete}>
            {rewardData.length > 0 ? (
              <div style={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
                {rewardData.map((reward: any) => (
                  <Card className="flex flex-col mb-4">
                    <div className="flex gap-2">
                      <Typography className="font-bold">{'Title - '}</Typography> <Typography>{reward.title}</Typography>
                    </div>
                    <div className="flex gap-2">
                      <Typography className="font-bold">{'Coupon code - '}</Typography> <Typography>{reward.code}</Typography>
                    </div>
                  </Card>
                ))}
              </div>
            ) : (
              <Typography style={{fontSize:'18px'}} className='font-bold'>Sorry 😔 No Coupon Found</Typography>
            )}
          </Form>
        </Modal>
      </div>
    </PrimaryLayout>
  );
};

export default CustomerListingPage;
