import { Button, Card, Col, Form, Input, Row, Select, Table, TableColumnsType, Typography } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import TenantInfo from '../../../../components/TenantIdInfo';
import CustomPagination from '../../../../components/custom-pagination';
import { convertQueryStringToObj, objectHelpers } from '../../../../helpers';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useLoader } from '../../../../stores/use-loader';
import { AnalyticsService } from '../../services/Analytics.service';
import { IRfmlistingResp } from './types/rfm.s';

interface IRfmProps {}

const filterOptions = [
  { label: 'Customer', value: 'customer_id' },
  { label: 'RFM Category', value: 'rfm_category' }
];

const sortOptions = [
  { label: 'Customer', value: 'customer_id' },
  { label: 'Total sales', value: 'total_sale' },
  { label: 'Frequency', value: 'frequency' },
  { label: 'Most Recent Customer Order Date', value: 'most_recent_customer_order_date' },
  { label: 'Most Recent Order', value: 'most_recent_order_date' },
  { label: 'Recency', value: 'recency' },
  { label: 'Rfm Value', value: 'rfm_value' }
];

const RFM: React.FunctionComponent<IRfmProps> = props => {
  const [rfmForm] = Form.useForm();
  // const [viewRfm, setViewRfm] = useState(false);
  // const [rfmResponseModal, setRfmResponseModal] = useState(rfmDummyRespModal);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [rfmlistingResp, setRfmListingResp] = useState({} as IRfmlistingResp);
  const [categoryOptions, setCategoryOptions] = useState<any>([])

  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;

  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const columns: TableColumnsType<any> = [
    //IRfmTabelEnteries
    {
      title: 'Customer',
      align: 'center',
      render(value, record, index) {
        return <>{record.customer_id}</>;
      }
    },
    {
      title: 'Total Sales',
      align: 'center',
      render(value, record, index) {
        return <>{record?.total_sale}</>;
      }
    },
    {
      title: 'Avg Monetary Value',
      align: 'center',
      render(value, record, index) {
        return <>{record?.avg_monetary_value}</>;
      }
    },
    {
      title: 'Frequency',
      align: 'center',
      render(value, record, index) {
        return <>{record.frequency}</>;
      }
    },
    {
      title: 'Most Recent Customer Order Date',
      align: 'center',
      render(value, record, index) {
        return <>{record.most_recent_customer_order_date}</>;
      }
    },
    {
      title: 'Most Recent Order',
      align: 'center',
      render(value, record, index) {
        return <>{record?.most_recent_order_date}</>;
      }
    },
    {
      title: 'Recency',
      align: 'center',
      render(value, record, index) {
        return <>{record.recency}</>;
      }
    },
    {
      title: 'RFM Value Point',
      align: 'center',
      render(value, record, index) {
        return <>{record.rfm_point}</>;
      }
    },
    {
      title: 'Rfm Category',
      align: 'center',
      render(value, record, index) {
        return <>{record.rfm_category}</>;
      }
    },
    {
      title: 'Rfm Recency',
      align: 'center',
      render(value, record, index) {
        return <>{record.rfm_recency}</>;
      }
    },
    {
      title: 'Rfm Frequency',
      align: 'center',
      render(value, record, index) {
        return <>{record.rfm_frequency}</>;
      }
    },
    {
      title: 'Rfm Monetary',
      align: 'center',
      render(value, record, index) {
        return <>{record.rfm_monetary}</>;
      }
    },
    {
      title: 'Rfm Value',
      align: 'center',
      render(value, record, index) {
        return <>{record.rfm_value}</>;
      }
    }
  ];

  useEffect(() => {
    setIntialData();
    loadCategoryOptions()
  }, []);

  const handlePageChange = (current_page: number) => {
    setCurrentPage(current_page);
    const offsetValue = (current_page - 1) * 10;
    handleSearch(offsetValue);
  };

  const loadCategoryOptions = async() => {
    setLoading(true)
    const {data , errors} = await AnalyticsService.getCategoryData()
    if (_.isEmpty(errors)) {
      const outputArray = data?.value?.map((item: any) => ({ label: item.replaceAll('_', ' '), value: item }));
      setCategoryOptions(outputArray)
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  }

  const setIntialData = () => {
    rfmForm.setFieldsValue({
      order_by: 'desc'
    });
    handleSearch(0);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    let formValues = rfmForm.getFieldsValue();
    const sortKey = formValues?.order_by;
    const sortValue = formValues?.sort_by?.toString();
    const dataObject = {
      [sortKey]: sortValue,
      ...formValues
    };
    delete dataObject.order_by;
    delete dataObject.sort_by;
    let params = { ...dataObject, offset, limit: 10 };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);
    const { data, errors } = await AnalyticsService.getRfmData(filteredParams);
    if (_.isEmpty(errors)) {
      setRfmListingResp(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };


  const handleOnReset = () => {
    rfmForm.setFieldsValue({
      filterBy: null,
      filterValue: null
    });
    handleSearch(0);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Form layout="vertical" form={rfmForm} onFinish={() => handleSearch(0)}>
          <Card>
            <Row justify={'space-between'} className="mb-4">
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  Customer Profiling
                </Typography.Title>
              </Col>
              <Col>
                <Button type="primary">
                  <BoldButtonLabel labelText="Download" />
                </Button>
              </Col>
            </Row>
            <TenantInfo />
            <section className="mt-4">
              {' '}
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Filter By"
                    name="filterBy"
                  >
                    <Select allowClear size="large" placeholder="Select Policy" options={filterOptions}></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item noStyle shouldUpdate>
                    {form => {
                      const { filterBy } = rfmForm.getFieldsValue();
                      return filterBy === 'customer_id' ? (
                        <>
                          <Form.Item
                            label="Customer Name"
                            name="filterValue"
                            rules={[{ required: true, message: 'This field cannot be empty' }]}
                          >
                            <Input size="large" placeholder="customer name" />
                          </Form.Item>
                          <div className="flex gap-2">
                            <div>
                              <Button htmlType="submit" type="primary" size="large">
                                <BoldButtonLabel labelText="search" />
                              </Button>
                            </div>
                            <div>
                              <Button onClick={handleOnReset} size="large">
                                <BoldButtonLabel labelText="Reset" />
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : null;
                    }}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {form => {
                      const { filterBy } = rfmForm.getFieldsValue();
                      return filterBy === 'rfm_category' ? (
                        <>
                          <Form.Item
                            label="RFM Category"
                            name={'filterValue'}
                            className="mb-0"
                            rules={[{ required: true, message: 'This field cannot be empty' }]}
                          >
                            <Select
                              allowClear
                              size="large"
                              placeholder="RFM Category"
                              options={categoryOptions}
                            ></Select>
                          </Form.Item>
                          <div className="flex gap-2">
                            <div className="mt-4">
                              <Button htmlType="submit" type="primary" size="large">
                                <BoldButtonLabel labelText="search" />
                              </Button>
                            </div>
                            <div className="mt-4">
                              <Button onClick={handleOnReset} size="large">
                                <BoldButtonLabel labelText="Reset" />
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </section>
            <div className="mt-2">
              {' '}
              <Row gutter={12} className="my-4">
                <Col xs={24} md={6}>
                  {' '}
                  <Form.Item label="Sort By" name={'sort_by'} className="mb-0">
                    <Select mode="multiple" size="large" placeholder="Sort By" options={sortOptions}></Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  {' '}
                  <Form.Item label="Order By" name={'order_by'} className="mb-0">
                    <Select
                      size="large"
                      placeholder="Order By"
                      options={[
                        { label: 'Ascending order', value: 'asc' },
                        { label: 'Descending order', value: 'desc' }
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Row gutter={12}>
              <Button htmlType="submit" type="primary" size="large">
                <BoldButtonLabel labelText="Sort" />
              </Button>
            </Row>
            <section className="mt-4">
              {!_.isEmpty(rfmlistingResp?.data) && (
                <Row className="flex flex-row-reverse" gutter={12}>
                  <Col className="flex flex-row-reverse">
                    <div>
                      <CustomPagination
                        currentPage={rfmlistingResp?.page_info?.current_page}
                        totalPages={rfmlistingResp?.page_info?.total_pages}
                        handleNext={handlePageChange}
                        handlePageChange={handlePageChange}
                        handlePrevious={handlePageChange}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </section>
            <section className="mt-4">
              {' '}
              <Table
                loading={false}
                pagination={false}
                bordered
                dataSource={rfmlistingResp.data}
                columns={columns}
                scroll={{ x: 1000 }}
              ></Table>
            </section>
          </Card>
        </Form>
      </div>
    </PrimaryLayout>
  );
};

export default RFM;
