import React from 'react';

import _ from '../../../../helpers/lodash';
import { logisticService } from '../../services';

const cache: Record<string, any> = {};

export const useDnn = () => {
  const [dnnDropDownFetching, setDnnDropDownFetching] = React.useState(false);
  const [dnnDropDownOptions, setDnnDropDownOptions] = React.useState<{ label: string; value: string }[]>([]);

  const fetchDnnDropdownOptions = async (searchQuery: string) => {
    setDnnDropDownFetching(true);
    if (cache[searchQuery]) {
      const transformedResponse = transformResponse(cache[searchQuery]);
      setDnnDropDownOptions(() => {
        return [...transformedResponse];
      });
    } else {
      const { data, errors } = await logisticService.getDNNForDebounce(searchQuery);
      cache[searchQuery] = data;
      if (_.isEmpty(errors)) {
        const transformedResponse = transformResponse(data);
        setDnnDropDownOptions(() => {
          return [...transformedResponse];
        });
      }
    }
    setDnnDropDownFetching(false);
  };

  const transformResponse = (data: any) => {
    const dnndata = data?.dnns || [];
    return (dnndata || []).map(({ dnn_name, dnn_id }: { dnn_name: string; dnn_id: string }) => {
      return { label: `${dnn_name}`, value: dnn_id };
    });
  };

  const debouncedFetchDnnDropdownOptions = _.debounce(fetchDnnDropdownOptions, 1000);

  return {
    debouncedFetchDnnDropdownOptions,
    dnnDropDownFetching,
    dnnDropDownOptions
  };
};
