import newCBTIcon from '../assets/images/newIcon.png'
import cbt_background from '../assets/images/CBT_Background.png'
import BG_Home from '../assets/images/BG_Home.avif'
import login_wall from '../assets/images/login.svg'
import PlanetButton from '../assets/images/PlanetButton.svg'
import Helpicon from '../assets/images/Helpicon.svg'
import home_1 from '../assets/images/home_1.svg'
import home_p2_bg from '../assets/images/home_p2_bg.svg'
import home_r1 from '../assets/images/home_r1.svg'
import home_r2 from '../assets/images/home_r2.svg'
import home_r3 from '../assets/images/home_r3.svg'
import home_r4 from '../assets/images/home_r4.svg'

export const images = {
    newCBTIcon,
    cbt_background,
    BG_Home,
    login_wall,
    PlanetButton,
    Helpicon,
    home_1,
    home_p2_bg,
    home_r1,
    home_r2,
    home_r3,
    home_r4
}