import { Button, Card, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Statistic, Typography } from 'antd';
import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import { cbtProducts } from '../../../data/cbt-product';
import BoldButtonLabel from '../../../components/BoldButtonLabel';

import { priceHelpers } from '../../../data/intl-data';
import {} from '@ant-design/icons';
import { RightOutlined, CheckCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { IDomainPlanMap, Plan } from '../../../types/cbt-product.types';
import { cbtProductService } from '../../../services/cbt-product.service';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import { useLocale } from '../../../stores/use-locale';

interface IProductsPageProps {}

const ProductItem = ({
  label,
  domain,
  description,
  handleClickProduct,
  key,
  isSelected
}: {
  label: string;
  domain: string;
  description: string;
  key: string;
  handleClickProduct: (domain: string) => void;
  isSelected: boolean;
}) => {
  return (
    <div
      onClick={() => handleClickProduct(domain)}
      key={key}
      role="button"
      className={`min-h-[60px] border-b-solid border-slate-300 border-b-2 p-4 cursor-pointer`}
    >
      <div className="flex justify-between">
        <div>
          <span className="font-bold text-xl">{label}</span>
        </div>
        <div>{isSelected ? <CheckCircleOutlined size={24} style={{ color: 'green' }} /> : <RightOutlined />}</div>
      </div>
    </div>
  );
};

const PlansList = ({ plans, handleClickAddPlan }: { plans: Plan[]; handleClickAddPlan: () => void }) => {
  if (!plans.length) {
    return (
      <div className="text-center flex items-center flex-col gap-4 mt-8">
        <div>
          <span className="text-lg font-semibold">There are no plans available for the selected product!</span>
        </div>
        <Button type="primary" onClick={handleClickAddPlan} size="large">
          <BoldButtonLabel labelText="Add Plan"></BoldButtonLabel>
        </Button>
      </div>
    );
  }

  return (
    <section className="p-2">
      <div className="mb-4">
        <span className="text-2xl">Plans</span>
      </div>

      <div>
        {plans.map(plan => (
          <PlanItem plan={plan} />
        ))}
      </div>
    </section>
  );
};

const PlanItem = ({
  plan: { name, amount, currency, plan_feature, description, interval, trial_days, overage, api_limit, tax_percent }
}: {
  plan: Plan;
}) => {
  const { userCurrency } = useLocale(({ userCurrency }) => ({ userCurrency }));
  return (
    <>
      <section className="border-2 border-solid border-slate-300 p-4 rounded-lg">
        <Row justify={'space-between'}>
          <Col>
            <span className="text-xl font-semibold">{name}</span>
          </Col>
          <Col>
            <span className="text-xl font-semibold">
              {priceHelpers.formatPriceToLocaleCurrency({ userCurrency, amount, baseCurrency: currency })}
            </span>
          </Col>
        </Row>
        <Row justify={'space-between'}>
          <Col xs={16}>
            <span className="text-sm font-semibold text-[#898a8c]">{description}</span>
          </Col>
          <Col xs={8} className="text-right">
            <span className="text-sm font-semibold text-[#898a8c]">{interval}</span>
          </Col>
        </Row>
        <div className="mt-4">
          <span className="text-sm font-semibold">{trial_days} Day(s) Free trial</span>
          <span className="text-sm font-semibold"> | Overage - {overage}</span>
          <span className="text-sm font-semibold"> | API Limit - {api_limit}</span>
          <span className="text-sm font-semibold"> | Tax - {tax_percent} %</span>
        </div>

        <div className="mt-2">
          <div>
            <span className="font-semibold text-lg">Features</span>
          </div>
          {plan_feature.map(({ feature_name }) => {
            return (
              <li key={feature_name} className="text-[#898a8c] font-semibold">
                - {feature_name}
              </li>
            );
          })}
        </div>
      </section>
      <Divider type="horizontal" />
    </>
  );
};

const ProductsPage: React.FunctionComponent<IProductsPageProps> = props => {
  const handleClickProduct = async (domain: string) => {
    setCurrentActiveDomain(domain);
  };
  const [planForm] = Form.useForm();

  const [currentActiveDomain, setCurrentActiveDomain] = React.useState<string | null>('nebula');

  const [planModalVisible, setPlanModalVisible] = React.useState(false);

  const [domainPlanMap, setDomainPlanMap] = React.useState({} as IDomainPlanMap);

  React.useEffect(() => {
    planForm.setFieldsValue({ domain: currentActiveDomain });
  }, [planModalVisible, currentActiveDomain]);

  React.useEffect(() => {
    getDomainPlanMap();
  }, []);

  const getDomainPlanMap = async () => {
    const { data, errors } = await cbtProductService.getAllPlansGroupedByDomain();

    if (_.isEmpty(errors)) setDomainPlanMap(data);
    else displayErrorNotifications(errors);
  };

  const handleClickAddPlan = () => {
    setPlanModalVisible(true);
  };

  const planList: Plan[] = domainPlanMap[currentActiveDomain as string] || [];

  return (
    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card>
          <Row className="border-2 border-solid border-slate-300">
            <Col xs={24} md={8}>
              <div className="border-r-2 border-r-solid border-slate-300 h-full border-b-2">
                <div className="flex justify-between items-center border-b-solid border-slate-300 border-b-2">
                  <div className="px-4 py-4">
                    <span className="text-xl font-bold">All Products</span>
                  </div>
                </div>
                <div>
                  {cbtProducts.map(({ description, label, domain }) => {
                    return (
                      <ProductItem
                        isSelected={domain === currentActiveDomain}
                        handleClickProduct={handleClickProduct}
                        domain={domain}
                        description={description}
                        label={label}
                        key={label}
                      />
                    );
                  })}
                </div>
              </div>
            </Col>
            <Col xs={24} md={16}>
              <div className="h-full">
                <div className="flex justify-between items-center border-b-solid border-slate-300 border-b-2">
                  <div className="px-4 py-4">
                    <span className="text-xl font-bold">
                      {currentActiveDomain
                        ? `${currentActiveDomain.toLocaleUpperCase()}`
                        : `Select a product to view its details`}
                    </span>
                  </div>
                  <div className="mr-2">
                    <Button type="primary" onClick={handleClickAddPlan} size="large">
                      <BoldButtonLabel labelText="Add Plan +"></BoldButtonLabel>
                    </Button>
                  </div>
                </div>

                {currentActiveDomain ? (
                  <section className="p-2 h-[70vh] overflow-y-auto custom-scroll-bar">
                    {planList.length !== 0 && (
                      <Row gutter={16}>
                        <Col xs={12} md={4}>
                          <Card bordered>
                            <Statistic
                              title="Plans"
                              value={planList.length}
                              precision={0}
                              valueStyle={{ color: '#3f8600' }}
                            />
                          </Card>
                        </Col>
                      </Row>
                    )}

                    <PlansList handleClickAddPlan={handleClickAddPlan} plans={planList} />
                  </section>
                ) : (
                  <>
                    {/* <div className="h-full mt-8 text-center">
                      <div className="my-auto">
                        <span className="text-xl font-bold">Please select a product to view its available plans</span>
                      </div>
                    </div> */}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Card>
        <Modal
          title={<span className="text-2xl font-bold">Add Plan</span>}
          footer={false}
          open={planModalVisible}
          onCancel={() => setPlanModalVisible(false)}
        >
          <Form form={planForm} layout="vertical">
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item name="plan_name" label="Plan name">
                  <Input placeholder="Enter plan name" size="large" />
                </Form.Item>
              </Col>{' '}
              <Col xs={24} md={12}>
                <Form.Item name="description" label="Description">
                  <Input.TextArea placeholder="Enter description" size="large" />
                </Form.Item>
              </Col>{' '}
              <Col xs={24} md={12}>
                <Form.Item name="interval" label="Interval">
                  <Select
                    options={[{ label: 'Monthly', value: 'MONTHLY' }]}
                    className="w-full"
                    placeholder="Select plan interval"
                    size="large"
                  />
                </Form.Item>
              </Col>{' '}
              <Col xs={24} md={12}>
                <Form.Item name="interval_count" label="Interval Count">
                  <InputNumber className="w-full" placeholder="Select plan interval" size="large" />
                </Form.Item>
              </Col>{' '}
              <Col xs={24} md={12}>
                <Form.Item name="currency" label="Currency">
                  <Select className="w-full" placeholder="Select plan currency" size="large" />
                </Form.Item>{' '}
              </Col>{' '}
              <Col xs={24} md={12}>
                <Form.Item name="amount" label="Plan Amount">
                  <InputNumber className="w-full" placeholder="Select plan interval" min={0} size="large" />
                </Form.Item>
              </Col>{' '}
              <Col xs={24} md={12}>
                <Form.Item name="trial_days" label="Trial Days">
                  <InputNumber className="w-full" placeholder="Enter Trial Days" min={0} size="large" />
                </Form.Item>{' '}
              </Col>{' '}
              <Col xs={24} md={12}>
                <Form.Item name="taxable" label="Taxable?">
                  <Select
                    className="w-full"
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false }
                    ]}
                    placeholder="Select if taxable"
                    size="large"
                  />
                </Form.Item>{' '}
              </Col>{' '}
              <Col xs={24} md={12}>
                <Form.Item name="trial_days" label="Tax Percent">
                  <InputNumber className="w-full" placeholder="Enter Tax Percent" min={0} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="overage" label="Overage">
                  <InputNumber className="w-full" placeholder="Enter Overage" min={0} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="api_limit" label="API Limit">
                  <InputNumber className="w-full" placeholder="Enter API Limit" min={0} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="domain" label="Domain">
                  <Input placeholder="Enter domain" disabled size="large" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.List name="contacts">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => {
                        return (
                          <React.Fragment key={key}>
                            <div className="mb-4">
                              <span className="text-lg">Feature - {index + 1} </span>
                            </div>
                            <Row gutter={12}>
                              <Col xs={22}>
                                <Form.Item
                                  {...restField}
                                  rules={[{ required: true, message: 'Please enter plan feature' }]}
                                  name={[name, 'feature']}
                                  label={`Feature ${index + 1}`}
                                >
                                  <Input className="w-full" size="large" />
                                </Form.Item>
                              </Col>
                              <div className="ml-2 my-2 flex justify-center items-center">
                                <DeleteOutlined
                                  className="text-red-500 text-xl"
                                  title="Delete Contact"
                                  onClick={() => remove(name)}
                                />
                              </div>
                            </Row>
                          </React.Fragment>
                        );
                      })}
                      <Form.Item>
                        <Row justify={'start'}>
                          <Col xs={24}>
                            <Button block onClick={() => add()} icon={<PlusOutlined />}>
                              <BoldButtonLabel labelText="Add Feature" />
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
            <Row justify={'center'}>
              <Col xs={24} md={12}>
                <Button block type="primary">
                  <BoldButtonLabel labelText="Save" />
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </PrimaryLayout>
  );
};

export default ProductsPage;
